// @intent: Display a form for editing/adding carrier contacts
import * as React from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";
import useUpsertCarrierContactForm from "features/Carriers/hooks/useUpsertCarrierContactForm";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPhoneTextBox from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import { ICarrierContact } from "features/Carriers/types";
import CPButton from "components/shared/CPButton/CPButton";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import { CPText } from "components/base";

interface CarrierContactFormProps {
  carrierExId?: string;
  onSubmit: (contact: ICarrierContact) => void;
  onCancel?: () => void;
  initData?: ICarrierContact;
}

const CarrierContactForm: React.FC<CarrierContactFormProps> = (props) => {
  const form = useUpsertCarrierContactForm({
    ...props,
    resetFormOnSubmit: true,
  });

  return (
    <StackLayout orientation='vertical' gap={0}>
      <CPErrorMessage errorMsg={form.formError} />

      <StackLayout gap={10}>
        <CPTextBox
          {...form}
          name='name'
          value={form.values.name}
          label='Name'
          errorMsg={form.errors.name}
          required
          personNameCharacters
        />
      </StackLayout>
      <StackLayout gap={10}>
        <CPEmailTextBox
          {...form}
          name='email'
          value={form.values.email}
          label='Email'
          maxLength={150}
          errorMsg={
            form.errors.email ?? (form.emailNeedsVerified && !form.fraudChecked)
              ? `Contact needs verified before saving.`
              : ""
          }
        />
        <CPPhoneTextBox
          {...form}
          name='phone'
          value={form.values.phone}
          label='Phone'
          errorMsg={form.errors.phone}
        />
        <CPPhoneTextBox
          {...form}
          name='fax'
          value={form.values.fax}
          label='Fax'
          errorMsg={form.errors.fax}
        />
      </StackLayout>

      <StackLayout orientation='vertical' style={{ marginTop: 40 }} gap={15}>
        {form.showCarrierVerified && (
          <CPText color='success'>Carrier verified.</CPText>
        )}
        <StackLayout gap={10}>
          <CPButton
            size={"small"}
            fillMode={"outline"}
            onClick={() => {
              form.reset();
              if (props.onCancel) {
                props.onCancel();
              }
            }}
          >
            Reset
          </CPButton>

          {form.emailNeedsVerified === false && (
            <CPButton
              size={"small"}
              fillMode={"outline"}
              themeColor={"primary"}
              disabled={form.disableSubmitBtn}
              onClick={form.onSubmit}
            >
              {props.initData != null ? "Update" : "Add"}
            </CPButton>
          )}

          {form.emailNeedsVerified && (
            <CPButton
              size={"small"}
              fillMode={"outline"}
              themeColor={"primary"}
              onClick={form.onVerify}
              showLoader={form.isLoading}
            >
              Verify Contact
            </CPButton>
          )}
        </StackLayout>
      </StackLayout>
    </StackLayout>
  );
};

export default CarrierContactForm;
