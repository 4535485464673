// @intent: Provide common methods for autocomplete components
import * as React from "react";
import { ValueObject } from "../../common/types";

import {
  ComboBoxBlurEvent,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
} from "@progress/kendo-react-dropdowns";

import { useDebouncedCallback } from "use-debounce";
import {
  CPAutoCompleteCustomFilteredResponse,
  CPAutoCompleteDataItem,
  CPAutoCompleteResponse,
} from "../../components/types";
import http from "common/http";

export interface useVirtualAutoCompleteAdapterOptions {
  initData?: ValueObject[];
  value?: CPAutoCompleteDataItem;
  route: string;
  routeParams?: Object;
  dropdownItemLabel?: string;
  allowManualEntry?: boolean;
  label?: string;
  required?: boolean;
  onResponseFilter?: (
    result: CPAutoCompleteResponse
  ) => CPAutoCompleteCustomFilteredResponse;
  onBlur?: (event: ComboBoxBlurEvent) => void;
  onInputTextChange?: (value: string | undefined) => void;
  onChange: (selectedItem?: CPAutoCompleteDataItem) => void;
  errorMsg?: string;
  labelKey?: string;
  valueKey?: string;
}

const loadingItem: ValueObject = {
  label: "...Loading...",
  value: null,
  id: -1,
};
const PAGE_SIZE = 10;
const loadingData: ValueObject[] = [];
while (loadingData.length < PAGE_SIZE) {
  loadingData.push({ ...loadingItem });
}

const VirtualAutoCompleteItemModel = (
  data: CPAutoCompleteDataItem | undefined = {
    label: "",
    value: undefined,
  }
) => {
  return {
    ...data,
  };
};

const useVirtualAutoCompleteAdapter = (
  options: useVirtualAutoCompleteAdapterOptions
) => {
  const initData = options.initData ?? ([] as ValueObject[]);
  const [viewingRecords, setViewingRecords] = React.useState<ValueObject[]>(
    initData.slice()
  );
  const [total, setTotal] = React.useState(initData.length);
  const skipRef = React.useRef(0);
  const resultCache = React.useRef<ValueObject[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);

  const resetCache = () => {
    resultCache.current.length = 0;
  };

  const resetAll = React.useCallback(() => {
    resetCache();
    setTotal(0);
    skipRef.current = 0;
    setViewingRecords([]);

    // Value was reset by parent/consuming component.
    const searchBy = !options.value ? "" : searchText;
    setSearchText(searchBy);
  }, [options.value, searchText]);

  // On init only...
  // React.useEffect(() => {
  //   const searchText = options.value?.label ?? "";
  //   fetchData(searchText, skipRef.current);

  //   return () => {
  //     resetAll();
  //   };
  // }, []);

  const fetchData = useDebouncedCallback(
    async (searchText: string, skip: number) => {
      const mapResultsArray = (sourceArray: ValueObject[]) => {
        return sourceArray.map((x: ValueObject) => {
          const model = VirtualAutoCompleteItemModel();
          model.value = x;
          model.label = x[options.labelKey ?? ""] ?? "";

          return model;
        });
      };

      // Helper function to map api results to autocomplete data
      const extractResultsFromResponse = (
        apiResponse: ValueObject[] | ValueObject
      ) => {
        let results: { records: ValueObject[]; totalCount: number } = {
          records: [],
          totalCount: 0,
        };
        if (!apiResponse) {
          return results;
        }

        if (Array.isArray(apiResponse)) {
          results.records = apiResponse; // mapResultsArray(apiResponse);
          results.totalCount = results.records.length;

          return results;
        }

        Object.keys(apiResponse).forEach((key: string) => {
          if (["RESULTS", "RECORDS", "DATA"].indexOf(key.toUpperCase()) > -1) {
            const item = apiResponse[key];
            results.records = item; //mapResultsArray(item);
          } else if (
            ["TOTAL", "COUNT", "TOTALRECORDS"].indexOf(key.toUpperCase()) > -1
          ) {
            results.totalCount = apiResponse[key];
          }
        });

        return results;
      };

      // Call Api
      try {
        setIsLoading(true);
        const response = await http.fetchAsync(options?.route ?? "", {
          ...options?.routeParams,
          searchText,
          skip,
          take: PAGE_SIZE,
        });

        const extractedResults = extractResultsFromResponse(response);

        setIsLoading(false);

        let totalCount = extractedResults.totalCount;
        let records = mapResultsArray(extractedResults.records);

        records.forEach((item, i) => {
          resultCache.current[i + skip] = item;
        });

        if (options?.onResponseFilter) {
          const customResponse = options.onResponseFilter(extractedResults);

          records = customResponse.records;
          totalCount = customResponse.totalCount;
        }

        if (skip === skipRef.current) {
          setViewingRecords(records);
          setTotal(totalCount);
        }
      } catch (err) {
        console.error(err);
      }
    },
    300
  );

  React.useEffect(() => {
    if (openPopup) {
      fetchData(searchText, 0);
    }
  }, [openPopup, searchText, fetchData]);
  // This is the text input
  const onFilter = React.useCallback(
    async (event: ComboBoxFilterChangeEvent) => {
      const searchBy = event.filter.value;

      resetCache();

      if (options?.onInputTextChange && options?.allowManualEntry) {
        options.onInputTextChange(searchBy);
      }

      setSearchText(searchBy);
      setViewingRecords(loadingData);
      skipRef.current = 0;
      await fetchData(searchBy, 0);
    },
    [fetchData, options]
  );

  const shouldRequestData = React.useCallback(
    (skip: number) => {
      for (let i = 0; i < PAGE_SIZE; i++) {
        if (!resultCache.current[skip + i]) {
          return true;
        }
      }

      return false;
    },
    [resultCache]
  );

  const getCachedData = React.useCallback((skip: number) => {
    const items: ValueObject[] = [];
    for (let i = 0; i < PAGE_SIZE; i++) {
      items.push(resultCache.current[i + skip] ?? loadingItem);
    }

    return items;
  }, []);

  const onPaging = React.useCallback(
    (event: ComboBoxPageChangeEvent) => {
      const newSkip = event.page.skip;

      if (shouldRequestData(newSkip)) {
        fetchData(searchText, newSkip);
      }

      const data = getCachedData(newSkip);

      setViewingRecords(data);
      skipRef.current = newSkip;
    },
    [fetchData, shouldRequestData, searchText, getCachedData]
  );

  // This is the filter selection
  const onSelect = React.useCallback(
    (event: ComboBoxChangeEvent) => {
      const result = event.target.value;

      if (result && result["id"] === -1) {
        return;
      }

      if (!result) {
        if (options?.onChange) {
          options.onChange(undefined);
        }

        if (options?.onInputTextChange) {
          options.onInputTextChange(undefined);
        }
      } else {
        const mapped = VirtualAutoCompleteItemModel(result);

        if (options?.onChange) {
          options.onChange(mapped);
        }

        if (options?.onInputTextChange) {
          options.onInputTextChange(mapped.label);
        }
      }

      //setInputVal(result);
      setOpenPopup(false);
    },
    [options]
  );

  const onPopupOpen = React.useCallback(async () => {
    resetAll();

    setOpenPopup(true);
  }, [resetAll]);

  const onBlur = React.useCallback((event: ComboBoxBlurEvent) => {
    setOpenPopup(false);
  }, []);

  const showError = () => {
    if (options?.errorMsg) {
      return true;
    }

    if (options?.allowManualEntry) {
      return options.required && options.value == null;
    }

    return options?.required && options.value == null;
  };

  return {
    virtual: {
      pageSize: PAGE_SIZE,
      skip: skipRef.current,
      total: total,
    },
    opened: openPopup,
    data: viewingRecords,
    loading: isLoading,
    showError,
    onBlur,
    onOpen: onPopupOpen,
    onChange: onSelect,
    onPageChange: onPaging,
    onFilterChange: onFilter,
  };
};

export default useVirtualAutoCompleteAdapter;
