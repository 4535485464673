// @intent: Checks if carrier contact passes fraud check
import { useQuery } from "react-query";
import http from "../../../common/http";
import CarrierContactFraudVerification from "../types/CarrierContactFraudVerification";

interface useGetCarrierContactFraudCheckOptions {
  carrierExId?: string;
  email?: string;
}

const useGetCarrierContactFraudCheckQuery = (
  options: useGetCarrierContactFraudCheckOptions
) => {
  const query = useQuery(
    ["Carrier/CheckCarrierContactFraud", options.carrierExId, options.email],
    () => {
      return http.fetchAsync("CarrierFraud/GetCarrierContactFraudCheck", {
        carrierExId: options.carrierExId,
        email: options.email,
      });
    },
    {
      enabled:
        options.carrierExId != null &&
        options.email != null &&
        options.email.length > 0,
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  );

  return {
    refresh: query.refetch,
    fraudCheck: CarrierContactFraudVerification({
      ...query.data,
      checked: query.data != null,
      checkedEmail: options.email ?? "",
    }),
    isLoading: query.isLoading,
  };
};

export default useGetCarrierContactFraudCheckQuery;
