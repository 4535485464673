// @intent: Container for invoice search form and invoice search details grid.
import * as React from "react";
import InvoiceSearchCard from "../../../features/Invoices/components/InvoiceSearchCard/InvoiceSearchCard";
import InvoiceSearchGrid from "../../../features/Invoices/components/InvoiceSearchGrid";
import InvoiceSearchForm from "../../../features/Invoices/components/InvoiceSearchForm";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";
import InvoiceSearchNoResultNotice from "../../../features/Invoices/components/InvoiceSearchNoResultNotice";
import useCSSClassMethods from "hooks/common/useCSSClassMethods";
import CPSavedSearchModal from "components/shared/CPSavedSearchModal/CPSavedSearchModal";
import {
  ButtonClickTracking,
  PageVisitTracking,
  useCPTracking,
} from "analytics";
import CPPanel from "components/shared/CPPanel/CPPanel";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";
import CPButton from "components/shared/CPButton/CPButton";
import useUserStore from "state/stores/useUserStore";

const InvoiceSearchPage: React.FC = () => {
  const { trackingEventDelegate } = useCPTracking(PageVisitTracking());
  const startNewSearch = useInvoiceSearchStore((ctx) => ctx.startNewSearch);
  const { addClass, removeClass } = useCSSClassMethods();
  const reset = useInvoiceSearchStore((ctx) => ctx.resetStore);
  const showNoResults = useInvoiceSearchStore((ctx) => ctx.showNoResults);
  const allowCarrierMultiSelect = useUserStore(
    (ctx) => ctx.isInRole(["Carrier"]) === false
  );

  const searchForm = useInvoiceSearchStore((ctx) => ctx.searchForm);

  const showSaveSearchModal = useInvoiceSearchStore(
    (ctx) => ctx.showSaveSearchModal
  );
  const closeSaveSearchModal = useInvoiceSearchStore(
    (ctx) => ctx.closeSaveSearchModal
  );

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      addClass("cp-side-menu-content", "prevent-scroll");
    }

    return () => {
      isMounted = false;
      reset();
      removeClass("cp-side-menu-content", "prevent-scroll");
    };
  }, []);

  return (
    <CPPanel
      header='Invoice Payment Search'
      actionBtns={
        <CPButton
          className='btn-small'
          fillMode={"outline"}
          themeColor={"primary"}
          onClick={trackingEventDelegate(
            () => startNewSearch(),
            ButtonClickTracking("Search Invoice")
          )}
        >
          <CPFontAwesome icon='fa-solid fa-magnifying-glass' inline />
          Search Invoices
        </CPButton>
      }
      style={{ overflow: "hidden", height: "100%" }}
    >
      <InvoiceSearchCard>
        <InvoiceSearchForm
          initValues={searchForm}
          key={searchForm._uid}
          allowCarrierMultiSelect={allowCarrierMultiSelect}
        />
      </InvoiceSearchCard>

      {showNoResults ? <InvoiceSearchNoResultNotice /> : <InvoiceSearchGrid />}

      <CPSavedSearchModal
        pageName='Invoice Search'
        show={showSaveSearchModal}
        onClose={closeSaveSearchModal}
        searchToSave={searchForm}
      />
    </CPPanel>
  );
};

export default InvoiceSearchPage;
