import * as React from "react";
import { LinkProps } from "../../types";
import classNames from "classnames";
import "./LinkStyles.scss";

const Link: React.FC<LinkProps> = ({
  url,
  title,
  children,
  target = "_self",
  color,
  style,
  as,
  block,
  onClick,
}) => {
  as = as ?? "anchor";
  color = color ?? "info";

  const handleClick = () => {
    window.open(url, target);
  };

  return as === "anchor" ? (
    <a
      href={url}
      target={target}
      className={classNames("cp-link", color, { block: block })}
      style={style}
    >
      {title ?? children}
    </a>
  ) : (
    <button
      onClick={() => (onClick != null ? onClick() : handleClick())}
      className={classNames("cp-link", "as-button", color, { block: block })}
      style={style}
    >
      {title ?? children}
    </button>
  );
};

export default Link;
