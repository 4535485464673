// @intent: Display actionable items for ConfirmEmailPage

import CPButton from "components/shared/CPButton/CPButton";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface ConfirmEmailCardFooterActionsProps {
  showResend: boolean;
  emailVerified: boolean;
  onResendConfirmationEmail: () => void;
}

const ConfirmEmailCardFooterActions: React.FC<
  ConfirmEmailCardFooterActionsProps
> = (props) => {
  const navigate = useNavigate();

  if (props.showResend) {
    return (
      <CPButton
        onClick={() => props.onResendConfirmationEmail()}
        size={"large"}
        themeColor={"primary"}
        style={{ width: "100%" }}
      >
        Resend Confirmation Email
      </CPButton>
    );
  }

  if (props.emailVerified) {
    return (
      <CPButton
        onClick={() => navigate("/Login")}
        size={"large"}
        themeColor={"primary"}
        style={{ width: "100%" }}
      >
        Sign In
      </CPButton>
    );
  }

  return null;
};

export default ConfirmEmailCardFooterActions;
