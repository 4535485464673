// @intent: Get list of assigned carriers for a factoring company
import * as React from "react";
import { useQuery } from "react-query";
import http from "../../../common/http";
import { IAssignedCarrier } from "../types";
import AssignedCarrier from "../types/AssignedCarrier";

const useGetAssignedCarriersQuery = (initFCompanyExId?: string) => {
  const [fCompanyExId, setFCompanyExId] = React.useState<string | undefined>(
    initFCompanyExId
  );

  const query = useQuery(
    ["FactoringCompany/GetAssignedCarriers", fCompanyExId],
    () => {
      return http.fetchAsync("FactoringCompany/GetAssignedCarriers", {
        factoringCompanyExId: fCompanyExId,
      });
    },
    {
      enabled: fCompanyExId != null,
      select: (data) => {
        return data.map((x: IAssignedCarrier) => AssignedCarrier({ ...x }));
      },
    }
  );

  const execute = (factoringCompanyExId: string) => {
    setFCompanyExId(factoringCompanyExId);
  };

  const clear = () => {
    setFCompanyExId(undefined);
    query.remove();
  };
  return {
    assignedCarriers: query.data ?? [],
    clear,
    execute,
    isLoading: query.isLoading,
    reload: query.remove,
  };
};

export default useGetAssignedCarriersQuery;
