// @intent: Methods for handling interactions for carriers grid

import * as React from "react";
import {
  useCPGridSavedSearches,
  useCPVirtualGridAdapter,
} from "components/shared/CPGrid";
import useAppStore from "state/stores/useAppStore";
import useUserStore from "state/stores/useUserStore";
import useSelectableOptions from "components/shared/CPGrid/Hooks/useSelectableOptions";
import { CPGridColumn, CPGridState } from "components/shared/CPGrid/types";
import LocalDataAdapter from "common/data/localDataAdapter";

const useCarriersGridMethods = (gridName: string) => {
  const isInRole = useUserStore((ctx) => ctx.isInRole);
  const canEditCarrier = isInRole(["Admin"]);

  const [openEditContactsModal, setOpenEditContactsModal] =
    React.useState(false);
  const userId = useUserStore((user) => user.userId);
  const toast = useAppStore((app) => app.setToastMsg);
  const { savedSearch, saveSearch } = useCPGridSavedSearches({
    route: "UserProfile/SavedSearchFilters",
    defaultRouteParams: {
      url: window.location.pathname,
      userId,
      name: gridName,
    },
  });

  const columns: CPGridColumn[] = [
    { field: "id", title: "", hide: true, pinned: true }, // pinned and hide prevents it from showing in the reorder modal
    { field: "name", title: "Name", width: 350 },
    { field: "dotNumber", title: "DOT Number" },
    {
      field: "mcNumber",
      title: "MC Number",
    },
    { field: "address", title: "Address", width: 400 },
    { field: "city", title: "City" },
    {
      field: "state",
      title: "State",

      filterType: "dropdownlist",
      dropdownListOptions: LocalDataAdapter("STATE_PROVINCE").map(
        (x) => x.abbr
      ),
    },
    { field: "zipCode", title: "Zip" },
  ];

  const columnsToUse = savedSearch?.columns ?? columns;

  const gridAdapter = useCPVirtualGridAdapter({
    columns: columnsToUse,
    route: "Carrier/SearchCarriersForGrid",
    setGridState: savedSearch,
  });

  const selectableOptions = useSelectableOptions({
    dataItemKey: "id",
    selectedField: "selected",
    data: gridAdapter.records.data,
    columns: gridAdapter.gridState.columns,
  });

  const onSaveSearch = (gridState?: CPGridState) => {
    const state = { ...gridAdapter.gridState, ...gridState };
    saveSearch(state, {
      onComplete: () => toast("Search saved successfully."),
    });
  };

  const handleClose = () => {
    setOpenEditContactsModal(false);

    gridAdapter.refresh();
  };

  return {
    selectableOptions,
    onSaveSearch,
    gridAdapter,
    closeModal: handleClose,
    canEditCarrier,
    columns: canEditCarrier
      ? selectableOptions.columns
      : gridAdapter.gridState.columns,
    openEditContactsModal: openEditContactsModal,
    setOpenEditContactsModal,
    selectedCarrierExId: selectableOptions.selectedItem?.externalId,
  };
};

export default useCarriersGridMethods;
