import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, CPText, Container } from "../../base";
import AppNavBar from "../CPAppNavBar/CPAppNavBar";
import CPSideMenu from "../CPSideMenu/CPSideMenu";
import CPAppActionBar from "../CPAppActionBar/CPAppActionBar";
import { Ripple } from "@progress/kendo-react-ripple";

import useUserStore from "../../../state/stores/useUserStore";
import CPLoader from "../CPLoader/CPLoader";
import useAppStore from "../../../state/stores/useAppStore";
import "./CPLoggedInLayoutStyles.scss";
import CPCacheBusterModal from "../CPCacheBusterModal/CPCacheBusterModal";
import useCacheBuster from "hooks/common/useCacheBuster";
import CP2faModal from "../CP2faModal/CP2faModal";
import CPConfirmModal from "../CPConfirmModal/CPConfirmModal";

const CPLoggedInLayout: React.FC = () => {
  const { needsUpdated, updateApp, checkingVersion } = useCacheBuster();
  const navigate = useNavigate();
  const isLoading = useAppStore((app) => app.isLoading);
  const userInitialized = useUserStore((user) => user.userInitialized);
  const menus = useUserStore((user) => user.menus);
  const nameInitials = useUserStore((user) => user.nameInitials);
  const menuItems = React.useMemo(() => {
    const items = menus
      .filter((x) => (x.hideFromMenu ?? false) === false)
      .map((x) => ({
        text: x.name,
        selected: false,
        route: x.url,
        icon: x.icon,
      }));

    const profileMenu = {
      text: nameInitials,
      route: "/MyProfile",
      icon: "",
      selected: false,
      render: () => (
        <Box
          className='cp-username-menu-item'
          htmlAttributes={{ title: "My Profile" }}
        >
          <CPText
            align='center'
            font={"montserrat"}
            style={{ fontSize: "14px" }}
          >
            {nameInitials}
          </CPText>
        </Box>
      ),
    };

    const logoutMenu = {
      text: "Logout",
      route: "/Logout",
      icon: "fa-solid fa-arrow-right-from-bracket",
      selected: false,
    };

    return [profileMenu, ...items, logoutMenu];
  }, [menus, nameInitials]);

  const handleMenuSelection = (route: string) => {
    navigate(route ?? "/");
  };

  if (!userInitialized) {
    return null;
  }

  return (
    <Ripple>
      <AppNavBar secondaryContent={<CPAppActionBar />} />
      <CPCacheBusterModal
        onConfirm={updateApp}
        show={needsUpdated && isLoading === false}
      />
      <CPLoader isLoading={isLoading} />

      <Container className='cp-app-main'>
        <CPSideMenu
          expandMenu={false}
          onMenuClick={handleMenuSelection}
          menuItems={menuItems}
        >
          <Outlet />
        </CPSideMenu>
      </Container>

      <CPConfirmModal />
      <CP2faModal />
    </Ripple>
  );
};

export default CPLoggedInLayout;
