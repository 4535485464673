import {
  CPDataType,
  CPTrackingActionType,
  ICPTrackingData,
} from "analytics/types";
import dayjs from "dayjs";
import { v4 as Guid } from "uuid";

export const EventTracking = (
  eventId: string,
  eventAction: CPTrackingActionType,
  data?: CPDataType
) => {
  return {
    _uid: Guid(),
    eventCategory: "event",
    eventAction,
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};
