// @intent: Submit a request to send a forgot password reset link

import http from "common/http";
import { useMutation } from "react-query";

const useSendForgotPasswordCommand = () => {
  const command = useMutation((email: string) => {
    return http.postAsync("Account/ForgotPassword", { email });
  });

  return command;
};

export default useSendForgotPasswordCommand;
