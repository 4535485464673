// @intent: Display a modal for user to input a name for saving searches
import { StackLayout } from "@progress/kendo-react-layout";
import * as React from "react";
import { ValueObject } from "common/types";
import useSaveSearchForm from "hooks/common/useSaveSearchForm";
import { CPText } from "components/base";
import CPAutoComplete from "../CPAutoComplete/CPAutoComplete";
import CPModal from "../CPModal/CPModal";
import CPButton from "../CPButton/CPButton";
import { ButtonClickTracking, useCPTracking } from "analytics";

interface CPSavedSearchModalProps {
  pageName: string;
  searchToSave: ValueObject;
  show?: boolean;
  onClose?: () => void;
}

const CPSavedSearchModal: React.FC<CPSavedSearchModalProps> = (props) => {
  const { trackingEventDelegate } = useCPTracking();
  const form = useSaveSearchForm(
    props.pageName,
    props.searchToSave,
    props.onClose
  );

  return (
    <CPModal
      open={props.show}
      title={"Save Search"}
      onClose={props.onClose}
      width={500}
      actionBtns={
        <CPButton
          themeColor={"primary"}
          disabled={form.disableSaveBtn}
          onClick={trackingEventDelegate(
            () => form.saveSearch(),
            ButtonClickTracking("Save Search", {
              page: props.pageName,
              search: props.searchToSave,
            })
          )}
        >
          Save
        </CPButton>
      }
    >
      <StackLayout orientation='vertical' gap={20}>
        <CPAutoComplete
          data={form.searches}
          name='invoiceSaveSearchList'
          label='Enter new name or update a previous search.'
          onChange={form.handleChange}
          labelKey='searchName'
        />
      </StackLayout>

      <CPText
        hidden={!form.showWarning}
        color='warning'
        transform='uppercase'
        textStyle='caption'
      >
        Notice: This will override the current search
      </CPText>
    </CPModal>
  );
};

export default CPSavedSearchModal;
