// @intent: Display a list of checkbox options to show/hide grid columns
import * as React from "react";
import { CPGridColumn } from "../types";
import CPSelectList, {
  CPSelectListItem,
} from "components/shared/CPSelectList/CPSelectList";
import { ValueObject } from "common/types";

interface CPShowHideListProps {
  columns: CPGridColumn[];
  onUpdate: (data: ValueObject) => void;
}

const CPShowHideList: React.FC<CPShowHideListProps> = (props) => {
  const data = props.columns
    .filter((x) => !x.pinned)
    .map((x) => ({ text: x.title, checked: !x.hide }));

  const handleSelect = (selectedItem: CPSelectListItem) => {
    const item = props.columns.find((item) => item.title === selectedItem.text);

    if (item) {
      props.onUpdate({ ...item, hide: !item.hide });
    }
  };

  return <CPSelectList data={data} onSelect={handleSelect} />;
};

export default CPShowHideList;
