// @intent: Global app state for handling Application specific state

import { create } from "zustand";
import { toast } from "react-toastify";

interface AppState {
  isLoading: boolean;
  showMenu: boolean;
}

interface AppStateActions {
  setIsLoading: (val: boolean) => void;
  setToastMsg: (
    message: string,
    type?: "success" | "error" | "info" | "warn"
  ) => void;
  toggleMenu: () => void;
}

const useAppStore = create<AppState & AppStateActions>()((set, get) => ({
  isLoading: false,
  showMenu: false,
  toggleMenu: () => set(() => ({ showMenu: !get().showMenu })),
  setIsLoading: (val: boolean) => set(() => ({ isLoading: val })),
  setToastMsg: (
    message: string,
    type?: "success" | "error" | "info" | "warn"
  ) => {
    type = type ?? "info";

    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: type === "error" ? 6000 : 3000,
    });
  },
}));

export default useAppStore;
