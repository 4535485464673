import * as React from "react";

import { Box, Container } from "../../base";
import useTrackAnalytics from "hooks/common/useTrackAnalytics";

interface CPAppLayoutProps {
  children?: React.ReactNode;
}

const CPAppLayout: React.FC<CPAppLayoutProps> = ({ children }) => {
  useTrackAnalytics();

  return (
    <Box className='cp-app-main'>
      <Container className='page-container'>{children}</Container>
    </Box>
  );
};

export default CPAppLayout;
