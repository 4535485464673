import * as React from "react";
import ReactDOM from "react-dom/client";
import "./themes/kendo-theme-custom.scss";
import "./App.scss";
import Router from "./router/Router";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isLocal } from "./common/config";
import CPAppLayout from "./components/shared/CPAppLayout/CPAppLayout";
import { ToastContainer, toast } from "react-toastify";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (query.meta != null && query.meta.errorMessage) {
        toast.error(query.meta.errorMessage as string);
      }
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CPAppLayout>
        <Router />
      </CPAppLayout>
      <ToastContainer />
      {isLocal && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
