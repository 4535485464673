// @intent: Utility methods for working with http requests

import axios, {
  AxiosHeaders,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { apiURL, accessTokenName } from "./config";
import { getValueFromCookie } from "./helpers";
import { ValueObject } from "./types";

interface HttpClientOptions {
  baseURL: string;
  timeout?: number;
  headers?: AxiosHeaders;
}

export { AxiosError as ResponseError };

// Example using middleware:
// In your module that you imported the singleton instance of 'http',
// call the addResponseMiddleware function, add the fulfilled / rejected
// callback functions. (rejected function is optional.)
//
// Be sure to return a promise like so:
//
//  http.addResponseMiddleware(
//    (val) => {
//      ...do work here
//      ...can return a different value in your promise
//
//      return Promise.resolve(val);
//    },
//    (err) => {
//      return Promise.reject(err);
//    }
//  );

type HttpResponseMiddleware = {
  onFulfilled: (
    response: AxiosResponse<any, any>
  ) => Promise<AxiosResponse<any, any>>;
  onRejected?: (error: any) => Promise<any>;
};

type HttpRequestMiddleware = {
  onFulfilled: (
    value: InternalAxiosRequestConfig
  ) => Promise<InternalAxiosRequestConfig<any>>;
  onRejected?: (error: any) => Promise<any>;
};

export function HTTPClient({
  baseURL,
  timeout = 4 * 60 * 1000,
  headers,
}: HttpClientOptions) {
  let accessToken: string = getValueFromCookie(accessTokenName, "token");

  const responseMiddleware: HttpResponseMiddleware[] = [];
  const requestMiddleware: HttpRequestMiddleware[] = [];

  const createAxiosInstance = (options?: ValueObject) => {
    return axios.create({
      baseURL,
      timeout,
      headers: {
        ...headers,
        Authorization: "Bearer " + accessToken,
      },
      withCredentials: true,
      ...options,
    });
  };

  const http = createAxiosInstance();

  // Return friendlier data / errors
  http.interceptors.response.use(
    function (response) {
      return Promise.resolve(response);
    },
    function (error) {
      const status = error.status ?? (error.request && error.request.status);
      if ([401, 403].indexOf(status) > -1) {
        return Promise.reject(error);
      }

      if (
        [307].indexOf(status) > -1 &&
        error.response &&
        error.response.headers["redirect-to"]
      ) {
        window.location.href = error.response.headers["redirect-to"];
      }

      const cleanError = cleanUpResponseError(error);
      return Promise.reject(cleanError);
    }
  );

  const cleanUpResponseError = (error: AxiosError) => {
    const responseErr = error && error.response ? error.response.data : null;

    return (responseErr ?? `An error occurred during request.`) as string;
  };

  const addResponseMiddleware = (
    onFulfilled: HttpResponseMiddleware["onFulfilled"],
    onRejected?: HttpResponseMiddleware["onRejected"]
  ) => {
    responseMiddleware.push({
      onFulfilled,
      onRejected,
    });

    applyResponseMiddleware();
  };

  const applyResponseMiddleware = () => {
    const fulfilledMiddlewares = buildResponseMiddlewarePipeline(
      "onFulfilled",
      responseMiddleware.filter((x) => x.onFulfilled)
    );

    const rejectdMiddlewares = buildResponseMiddlewarePipeline(
      "onRejected",
      responseMiddleware.filter((x) => x.onRejected)
    );

    http.interceptors.response.use(fulfilledMiddlewares, rejectdMiddlewares);
  };

  const addRequestMiddleware = (
    onFulfilled: HttpRequestMiddleware["onFulfilled"],
    onRejected?: HttpRequestMiddleware["onRejected"]
  ) => {
    requestMiddleware.push({
      onFulfilled,
      onRejected,
    });

    applyRequestMiddleware();
  };

  const applyRequestMiddleware = () => {
    const fulfilledMiddlewares = buildRequestMiddlewarePipeline(
      "onFulfilled",
      requestMiddleware.filter((x) => x.onFulfilled)
    );

    const rejectdMiddlewares = buildRequestMiddlewarePipeline(
      "onRejected",
      requestMiddleware.filter((x) => x.onRejected)
    );

    http.interceptors.request.use(fulfilledMiddlewares, rejectdMiddlewares);
  };

  const buildResponseMiddlewarePipeline = (
    type: "onFulfilled" | "onRejected",
    middlewares: HttpResponseMiddleware[] = []
  ) => {
    return middlewares.reduceRight(
      (next, middleware) => async (response: AxiosResponse<any, any>) => {
        try {
          let updatedResponse = response;
          if (type === "onRejected" && middleware.onRejected == null) {
            return next(updatedResponse);
          }

          updatedResponse =
            type === "onRejected" && middleware.onRejected != null
              ? await middleware.onRejected(response)
              : await middleware.onFulfilled(response);
          return next(updatedResponse);
        } catch (err) {
          return Promise.reject(response);
        }
      },
      (response: AxiosResponse) => Promise.resolve(response)
    );
  };

  const buildRequestMiddlewarePipeline = (
    type: "onFulfilled" | "onRejected",
    middlewares: HttpRequestMiddleware[] = []
  ) => {
    return middlewares.reduce(
      (next, middleware) =>
        async (request: InternalAxiosRequestConfig<any>) => {
          try {
            let updatedRequest = request;

            if (type === "onRejected" && middleware.onRejected == null) {
              return next(updatedRequest);
            }

            updatedRequest =
              type === "onRejected" && middleware.onRejected != null
                ? await middleware.onRejected(updatedRequest)
                : await middleware.onFulfilled(updatedRequest);
            return next(updatedRequest);
          } catch (err) {
            return next(request);
          }
        },
      (request: InternalAxiosRequestConfig) => Promise.resolve(request)
    );
  };

  const setAuthHeader = (token: string) => {
    http.defaults.headers.Authorization = "Bearer " + token;
    accessToken = token;
  };

  const getUrlParams = (data = {}) => {
    return new URLSearchParams(data).toString();
  };

  const fetchAsync = async (
    url: string,
    params = {},
    dontAddBaseURL = false
  ) => {
    if (params && Object.keys(params).length) {
      const { data } =
        (await http.get(
          (dontAddBaseURL ? url : apiURL + url) + "?" + getUrlParams(params)
        )) || {};

      return data;
    }

    const { data } =
      (await http.get(dontAddBaseURL ? url : apiURL + url)) || {};
    return data;
  };

  const postAsync = async (
    url: string,
    params = {},
    dontAddBaseURL = false
  ) => {
    const { data } =
      (await http.post(dontAddBaseURL ? url : apiURL + url, {
        ...params,
      })) || {};
    return data;
  };

  const putAsync = async (url: string, params = {}, dontAddBaseURL = false) => {
    const { data } =
      (await http.put(dontAddBaseURL ? url : apiURL + url, {
        ...params,
      })) || {};
    return data;
  };

  const deleteAsync = async (
    url: string,
    id: number,
    dontAddBaseURL = false
  ) => {
    const sendUrl = dontAddBaseURL ? url : apiURL + url;
    const { data } = (await http.delete(sendUrl + "/" + id)) || {};
    return data;
  };

  return {
    setAuthHeader,
    fetchAsync,
    postAsync,
    deleteAsync,
    putAsync,
    getInstance: () => http,
    createAxiosInstance,
    addResponseMiddleware,
    addRequestMiddleware,
  };
}

const instance = HTTPClient({
  baseURL: apiURL,
});

export const { setAuthHeader, createAxiosInstance } = instance;

export default instance;
