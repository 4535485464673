// @intent: module for holding config settings

const host = (window.location.host && window.location.host.toUpperCase()) || "";
export const isLocal = host.toUpperCase().indexOf("LOCALHOST") > -1;
const isDEV = host.toUpperCase().indexOf("VFGW-GEDEV") > -1;
const isUAT = host.toUpperCase().indexOf("CARRIERPORTAL") > -1;
const isPROD = host.toUpperCase().indexOf("CARRIERPORTAL") > -1;
const isPREVIEW = host.toUpperCase().indexOf("CARRIERPORTAL") > -1;

export const URLS = {
  API: `/api/CarrierPortal/`,
  TOKEN: `/token`,
  LOCAL: "https://localhost:44345",
  DEV: "http://vfgw-gedev.atf.local:2023",
  UAT: "https://carrierportal.evansdelivery.com",
  PROD: "https://carrierportal.evansdelivery.com",
  PREVIEW: "https://carrierportal.evansdelivery.com",
};

export const baseURL =
  (isLocal && URLS.LOCAL) ||
  (isDEV && URLS.DEV) ||
  (isUAT && URLS.UAT) ||
  (isPROD && URLS.PROD) ||
  (isPREVIEW && URLS.PREVIEW);

export const KEYS = {};
export const tokenURL = `${baseURL}${URLS.TOKEN}`;
export const apiURL = `${baseURL}${URLS.API}`;
export const accessTokenName = `${host}_CPAccessToken`;
export const refreshTokenName = `${host}_CPRefreshToken`;
export const sendAnalyticsTracking = false;
