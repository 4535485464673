import { updateColumnState } from "../Utils/Utils";
import {
  CompositeFilterDescriptor,
  DataResult,
  process,
} from "@progress/kendo-data-query";
import {
  GridColumnProps,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { CPColumnStateUpdate, CPGridColumn, CPGridState } from "../types";
import { objToString } from "../../../../common/helpers";

interface CPGridStateOptions {
  columns: CPGridColumn[];
  records: DataResult;
  initFilters?: CompositeFilterDescriptor;
  setGridState?: CPGridState;
  localFiltering?: boolean;
}

/**
 * Custom Hook to handle grid state -> column info, filters, sorting, etc.
 */
const useCPGridState = (props: CPGridStateOptions) => {
  const currentState = React.useRef<string>("");
  const [gridState, setGridState] = React.useState<CPGridState>({
    columns: props.columns,
    controls: { take: 20, skip: 0, sort: undefined, filter: props.initFilters },
  });

  React.useEffect(() => {
    if (
      props.setGridState &&
      objToString(props.setGridState) !== currentState.current
    ) {
      currentState.current = objToString(props.setGridState);
      setGridState(props.setGridState);
    }
  }, [props.setGridState]);

  const convertStrToDateObj = (val: string) => {
    try {
      const x = new Date(val);
      return x.toString() === "Invalid Date" ? val : x;
    } catch (e) {
      return val;
    }
  };

  const records = React.useMemo(() => {
    try {
      if (props.localFiltering) {
        // Convert any date strings to actual date objects so sorting on these works properly
        // otherwise they are sorted like a string
        const mappedDates = props.records.data.map((x) => {
          if (typeof x === "object" && Object.keys(x).length) {
            Object.keys(x).forEach((key) => {
              if (typeof x[key] === "string" && x[key].indexOf("/") > -1) {
                x[key] = convertStrToDateObj(x[key]);
              }
            });
          }

          return x;
        });
        return process(mappedDates, gridState.controls);
      }

      return props.records;
    } catch (err) {
      console.error(err);
      return props.records;
    }
  }, [props.localFiltering, props.records, gridState.controls]);

  const onFilter = (event: GridFilterChangeEvent) => {
    setGridState((prev) => {
      // Reset paging on filter changes
      return {
        ...prev,
        controls: {
          ...prev.controls,
          filter: event.filter,
          take: 20,
          skip: 0,
        },
      };
    });
  };

  const onSort = (event: GridSortChangeEvent) => {
    setGridState((prev) => ({
      ...prev,
      controls: { ...prev.controls, sort: event.sort },
    }));
  };

  const onGridStateUpdate = (event: GridDataStateChangeEvent) => {
    setGridState((prev) => {
      // PAging is updated here, dont reset it
      return {
        ...prev,
        controls: {
          ...event.dataState,
        },
      };
    });
  };

  const clearFilters = () => {
    setGridState((prev) => {
      return {
        ...prev,
        controls: {
          ...prev.controls,
          filter: undefined,
          take: 20,
          skip: 0,
        },
      };
    });
  };

  const handleUpdateColumnState = (
    columns: CPGridColumn[],
    updatedColumns: GridColumnProps[]
  ) => {
    const updated = updateColumnState(columns, updatedColumns);
    setGridState((prev) => ({ ...prev, columns: updated }));
  };

  const reload = () => clearFilters();

  return {
    records: records,
    reload,
    onFilter: onFilter,
    onSort: onSort,
    onGridStateUpdate: onGridStateUpdate,
    getControls: () => gridState.controls,
    clearFilters: clearFilters,
    setGridState,
    onColumnUpdate: (state: CPColumnStateUpdate) => {
      const cols = state.columns ?? gridState.columns;
      handleUpdateColumnState(cols, state.updatedColumns ?? cols);
    },
    getColumnState: () => gridState.columns || [],
    gridState,
    exportData: () =>
      process(props.records.data, { filter: gridState.controls.filter }).data,
  };
};

export default useCPGridState;
