// @intent: Methods for validation phone  numbers
import * as React from "react";
import PhoneNumber from "libphonenumber-js";

const usePhoneValidator = (phone: string, required: boolean = false) => {
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [error, setError] = React.useState<unknown>();

  React.useEffect(() => {
    const validate = async () => {
      try {
        const check = PhoneNumber(phone, "US");
        setIsValid(check != null ? check.isValid() : false);
      } catch (err) {
        setError(err);
        setIsValid(false);
      }
    };

    if (phone && required) {
      validate();
    } else {
      setError("");
    }
  }, [phone, required]);

  return {
    isValid,
    error,
  };
};

export default usePhoneValidator;
