// @intent: Helper functions

import dayjs, { Dayjs } from "dayjs";
import { ValueObject } from "./types";

// No Operation
export const noop = <T>(...args: T[]) => {};

interface SourceObject extends ValueObject {}

interface ResultObject extends ValueObject {}

/**
 *
 * @param source Merges deeply nested objects by properties.
 * @param properties
 * @returns
 */
export const mergeObjects = (source: SourceObject[], properties: string[]) => {
  if (!source || source.length === 0) return [];
  let mergedItems: ResultObject[] = [];

  const doMerge = (sourceObj: SourceObject) => {
    const result: ResultObject = {};

    Object.keys(sourceObj).forEach((key) => {
      if (Array.isArray(sourceObj[key])) {
        const items = mergeObjects(sourceObj[key], properties);
        items.forEach((item) => {
          mergedItems.push(doMerge(item));
        });
      } else if (properties.includes(key)) {
        result[key] = sourceObj[key];
      }
    });

    return result;
  };

  source.forEach((obj) => {
    const item = doMerge(obj);
    mergedItems.push(item);
  });

  return mergedItems;
};

export const removeCookie = (cookieName: string) => {
  const expireTokenDate = dayjs(Date.now()).add(-5, "day").toDate();
  document.cookie = `${cookieName}=; expires=${expireTokenDate.toUTCString()}; path=/;`;
};

/**
 * Stores a browser cookie using JSON.stringify with properties: 'token' & 'expires'.
 * (Can pass in additional key/values via the extraParams argument.)
 * @param cookieName The key name for the browser's cookie.
 * @param token The value stored for the cookie. (Can be a JSON.stringify of data)
 * @param expires The datetime the browser will automatically remove the cookie.
 * @param extraParams An object with key/value pairs that will be stored in the 'token' value.
 */
export const storeCookie = (
  cookieName: string,
  token: string,
  expires: string,
  extraParams?: ValueObject
) => {
  const d = dayjs(expires);
  const dStr = d.isValid() ? d.toDate().toUTCString() : "";
  document.cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify({
      token,
      expires: dStr,
      ...extraParams,
    })
  )}; expires=${dStr}; path=/;`;
};

/**
 * A utility getter that is used in conjunction with 'storeCookie' utility.
 * @param cookieName The key name of the browser's cookie
 * @param key An optional key to return the matching value when JSON.parse the return cookie's value. When omitted returns the complete object.
 * The default without providing a key, should always be returning an object with 'token' and 'expires' key/value
 * @returns
 */
export const getValueFromCookie = (cookieName: string, key?: string) => {
  const allCookies = document.cookie.split(";").map((x) => x.trim());
  const cookie = allCookies.find(
    (x) => x && x.toUpperCase().startsWith(cookieName.toUpperCase())
  );

  if (cookie) {
    const encData = cookie.split("=")[1];
    const data = JSON.parse(decodeURIComponent(encData));

    return key ? data[key] : data;
  }

  return null;
};

/**
 *
 * @param obj Copy an object, optionally if property names are included can keep or omit
 * @param properties
 * @param omit
 * @returns
 */
export function copyObj<T extends ValueObject, K extends keyof T>(
  obj: T,
  properties?: K[],
  omit: boolean = true
) {
  if (!properties) {
    return { ...obj };
  }

  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key]) => omit !== properties.includes(key as K)
    )
  );
}

/**
 * Convert object to JSON string
 * @param obj
 * @returns
 */
export const objToString = (obj: ValueObject) => {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return "";
  }
};

/**
 * Parse JSON string to an object
 * @param str
 * @returns
 */
export const strToObject = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return {};
  }
};

/**
 * Gets all errors from a formik error object into an array.
 * @param errors
 */
export const getFormikErrors = (errors: ValueObject) => {
  return Object.keys(errors).reduce((errs: string[], key: string) => {
    if (errors[key]) {
      errs.push(errors[key]);
    }

    return errs;
  }, []);
};

/**
 * Removes the time portion from date.
 *
 *  Returns a dayjs instance.
 * @param val
 * @returns
 */
export const getFlatDayjsDate = (val: Date | string | number | undefined) => {
  let d1 = parseDate(val);
  d1 = d1.hour(0);
  d1 = d1.minute(0);
  d1 = d1.second(0);
  d1 = d1.millisecond(0);

  return d1;
};

/**
 *  Helper function parse certain date formats into a valid date.
 * @param date
 * @param format
 * @returns
 */
export const parseDate = (date: Date | Dayjs | string | number | undefined) => {
  let dateToParse = date ?? "";
  if (typeof dateToParse === "string") {
    if (dateToParse.length > 19) {
      dateToParse = dateToParse.slice(0, 19);
    }
  }

  return dayjs(dateToParse);
};

export const RegExpPatterns = {
  lettersOnly: /^[A-Za-z]+$/,
  lettersSpaces: /^[A-Za-z\s]+$/,
  validEmailCharacters: /^[A-Za-z0-9!@#$%^&*=_.-]+$/,
  alphaNumeric: /^[A-Za-z0-9]+$/,
  alphaNumericSpaces: /^[A-Za-z0-9\s]+$/,
  zipCodes: /^[A-Za-z0-9-\s]+$/,
  personNameCharacters: /^[A-Za-z.`-\s]+$/,
  numbersOnly: /^[0-9]+$/,
  phoneNumber: /^[0-9()-\s]+$/,
};

export const filterValidCharacters = (value: string, pattern: RegExp) => {
  return Object.keys(value).reduce((val, _, index) => {
    const isValid = pattern.test(value[index]);

    if (isValid) {
      val = val + value[index];
    }
    return val;
  }, "");
};

export function getDistinctObjects<T>(items: T[], property: keyof T): T[] {
  const uniqueSet = new Set<T[keyof T]>();

  const distinctObjects = items.filter((item) => {
    const val = item[property];
    if (uniqueSet.has(val)) {
      return false;
    }

    uniqueSet.add(val);
    return true;
  });

  return distinctObjects;
}

/**
 *  Checks if both objects have a similar key
 * @param keyToCheck
 * @param obj1
 * @param obj2
 * @returns
 */
export const hasSharedKeys = (
  obj1: object,
  obj2: object,
  keyToCheck?: string | number
) => {
  for (const key in obj1) {
    const checkKey = keyToCheck ?? key;
    if (key === checkKey && obj2.hasOwnProperty(key)) {
      return true;
    }
  }

  return false;
};

/**
 * Get first matching shared key.
 * @param obj1
 * @param obj2
 * @returns
 */
export const getFirstSharedKey = (obj1: object, obj2: object) => {
  for (const key in obj1) {
    if (obj2.hasOwnProperty(key)) {
      return key;
    }
  }

  return "";
};

export const getRecordsFromObj = (data: ValueObject) => {
  if ("records" in data) {
    return data.records;
  }

  if ("results" in data) {
    return data.results;
  }

  if ("data" in data) {
    return data.data;
  }

  if ("dataRecords" in data) {
    return data.dataRecords;
  }
};

export const getTotalFromObj = (data: ValueObject) => {
  if ("total" in data) {
    return data.total;
  }
  if ("totalCount" in data) {
    return data.totalCount;
  }
  if ("count" in data) {
    return data.count;
  }

  if ("totalRecords" in data) {
    return data.totalRecords;
  }

  return undefined;
};
