// @intent: Methods and handlers for report forms
import * as React from "react";
import dayjs from "dayjs";
import { IReportListOption, IReportRequest } from "../types";
import ReportRequest from "../types/ReportRequest";
import { ValueObject } from "common/types";
import { CPAutoCompleteDataItem } from "components/types";
import { CPDateRangeChangeEvent } from "components/shared/CPDateRange/CPDateRange";

interface useReportFormOptions {
  formData?: IReportRequest;
  onSubmit?: (
    data: IReportRequest,
    onComplete?: () => void
  ) => void | Promise<void>;
  isLoading?: boolean;
}

const useReportRequestForm = (options: useReportFormOptions) => {
  const [form, setForm] = React.useState<IReportRequest>(
    ReportRequest({ ...options.formData })
  );

  const [selectedCarrier, setSelectedCarrier] = React.useState<
    CPAutoCompleteDataItem | undefined
  >();
  const [selectedReport, setSelectedReport] = React.useState<
    IReportListOption | undefined
  >();

  const quickSelectOptions = [
    { label: "Today", value: new Date() },
    { label: "Prior Day", value: dayjs(new Date()).add(-1, "days") },
    { label: "Prior 7 Days", value: dayjs(new Date()).add(-7, "days") },
    { label: "Prior 30 Days", value: dayjs(new Date()).add(-30, "days") },
  ];
  const formErrors = React.useMemo(() => {
    const errors: ValueObject = {};

    if (!form.reportId) {
      errors.scheduleInterval = `Please select a report.`;
    }

    return errors;
  }, [form]);

  const isValid = React.useMemo(() => {
    return Object.keys(formErrors).length === 0;
  }, [formErrors]);

  const handleSubmit = () => {
    if (isValid && options.onSubmit) {
      options.onSubmit(form, () => handleReset());
    }
  };

  const handleReset = () => {
    setForm(ReportRequest(options.formData));
    setSelectedReport(undefined);
    setSelectedCarrier(undefined);
  };

  const handleCarrierSelect = (selectedItem?: CPAutoCompleteDataItem) => {
    setSelectedCarrier(selectedItem);

    setForm((prev) => ({
      ...prev,
      carriers: [selectedItem?.value.id],
    }));
  };

  const handleDateChange = (event: CPDateRangeChangeEvent) => {
    setForm((prev) => ({ ...prev, reportDates: event.value }));
  };

  const handleReportSelection = (selection?: IReportListOption) => {
    setSelectedReport(selection);
    setForm((prev) => ({
      ...prev,
      reportId: selection?.id,
      reportName: selection?.name,
    }));
  };

  return {
    values: form,
    errors: formErrors,
    handleReportSelection,
    selectedReport,
    quickSelectOptions,
    resetForm: handleReset,
    disabled: isValid === false,
    isLoading: options.isLoading ?? false,
    selectedCarrier,
    handleCarrierSelect,
    onsubmit: handleSubmit,
    handleDateChange,
  };
};

export default useReportRequestForm;
