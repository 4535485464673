// @intent: Delete carrier contact

import http from "common/http";

import { useMutation } from "react-query";

const useDeleteCarrierContactCommand = () => {
  return useMutation((contactId: number) => {
    return http.deleteAsync("CarrierContact/DeleteCarrierContact", contactId);
  });
};

export default useDeleteCarrierContactCommand;
