// @intent: Query to check if user email is blacklisted
import { useQuery } from "react-query";
import http from "../../../common/http";
import { IAccessRequest } from "../types";

const useGetLoginRequestByIdQuery = (id?: number) => {
  const query = useQuery(
    ["LoginRequests/GetLoginRequestById", id],
    () => {
      return http.fetchAsync("LoginRequests/GetLoginRequestById", {
        id,
      });
    },
    {
      enabled: id != null && id > 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data as IAccessRequest;
      },
    }
  );

  return {
    isLoading: query.isLoading,
    request: query.data,
  };
};

export default useGetLoginRequestByIdQuery;
