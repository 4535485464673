import { CPDataType, ICPTrackingData } from "analytics/types";
import dayjs from "dayjs";
import { v4 as Guid } from "uuid";

export const PageVisitTracking = (eventId?: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "page",
    eventAction: "visit",
    eventId: eventId ?? window.location.pathname,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const PageExitTracking = (eventId?: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "page",
    eventAction: "exit",
    eventId: eventId ?? window.location.pathname,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const PageOtherTracking = (eventId?: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "page",
    eventAction: "other",
    eventId: eventId ?? window.location.pathname,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};
