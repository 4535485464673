// @intent: Form for user contact information
import * as React from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import { Form } from "@progress/kendo-react-form";
import CPErrorMessage from "../../../components/shared/CPErrorMessage/CPErrorMessage";
import CPButton from "../../../components/shared/CPButton/CPButton";
import { ButtonClickTracking, useCPTracking } from "analytics";
import CPPasswordTextBox from "../../../components/shared/CPPasswordTextBox/CPPasswordTextBox";
import useChangePasswordForm from "features/Auth/hooks/useChangePasswordForm";

const ChangePasswordForm: React.FC = () => {
  const { trackingEventDelegate } = useCPTracking();
  const form = useChangePasswordForm();
  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={5}>
        <CPErrorMessage errorMsg={form.apiError} />
        <CPPasswordTextBox
          {...form}
          name='currentPassword'
          placeholder='Current Password'
          value={form.values.currentPassword}
          errorMsg={form.errors.currentPassword}
          required
        />
        <CPPasswordTextBox
          {...form}
          name='newPassword'
          placeholder='New Password'
          value={form.values.newPassword}
          errorMsg={form.errors.newPassword}
          required
        />
        <CPPasswordTextBox
          {...form}
          name='confirmNewPassword'
          placeholder='Confirm New Password'
          value={form.values.confirmNewPassword}
          required
          errorMsg={form.errors.confirmNewPassword}
        />
        <CPButton
          size={"large"}
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Update Password")
          )}
          showLoader={form.isLoading}
        >
          Update Password
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default ChangePasswordForm;
