// @intent: Modal for upserting carrier contacts
import CPModal from "components/shared/CPModal/CPModal";
import * as React from "react";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import CarrierContactForm from "./CarrierContactForm";
import CarrierContactList from "./CarrierContactList";
import { ICarrierContact } from "../types";
import useUpsertCarrierContacts from "../hooks/useUpsertCarrierContacts";
import useCPConfirmModalContext from "components/shared/CPConfirmModal/useCPConfirmModalContext";
import { Container } from "components/base";

interface UpsertContactModalProps {
  carrierExId?: string;
  onClose: () => void;
}

const UpsertContactModal: React.FC<UpsertContactModalProps> = (props) => {
  const [contactToEdit, setContactToEdit] = React.useState<
    ICarrierContact | undefined
  >();
  const { confirm } = useCPConfirmModalContext();

  const model = useUpsertCarrierContacts(props.carrierExId);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const handleEditContact = (contact?: ICarrierContact) => {
    setContactToEdit(contact);
    setSelectedTab(0);
  };

  const handleRemoveContact = (contact: ICarrierContact) => {
    confirm(
      (yes) => yes && model.removeContact(contact),
      "Are you sure you want to remove the contact?"
    );
  };

  const handleSubmitContact = (contact: ICarrierContact) => {
    model.updateContact(contact);
    setContactToEdit(undefined);
    return;
  };

  const contactListTabTitle =
    model.contacts.length > 0 ? `(${model.contacts.length})` : "";

  return (
    <CPModal open={true} title='Carrier Contacts' onClose={props.onClose}>
      <Container width={800}>
        <TabStrip
          selected={selectedTab}
          onSelect={handleTabSelect}
          animation={false}
        >
          <TabStripTab title='Add/Edit Contact'>
            <CarrierContactForm
              carrierExId={props.carrierExId}
              onSubmit={handleSubmitContact}
              onCancel={handleEditContact}
              initData={contactToEdit}
              key={contactToEdit?._uid}
            />
          </TabStripTab>
          <TabStripTab title={`Contacts ${contactListTabTitle}`}>
            <CarrierContactList
              contacts={model.contacts}
              onEdit={handleEditContact}
              onRemove={handleRemoveContact}
            />
          </TabStripTab>
        </TabStrip>
      </Container>
    </CPModal>
  );
};

export default UpsertContactModal;
