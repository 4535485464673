import * as React from "react";
import classNames from "classnames";
import { LinkButtonProps, SubMenuItem } from "../../types";
import "./LinkButtonStyles.scss";

const LinkButton: React.FC<LinkButtonProps> = ({
  url,
  text,
  newWindow,
  styleType,
  color,
  size,
  subMenuItems,
  style,
  block,
  onClick,
}) => {
  newWindow = newWindow ?? true;

  const renderSubMenu = (items: SubMenuItem[]) => {
    return (
      <div className={classNames("cp-submenu", color ?? "default")}>
        {items.map((item, index) => (
          <a href={item.url} key={index} className='cp-submenu-item'>
            {item.text}
          </a>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "cp-link-button-base",
        styleType ?? "standard",
        color ?? "default",
        size ?? "default"
      )}
    >
      <a
        href={url}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (onClick) {
            onClick();
          } else {
            window.open(url, newWindow ? "_blank" : "_self");
          }
        }}
        rel='noreferrer'
        className='cp-link-button-main'
        style={{ display: block ? "block" : "inline-block", ...style }}
      >
        {text}
      </a>
      {subMenuItems && renderSubMenu(subMenuItems)}
    </div>
  );
};

export default LinkButton;
