// @intent: Send payload for updating contact information

import http from "common/http";
import { useMutation } from "react-query";
import { IUserProfileContact } from "../types";

const useUpdateContactInfoCommand = () => {
  const command = useMutation((payload: IUserProfileContact) => {
    return http.postAsync("UserProfile/Contacts", payload);
  });

  return command;
};

export default useUpdateContactInfoCommand;
