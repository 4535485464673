// @intent: Display a listing of carrier contacts
import * as React from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import CPListView from "components/shared/CPListView/CPListView";
import { ICarrierContact } from "../types";
import { noop } from "common/helpers";

interface CarrierContactListProps {
  contacts?: ICarrierContact[];
  onEdit?: (contact: ICarrierContact) => void;
  onRemove?: (contact: ICarrierContact) => void;
}

const CarrierContactList: React.FC<CarrierContactListProps> = (props) => {
  const contacts = props.contacts ?? [];
  const handleEdit = props.onEdit ?? noop;
  const handleRemove = props.onRemove ?? noop;

  const renderContactList = () => {
    if (contacts.length === 0) {
      return <CPText align='center'>No contacts</CPText>;
    }

    return (
      <CPListView
        header={[
          { label: "Name", dataField: "name" },
          { label: "Email", dataField: "email" },
          { label: "Phone", dataField: "phone" },
          { label: "Fax", dataField: "fax" },
        ]}
        data={contacts}
        actionBtns={[
          {
            fontAwesomeIcon: "fa-solid fa-pen-to-square",
            onClick: handleEdit,
          },
          {
            fontAwesomeIcon: "fa-solid fa-trash-can",
            onClick: handleRemove,
          },
        ]}
      />
    );
  };
  return (
    <StackLayout style={{ minHeight: "200px" }} align={{ vertical: "middle" }}>
      {renderContactList()}
    </StackLayout>
  );
};

export default CarrierContactList;
