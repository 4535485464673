import {
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
  MaskedTextBoxEvent,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { StackLayout } from "@progress/kendo-react-layout";
import * as React from "react";
import CPErrorMessage from "../CPErrorMessage/CPErrorMessage";
import usePhoneValidator from "hooks/common/usePhoneValidator";
import { RegExpPatterns, filterValidCharacters } from "common/helpers";

interface CPPhoneTextBoxProps
  extends Omit<
    MaskedTextBoxProps,
    "label" | "onChange" | "onBlur" | "required"
  > {
  label?: string;
  onChange?: (event: CPPhoneTextChangeEvent) => void;
  onBlur?: (event: CPPhoneTextEvent) => void;
  useValidator?: boolean;
  errorMsg?: string;
  required?: boolean;
}

export interface CPPhoneTextChangeEvent extends Omit<CPPhoneTextEvent, "type"> {
  type: "CPPhoneTextChangeEvent";
}

export interface CPPhoneTextEvent {
  name: string;
  type: "CPPhoneTextEvent";
  syntheticEvent: React.SyntheticEvent<HTMLElement>;
  value: React.InputHTMLAttributes<HTMLInputElement>["value"];
  error: string | undefined;
  isValid: boolean;
  target: MaskedTextBox;
}

const defaultErrorMsg = "Please enter a valid phone number.";

const CPPhoneTextBox: React.FC<CPPhoneTextBoxProps> = (props) => {
  const required = props.required ?? props.useValidator ?? true;
  const { isValid } = usePhoneValidator(props.value ?? "", required);

  const handleOnChange = (event: MaskedTextBoxChangeEvent) => {
    const isEmpty = /\d/.test(event.value ?? "") === false;
    const value = filterValidCharacters(
      event.value ?? "",
      RegExpPatterns.numbersOnly
    );

    if (props.onChange) {
      props.onChange({
        name: props.name ?? "",
        type: "CPPhoneTextChangeEvent",
        value: isEmpty ? value : event.value, // Remove empty fields masks (___) ___-_____
        syntheticEvent: event.syntheticEvent,
        target: event.target,
        isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const handleOnBlur = (event: MaskedTextBoxEvent) => {
    const isEmpty = /\d/.test(event.target.value ?? "") === false;
    const value = filterValidCharacters(
      event.target.value ?? "",
      RegExpPatterns.numbersOnly
    );
    if (props.onBlur) {
      props.onBlur({
        name: props.name ?? "",
        type: "CPPhoneTextEvent",
        value: isEmpty ? value : event.target.value ?? props.value,
        syntheticEvent: event.syntheticEvent,
        target: event.target,
        isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const renderTextBox = () => {
    return (
      <MaskedTextBox
        mask='(999) 000-0000'
        placeholder='(999) 000-0000'
        label={undefined}
        name={props.name ?? "phone"}
        value={props.value ?? ""}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
    );
  };
  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <FloatingLabel
          label={props.required ? `${props.label} *` : props.label}
          editorValue={props.value as string}
          editorId={props.name ?? "phone"}
        >
          {renderTextBox()}
        </FloatingLabel>
      ) : (
        renderTextBox()
      )}
      <CPErrorMessage
        errorMsg={
          !props.value
            ? undefined
            : isValid === false
            ? defaultErrorMsg
            : props.errorMsg
        }
      />
    </StackLayout>
  );
};

export default CPPhoneTextBox;
