import * as React from "react";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
  StackLayout,
} from "@progress/kendo-react-layout";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import "./CPSideMenuStyles.scss";

import { CPSideMenuItem, CPSideMenuProps } from "../../types";
import { noop } from "../../../common/helpers";
import { CPText } from "../../base";

const CPSideMenu: React.FC<CPSideMenuProps> = ({
  expandMenu = false,
  menuItems = [],
  onMenuClick = noop,
  children,
}) => {
  const onSelect = (e: DrawerSelectEvent) => {
    const route = e.itemTarget.props.route;
    onMenuClick(route);
  };

  const setSelectedItem = (pathName: string) => {
    const currentPath: CPSideMenuItem | undefined = menuItems.find(
      (item) => item.route === pathName
    );
    if (currentPath && currentPath.text) {
      return currentPath.text;
    }
  };
  let selected = setSelectedItem(window.location.pathname);

  const renderDrawerItem = (props: DrawerItemProps) => {
    const menuItem = menuItems.find((x) => x.text === props.text);

    if (menuItem && menuItem.render) {
      return <DrawerItem {...props}>{menuItem.render()}</DrawerItem>;
    }

    return (
      <DrawerItem {...props}>
        <div
          title={props.text}
          style={{
            width: 70,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CPFontAwesome
            icon={props.icon}
            style={{ justifyContent: "center" }}
          />
          <CPText align='center' style={{ fontSize: "11px" }}>
            {props.text}
          </CPText>
        </div>
      </DrawerItem>
    );
  };

  return (
    <div className='cp-side-menu'>
      <Drawer
        className='cp-side-menu-drawer'
        expanded={expandMenu}
        position={"start"}
        mode={"push"}
        mini={true}
        items={menuItems.map((item) => ({
          ...item,
          selected: item.text === selected,
          render: undefined, // Need to remove it from this array portion
        }))}
        onSelect={onSelect}
        item={renderDrawerItem}
      >
        <DrawerContent className='cp-side-menu-content'>
          {children}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default CPSideMenu;
