// @intent: Display a dropdown list of available report options.
import * as React from "react";
import useReportListQuery from "../api/useReportListQuery";
import CPDropDownList, {
  CPDropDownListEventChangeEvent,
} from "components/shared/CPDropDownList/CPDropDownList";
import { IReportListOption } from "../types";

interface ReportListDDLProps {
  reportId?: number;
  value?: IReportListOption;
  onSelect?: (item: IReportListOption | undefined) => void;
}

const ReportListDDL: React.FC<ReportListDDLProps> = (props) => {
  const reportsQuery = useReportListQuery();

  let value = props.value;

  if (!value && props.reportId) {
    value = reportsQuery.reports.find((x) => x.id === props.reportId);
  }

  const handleSelect = (event: CPDropDownListEventChangeEvent) => {
    const value = event.value as IReportListOption;
    if (props.onSelect) {
      props.onSelect(value);
    }
  };

  return (
    <CPDropDownList
      size={"small"}
      label='Available Reports'
      name='reportList'
      data={reportsQuery.reports}
      textField='name'
      helpText='Select report to generate.'
      onChange={handleSelect}
      value={value}
    />
  );
};

export default ReportListDDL;
