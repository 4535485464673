// @intent: Remove / delete a user's report subscription

import http from "common/http";
import { useMutation } from "react-query";
const useDeleteReportSubscriptionCommand = () => {
  const command = useMutation((id: number) => {
    return http.deleteAsync("Report/ReportSubscription", id);
  });

  return command;
};

export default useDeleteReportSubscriptionCommand;
