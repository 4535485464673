import * as React from "react";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";

import "./CPActionListStyles.scss";
import { CPActionItemProps } from "./CPActionItem";

export type ActionListChildren =
  | React.ReactElement<CPActionItemProps>
  | React.ReactElement<CPActionItemProps>[];

interface CPActionListProps {
  style?: React.CSSProperties;
  children?: ActionListChildren;
}

const CPActionList: React.FC<CPActionListProps> = (props) => {
  const btnLabel = "Actions";

  const handleSelect = (event: MenuSelectEvent) => {
    const val = event.item.text;

    if (val === btnLabel) {
      return;
    }

    const items = React.Children.toArray(
      props.children
    ) as React.ReactElement<CPActionItemProps>[];
    const item = items.find((x) => x.props.label === val);

    if (item && item.props.onSelect) {
      item.props.onSelect();
    }
  };

  return (
    <Menu
      className='cp-grid-action-list'
      onSelect={handleSelect}
      style={{ ...props.style }}
    >
      <MenuItem text={btnLabel} cssStyle={{ width: "100%", fontSize: ".9em" }}>
        {React.Children.map(
          props.children,
          (child, i) =>
            child != null && (
              <MenuItem
                text={child.props.label}
                key={i}
                cssStyle={{ width: "100%", fontSize: ".8em" }}
              />
            )
        )}
      </MenuItem>
    </Menu>
  );
};

export default CPActionList;
