// @intent: Base methods for handling analytic tracking events

import * as React from "react";
import { v4 as Guid } from "uuid";

import { CPTrackingData, CPTrackingEventDelegateCbFn } from "./types";
import trackingStore from "./trackingSessionStore";
import { PageExitTracking } from "./models/PageTrackings";

const useCPTracking = (initData?: CPTrackingData) => {
  React.useEffect(() => {
    if (initData) {
      trackEvent(initData);
    }

    return () => {
      if (initData != null && initData.eventCategory === "page") {
        trackEvent(PageExitTracking(initData.eventId));
      }
    };
  }, []);

  const trackEvent = async (dataToAdd: CPTrackingData) => {
    const current = await trackingStore.getSession();

    current.tracking.push({
      _uid: Guid(),
      ...dataToAdd,
      sessionId: current.sessionId,
      deviceInfo: current.device?.description,
    });

    trackingStore.saveSession(current);
  };

  const trackingEventDelegate = (
    eventHandlerCbFn: CPTrackingEventDelegateCbFn,
    data: CPTrackingData
  ) => {
    return (event?: any) => {
      trackEvent(data);

      eventHandlerCbFn(event);
    };
  };

  return {
    trackingData: trackingStore.getSession,
    resetSession: trackingStore.resetSession,
    trackEvent,
    trackingEventDelegate,
  };
};

export default useCPTracking;
