// @intent: Component to view/delete saved grid filters
import * as React from "react";
import CPListView from "components/shared/CPListView/CPListView";
import useGetAllSavedFiltersQuery from "../api/useGetAllSavedFiltersQuery";
import useDeleteSavedGridFilterCommand from "../api/useDeleteSavedGridFilterCommand";
import useCPConfirmModalContext from "components/shared/CPConfirmModal/useCPConfirmModalContext";
import { IUserSearchFilter } from "../types";
import { toast } from "react-toastify";

const UserSavedGridFilterList: React.FC = () => {
  const query = useGetAllSavedFiltersQuery();
  const deleteCommand = useDeleteSavedGridFilterCommand();
  const { confirm } = useCPConfirmModalContext();
  const handleRemoveSavedFilter = (dataItem: IUserSearchFilter) => {
    confirm((yes) => {
      if (yes && dataItem.id) {
        deleteCommand
          .mutateAsync(dataItem.id)
          .then(() => {
            query.reload();
            toast.success(`Filter deleted.`);
          })
          .catch(console.error);
      }
    }, `Are you sure you want to delete '${dataItem.pageName}' filter?`);
  };

  return (
    <CPListView
      key={query.savedGridFilters.length}
      pageable={false}
      data={query.savedGridFilters}
      header={[{ label: "Name", dataField: "pageName" }]}
      actionBtns={[
        {
          fontAwesomeIcon: "fa-solid fa-trash-can",
          onClick: handleRemoveSavedFilter,
        },
      ]}
    />
  );
};

export default UserSavedGridFilterList;
