import { IUserProfileContact } from "./types";

const UserProfileContact = (
  initData: IUserProfileContact | undefined = {
    id: -1,
    firstname: "",
    lastname: "",
    title: "",
    email: "",
    phone: "",
    fax: "",
  }
) => {
  return {
    ...initData,
  };
};

export default UserProfileContact;
