// @intent: Provide a context store that will allow the 2FA to be placed anywhere in the app.
import { noop } from "common/helpers";
import http from "../../../common/http";
import { create } from "zustand";

interface CP2faState {
  openModal: boolean;
  sendTo?: string;
  error?: string;
  token?: ICP2faToken | null;
  isValid: boolean;
  reachedMaxAttempts: boolean;
}

interface CP2faActions {
  closeModal: () => void;
  request2FA: (sendTo: string, onSuccess: () => void) => void;
  validate: (userEnteredCode: string) => void;
  reset: () => void;
  resend: () => void;
}

interface ICP2faToken {
  contact: string;
  error?: string;
  failedAttempts: number;
  hashedCode: string;
  timestamp: number;
  userEnteredCode: string;
}

const initState = {
  sendTo: undefined,
  openModal: false,
  error: "",
  isValid: false,
  reachedMaxAttempts: false,
};

const useCP2faContext = create<CP2faState & CP2faActions>()((set, get) => {
  let continueCbfn = noop;

  const request2FA = async (sendTo: string, onSuccess: () => void) => {
    try {
      reset();

      continueCbfn = onSuccess;

      const token = (await http.fetchAsync("User/Get2FACode", {
        sendTo,
      })) as ICP2faToken;

      set(() => ({ openModal: true, sendTo, token }));
    } catch (err) {
      console.error(err);
      set(() => ({
        error: "Error occurred during request.",
        isLoading: false,
      }));
    }
  };

  const validate = async (userEnteredCode: string) => {
    try {
      const token = get().token;

      if (token) {
        const tokenResult = (await http.postAsync("User/Validate2FACode", {
          ...token,
          userEnteredCode,
        })) as ICP2faToken;

        const reachedMaxAttempts = tokenResult.failedAttempts >= 3;
        const isValid = tokenResult.error == null;

        if (isValid) {
          continueCbfn();
          handleClose();
        } else {
          set(() => ({
            token: tokenResult,
            error: reachedMaxAttempts
              ? `You have reached the max attempts. Either change the email or resend another code.`
              : tokenResult.error,
            isValid: isValid,
            reachedMaxAttempts: reachedMaxAttempts,
          }));
        }
      }
    } catch (err) {
      console.error(err);
      set(() => ({ error: "Error occurred during request." }));
    }
  };

  const reset = () => {
    set(initState);
    continueCbfn = noop;
  };

  const resend = () => {
    const sendTo = get().sendTo;

    set(() => ({ token: null, error: "", reachedMaxAttempts: false }));

    if (sendTo) {
      request2FA(sendTo, continueCbfn);
    }
  };

  const handleClose = () => {
    reset();
  };

  return {
    ...initState,
    closeModal: handleClose,
    request2FA,
    validate,
    reset,
    resend,
  };
});

export default useCP2faContext;
