// @intent: Custom component using CPMultiCheckBox with virtual options loaded from an api.
import * as React from "react";
import CPMultiCheckBox, {
  CPMultiCheckBoxOption,
  CPMultiCheckBoxProps,
  CPMultiCheckBoxSelectEvent,
} from "../CPMultiCheckBox/CPMultiCheckBox";
import { ValueObject } from "common/types";
import { Container } from "components/base";
import { TextBox, TextBoxHandle } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import useVirtualPaging from "hooks/adapters/useVirtualPaging";

interface CPVirtualMultiCheckBoxProps
  extends Omit<
    CPMultiCheckBoxProps,
    | "options"
    | "filter"
    | "selectedItems"
    | "mappingLabelKey"
    | "mappingValueKey"
    | "onSearch"
    | "searchText"
  > {
  route: string;
  routeParams?: ValueObject;
  httpType: "GET" | "POST";
  pageSize?: number;
  idKey?: string;
  mappingLabelKey: string;
  mappingValueKey?: string;
  selectedItems: CPMultiCheckBoxOption[];
  onSelect: (event: CPMultiCheckBoxSelectEvent) => void;
}

export interface CPVirtualMultiCheckBoxResult {
  records: ValueObject[];
  totalCount: number;
}

const CPVirtualMultiCheckBox: React.FC<CPVirtualMultiCheckBoxProps> = (
  props
) => {
  const PAGE_SIZE = props.pageSize ?? 10;
  const filterIsFocusedRef = React.useRef(false);
  const textBoxIsFocusedRef = React.useRef(false);
  const textBoxRef = React.useRef<TextBoxHandle | null>(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const virtualState = useVirtualPaging({
    route: props.route,
    routeParams: props.routeParams,
    httpType: props.httpType,
    mappingLabelKey: props.mappingLabelKey,
    mappingValueKey: props.mappingValueKey,
    pageSize: PAGE_SIZE,
  });

  const renderHeader = () => {
    const SuffixLoader = () => {
      return (
        <Container padding={5}>
          <Loader size='small' type={"infinite-spinner"} />
        </Container>
      );
    };

    const PrefixIcon = () => {
      return (
        <Container padding={"8px 2px"}>
          <CPFontAwesome icon='fa-solid fa-magnifying-glass' />
        </Container>
      );
    };

    return (
      <Container padding={"10px"}>
        <TextBox
          onFocus={() => (filterIsFocusedRef.current = true)}
          onBlur={() => {
            filterIsFocusedRef.current = false;

            setTimeout(() => {
              if (textBoxIsFocusedRef.current === false) {
                setOpenPopup(false);
                virtualState.onInputChange("");
              }
            }, 100);
          }}
          prefix={PrefixIcon}
          ref={textBoxRef}
          onChange={(event) =>
            virtualState.onInputChange((event.value ?? "") as string)
          }
          suffix={virtualState.loading ? SuffixLoader : undefined}
        />
      </Container>
    );
  };

  const handleBlur = () => {
    textBoxIsFocusedRef.current = false;
  };

  const handleFocus = () => {
    setOpenPopup(true);
    textBoxIsFocusedRef.current = true;

    setTimeout(() => {
      if (
        textBoxRef.current &&
        textBoxRef.current.element &&
        !filterIsFocusedRef.current
      ) {
        filterIsFocusedRef.current = true;
        textBoxRef.current.element.focus();
      }
    }, 200);
  };

  return (
    <CPMultiCheckBox
      {...props}
      filter={""}
      options={virtualState.data}
      onSelect={props.onSelect}
      selectedItems={props.selectedItems}
      autoClose={false}
      virtual={virtualState.virtual}
      onPageChange={virtualState.onPageChange}
      onFocus={() => handleFocus()}
      onBlur={() => handleBlur()}
      opened={openPopup}
      disableSelectAll
      header={renderHeader()}
      filterable={false}
      popupSettings={{
        height: 300,
      }}
    />
  );
};

export default CPVirtualMultiCheckBox;
