import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { CPTextProps } from "../../types";
import { parseDate } from "common/helpers";
import CPText from "../CPText/CPText";

interface DateTextProps extends Omit<CPTextProps, "children"> {
  date: Date | Dayjs | string | undefined;
  format?: string;
}

const DateText: React.FC<DateTextProps> = (props) => {
  const format = props.format ?? "MM/DD/YYYY";

  const getDateStr = React.useCallback(
    (val: Date | Dayjs | string | number | undefined, format: string) => {
      const d = parseDate(val);
      return d.isValid() ? d.format(format) : "N/A";
    },
    []
  );

  return (
    <CPText style={{ fontSize: "1em" }}>
      {getDateStr(props.date, format)}
    </CPText>
  );
};

export default DateText;
