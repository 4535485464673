import * as React from "react";
import CarrierSelfServiceForm from "../CarrierSelfServiceForm/CarrierSelfServiceForm";
import EmployeeSetupForm from "../EmployeeSetupForm/EmployeeSetupForm";
import CarrierManualForm from "../CarrierManualForm/CarrierManualForm";
import FCompanyManualForm from "../FCompanySetupForm/FCompanyManualForm";
import useRenderRegisterForm from "features/LoginRequests/hooks/useRenderRegisterForm";

interface RegisterFormBodyProps {
  renderForm: string;
  onSuccess: (message: string) => void;
}

const RegisterFormBody: React.FC<RegisterFormBodyProps> = ({
  renderForm,
  onSuccess,
}) => {
  const { view, shouldRenderCarrierManualSetup } =
    useRenderRegisterForm(renderForm);

  if (view.CARRIER_MANUAL) {
    return (
      <CarrierManualForm
        onSuccess={onSuccess}
        onSelfServiceSetupClick={() => shouldRenderCarrierManualSetup(false)}
      />
    );
  }

  if (view.CARRIER) {
    return (
      <CarrierSelfServiceForm
        onSuccess={onSuccess}
        onManualSetupClick={() => shouldRenderCarrierManualSetup(true)}
      />
    );
  }

  if (view.EMPLOYEE) {
    return <EmployeeSetupForm onSuccess={onSuccess} />;
  }

  if (view.FCOMPANY) {
    return <FCompanyManualForm onSuccess={onSuccess} />;
  }

  return null;
};

export default RegisterFormBody;
