// @intent: Methods for handling user contact forms
import * as Yup from "yup";
import { useFormik } from "formik";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useAppStore from "state/stores/useAppStore";
import useUpdateContactInfoCommand from "features/Users/api/useUpdateContactInfoCommand";
import useCP2faContext from "components/shared/CP2faModal/useCP2faContext";
import { IUserProfileContact } from "../types";
import UserProfileContact from "../types/UserProfileContact";

const FormSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required."),
  phone: Yup.string().required("A valid phone number is required."),
});

const useContactInfoForm = (initData: IUserProfileContact) => {
  const { request2FA, error } = useCP2faContext();
  const toast = useAppStore((app) => app.setToastMsg);
  const command = useUpdateContactInfoCommand();
  const formik = useFormik({
    initialValues: UserProfileContact({ ...initData }),
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = async () => {
    if (formik.isValid && handlers.isValid()) {
      formik.values.email.toUpperCase() !== initData.email.toUpperCase()
        ? await request2FA(formik.values.email, submitToServer)
        : await submitToServer();
    }
  };

  const submitToServer = async () => {
    command
      .mutateAsync(UserProfileContact({ ...formik.values }))
      .then(() => {
        toast(`Contact info updated successfully.`);
      })
      .catch(console.error);
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    apiError: (command.error ?? error) as string,
    disableSubmit: () => !formik.isValid || !handlers.isValid(),
    isLoading: false,
    onSubmit: () => formik.handleSubmit(),
  };
};

export default useContactInfoForm;
