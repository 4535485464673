// @intent: Display form for factoring company access requests
import * as React from "react";

import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import CPVirtualAutoComplete from "../../../../../components/shared/CPVirtualAutoComplete/CPVirtualAutoComplete";

import CPButton from "../../../../../components/shared/CPButton/CPButton";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPhoneTextBox from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import CPZipCodeTextBox from "components/shared/CPZipCodeTextBox/CPZipCodeTextBox";
import { CPText } from "components/base";
import {
  ButtonClickTracking,
  PageVisitTracking,
  useCPTracking,
} from "analytics";
import useFCompanySetupForm from "features/LoginRequests/hooks/useFCompanySetupForm";
import CPStateProvinceDropDown from "components/shared/CPStateProvinceDropDown/CPStateProvinceDropDown";

interface FCompanyFormProps {
  onSuccess: (message: string) => void;
}

const FCompanyManualForm: React.FC<FCompanyFormProps> = ({ onSuccess }) => {
  const { trackingEventDelegate } = useCPTracking(
    PageVisitTracking("Register/FactoringCompany")
  );
  const form = useFCompanySetupForm(onSuccess);

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={5}>
        <StackLayout orientation='vertical'>
          <CPText align='center' textStyle='subtitle'>
            Factoring Company Access
          </CPText>
          <CPText align='center'>
            Request to have the account details reviewed and approved manually.
          </CPText>
        </StackLayout>
        <CPErrorMessage errorMsg={form.apiError} align='center' />

        <StackLayout gap={10}>
          <CPTextBox
            {...form}
            name='firstName'
            value={form.values.firstName}
            errorMsg={form.errors.firstName}
            label='First Name'
            required
            personNameCharacters
            toCapital
          />
          <CPTextBox
            {...form}
            name='lastName'
            value={form.values.lastName}
            errorMsg={form.errors.lastName}
            label='Last Name'
            required
            personNameCharacters
            toCapital
          />
        </StackLayout>

        <CPEmailTextBox
          {...form}
          name='email'
          label='Email Address'
          value={form.values.email}
          errorMsg={form.errors.email}
          required
        />

        <CPPhoneTextBox
          {...form}
          name='phoneNumber'
          label='Phone Number'
          value={form.values.phoneNumber}
          errorMsg={form.errors.phoneNumber}
          required
        />

        <CPVirtualAutoComplete
          value={form.selectedCompany}
          label='Company Name'
          labelKey='name'
          name='companyName'
          route='FactoringCompany/Search'
          onChange={form.onCompanyNameSelected}
          errorMsg={form.errors.companyName}
          allowManualEntry
          required
        />

        <CPTextBox
          {...form}
          name='companyRemitAddress'
          value={form.values.companyRemitAddress}
          errorMsg={form.errors.companyRemitAddress}
          label='Company Address'
          toCapital
        />

        <CPTextBox
          {...form}
          name='companyRemitCity'
          value={form.values.companyRemitCity}
          errorMsg={form.errors.companyRemitCity}
          label='Company City'
          required
          textOnly
          toCapital
        />

        <StackLayout gap={20}>
          <CPStateProvinceDropDown
            {...form}
            name='companyRemitState'
            value={form.values.companyRemitState}
            required
          />
          <CPZipCodeTextBox
            {...form}
            name='companyRemitZip'
            value={form.values.companyRemitZip}
            errorMsg={form.errors.companyRemitZip}
            label='Company Zip'
            required
          />
        </StackLayout>

        <CPButton
          size={"large"}
          icon='track-changes-enable'
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Request Access - Factoring Company", {
              ...form,
            })
          )}
          showLoader={form.isLoading}
        >
          Request Access
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default FCompanyManualForm;
