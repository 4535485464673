// @intent: A modal with sections/steps to use with more complete forms
import * as React from "react";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import CPModal from "../CPModal/CPModal";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import { CPHrBreak, CPText } from "components/base";
import { CPWizardModalProps } from ".";
import "./CPWizardModalStyles.scss";
import classNames from "classnames";
import CPButton from "../CPButton/CPButton";

const CPWizardModal: React.FC<CPWizardModalProps> = (props) => {
  const firstStep = props.steps[0];
  const lastStep = [...props.steps].reverse()[0];
  const currentStep = props.steps.find((x) => x.step === props.currentStep);
  const nextStep = props.steps.find(
    (x) => x.step === (currentStep != null ? currentStep.step + 1 : -1)
  );

  // The content sections
  const children = React.Children.toArray(
    props.children
  ) as React.ReactPortal[];

  const currentContent = children.find(
    (child) => child.props.step === currentStep?.step
  );

  const steps = React.useMemo(() => {
    return props.steps.map((x, index) => ({
      ...x,
      selected: x.step === currentStep?.step,
    }));
  }, [props.steps, currentStep]);

  const disableCompleteBtn = React.useMemo(() => {
    return steps.some((x) => x.disabled || x.showInvalid);
  }, [steps]);

  const onSelect = (e: DrawerSelectEvent) => {
    const text = e.itemTarget.props.text;
    const itemIndex = steps.findIndex((x) => x.text === text);
    const item = steps[itemIndex];

    if (item && !item.disabled) {
      const step = item.step ?? itemIndex;
      handleUpdateStep(step);
    }
  };

  const handleUpdateStep = (step: number) => {
    // Check if a next step increment is greater than our last step.
    const isLastStep = step > lastStep.step;

    // No more steps
    if (props.onComplete && isLastStep) {
      props.onComplete();
      return;
    }

    if (props.onStepSelect) {
      props.onStepSelect(step);
    }
  };

  const renderItem = (props: DrawerItemProps) => {
    // only supply draweritem with expected props, remove any custom props
    const drawerProps = { ...props };
    delete drawerProps.showInvalid;
    delete drawerProps.stepSubText;
    delete drawerProps.stepFooterText;

    let stepSubText = props.stepSubText;
    if (props.showInvalid) {
      stepSubText = `* ${stepSubText}`;
    }
    return (
      <DrawerItem {...drawerProps}>
        <div
          title={props.text}
          className={classNames("cp-drawer-item", {
            "cp-drawer-disabled": props.disabled,
          })}
        >
          <div className='cp-wizard-modal-step-text'>
            <CPText transform='capitalize'>{props.text}</CPText>
            <CPText fontSize='12px' style={{ opacity: 0.8 }}>
              {stepSubText}
            </CPText>
          </div>

          <CPFontAwesome icon='fa-solid fa-chevron-right' />
        </div>
      </DrawerItem>
    );
  };
  const wizardTextError =
    props.errorMessage ??
    props.completeBtnDisabled ??
    (disableCompleteBtn ? `Please complete all * required sections.` : "");

  const stepFooterText = currentStep?.stepFooterText;

  return (
    <CPModal
      className='cp-wizard-modal'
      {...props}
      contentPadding={0}
      key={props.steps.length}
    >
      <Drawer
        expanded={true}
        position={"start"}
        mode={"push"}
        mini={false}
        items={steps}
        onSelect={onSelect}
        item={renderItem}
      >
        <div className='cp-wizard-progress-container'>
          <div>
            {wizardTextError && (
              <CPText fontSize='13px' color='danger' gutterBottom>
                {wizardTextError}
              </CPText>
            )}
          </div>
          {props.completeBtnText && (
            <CPButton
              onClick={props.onComplete}
              themeColor={"primary"}
              disabled={props.completeBtnDisabled ?? disableCompleteBtn}
            >
              {props.completeBtnText}
            </CPButton>
          )}
        </div>
        <DrawerContent style={{ width: props.modalWidth }}>
          {currentContent}
          <CPHrBreak marginTop={10} />
          <div className='cp-wizard-modal-action-container'>
            <CPText
              className='cp-wizard-modal-section-helptext'
              color={currentStep?.showInvalid ? "error" : "primary"}
            >
              {stepFooterText}
            </CPText>
            <div className='cp-wizard-modal-action-btns'>
              {props.currentStep > firstStep.step && (
                <CPButton
                  fillMode={"outline"}
                  themeColor={"primary"}
                  onClick={() => handleUpdateStep(props.currentStep - 1)}
                >
                  Previous
                </CPButton>
              )}
              {nextStep != null && (
                <CPButton
                  fillMode={nextStep ? "solid" : "outline"}
                  themeColor={"primary"}
                  disabled={currentStep?.disabled}
                  onClick={() => handleUpdateStep(props.currentStep + 1)}
                >
                  Next
                </CPButton>
              )}
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </CPModal>
  );
};

export default CPWizardModal;
