// @intent: Component wrapper for kendo date range picker with additional custom date droplist, default items
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
  DateRangePickerHandle,
} from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import { CPDateValue } from "common/types";
import { CPText } from "components/base";
import { Label } from "@progress/kendo-react-labels";
import CPButton from "../CPButton/CPButton";

interface CPDateRangeProps {
  name: string;
  value?: { start: CPDateValue; end: CPDateValue };
  showQuickOptions?: boolean;
  quickDateOptions?: QuickDateOption[];
  label?: string;
  required?: boolean;
  errorMsg?: string;
  helpText?: string;
  disabled?: boolean;
  onChange?: (event: CPDateRangeChangeEvent) => void;
}

export interface CPDateRangeChangeEvent {
  type: "CPDateRangeChangeEvent";
  value: { start: Date | null; end: Date | null };
  name: string;
}

export interface QuickDateOption {
  label: string;
  value: Date | Dayjs | null;
}

const defaultQuickOptions = [
  { label: "Today", value: new Date() },
  { label: "Prior 7 Days", value: dayjs(new Date()).add(-7, "days") },
  { label: "Prior 14 Days", value: dayjs(new Date()).add(-14, "days") },
  { label: "Prior 30 Days", value: dayjs(new Date()).add(-30, "days") },
  { label: "Prior 60 Days", value: dayjs(new Date()).add(-60, "days") },
  { label: "Prior 90 Days", value: dayjs(new Date()).add(-90, "days") },
];

const defaultDates = { start: null, end: null };
const defaultQuickDate = { label: "Choose Quick Date", value: null };

const CPDateRange: React.FC<CPDateRangeProps> = (props) => {
  const value = props.value ?? defaultDates;
  const [selectedQuickDate, setSelectedQuickDate] =
    React.useState<QuickDateOption>(defaultQuickDate);

  React.useEffect(() => {
    if (
      value.start == null &&
      value.end == null &&
      defaultQuickDate.value == null
    ) {
      setSelectedQuickDate(defaultQuickDate);
    }
  }, [value]);

  const convertDate = (date: CPDateValue) => {
    if (date == null) return null;

    const d = dayjs(date);
    return d.isValid() ? d.toDate() : null;
  };

  const handleQuickSelect = (event: DropDownListChangeEvent) => {
    const selected: QuickDateOption = event.value;

    if (props.onChange) {
      props.onChange({
        type: "CPDateRangeChangeEvent",
        name: props.name,
        value: { start: dayjs(selected.value).toDate(), end: new Date() },
      });
    }

    setSelectedQuickDate(selected);
  };

  const handleResetDates = () => {
    if (props.onChange) {
      props.onChange({
        type: "CPDateRangeChangeEvent",
        name: props.name,
        value: defaultDates,
      });
    }

    setSelectedQuickDate(defaultQuickDate);
  };

  const handleOnChange = (event: DateRangePickerChangeEvent) => {
    setSelectedQuickDate(defaultQuickDate);

    const value = event.value;
    if (props.onChange) {
      props.onChange({
        type: "CPDateRangeChangeEvent",
        name: props.name,
        value: value,
      });
    }
  };

  const render = () => {
    return (
      <StackLayout orientation='vertical' style={{ minWidth: 330 }} gap={5}>
        <StackLayout align={{ vertical: "middle", horizontal: "stretch" }}>
          <DateRangePicker
            {...props}
            value={{
              start: convertDate(value.start),
              end: convertDate(value.end),
            }}
            onChange={handleOnChange}
          />
          <CPButton
            size='small'
            fillMode={"flat"}
            title='Reset Dates'
            onClick={() => handleResetDates()}
            style={{ marginTop: 20, marginLeft: 5, width: 10 }}
          >
            <CPFontAwesome icon='fa-solid fa-rotate-left' />
          </CPButton>
        </StackLayout>

        <DropDownList
          data={props.quickDateOptions ?? defaultQuickOptions}
          defaultItem={defaultQuickDate}
          textField='label'
          dataItemKey='value'
          onChange={handleQuickSelect}
          value={selectedQuickDate}
        />
      </StackLayout>
    );
  };

  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <>
          <Label editorId={props.name}>
            {props.required ? `${props.label} *` : props.label}
          </Label>
          {render()}
        </>
      ) : (
        render()
      )}
      <CPText textStyle='caption' color={props.errorMsg ? "error" : "primary"}>
        {props.errorMsg ?? props.helpText}
      </CPText>
    </StackLayout>
  );
};

export default CPDateRange;
