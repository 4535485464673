// @intent: Display grid and handle user interactions for invoice searches
import * as React from "react";
import { Container } from "components/base";
import { CPActionItem, CPGrid } from "components/shared/CPGrid";
import CPDefaultActionList from "components/shared/CPGrid/CPDefaultActionList";
import { StackLayout } from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";
import useCarriersGridMethods from "../hooks/useCarriersGridMethods";
import { ButtonClickTracking, useCPTracking } from "analytics";
import UpsertContactModal from "./UpsertContactModal";

const CarriersGrid: React.FC = () => {
  const { trackingEventDelegate } = useCPTracking();
  const GRIDNAME = "carriersGrid";
  const carrierGrid = useCarriersGridMethods(GRIDNAME);

  const renderToolBar = () => {
    return (
      <StackLayout gap={10} align={{ vertical: "middle" }}>
        <CPDefaultActionList
          dataAdapter={{
            ...carrierGrid.gridAdapter,
            onSaveSearch: carrierGrid.onSaveSearch,
          }}
        >
          <CPActionItem
            label='Clear Selection'
            onSelect={carrierGrid.selectableOptions.clearSelection}
          />
        </CPDefaultActionList>

        {carrierGrid.canEditCarrier && (
          <CPButton
            icon='edit'
            className='btn-small'
            onClick={trackingEventDelegate(
              () => carrierGrid.setOpenEditContactsModal(true),
              ButtonClickTracking(
                "CarriersGrid-Edit-Carrier",
                carrierGrid.selectableOptions.selectedItem
              )
            )}
            disabled={carrierGrid.selectableOptions.selectedItem == null}
          >
            Contacts
          </CPButton>
        )}
      </StackLayout>
    );
  };

  return (
    <Container>
      <CPGrid
        {...carrierGrid.selectableOptions}
        name={GRIDNAME}
        dataAdapter={carrierGrid.gridAdapter}
        filterable={true}
        sortable={true}
        pageable={true}
        showToolbar={true}
        renderToolBar={renderToolBar}
        columns={carrierGrid.columns}
      />

      {carrierGrid.openEditContactsModal && (
        <UpsertContactModal
          onClose={carrierGrid.closeModal}
          carrierExId={carrierGrid.selectedCarrierExId}
        />
      )}
    </Container>
  );
};

export default CarriersGrid;
