import * as React from "react";
import "./CPLoaderStyles.scss";
interface CPLoaderProps {
  showLoader?: boolean;
}

const CPLoader: React.FC<CPLoaderProps> = ({ showLoader }) => {
  return showLoader ? (
    <div className='cp-grid-loader k-loading-mask'>
      <div className='k-loading-image' />
      <div className='k-loading-color' />
    </div>
  ) : null;
};

export default CPLoader;
