// @intent: Generic wrapper component for kendo dialog providing common state/methods
import { Dialog } from "@progress/kendo-react-dialogs";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPHrBreak, Container } from "components/base";
import * as React from "react";

interface CPModalProps {
  title: string;
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  contentPadding?: number;
  actionBtns?: React.ReactNode;
  actionBtnsDisplayVertical?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const CPModal: React.FC<CPModalProps> = (props) => {
  return props.open ? (
    <Dialog {...props}>
      <Container padding={props.contentPadding ?? 15}>
        {props.children}
      </Container>
      {props.actionBtns != null ? (
        <>
          <CPHrBreak spacing={10} />
          <StackLayout
            gap={10}
            orientation={
              props.actionBtnsDisplayVertical ? "vertical" : "horizontal"
            }
          >
            {props.actionBtns}
          </StackLayout>
        </>
      ) : null}
    </Dialog>
  ) : null;
};

export default CPModal;
