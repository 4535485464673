import * as React from "react";

import {
  InputSuffix,
  TextBox,
  TextBoxChangeEvent,
  TextBoxHandle,
  TextBoxProps,
} from "@progress/kendo-react-inputs";

import { FloatingLabel } from "@progress/kendo-react-labels";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import { RegExpPatterns, filterValidCharacters } from "common/helpers";

export interface CPTextBoxProps
  extends Omit<
    TextBoxProps,
    "name" | "suffix" | "prefix" | "label" | "required" | "onChange" | "onBlur"
  > {
  name: string;
  label?: string;
  helpText?: string;
  errorMsg?: string;
  required?: boolean;
  showLoader?: boolean;
  disableSpaces?: boolean;
  textOnly?: boolean;
  alphaNumeric?: boolean;
  personNameCharacters?: boolean;
  toUppercase?: boolean;
  toCapital?: boolean;
  regexPattern?: RegExp;
  renderSuffix?: () => JSX.Element;
  renderPrefix?: () => JSX.Element;
  onChange?: (event: CPTextBoxChangeEvent) => void;
  onBlur?: (event: CPTextBoxEvent) => void;
  ref?: React.MutableRefObject<any>;
}

export interface CPTextBoxChangeEvent extends Omit<CPTextBoxEvent, "type"> {
  type: "CPTextBoxChangeEvent";
}

export interface CPTextBoxEvent {
  name: string;
  type: "CPTextBoxEvent";
  syntheticEvent: React.SyntheticEvent<HTMLElement, Event>;
  value: React.InputHTMLAttributes<HTMLInputElement>["value"];
  error: string | undefined;
  isValid: boolean;
  target: TextBoxHandle;
}

const defaultErrorMsg = "This field is required.";

const CPTextBox: React.FC<CPTextBoxProps> = (props) => {
  const formatString = React.useCallback(
    (value: string | undefined) => {
      if (value == null || value.length === 0) return "";

      if (props.regexPattern) {
        return filterValidCharacters(value, props.regexPattern);
      }

      if (props.textOnly) {
        return filterValidCharacters(
          value,
          props.disableSpaces
            ? RegExpPatterns.lettersOnly
            : RegExpPatterns.lettersSpaces
        );
      }

      if (props.alphaNumeric) {
        return filterValidCharacters(
          value,
          props.disableSpaces
            ? RegExpPatterns.alphaNumeric
            : RegExpPatterns.alphaNumericSpaces
        );
      }

      if (props.personNameCharacters) {
        return filterValidCharacters(
          value,
          RegExpPatterns.personNameCharacters
        );
      }

      if (props.disableSpaces) {
        // use alphanumeric no spaces for now...
        return filterValidCharacters(value, RegExpPatterns.alphaNumeric);
      }

      return value;
    },
    [props]
  );

  const handleChange = (event: TextBoxChangeEvent) => {
    if (props.onChange) {
      const isValid = event.target.value != null || event.target.value !== "";
      let value = formatString(event.value as string);

      if (props.toUppercase) {
        value = value.toUpperCase();
      }

      props.onChange({
        name: props.name ?? "",
        type: "CPTextBoxChangeEvent",
        syntheticEvent: event.syntheticEvent,
        target: event.target,
        value: value,
        isValid: isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const handleBlur = (event: React.FocusEvent<any, Element>) => {
    if (props.onBlur) {
      const isValid = event.target.value != null || event.target.value !== "";
      let value = event.target.value ?? props.value ?? "";

      if (props.toUppercase) {
        value = value.toUpperCase();
      }

      props.onBlur({
        name: props.name ?? "",
        type: "CPTextBoxEvent",
        syntheticEvent: event,
        target: event.target,
        value: value,
        isValid: isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const renderLoader = () => {
    return (
      <InputSuffix>
        <CPFontAwesome icon='fa-solid fa-circle-notch fa-spin' />
      </InputSuffix>
    );
  };

  const renderSuffix = () => {
    if (props.renderSuffix) {
      return props.renderSuffix();
    }

    if (props.showLoader) {
      return renderLoader();
    }

    return null;
  };

  const renderTextBox = () => {
    return (
      <TextBox
        ref={(input) => {
          if (input?.element && props.toCapital) {
            input.element.setAttribute("style", "text-transform:capitalize");
          }
        }}
        style={{
          ...props.style,
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        className={props.className}
        color={props.color}
        onFocus={props.onFocus}
        id={props.name}
        placeholder={props.placeholder}
        size={props.size ?? "medium"}
        fillMode={props.fillMode}
        rounded={props.rounded}
        name={props.name}
        autoComplete='off'
        suffix={renderSuffix}
        prefix={props.renderPrefix}
        type={props.type ?? "text"}
        value={props.value ?? ""}
        maxLength={props.maxLength}
        disabled={props.disabled}
      />
    );
  };

  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <FloatingLabel
          label={props.required ? `${props.label} *` : props.label}
          editorValue={props.value as string}
          editorId={props.name}
        >
          {renderTextBox()}
        </FloatingLabel>
      ) : (
        renderTextBox()
      )}
      {props.errorMsg != null || props.helpText != null ? (
        <CPText
          textStyle='caption'
          color={props.errorMsg ? "error" : "primary"}
        >
          {props.errorMsg ?? props.helpText}
        </CPText>
      ) : null}
    </StackLayout>
  );
};

export default CPTextBox;
