// @intent: display an a searchable autocomplete multiselect list of carriers
import * as React from "react";
import { ValueObject } from "common/types";
import CPVirtualMultiCheckBox from "../CPVirtualMultiCheckBox/CPVirtualMultiCheckBox";
import { StackLayout } from "@progress/kendo-react-layout";
import CPButton from "../CPButton/CPButton";
import { Container } from "components/base";
import { IUserAssignedCarrier } from "features/Users";

interface CPCarrierMultiSelectProps {
  onAssign: (items: IUserAssignedCarrier[]) => void;
  currentAssignments: IUserAssignedCarrier[];
}

const CPCarrierMultiSelect: React.FC<CPCarrierMultiSelectProps> = (props) => {
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const handleItemSubTextRender = (data: ValueObject) => {
    const address = `
      ${data.address ?? ""} 
      ${data.city ?? ""}
      ${data.state ?? ""}
      ${data.zipCode ?? ""}`;

    const code = data.externalId ? `(${data.externalId})` : "";

    return `DOT: ${data.dotNumber ?? "N/A"} - MC: ${
      data.mcNumber ?? "N/A"
    } - ${code}  - ${address}`;
  };

  const handleAssign = () => {
    props.onAssign(selectedItems.map((x) => x.value));
    setSelectedItems([]);
  };

  return (
    <StackLayout gap={10} align={{ vertical: "middle", horizontal: "start" }}>
      <Container style={{ flex: 1 }}>
        <CPVirtualMultiCheckBox
          name='carriers'
          label={"Carrier Search"}
          idKey='id'
          mappingLabelKey='name'
          route='Carrier/Search'
          routeParams={{
            excludeIds: props.currentAssignments.map((x) => x.id),
          }}
          httpType='GET'
          allowManualEntry={false}
          helpText={"Search carrier by name, dot, or mc number."}
          style={{ width: "100%" }}
          renderItemSubText={handleItemSubTextRender}
          onSelect={(event) => setSelectedItems(event.value)}
          selectedItems={selectedItems}
        />
      </Container>

      <CPButton
        themeColor={"primary"}
        fillMode={"outline"}
        onClick={handleAssign}
        disabled={selectedItems.length === 0}
      >
        Assign
      </CPButton>
    </StackLayout>
  );
};

export default CPCarrierMultiSelect;
