// @intent: Container for creating / editing user
import * as React from "react";
import { CPWizardModalStepItem } from "components/shared/CPWizardModal";
import CPWizardModal from "components/shared/CPWizardModal/CPWizardModal";
import CPWizardModalContent from "components/shared/CPWizardModal/CPWizardModalContent";
import { IUpsertAccount } from "../../types";
import useCreateAccountForm from "../../hooks/useUpsertAccountForm";
import CPRoleXListBox from "components/shared/CPRoleXListBox/CPRoleXListBox";
import { Switch } from "@progress/kendo-react-inputs";
import { Box, CPHrBreak, CPText, Container } from "components/base";
import { StackLayout } from "@progress/kendo-react-layout";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";
import CPPhoneTextBox from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import AccountTypeDDL from "./AccountTypeDDL";
import CPVirtualAutoComplete from "components/shared/CPVirtualAutoComplete/CPVirtualAutoComplete";
import SearchCarriersAutoComplete from "./SearchCarriersAutoComplete";
import AssignedCarrierList from "./AssignedCarrierList";
import useAppStore from "state/stores/useAppStore";
import CPCarrierMultiSelect from "components/shared/CPCarrierMultiSelect/CPCarrierMultiSelect";
import CPCarrierAutoComplete from "components/shared/CPCarrierAutoComplete/CPCarrierAutoComplete";
import { CPAutoCompleteDataItem } from "components/types";

interface UpsertAccountWizardModalProps {
  onClose: () => void;
  model: IUpsertAccount;
  title?: string;
}

const UpsertAccountWizardModal: React.FC<UpsertAccountWizardModalProps> = (
  props
) => {
  const showLoader = useAppStore((ctx) => ctx.setIsLoading);
  const form = useCreateAccountForm(props.model);
  const [step, setStep] = React.useState(1);

  const steps: CPWizardModalStepItem[] = [
    { text: "User Info", step: 1 },
    { text: "Account Type", step: 2 },
    { text: "Roles", step: 3 },
  ];

  showLoader(form.isLoading);

  const renderTemplate = (dataItem: CPAutoCompleteDataItem) => {
    const address = `
      ${dataItem.value?.address ?? ""} 
      ${dataItem.value?.city ?? ""}
      ${dataItem.value?.state ?? ""}
      ${dataItem.value?.zipCode ?? ""}`;

    return (
      <Container spacing={5} padding={5}>
        <CPText fontSize='14px'>{`(${dataItem.value?.externalId}) ${dataItem.label}`}</CPText>
        <Container direction='row' spacing={15}>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {address}
          </CPText>
        </Container>
      </Container>
    );
  };

  return (
    <CPWizardModal
      title={props.title ?? "Manage User"}
      open
      onClose={props.onClose}
      steps={steps}
      currentStep={step}
      onStepSelect={setStep}
      onComplete={form.onSubmit}
      completeBtnText='Save'
      errorMessage={form.formError || form.saveError}
      modalWidth={700}
      completeBtnDisabled={form.disableSubmit()}
    >
      <CPWizardModalContent step={1}>
        <StackLayout gap={10}>
          <CPTextBox
            {...form}
            label='First Name'
            name='firstName'
            value={form.values.firstName}
            errorMsg={form.errors.firstName}
            required
            personNameCharacters
            toCapital
          />

          <CPTextBox
            {...form}
            label='Last Name'
            name='lastName'
            value={form.values.lastName}
            errorMsg={form.errors.lastName}
            required
            personNameCharacters
            toCapital
          />
        </StackLayout>
        <CPEmailTextBox
          {...form}
          name='email'
          label='Email Address'
          value={form.values.email}
          errorMsg={form.errors.email}
          required
        />
        {form.isEdit && (
          <CPText textStyle='caption'>
            *Changing the email will send the user a notice of the change.
          </CPText>
        )}

        <CPPhoneTextBox
          {...form}
          name='phoneNumber'
          label='Phone Number'
          value={form.values.phoneNumber}
          errorMsg={form.errors.phoneNumber}
          required
        />

        {form.isEdit && (
          <StackLayout
            orientation='vertical'
            align={{ horizontal: "stretch" }}
            gap={20}
          >
            <CPTextBox
              {...form}
              name='newPassword'
              label='New Password'
              maxLength={50}
              minLength={6}
              value={form.values.newPassword}
              errorMsg={form.errors.newPassword}
            />
            <Box alignItems={"center"} flexDirection='row' spacing={10}>
              <Switch onChange={form.updateBanned} checked={form.userBanned} />
              <CPText>
                {`${
                  form.userBanned
                    ? "User is banned from site"
                    : "User is allowed access"
                }`}
              </CPText>
            </Box>
          </StackLayout>
        )}
      </CPWizardModalContent>
      <CPWizardModalContent step={2}>
        <AccountTypeDDL {...form} value={form.values.accountType} />

        {form.values.accountType === "Factoring Company" && (
          <CPVirtualAutoComplete
            value={form.selectedCompany}
            label='Company Name *'
            labelKey='name'
            valueKey='externalId'
            name='factoringCompanyExId'
            route='FactoringCompany/Search'
            allowManualEntry={false}
            onChange={form.onCompanySelected}
            helpText='Search by company name.'
            required={true}
            errorMsg={form.errors.factoringCompanyExId}
            useTemplate={renderTemplate}
          />
        )}

        {form.values.accountType === "Carrier" && (
          <StackLayout gap={50} orientation='vertical'>
            <CPCarrierMultiSelect
              onAssign={form.handleAddCarrier}
              currentAssignments={form.values.assignedCarriers}
            />

            <StackLayout orientation='vertical'>
              <CPText>Assigned Carriers</CPText>
              <CPHrBreak spacing={5} />

              <AssignedCarrierList
                carriers={form.values.assignedCarriers}
                onDelete={form.handleRemoveAssignedCarrier}
              />
            </StackLayout>
          </StackLayout>
        )}
      </CPWizardModalContent>
      <CPWizardModalContent step={3}>
        <CPRoleXListBox
          currentRoles={form.values.rolesToAdd}
          onTransfer={form.onTransfer}
        />
      </CPWizardModalContent>
    </CPWizardModal>
  );
};

export default UpsertAccountWizardModal;
