// @intent: Methods for handling a password 'reveal' toggle.

import * as React from "react";

const usePasswordToggle = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  return {
    showPassword,
    toggleShowPassword: () => setShowPassword((prev) => !prev),
    inputType: showPassword ? "text" : "password",
  };
};

export default usePasswordToggle;
