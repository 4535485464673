import { ICPTrackingSession } from "./types";
import { ValueObject } from "common/types";
import TrackingSession from "./models/TrackingSession";

export type TrackingStoreType = "device" | "sessions" | "trackings";

const store = (storageKey: string) => {
  const defaultItem = TrackingSession();

  const setItem = (data: ValueObject) => {
    const dataStr = JSON.stringify(data);
    window.sessionStorage.setItem(storageKey, dataStr);
  };

  const getItem = () => {
    try {
      const dataStr = window.sessionStorage.getItem(storageKey);
      if (dataStr) {
        return JSON.parse(dataStr);
      }

      return defaultItem;
    } catch (err) {
      console.error(err);
      return defaultItem;
    }
  };

  return {
    setItem,
    getItem,
  };
};

function trackingStore(storageKey: string) {
  const storeDB = store(storageKey);

  const getSession = () => {
    const session = storeDB.getItem() as ICPTrackingSession;
    return session;
  };

  const saveSession = (data: ICPTrackingSession) => {
    if (data.sessionId == null) return;

    const current = getSession();

    if (current != null && current.tracking) {
      const events = data.tracking.filter(
        (x) => current.tracking.some((y) => y._uid === x._uid) === false
      );

      data.tracking = [...current.tracking, ...events];
    }

    storeDB.setItem({ ...(current ?? {}), ...data });
  };

  const resetSession = () => {
    const session = getSession();

    // Clear out the array;
    session.tracking.length = 0;

    storeDB.setItem(session);
  };

  const init = async () => {
    const session = getSession();
    if (session == null) {
      storeDB.setItem(TrackingSession());
    }
  };

  init();

  return {
    resetSession,
    getSession,
    saveSession,
  };
}

const instance = trackingStore("CP_Analytics");
export default instance;
