// @intent: Handle user logins
import http from "common/http";
import { useMutation } from "react-query";
import { ILoginRequest } from "../types";
import { accessTokenName, refreshTokenName } from "../../../common/config";
import { removeCookie, storeCookie } from "../../../common/helpers";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import TokenResponse from "../types/TokenResponse";

const useLoginCommands = () => {
  const command = useMutation(
    async (creds: ILoginRequest) => {
      const result = TokenResponse();
      try {
        removeCookie(accessTokenName);
        removeCookie(refreshTokenName);

        const tokenResponse = await http.postAsync("Token/Validate", creds);

        if (!tokenResponse) {
          result.error = `Error occurred during token request.`;
          return result;
        }

        if (!tokenResponse.success) {
          const tokenError =
            (tokenResponse.errors && tokenResponse.errors[0]) ||
            `Error occurred during token request.`;
          console.error(tokenError);
          result.error = tokenError;
          return result;
        }

        if (tokenResponse.accessToken) {
          storeCookie(
            accessTokenName,
            tokenResponse.accessToken.token,
            tokenResponse.accessToken.expiresAt ??
              dayjs(Date.now()).add(1, "hour").toDate().toUTCString()
          );

          http.setAuthHeader(tokenResponse.accessToken.token);

          result.accessToken = tokenResponse.accessToken.token;
        }

        if (tokenResponse.refreshToken) {
          storeCookie(
            refreshTokenName,
            tokenResponse.refreshToken.token,
            tokenResponse.refreshToken.expiresAt ??
              dayjs(Date.now()).add(7, "days").toDate().toUTCString()
          );

          result.refreshToken = tokenResponse.refreshToken.token;
        }
      } catch (err) {
        const error = err as AxiosError;
        console.error(error);

        const responseErr =
          error && error.response ? (error.response.data as any[]) : null;
        if (responseErr && responseErr.indexOf("TOKEN_INVALID") > -1) {
          removeCookie(refreshTokenName);
        }

        if (typeof err === "string" && err.indexOf("User is banned") > -1) {
          removeCookie(refreshTokenName);
          result.error =
            "This account is locked. Contact Help Desk for further assistance.";
        }

        result.error = "Email or password is incorrect.";
      }

      return result;
    },
    {
      mutationKey: "Auth",
    }
  );

  return {
    isLoading: command.isLoading,
    loginAsync: command.mutateAsync,
    result: command.data ?? TokenResponse(),
  };
};

export default useLoginCommands;
