// @intent: display a form for setting up report subscriptions
import * as React from "react";
import useReportSchedulerForm from "../hooks/useReportSchedulerForm";
import { StackLayout } from "@progress/kendo-react-layout";
import ReportListDDL from "./ReportListDDL";
import CPDropDownList from "components/shared/CPDropDownList/CPDropDownList";
import { IReportScheduleRequest, ReportScheduleInterval } from "../types";
import CPButton from "components/shared/CPButton/CPButton";
import CPDaysOfWeekBtnGrp from "../../../components/shared/CPDaysOfWeekBtnGrp/CPDaysOfWeekBtnGrp";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPTimePicker from "components/shared/CPTimePicker/CPTimePicker";
import CPEmailTextBox, {
  CPEmailTextBoxChangeEvent,
} from "components/shared/CPEmailTextBox/CPEmailTextBox";

import CPDayOfMonthPicker from "components/shared/CPDayOfMonthPicker/CPDayOfMonthPicker";
import { CPHrBreak, CPText } from "components/base";

interface ReportSchedulerFormProps {
  defaultEmail?: string;
  initData?: IReportScheduleRequest;
  onComplete?: () => void;
}

const ReportSchedulerForm: React.FC<ReportSchedulerFormProps> = (props) => {
  const form = useReportSchedulerForm({
    onComplete: props.onComplete,
    formData: { sendToEmail: props.defaultEmail, ...props.initData },
  });

  return (
    <StackLayout orientation='vertical' gap={20}>
      <StackLayout gap={20}>
        <ReportListDDL
          reportId={form.values.reportId}
          onSelect={form.handleSelectedReport}
        />
        <CPEmailTextBox
          label='Send Report To'
          name='sendToEmail'
          value={form.values.sendToEmail}
          onChange={(event: CPEmailTextBoxChangeEvent) =>
            form.updateForm({ sendToEmail: event.value as string })
          }
          required
        />
      </StackLayout>

      <CPDropDownList
        label='Schedule Interval *'
        name='scheduleInterval'
        data={form.scheduleOptions}
        textField='label'
        helpText='Select how often you want to receive report.'
        onChange={(event) =>
          form.handleSelectedScheduleInterval(
            event.value as ReportScheduleInterval
          )
        }
        valueKey='value'
        value={form.values.scheduleInterval}
      />
      {form.values.scheduleInterval === "weekly" && (
        <CPDaysOfWeekBtnGrp
          label='Send on *'
          onSelect={form.handleSelectedDaysOfWeek}
          selectedOptions={form.values.daysOfWeek ?? []}
        />
      )}
      {form.values.scheduleInterval === "monthly" && (
        <CPDayOfMonthPicker
          label='Select day of month'
          value={form.values.dayOfMonth}
          onClick={(day: number) => form.updateForm({ dayOfMonth: day })}
          helpText="If selected day exceeds the current month's total days, then report will be sent on the last day of that month."
        />
      )}
      {/* {form.values.scheduleInterval != null && (
        <CPTimePicker
          label='Send At *'
          name='timeOfDay'
          onChange={form.handleSelectedTimeOfDay}
          steps={{
            hour: 1,
            minute: 15,
          }}
        />
      )} */}

      <CPErrorMessage errorMsg={form.saveError} />

      <CPHrBreak />

      <StackLayout gap={20} style={{ marginTop: 20 }}>
        {form.values.id == null && (
          <CPButton fillMode={"outline"} onClick={form.resetForm}>
            Reset
          </CPButton>
        )}
        <CPButton
          showLoader={form.isLoading}
          themeColor={"primary"}
          onClick={form.onSubmit}
          disabled={!form.isValid}
        >
          {form.values.id ? `Update` : `Subscribe`}
        </CPButton>
      </StackLayout>

      <CPText fontSize='14px'>
        Note: Reports are run one time per day each morning. If you do not see
        your reports by 9AM EST please contact helpdesk@greatwide-tm.com.
      </CPText>
    </StackLayout>
  );
};

export default ReportSchedulerForm;
