// @intent: Form for user contact information

import { CPText } from "components/base";
import useContactInfoForm from "features/Users/hooks/useContactInfoForm";
import * as React from "react";
import CPTextBox from "../../../components/shared/CPTextBox/CPTextBox";
import { StackLayout } from "@progress/kendo-react-layout";
import { Form } from "@progress/kendo-react-form";
import CPErrorMessage from "../../../components/shared/CPErrorMessage/CPErrorMessage";
import CPButton from "../../../components/shared/CPButton/CPButton";
import CPPhoneTextBox from "../../../components/shared/CPPhoneTextBox/CPPhoneTextBox";
import { ButtonClickTracking, useCPTracking } from "analytics";
import CPEmailTextBox from "../../../components/shared/CPEmailTextBox/CPEmailTextBox";
import CPFontAwesome from "../../../components/shared/CPFontAwesome/CPFontAwesome";
import { IUserProfileContact } from "../types";

interface ContactInfoFormProps {
  userContactData: IUserProfileContact;
}

const ContactInfoForm: React.FC<ContactInfoFormProps> = (props) => {
  const { trackingEventDelegate } = useCPTracking();
  const form = useContactInfoForm(props.userContactData);
  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={5}>
        <CPErrorMessage errorMsg={form.apiError} align='center' />
        <StackLayout gap={50}>
          <CPTextBox
            {...form}
            name='firstname'
            value={form.values.firstname}
            errorMsg={form.errors.firstname}
            label='First name'
            required
            personNameCharacters
          />
          <CPTextBox
            {...form}
            name='lastname'
            value={form.values.lastname}
            errorMsg={form.errors.lastname}
            label='Last name'
            required
            personNameCharacters
          />
        </StackLayout>

        <CPEmailTextBox
          {...form}
          name='email'
          label='Email Address'
          value={form.values.email}
          errorMsg={form.errors.email}
          required
        />
        <CPText textStyle='caption' gutterBottom>
          <CPFontAwesome icon='fa-solid fa-circle-info' inline />
          Note: Updating the email address requires verification before contact
          info is saved.
        </CPText>

        <CPPhoneTextBox
          {...form}
          name='phone'
          label='Phone'
          value={form.values.phone}
          errorMsg={form.errors.phone}
          required
        />

        <CPPhoneTextBox
          {...form}
          name='fax'
          label='Fax'
          value={form.values.fax}
          errorMsg={form.errors.fax}
        />
        <CPButton
          size={"large"}
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Update Contact Info")
          )}
          showLoader={form.isLoading}
        >
          Update Contact Info
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default ContactInfoForm;
