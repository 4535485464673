// @intent: Methods and handlers for report forms
import * as React from "react";
import {
  IReportListOption,
  IReportScheduleRequest,
  ReportScheduleInterval,
  ReportScheduleIntervalDayOfWeek,
} from "../types";
import ReportScheduleRequest from "../types/ReportScheduleRequest";
import { ValueObject } from "common/types";
import useScheduleReportCommand from "../api/useScheduleReportCommand";
import { CPTimePickerChangeEvent } from "components/shared/CPTimePicker/CPTimePicker";
import { toast } from "react-toastify";

interface useReportSchedulerFormOptions {
  formData?: IReportScheduleRequest;
  onComplete?: () => void;
}

const scheduleOptions = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

const useReportSchedulerForm = (options?: useReportSchedulerFormOptions) => {
  const [form, setForm] = React.useState(
    ReportScheduleRequest({ ...options?.formData })
  );
  const subscribeCommand = useScheduleReportCommand();

  const formErrors = React.useMemo(() => {
    const errors: ValueObject = {};

    if (!form.scheduleInterval) {
      errors.scheduleInterval = `Please select a schedule interval.`;
    }

    if (!form.reportId) {
      errors.reportId = `Please select a report.`;
    }

    if (
      form.scheduleInterval === "weekly" &&
      (form.daysOfWeek == null || form.daysOfWeek.length === 0)
    ) {
      errors.daysOfWeek = `Please select a day of week.`;
    }

    // if (form.timeOfDay == null) {
    //   errors.timeOfDay = `Please select a time of day.`;
    // }

    if (form.scheduleInterval === "monthly" && form.dayOfMonth == null) {
      errors.specificDate = `Please select a day of month.`;
    }

    if (!form.sendToEmail) {
      errors.sendToEmail = `Please enter an email address.`;
    }

    return errors;
  }, [form]);

  const isValid = React.useMemo(() => {
    return Object.keys(formErrors).length === 0;
  }, [formErrors]);

  const handleSubmit = async () => {
    if (!isValid) {
      return;
    }

    try {
      await subscribeCommand.mutateAsync(form);
      handleReset();
      toast.success("Report subscription saved.");

      if (options?.onComplete) {
        options.onComplete();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleReset = () => {
    setForm(ReportScheduleRequest({ ...options?.formData }));
    subscribeCommand.clear();
  };

  const handleUpdateForm = (data: ValueObject) => {
    setForm((prev) => ({
      ...prev,
      ...data,
    }));
  };

  return {
    values: form,
    errors: formErrors,
    isValid,
    saveError: subscribeCommand.error as string,
    updateForm: (data: ValueObject) => {
      setForm((prev) => ({ ...prev, ...data }));
    },
    onSubmit: handleSubmit,
    handleSelectedReport: (option?: IReportListOption) => {
      handleUpdateForm({ reportId: option?.id });
    },

    scheduleOptions,
    handleSelectedScheduleInterval: (item: ReportScheduleInterval) => {
      handleUpdateForm({
        scheduleInterval: item,
        dayOfMonth: undefined,
        daysOfWeek: [],
      });
    },
    resetForm: handleReset,
    handleSelectedDaysOfWeek: (items: string[]) => {
      handleUpdateForm({
        daysOfWeek: items as ReportScheduleIntervalDayOfWeek[],
      });
    },
    handleSelectedTimeOfDay: (event: CPTimePickerChangeEvent) => {
      handleUpdateForm({
        timeOfDay: event.value,
      });
    },
    isLoading: subscribeCommand.isLoading,
  };
};

export default useReportSchedulerForm;
