// @intent: Container for user management grid and form components
import * as React from "react";
import UserGrid from "../../../features/Users/components/UserGrid";
import { PageVisitTracking, useCPTracking } from "analytics";
import CPPanel from "components/shared/CPPanel/CPPanel";

const UserManagementPage: React.FC = () => {
  useCPTracking(PageVisitTracking());

  return (
    <CPPanel header='User Listing'>
      <UserGrid />
    </CPPanel>
  );
};

export default UserManagementPage;
