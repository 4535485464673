// @intent: api mutation command to delete user saved grid filter

import http from "common/http";
import { useMutation } from "react-query";

const useDeleteSavedGridFilterCommand = () => {
  const command = useMutation((id: number) => {
    return http.deleteAsync("UserProfile/SavedGridFilter", id);
  });

  return command;
};

export default useDeleteSavedGridFilterCommand;
