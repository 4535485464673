// @intent: Send password reset request

import http from "common/http";
import { useMutation } from "react-query";
import { IPasswordReset } from "../types";

const useResetPasswordCommand = () => {
  const command = useMutation((payload: IPasswordReset) => {
    return http.postAsync("Account/ResetPassword", payload);
  });

  return command;
};

export default useResetPasswordCommand;
