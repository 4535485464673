// @intent: Container for login request grid / page
import * as React from "react";
import LoginRequestsGrid from "../../../features/LoginRequests/components/LoginRequestsGrid";
import { PageVisitTracking, useCPTracking } from "analytics";
import CPPanel from "components/shared/CPPanel/CPPanel";

const LoginRequestsPage: React.FC = () => {
  useCPTracking(PageVisitTracking());
  return (
    <CPPanel header='Login Requests'>
      <LoginRequestsGrid />
    </CPPanel>
  );
};

export default LoginRequestsPage;
