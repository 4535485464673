import * as React from "react";
import classNames from "classnames";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import { isDatesEqual } from "./CPFilterOperators";

export interface CPDateFilterProps extends GridFilterCellProps {
  placeholder?: string;
}

const CPDateFilter: React.FC<CPDateFilterProps> = (props) => {
  const onChange = (event: DatePickerChangeEvent) => {
    props.onChange({
      value: event.value,
      operator: isDatesEqual,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const value = props.value || null;

  return (
    <DatePicker
      value={value}
      placeholder={props.placeholder ?? "MM/DD/YYYY"}
      onChange={onChange}
      className={classNames("cp-filter-base")}
    />
  );
};

export default CPDateFilter;
