import * as React from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";

import http from "../../../common/http";
import { CPText } from "../../../components/base";
import CPButton from "../../../components/shared/CPButton/CPButton";

import useAppStore from "../../../state/stores/useAppStore";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";

interface DenyRequestFormProps {
  id: number;
  reason?: string;
  onComplete: () => void;
}

const DenyRequestForm: React.FC<DenyRequestFormProps> = (props) => {
  const setSuccessMsg = useAppStore((app) => app.setToastMsg);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [reason, setReason] = React.useState<string>(props.reason ?? "");
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleSave = useEnterKeySubmit(async () => {
    try {
      setErrMsg("");
      setIsProcessing(true);
      await http.postAsync("LoginRequests/DenyLoginRequest", {
        id: props.id,
        reason,
      });
      setIsProcessing(false);
      setSuccessMsg("Request status updated.", "success");
      props.onComplete();
    } catch (err) {
      console.error(err);
      setErrMsg(err as string);
      setIsProcessing(false);
    }
  });

  return (
    <StackLayout orientation='vertical' gap={10}>
      {errMsg && (
        <CPText color={"error"} align='center'>
          {errMsg}
        </CPText>
      )}
      <TextArea
        id='reasonInput'
        name='reason'
        value={reason}
        rows={8}
        onChange={(event) => setReason(event.target.value as string)}
        placeholder='Enter optional reason for decision.'
      />
      <CPButton
        themeColor={"primary"}
        fillMode={"solid"}
        onClick={() => handleSave()}
        showLoader={isProcessing}
      >
        Save
      </CPButton>
    </StackLayout>
  );
};

export default DenyRequestForm;
