// @intent: Display form for setting up carriers manually.

import * as React from "react";
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";

import CPButton from "../../../../../components/shared/CPButton/CPButton";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";

import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPhoneTextBox from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import { CPText, Container, Link } from "components/base";
import {
  ButtonClickTracking,
  PageVisitTracking,
  useCPTracking,
} from "analytics";
import useCarrierManualForm from "features/LoginRequests/hooks/useCarrierManualForm";
import CPVirtualAutoComplete from "components/shared/CPVirtualAutoComplete/CPVirtualAutoComplete";
import { ValueObject } from "common/types";
import { CPAutoCompleteDataItem } from "components/types";

interface CarrierManualFormProps {
  onSelfServiceSetupClick: () => void;
  onSuccess: (message: string) => void;
}

const CarrierManualForm: React.FC<CarrierManualFormProps> = ({
  onSelfServiceSetupClick,
  onSuccess,
}) => {
  const { trackingEventDelegate } = useCPTracking(
    PageVisitTracking("Register/CarrierManualRequest")
  );
  const form = useCarrierManualForm(onSuccess);

  const renderCarrierSearchTemplate = (dataItem: CPAutoCompleteDataItem) => {
    return (
      <Container spacing={5} padding={5}>
        <CPText fontSize='14px'>{dataItem.label}</CPText>
        <Container direction='row' spacing={15}>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {`DOT: ${dataItem.value.dotNumber ?? "N/A"}`}
          </CPText>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {`MC: ${dataItem.value.mcNumber ?? "N/A"}`}
          </CPText>
        </Container>
      </Container>
    );
  };

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={5}>
        <StackLayout orientation='vertical'>
          <CPText align='center' textStyle='subtitle'>
            Request Carrier Access
          </CPText>
          <CPText align='center'>
            Request to have your account details reviewed and approved manually.
          </CPText>
          <Link
            as='button'
            onClick={() => onSelfServiceSetupClick()}
            style={{ fontSize: "1em" }}
          >
            Use Self Service Option
          </Link>
        </StackLayout>
        <CPErrorMessage errorMsg={form.apiError} align='center' />
        <StackLayout gap={10}>
          <CPTextBox
            {...form}
            name='firstName'
            value={form.values.firstName}
            errorMsg={form.errors.firstName}
            label='First Name'
            required
            personNameCharacters
            toCapital
          />
          <CPTextBox
            {...form}
            name='lastName'
            value={form.values.lastName}
            errorMsg={form.errors.lastName}
            label='Last Name'
            required
            personNameCharacters
            toCapital
          />
        </StackLayout>

        <CPEmailTextBox
          {...form}
          name='email'
          label='Email Address'
          value={form.values.email}
          errorMsg={form.errors.email}
          required
        />
        <CPPhoneTextBox
          {...form}
          name='phoneNumber'
          label='Phone Number'
          value={form.values.phoneNumber}
          errorMsg={form.errors.phoneNumber}
          required
        />

        <CPVirtualAutoComplete
          value={form.selectedCompany}
          label='Company Name'
          labelKey='name'
          name='companyName'
          route='Carrier/Search'
          onChange={form.onCompanyNameSelected}
          errorMsg={form.errors.companyName}
          allowManualEntry
          useTemplate={renderCarrierSearchTemplate}
        />
        <CPTextBox
          {...form}
          name='companyMCNumber'
          value={form.values.companyMCNumber}
          label='Company MC Number'
          errorMsg={form.errors.companyMCNumber}
          required
          alphaNumeric
          disableSpaces
        />

        <CPTextBox
          {...form}
          name='companyDOTNumber'
          value={form.values.companyDOTNumber}
          label='Company DOT Number'
          errorMsg={form.errors.companyDOTNumber}
          required
          alphaNumeric
          disableSpaces
          toUppercase
        />

        <CPButton
          size={"large"}
          icon='track-changes-enable'
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Request Access - Carrier Manual", { ...form })
          )}
          showLoader={form.isLoading}
        >
          Request Access
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default CarrierManualForm;
