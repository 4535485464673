// @intent: Container for registration forms.

import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Container,
  CPText,
  Box,
  IconButton,
} from "../../../components/base";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
  CardFooter,
  StackLayout,
  Stepper,
  StepperChangeEvent,
} from "@progress/kendo-react-layout";

import "./RegisterPageStyles.scss";
import RegisterFormBody from "./components/RegisterFormBody/RegisterFormBody";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";
import CPButton from "components/shared/CPButton/CPButton";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = React.useState<string>("");
  const [registerStep, setRegisterStep] = React.useState<number>(0);
  const [successMsg, setSuccessMsg] = React.useState("");

  const handleRegistrationStepChange = (event: StepperChangeEvent) => {
    const step = event.value;

    // Only allow going back, but not on last step. The 'completed' step.
    if (step < registerStep && registerStep !== 2) {
      setRegisterStep(step);
    }
  };

  const handleAccountTypeSelect = (type: string) => {
    setRegisterStep(1);
    setAccountType(type);
  };

  const renderCardBody = () => {
    const handleSuccess = (message: string) => {
      setSuccessMsg(message);
      setRegisterStep(2);
    };

    if (registerStep === 1) {
      return (
        <StackLayout orientation='vertical' gap={10}>
          <RegisterFormBody
            renderForm={accountType}
            onSuccess={handleSuccess}
          />
          <CPButton fillMode={"flat"} onClick={() => setRegisterStep(0)}>
            Go Back
          </CPButton>
        </StackLayout>
      );
    }

    if (registerStep === 2) {
      return (
        <StackLayout style={{ padding: 20 }} orientation='vertical' gap={10}>
          <CPText color='success' textStyle='subtitle' align='center'>
            {successMsg}
          </CPText>
          <Link as='button' onClick={() => navigate("/")}>
            Go Home
          </Link>
        </StackLayout>
      );
    }

    return (
      <StackLayout orientation='vertical' gap={30}>
        <CPText align='center'>
          Please choose how you would like to access the carrier portal:
        </CPText>
        <StackLayout gap={30} orientation='vertical'>
          <Box
            className='account-type-button'
            onClick={() => handleAccountTypeSelect("Carrier")}
          >
            <CPFontAwesome icon='fa-solid fa-truck-moving' size={20} />
            <CPText bold>Carrier Access</CPText>
          </Box>
          <Box
            className='account-type-button'
            onClick={() => handleAccountTypeSelect("Factoring Company")}
          >
            <CPFontAwesome icon='fa-solid fa-money-bill-transfer' size={20} />
            <CPText bold>Factoring Company Access</CPText>
          </Box>
        </StackLayout>

        <CPButton
          fillMode={"flat"}
          themeColor={"inverse"}
          onClick={() => handleAccountTypeSelect("Employee")}
        >
          Request Employee Access
        </CPButton>
      </StackLayout>
    );
  };

  return (
    <Card className='request-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center' style={{ flex: 1 }}>
            Register Account
          </CPText>
        </CardTitle>
        <CardSubtitle>
          <StackLayout
            orientation={"vertical"}
            gap={20}
            style={{ marginBottom: 10 }}
          >
            <CPText textStyle='subtitle' align='center'>
              Register to get pending and historical payment information from
              the Evans Network of Companies
            </CPText>
            <Stepper
              linear={true}
              value={registerStep}
              onChange={handleRegistrationStepChange}
              items={[
                { icon: "k-i-user", label: "Account Type" },
                { icon: "k-i-track-changes", label: "Registration" },
                { icon: "k-i-check", label: "Complete" },
              ]}
            />
          </StackLayout>
        </CardSubtitle>
      </CardHeader>
      <CardBody>{renderCardBody()}</CardBody>
      <CardFooter>
        <StackLayout
          align={{ vertical: "middle", horizontal: "start" }}
          gap={10}
        >
          <CPText>Have an account?</CPText>
          <Link as='button' title='Login Here' onClick={() => navigate("/")} />
        </StackLayout>
      </CardFooter>
    </Card>
  );
};

export default RegisterPage;
