// @intent: Display grid and handle user interactions for invoice searches
import * as React from "react";
import { CPActionItem, CPActionList, CPGrid } from "components/shared/CPGrid";
import CPDefaultActionList from "components/shared/CPGrid/CPDefaultActionList";
import useFCompanyGridMethods from "features/FCompanies/hooks/useFCompanyGridMethods";
import { StackLayout } from "@progress/kendo-react-layout";
import { Container } from "components/base";
import { ButtonClickTracking, useCPTracking } from "analytics";
import CPModal from "components/shared/CPModal/CPModal";
import CPButton from "components/shared/CPButton/CPButton";
import AssignCarrierModal from "features/FCompanies/components/AssignCarrierModal";

const FCompaniesGrid: React.FC = () => {
  const { trackingEventDelegate } = useCPTracking();
  const GRIDNAME = "fCompaniesGrid";
  const fCompanyGrid = useFCompanyGridMethods(GRIDNAME);

  const renderToolBar = () => {
    return (
      <StackLayout gap={10} align={{ vertical: "middle" }}>
        <CPDefaultActionList
          dataAdapter={{
            ...fCompanyGrid.gridAdapter,
            onSaveSearch: fCompanyGrid.onSaveSearch,
          }}
        >
          <CPActionItem
            label='Clear Selection'
            onSelect={fCompanyGrid.selectableOptions.clearSelection}
          />
        </CPDefaultActionList>
        <CPButton
          className='btn-small'
          disabled={!fCompanyGrid.selectableOptions.selectedItem}
          onClick={trackingEventDelegate(
            () => fCompanyGrid.onAssignBtnClick("assign"),
            ButtonClickTracking("Assign Carrier", {
              ...fCompanyGrid.selectableOptions.selectedItem,
            })
          )}
        >
          Assign Carriers
        </CPButton>
      </StackLayout>
    );
  };

  return (
    <Container>
      <CPGrid
        {...fCompanyGrid.selectableOptions}
        name={GRIDNAME}
        dataAdapter={fCompanyGrid.gridAdapter}
        filterable={true}
        sortable={true}
        pageable={true}
        showToolbar={true}
        renderToolBar={renderToolBar}
      />

      {fCompanyGrid.openAssignModal && (
        <AssignCarrierModal
          onClose={fCompanyGrid.closeModal}
          factoringCompanyExId={
            fCompanyGrid.selectableOptions.selectedItem?.externalId
          }
          factoringCompanyName={
            fCompanyGrid.selectableOptions.selectedItem?.name
          }
        />
      )}

      {/* <CPModal
        width={400}
        open={fCompanyGrid.openBatchUpload}
        title='Batch Assign'
        onClose={fCompanyGrid.closeModal}
      ></CPModal> */}
    </Container>
  );
};

export default FCompaniesGrid;
