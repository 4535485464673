// @intent: Display item for action list
import { MenuItem } from "@progress/kendo-react-layout";
import * as React from "react";

export interface CPActionItemProps {
  label: string;
  onSelect?: () => void;
}

const CPActionItem: React.FC<CPActionItemProps> = (props) => {
  return (
    <MenuItem
      text={props.label}
      key={props.label}
      cssStyle={{ fontSize: ".8em" }}
    />
  );
};

export default CPActionItem;
