// @intent: Display a dropdown select list of account types using a different event handler

import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import * as React from "react";

interface AccountTypeDDLProps {
  value?: string;
  onAccountSelected?: (event: DropDownListChangeEvent) => void;
}

const AccountTypeDDL: React.FC<AccountTypeDDLProps> = (props) => {
  return (
    <DropDownList
      {...props}
      name='accountType'
      id='accountType'
      onChange={props.onAccountSelected}
      label='Account Type *'
      data={["Carrier", "Employee", "Factoring Company"]}
      defaultItem='Select Account Type...'
    />
  );
};

export default AccountTypeDDL;
