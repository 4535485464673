// @intent: Provide methods and handlers for listing/adding/updating/removing carrier contacts
import * as React from "react";
import useUpsertCarrierContactListCommand from "../api/useUpsertCarrierContactListCommand";
import { ICarrierContact } from "../types";
import useGetCarrierContactsQuery from "../api/useGetCarrierContactsQuery";
import useDeleteCarrierContactCommand from "../api/useDeleteCarrierContactCommand";
import { toast } from "react-toastify";
import useAppStore from "state/stores/useAppStore";

const useUpsertCarrierContacts = (carrierExId?: string) => {
  const isLoading = useAppStore((ctx) => ctx.setIsLoading);
  const [upsertContacts, setUpsertContacts] = React.useState<ICarrierContact[]>(
    []
  );
  const upsertListCommand = useUpsertCarrierContactListCommand();
  const deleteContactCommand = useDeleteCarrierContactCommand();
  const { contacts: currentContacts, refresh } =
    useGetCarrierContactsQuery(carrierExId);
  const contacts = React.useMemo(() => {
    return [
      ...upsertContacts,
      ...currentContacts.filter(
        (x: ICarrierContact) =>
          upsertContacts.some((y) => x._uid === y._uid || x.id === y.id) ===
          false
      ),
    ];
  }, [upsertContacts, currentContacts]);

  const addContact = (contact: ICarrierContact) => {
    const updated = contacts.filter((x) => x._uid !== contact._uid);
    setUpsertContacts([...updated, contact]);
  };

  const updateContact = (contact: ICarrierContact) => {
    if (contact.carrierExId) {
      isLoading(true);
      upsertListCommand
        .mutateAsync([contact])
        .then(() => {
          isLoading(false);
          toast.success(
            `Contact ${contact.id == null ? "Saved." : "Updated."}`
          );
          refresh();
        })
        .catch((err) => {
          isLoading(false);
          console.error(err);
        });
    }
  };

  const removeContact = (contact: ICarrierContact) => {
    if (contact.id != null) {
      isLoading(true);
      deleteContactCommand
        .mutateAsync(contact.id)
        .then(() => {
          isLoading(false);
          toast.success(`Contact Removed.`);
          refresh();
        })
        .catch((err) => {
          isLoading(false);
          console.error(err);
        });
    } else {
      const updated = contacts.filter((x) => x._uid !== contact._uid);
      setUpsertContacts(updated);
    }
  };

  return {
    addContact,
    updateContact,
    removeContact,
    isLoading: upsertListCommand.isLoading || deleteContactCommand.isLoading,
    contacts,
  };
};

export default useUpsertCarrierContacts;
