// @intent: Container for factoring company grid and management components
import { PageVisitTracking, useCPTracking } from "analytics";
import { Container } from "components/base";
import * as React from "react";
import FCompaniesGrid from "../../../features/FCompanies/components/FCompaniesGrid";
import CPPanel from "components/shared/CPPanel/CPPanel";

const FactoringCompaniesPage: React.FC = () => {
  useCPTracking(PageVisitTracking());
  return (
    <CPPanel header='Factoring Company Match'>
      <FCompaniesGrid />
    </CPPanel>
  );
};

export default FactoringCompaniesPage;
