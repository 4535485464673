// @intent: Methods for handling interactions for user grid
import * as React from "react";

import {
  useCPGridSavedSearches,
  useCPVirtualGridAdapter,
} from "components/shared/CPGrid";

import { CPGridColumn, CPGridState } from "components/shared/CPGrid/types";
import useAppStore from "state/stores/useAppStore";
import useUserStore from "state/stores/useUserStore";

import useSelectableOptions from "components/shared/CPGrid/Hooks/useSelectableOptions";
import useGetUserToEditQuery from "features/Users/api/useGetUserToEditQuery";
import { CPText } from "components/base";

const useUserGridMethods = (gridName: string) => {
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const { userToEdit, execute, reset, isLoading } = useGetUserToEditQuery();
  const userId = useUserStore((user) => user.userId);
  const isInRole = useUserStore((ctx) => ctx.isInRole);
  const toast = useAppStore((app) => app.setToastMsg);
  const setIsLoading = useAppStore((app) => app.setIsLoading);
  const { savedSearch, saveSearch } = useCPGridSavedSearches({
    route: "UserProfile/SavedSearchFilters",
    defaultRouteParams: {
      url: window.location.pathname,
      userId,
      name: gridName,
    },
  });

  React.useEffect(() => {
    setIsLoading(isLoading ?? false);
  }, [isLoading, setIsLoading]);

  const columns: CPGridColumn[] = [
    { field: "name", title: "Name" },
    { field: "email", title: "Email", filterType: "email" },
    {
      field: "companyName",
      title: "Company",
      width: 200,
      noWrap: true,
    },
    {
      field: "accountType",
      title: "Account Type",
      filterType: "dropdownlist",
      dropdownListOptions: ["Carrier", "Factoring Company", "Employee"],
    },
    {
      field: "phone",
      title: "Phone",
      filterType: "phone_number",
    },
  ];

  const columnsToUse = savedSearch?.columns ?? columns;

  const gridAdapter = useCPVirtualGridAdapter({
    columns: columnsToUse,
    route: "User/Search",
    setGridState: savedSearch,
  });

  const selectableOptions = useSelectableOptions({
    dataItemKey: "id",
    selectedField: "selected",
    data: gridAdapter.records.data,
    columns: gridAdapter.gridState.columns,
  });

  const onSaveSearch = (gridState?: CPGridState) => {
    const state = { ...gridAdapter.gridState, ...gridState };
    saveSearch(state, {
      onComplete: () => toast("Search saved successfully."),
    });
  };

  const toggleModal = (open: boolean) => {
    if (open === false) {
      gridAdapter.refresh();
      reset();
    }

    setOpenEditModal(open);
  };

  const handleEditUser = async () => {
    const user = selectableOptions.selectedItem;
    execute(user.id);
  };

  return {
    canCreateAccount: () => isInRole(["Admin", "User Creator"]),
    canEditAccount: () => isInRole(["Admin", "User Creator"]),
    openModal: openEditModal || userToEdit != null,
    toggleModal,
    onSaveSearch,
    gridAdapter,
    selectableOptions,
    columns: isInRole(["Admin", "User Creator"])
      ? selectableOptions.columns
      : gridAdapter.gridState.columns,
    editButtonDisabled: selectableOptions.selectedItem == null,
    editUser: handleEditUser,
    userToEdit,
  };
};

export default useUserGridMethods;
