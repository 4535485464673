import { IReportRequest } from "./types";

const ReportRequest = (
  initData: IReportRequest | undefined = {
    carriers: [],
    reportId: undefined,
    reportDates: { start: null, end: null },
    reportName: undefined,
  }
) => {
  return {
    ...initData,
  };
};

export default ReportRequest;
