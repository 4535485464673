// @intent: Display a reorder columnds list for grid column order
import CPSortableList, {
  CPSortableListItem,
} from "components/shared/CPSortableList/CPSortableList";
import * as React from "react";
import { CPGridColumn } from "../types";

interface CPReorderColumnsListProps {
  columns: CPGridColumn[];
  onUpdate: (sortedColumns: CPGridColumn[]) => void;
}

const CPReorderColumnsList: React.FC<CPReorderColumnsListProps> = (props) => {
  const data = props.columns
    .filter((x) => !x.pinned && !x.hide)
    .map((x, i) => ({
      key: x.title ?? i,
      text: x.title,
    }));

  const handleSort = (items: CPSortableListItem[]) => {
    const updatedCols = items.reduce((prev, item) => {
      const column = props.columns.find((x) => x.title === item.key);

      if (column) {
        prev.push({ ...column });
      }

      return prev;
    }, [] as CPGridColumn[]);

    const nonMatches = props.columns.filter(
      (x) => updatedCols.some((y) => x.title === y.title) === false
    );

    props.onUpdate([...updatedCols, ...nonMatches]);
  };

  return <CPSortableList data={data} onSort={handleSort} />;
};

export default CPReorderColumnsList;
