// @intent: Methods for handling saving and updating user saved searches
import * as React from "react";
import { ValueObject } from "common/types";
import useUserSavedSearches from "hooks/common/useUserSavedSearches";
import { CPAutoCompleteDataItem } from "components/types";

const useSaveSearchForm = (
  pageName: string,
  searchToSave: ValueObject,
  onSave?: () => void
) => {
  const [searchName, setSearchName] = React.useState<string | undefined>();
  const { searches, saveSearch } = useUserSavedSearches({
    pageName,
    searchToSave,
  });

  const disableSaveBtn = React.useMemo(() => {
    return searchName == null || searchName.length === 0;
  }, [searchName]);

  const showWarning = React.useMemo(() => {
    return (
      searchName &&
      searches.some(
        (x) => x.searchName.toUpperCase() === searchName.toUpperCase()
      )
    );
  }, [searchName, searches]);

  const handleSave = async () => {
    if (searchName) {
      await saveSearch(searchName);
      if (onSave) {
        onSave();
      }
    }
  };

  const handleChange = (selectedItem?: CPAutoCompleteDataItem | undefined) => {
    setSearchName(selectedItem?.inputValue);
  };

  return {
    searches,
    saveSearch: handleSave,
    disableSaveBtn,
    handleChange,
    showWarning,
  };
};

export default useSaveSearchForm;
