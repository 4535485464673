// @intent: Save a user search
import { objToString } from "common/helpers";
import http from "common/http";
import { ValueObject } from "common/types";
import { IUserSavedSearch } from "features/Users";

import { useMutation } from "react-query";

const useSaveUserSearchCommand = (route: string) => {
  const command = useMutation((payload: IUserSavedSearch) => {
    if (typeof payload.searchParams !== "string") {
      payload.searchParams = objToString(payload.searchParams as ValueObject);
    }

    return http.postAsync(route, payload);
  });

  return command;
};

export default useSaveUserSearchCommand;
