// @intent: display a button group for selecting days of week
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import * as React from "react";
import "./CPDaysOfWeekBtnGrpStyles.scss";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";

const DAY_OPTIONS = [
  { label: "Sun", value: "SUN" },
  { label: "Mon", value: "MON" },
  { label: "Tues", value: "TUE" },
  { label: "Wed", value: "WED" },
  { label: "Thurs", value: "THU" },
  { label: "Fri", value: "FRI" },
  { label: "Sat", value: "SAT" },
];

interface CPDaysOfWeekBtnGrpProps {
  label?: string;
  onSelect: (values: string[]) => void;
  selectedOptions: string[];
  multiple?: boolean;
}

const CPDaysOfWeekBtnGrp: React.FC<CPDaysOfWeekBtnGrpProps> = (props) => {
  const handleClick = (dayAbbr: string) => {
    const index = props.selectedOptions.indexOf(dayAbbr);

    if (index > -1) {
      props.onSelect(
        props.selectedOptions.filter((day: string) => day !== dayAbbr)
      );
    } else {
      const items = !props.multiple
        ? [dayAbbr]
        : [...props.selectedOptions, dayAbbr];

      props.onSelect(items);
    }
  };

  return (
    <StackLayout orientation='vertical' gap={2}>
      <CPText
        textStyle='caption'
        transform={"capitalize"}
        style={{ opacity: 0.8 }}
      >
        {props.label}
      </CPText>
      <ButtonGroup className='cp-days-of-week-btn-grp'>
        {DAY_OPTIONS.map((option, i) => (
          <Button
            togglable
            selected={props.selectedOptions.some(
              (x) => x.toUpperCase() === option.value.toUpperCase()
            )}
            fillMode={"outline"}
            key={i}
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </StackLayout>
  );
};

export default CPDaysOfWeekBtnGrp;
