// @intent: Wrapper for search other carrier autocomplete and assigning selected carrier to factoring company
import { EventTracking, useCPTracking } from "analytics";
import * as React from "react";
import { CPText, Container } from "components/base";
import CPButton from "components/shared/CPButton/CPButton";
import {
  CPAutoCompleteDataItem,
  CPAutoCompleteResponse,
} from "components/types";
import CPCarrierAutoComplete from "components/shared/CPCarrierAutoComplete/CPCarrierAutoComplete";

interface SearchOtherCarriersAutoCompleteProps {
  factoringCompanyExId: string;
  onAdd: (carrierExId: string) => void;
}

const SearchOtherCarriersAutoComplete: React.FC<
  SearchOtherCarriersAutoCompleteProps
> = (props) => {
  const [carrierToAssign, setCarrierToAssign] = React.useState<
    CPAutoCompleteDataItem | undefined
  >();
  const { trackEvent } = useCPTracking();

  const handleAddCarrier = () => {
    props.onAdd(carrierToAssign?.value.externalId);
    setCarrierToAssign(undefined);
  };

  const mapSearchResponse = React.useCallback(
    (data: CPAutoCompleteResponse) => {
      const records = data.records
        .filter((x) => x.factoringCompany !== props.factoringCompanyExId)
        .map((x) => ({ label: x.name, value: x }));

      const totalCount =
        data.totalCount - (data.records.length - records.length);

      return { records, totalCount };
    },
    [props.factoringCompanyExId]
  );

  return (
    <Container direction='column'>
      <Container
        direction='row'
        justified='space-between'
        aligned={"center"}
        style={{ marginBottom: "5px" }}
      >
        <CPCarrierAutoComplete
          label='Search By Name, DOT, or MC Number(s)'
          onChange={(item) => {
            trackEvent(
              EventTracking("Add Assign Carrier Search", "change", { ...item })
            );
            setCarrierToAssign(item);
          }}
          onResponseFilter={mapSearchResponse}
          value={carrierToAssign}
          helpText='Search and assign other carrier(s) to this factoring company.'
        />
        <CPButton
          themeColor={"primary"}
          onClick={handleAddCarrier}
          disabled={!carrierToAssign}
          style={{ marginLeft: 10 }}
        >
          Assign
        </CPButton>
      </Container>
      {carrierToAssign != null && carrierToAssign.value.factoringCompany && (
        <CPText color='error'>
          Current Factoring Company: {carrierToAssign.value.factoringCompany}
        </CPText>
      )}
    </Container>
  );
};

export default SearchOtherCarriersAutoComplete;
