// @intent: Methods for handling 2FA
import * as Yup from "yup";
import { useTimer } from "react-timer-hook";
import { useFormik } from "formik";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useCP2faContext from "./useCP2faContext";
import dayjs from "dayjs";

const FormSchema = Yup.object().shape({
  verificationCode: Yup.string().required("Verification Code is required"),
});

const use2faForm = () => {
  const { minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp: dayjs(new Date()).add(3, "minute").toDate(),
    onExpire: () => console.warn("onExpire called"),
  });
  const { validate, error, resend, reachedMaxAttempts } = useCP2faContext();
  const formik = useFormik({
    initialValues: {
      verificationCode: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = async () => {
    if (formik.isValid && handlers.isValid()) {
      validate(formik.values.verificationCode);
    }
  };

  const handleResend = () => {
    formik.resetForm();
    resend();
    restart(dayjs(new Date()).add(3, "minute").toDate());
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    apiError: error as string,
    disableSubmit: () =>
      !formik.dirty ||
      !formik.isValid ||
      !handlers.isValid() ||
      reachedMaxAttempts,
    disableResend: () => isRunning,
    isLoading: false,
    onSubmit: () => formik.handleSubmit(),
    onResendCode: handleResend,
    resendBtnText: () =>
      isRunning
        ? `Can Resend in ${minutes}:${
            seconds === 0 ? "00" : seconds < 10 ? "0" + seconds : seconds
          }`
        : "Resend Code",
  };
};

export default use2faForm;
