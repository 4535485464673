// @intent: Display a listing of user's subscribed reports
import { ValueObject } from "common/types";
import { CPText } from "components/base";
import useCPConfirmModalContext from "components/shared/CPConfirmModal/useCPConfirmModalContext";
import CPListView from "components/shared/CPListView/CPListView";
import * as React from "react";
import useGetUserReportSubscriptionsQuery from "../api/useGetUserReportSubscriptionsQuery";
import { IReportSubscription } from "../types";
import useDeleteReportSubscriptionCommand from "../api/useDeleteReportSubscriptionCommand";
import { toast } from "react-toastify";

interface SubscribedReportsListProps {
  onEdit: (data: IReportSubscription) => void;
}

const SubscribedReportsList: React.FC<SubscribedReportsListProps> = (props) => {
  const reportSubQuery = useGetUserReportSubscriptionsQuery();
  const deleteSub = useDeleteReportSubscriptionCommand();
  const { confirm } = useCPConfirmModalContext();

  if (!reportSubQuery.reports || reportSubQuery.reports.length === 0) {
    return <CPText>Not subscribed to any reports</CPText>;
  }

  const handleConfirmDelete = (dataItem: ValueObject) => {
    confirm(() => {
      deleteSub.mutateAsync(dataItem.id).then(() => {
        toast.success("Removed report subscription.");
        reportSubQuery.refresh();
      });
    }, "Are you sure you want to delete the report subscription?");
  };

  const handleEdit = (dataItem: ValueObject) => {
    if (dataItem.id && reportSubQuery.reports) {
      const item = reportSubQuery.reports.find((x) => x.id === dataItem.id);
      if (item) {
        props.onEdit(item);
      }
    }
  };

  const mapData = (data?: IReportSubscription[]) => {
    if (!data) return [];

    return data.map((x) => {
      let interval = "---";

      if (x.scheduleInterval === "weekly" && x.daysOfWeek != null) {
        interval = x.daysOfWeek;
      }

      if (x.scheduleInterval === "monthly" && x.dayOfMonth != null) {
        interval = x.dayOfMonth.toString();
      }

      return {
        id: x.id,
        name: x.reportName,
        email: x.sendToEmail,
        schedule: x.scheduleInterval?.toUpperCase(),
        interval,
      };
    });
  };

  return (
    <CPListView
      data={mapData(reportSubQuery.reports)}
      actionBtns={[
        {
          title: "Edit Subscription",
          fontAwesomeIcon: "fa-solid fa-pen-to-square",
          onClick: handleEdit,
        },
        {
          title: "Delete Subscription",
          fontAwesomeIcon: "fa-solid fa-trash-can",
          onClick: handleConfirmDelete,
        },
      ]}
      header={[
        { label: "Report", dataField: "name" },
        { label: "Subscribed Email", dataField: "email" },
        { label: "Schedule", dataField: "schedule" },
        { label: "Day", dataField: "interval" },
      ]}
    />
  );
};

export default SubscribedReportsList;
