import { v4 as Guid } from "uuid";
import { ICarrierContact } from "./types";

const CarrierContact = (init?: ICarrierContact | undefined) => {
  const _uid = Guid();

  return {
    ...init,
    _uid,
  };
};

export default CarrierContact;
