// @intent: Handles validating password reset tokens

import useSendVerifyResetPasswordTokenCommand from "api/commands/useSendVerifyResetPasswordTokenCommand";
import useResetPasswordUrlParams from "./useResetPasswordUrlParams";

const useValidatePasswordResetToken = () => {
  const passwordUrlParams = useResetPasswordUrlParams();
  const command = useSendVerifyResetPasswordTokenCommand(
    passwordUrlParams.email,
    passwordUrlParams.resetToken
  );

  return command;
};

export default useValidatePasswordResetToken;
