// @intent: Provide modal dialog asking user to make a choice
import * as React from "react";
import useCPConfirmModalContext from "./useCPConfirmModalContext";
import { Dialog } from "@progress/kendo-react-dialogs";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPHrBreak, CPText, Container } from "components/base";
import CPButton from "../CPButton/CPButton";

const CPConfirmModal = () => {
  const state = useCPConfirmModalContext();

  return state.display ? (
    <Dialog
      title={"Please Confirm"}
      onClose={() => state.onResponse(false)}
      width={400}
    >
      <Container padding='30px 10px'>
        <CPText align='center' fontSize='16px'>
          {state.confirmMessage ?? "Are you sure?"}
        </CPText>
      </Container>
      <CPHrBreak spacing={10} />
      <StackLayout gap={10} orientation={"horizontal"}>
        <CPButton onClick={() => state.onResponse(false)} fillMode={"outline"}>
          Cancel
        </CPButton>
        <CPButton onClick={() => state.onResponse(true)} themeColor={"primary"}>
          Confirm
        </CPButton>
      </StackLayout>
    </Dialog>
  ) : null;
};

export default CPConfirmModal;
