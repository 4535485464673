// @intent: Methods for validating email addresses.

import * as React from "react";
import * as Yup from "yup";

const EmailSchema = Yup.object().shape({
  email: Yup.string().email(),
});

const useEmailValidator = (email: string, required: boolean = false) => {
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [error, setError] = React.useState<unknown>();

  React.useEffect(() => {
    const validate = async () => {
      try {
        await EmailSchema.validate({ email });
        setIsValid(true);
      } catch (err) {
        setError(err);
        setIsValid(false);
      }
    };

    if (email && required) {
      validate();
    } else {
      setError("");
    }
  }, [email, required]);

  return {
    isValid,
    error,
  };
};

export default useEmailValidator;
