// @intent: Display container for forgot password form

import * as React from "react";
import { useNavigate } from "react-router-dom";
import ForgotPasswordForm from "./components/ForgotPasswordForm/ForgotPasswordForm";
import { CPText, Container } from "../../../components/base";
import "./ForgotPasswordPageStyles.scss";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card className='forgot-password-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center' style={{ flex: 1 }}>
            Forgot Password
          </CPText>
        </CardTitle>
        <CardSubtitle>
          <CPText align='center' textStyle='subtitle'>
            Enter the email address associated with your account.
          </CPText>
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <ForgotPasswordForm />
        <CPButton
          onClick={() => navigate("/")}
          fillMode={"flat"}
          themeColor={"dark"}
          style={{ width: "100%", marginTop: 20 }}
        >
          Go Home
        </CPButton>
      </CardBody>
    </Card>
  );
};

export default ForgotPasswordPage;
