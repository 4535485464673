// @intent: A top level hook to capture and send tracking analytic data.
import * as React from "react";
import { useCPTracking, useInactivityTracker } from "analytics";
import useSaveAnalyticsCommand from "api/commands/useSaveAnalyticsCommand";
import useUserStore from "state/stores/useUserStore";
import { objToString } from "common/helpers";
import { sendAnalyticsTracking } from "common/config";

const useTrackAnalytics = () => {
  const userId = useUserStore((ctx) => ctx.userId);
  const pendingRequest = React.useRef("");
  const command = useSaveAnalyticsCommand();
  const { trackingData, resetSession } = useCPTracking();
  useInactivityTracker(() => sendData());

  const sendData = async () => {
    const { tracking } = await trackingData();
    const nextRequest = objToString(tracking);
    if (pendingRequest.current === nextRequest) {
      return;
    }

    pendingRequest.current = nextRequest;
    if (!sendAnalyticsTracking) {
      resetSession();
      return;
    }

    if (tracking.length === 0) {
      return;
    }

    await command
      .mutateAsync(
        tracking.map((x) => ({
          ...x,
          JSONData: JSON.stringify(x.data ?? {}),
          userId: userId > -1 ? userId : null,
        }))
      )
      .then(() => {
        resetSession();
      })
      .catch(console.error);
  };
};

export default useTrackAnalytics;
