// @intent: Methods for handling interactions for carriers grid
import * as React from "react";

import {
  useCPGridSavedSearches,
  useCPVirtualGridAdapter,
} from "components/shared/CPGrid";
import useAppStore from "state/stores/useAppStore";
import useUserStore from "state/stores/useUserStore";
import useSelectableOptions from "components/shared/CPGrid/Hooks/useSelectableOptions";
import LocalDataAdapter from "common/data/localDataAdapter";
import { CPGridColumn, CPGridState } from "components/shared/CPGrid/types";

const useFCompanyGridMethods = (gridName: string) => {
  const [openModalName, setOpenModalName] = React.useState("");
  const userId = useUserStore((user) => user.userId);
  const toast = useAppStore((app) => app.setToastMsg);
  const { savedSearch, saveSearch } = useCPGridSavedSearches({
    route: "UserProfile/SavedSearchFilters",
    defaultRouteParams: {
      url: window.location.pathname,
      userId,
      name: gridName,
    },
  });

  const columns = [
    { field: "name", title: "Name" },
    { field: "address", title: "Address" },
    {
      field: "city",
      title: "City",
    },
    {
      field: "state",
      title: "State",
      width: 250,
      filterType: "dropdownlist",
      dropdownListOptions: LocalDataAdapter("STATE_PROVINCE").map(
        (x) => x.abbr
      ),
    },
    { field: "phone", title: "Phone", filterType: "phone_number" },
    { field: "email", title: "Email", filterType: "email" },
  ];

  const columnsToUse = savedSearch?.columns ?? columns;

  const gridAdapter = useCPVirtualGridAdapter({
    columns: columnsToUse,
    route: "FactoringCompany/SearchFCompaniesForGrid",
    setGridState: savedSearch,
  });

  const selectableOptions = useSelectableOptions({
    dataItemKey: "externalId",
    selectedField: "selected",
    data: gridAdapter.records.data,
    columns: gridAdapter.gridState.columns,
  });

  const onSaveSearch = (gridState?: CPGridState) => {
    const state = { ...gridAdapter.gridState, ...gridState };
    saveSearch(state, {
      onComplete: () => toast("Search saved successfully."),
    });
  };

  return {
    onAssignBtnClick: (type: "assign" | "batch") => setOpenModalName(type),
    openAssignModal: openModalName === "assign",
    openBatchUpload: openModalName === "batch",
    closeModal: () => setOpenModalName(""),
    selectableOptions,
    onSaveSearch,
    gridAdapter,
  };
};

export default useFCompanyGridMethods;
