// @intent: Send a batch save of analytic data
import http from "common/http";
import { ValueObject } from "common/types";
import { useMutation } from "react-query";

const useSaveAnalyticsCommand = () => {
  const command = useMutation((tracking: ValueObject[]) => {
    return http.postAsync("Analytics/SaveTracking", { tracking });
  });
  return command;
};

export default useSaveAnalyticsCommand;
