// @intent: Methods for handling user password updates forms
import * as Yup from "yup";
import { useFormik } from "formik";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useAppStore from "state/stores/useAppStore";
import useUpdatePassword from "features/Auth/hooks/useUpdatePassword";

const FormSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Please enter your current password."),
  newPassword: Yup.string()
    .required("New password is required.")
    .min(8, "Password must be at least 8 characters"),
  confirmNewPassword: Yup.string()
    .required("Confirm New Password is required.")
    .oneOf([Yup.ref("newPassword")], "Passwords must match."),
});

const useChangePasswordForm = () => {
  const command = useUpdatePassword();
  const toast = useAppStore((app) => app.setToastMsg);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = async () => {
    if (formik.isValid && handlers.isValid()) {
      command
        .mutateAsync(formik.values)
        .then(() => {
          toast(`Password updated successfully.`);
          formik.resetForm();
        })
        .catch(console.error);
    }
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    apiError: command.error as string,
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    isLoading: false,
    onSubmit: () => formik.handleSubmit(),
  };
};

export default useChangePasswordForm;
