// @intent: Display a grid for handling login requests

import * as React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  CPActionItem,
  CPGrid,
  useCPGridSavedSearches,
  useCPVirtualGridAdapter,
} from "../../../components/shared/CPGrid";

import useUserStore from "../../../state/stores/useUserStore";
import useAppStore from "../../../state/stores/useAppStore";
import CPDefaultActionList from "../../../components/shared/CPGrid/CPDefaultActionList";
import { Container, DateText } from "../../../components/base";
import useSelectableOptions from "components/shared/CPGrid/Hooks/useSelectableOptions";
import { ValueObject } from "common/types";
import { StackLayout } from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";
import { IAccessRequest } from "features/LoginRequests";
import { CPGridState } from "components/shared/CPGrid/types";
import DenyRequestForm from "./DenyRequestForm";
import CPModal from "components/shared/CPModal/CPModal";
import UpsertAccountWizardModal from "features/Users/components/UpsertAccountWizardModal/UpsertAccountWizardModal";
import UpsertAccount from "features/Users/types/UpsertAccount";
import ChangeDenyConfirm from "./ChangeDenyConfirm";
import useGetLoginRequestByIdQuery from "../api/useGetLoginRequestByIdQuery";

const LoginRequestsGrid: React.FC = () => {
  const userId = useUserStore((user) => user.userId);
  const toast = useAppStore((app) => app.setToastMsg);
  const setIsLoading = useAppStore((app) => app.setIsLoading);
  const [selectedRow, setSelectedRow] = React.useState({
    id: undefined as number | undefined,
    changeStatus: undefined as "approve" | "deny" | "confirm" | undefined,
  });

  const loginRequestQuery = useGetLoginRequestByIdQuery(selectedRow.id);
  setIsLoading(loginRequestQuery.isLoading);

  const initFilters: CompositeFilterDescriptor = {
    logic: "and",
    filters: [{ field: "status", operator: "eq", value: "Pending" }],
  };

  const { savedSearch, saveSearch } = useCPGridSavedSearches({
    route: "UserProfile/SavedSearchFilters",
    defaultRouteParams: {
      url: window.location.pathname,
      userId,
      name: "LoginRequestsGrid",
    },
  });

  const columns = [
    {
      field: "status",
      title: "Status",
      filterType: "dropdownlist",
      dropdownListOptions: ["Pending", "Approved", "Denied"],
      width: 250,
    },
    { field: "email", title: "Email", width: 250 },
    { field: "name", title: "Name", width: 250 },
    {
      field: "phone",
      title: "Phone",
      filterType: "phone_number",
      width: 175,
    },
    {
      field: "requestType",
      title: "Request Type",
      filterType: "dropdownlist",
      dropdownListOptions: ["Carrier", "Employee", "Factoring Company"],
      width: 200,
    },

    { field: "companyName", title: "Company Name", width: 200 },
    {
      field: "companyRemitAddress",
      title: "Company Remit Address",
      width: 200,
    },
    { field: "companyRemitCity", title: "Company Remit City", width: 200 },
    { field: "companyRemitState", title: "Company Remit State", width: 200 },
    { field: "companyRemitZip", title: "Company Remit Zip", width: 200 },
    { field: "mcNumber", title: "MC Number", width: 200 },
    { field: "dotNumber", title: "DOT Number", width: 200 },
    { field: "statusUpdatedBy", title: "Status Updated By", width: 200 },
    {
      field: "createdAt",
      title: "Created At",
      width: 150,
      minResizableWidth: 80,
      filterType: "date_range",
      renderCell: (data: ValueObject) => (
        <DateText date={data.dataItem.createdAt} />
      ),
    },
  ];

  const columnsToUse = savedSearch?.columns ?? columns;

  const gridAdapter = useCPVirtualGridAdapter({
    columns: columnsToUse,
    route: "LoginRequests/Search",
    initFilters,
    setGridState: savedSearch,
  });

  const selectableOptions = useSelectableOptions({
    data: gridAdapter.records.data,
    dataItemKey: "id",
    selectedField: "selected",
    columns: gridAdapter.gridState.columns,
    verifyCanSelectItem: (item) => item.status !== "Approved",
  });

  const onSaveSearch = (gridState?: CPGridState) => {
    const state = { ...gridAdapter.gridState, ...gridState };
    saveSearch(state, {
      onComplete: () => toast("Search saved successfully."),
    });
  };
  const handleModalClose = () => {
    setSelectedRow({ id: undefined, changeStatus: undefined });
    selectableOptions.clearSelection();
    gridAdapter.refresh();
  };

  const renderToolBar = () => {
    return (
      <>
        <CPDefaultActionList
          dataAdapter={{ ...gridAdapter, onSaveSearch: onSaveSearch }}
        >
          <CPActionItem
            label='Clear Selection'
            onSelect={selectableOptions.clearSelection}
          />
        </CPDefaultActionList>

        <StackLayout gap={5}>
          <Tooltip anchorElement='pointer' position='top'>
            <CPButton
              className='btn-small'
              themeColor={"light"}
              icon='edit'
              disabled={
                selectableOptions.selectedItem == null ||
                selectableOptions.selectedItem["status"] === "Approved"
              }
              onClick={() =>
                setSelectedRow({
                  id: selectableOptions.selectedItem.id,
                  changeStatus:
                    selectableOptions.selectedItem.status === "Denied"
                      ? "confirm"
                      : "approve",
                })
              }
            >
              Approve Request
            </CPButton>
          </Tooltip>
          <Tooltip anchorElement='pointer' position='top'>
            <CPButton
              className='btn-small'
              themeColor={"light"}
              icon='edit'
              disabled={
                selectableOptions.selectedItem == null ||
                selectableOptions.selectedItem["status"] === "Denied"
              }
              onClick={() =>
                setSelectedRow({
                  id: selectableOptions.selectedItem.id,
                  changeStatus: "deny",
                })
              }
            >
              Deny Request
            </CPButton>
          </Tooltip>
        </StackLayout>
      </>
    );
  };

  return (
    <Container>
      <CPGrid
        {...selectableOptions}
        name='loginRequestsGrid'
        dataAdapter={gridAdapter}
        filterable={true}
        sortable={true}
        pageable={true}
        columns={selectableOptions.columns}
        showToolbar={true}
        renderToolBar={renderToolBar}
      />
      <CPModal
        title='Deny Request'
        open={
          loginRequestQuery.request != null &&
          selectedRow.changeStatus === "deny"
        }
        onClose={handleModalClose}
        width={400}
      >
        <DenyRequestForm
          id={loginRequestQuery.request?.id ?? -1}
          onComplete={handleModalClose}
          reason={loginRequestQuery.request?.reason}
        />
      </CPModal>
      <CPModal
        title='Change Denied Status Confirm'
        open={
          loginRequestQuery.request != null &&
          selectedRow.changeStatus === "confirm"
        }
        onClose={handleModalClose}
        width={400}
      >
        <ChangeDenyConfirm
          updatedBy={loginRequestQuery.request?.statusUpdatedBy}
          reason={loginRequestQuery.request?.reason}
          onProceed={() =>
            setSelectedRow((prev) => ({ ...prev, changeStatus: "approve" }))
          }
        />
      </CPModal>

      {loginRequestQuery.request != null &&
        selectedRow.changeStatus === "approve" && (
          <UpsertAccountWizardModal
            title='Create User'
            model={UpsertAccount({
              ...loginRequestQuery.request,
              id: undefined,
              phoneNumber: loginRequestQuery.request.phone,
              accountType: loginRequestQuery.request.requestType,
              assignedCarriers: loginRequestQuery.request.carriersToAssign,
            })}
            onClose={handleModalClose}
          />
        )}
    </Container>
  );
};

export default LoginRequestsGrid;
