// @intent: Container for reports
import * as React from "react";
import { PageVisitTracking, useCPTracking } from "analytics";
import CPPanel from "components/shared/CPPanel/CPPanel";
import ReportForm from "features/Reports/components/ReportRequestForm";
import { Container } from "components/base";
import { IReportRequest } from "features/Reports/types";
import ReportSchedulerForm from "features/Reports/components/ReportSchedulerForm";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import useGenerateReportCommand from "features/Reports/api/useGenerateReportCommand";
import CPExcelExport from "components/shared/CPExcelExport/CPExcelExport";
import SubscribedReports from "features/Reports/components/SubscribedReports";
import useUserStore from "state/stores/useUserStore";

const ReportsPage: React.FC = () => {
  const defaultEmail = useUserStore((ctx) => ctx.primaryEmail);
  const allowCarrierSelection = useUserStore((ctx) =>
    ctx.isInRole(["Admin", "Employee"])
  );
  const genReport = useGenerateReportCommand();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  useCPTracking(PageVisitTracking());

  const handleGenerateReport = async (
    data: IReportRequest,
    onComplete?: () => void
  ) => {
    try {
      await genReport.mutateAsync(data);

      if (onComplete) {
        onComplete();
      }
    } catch (err) {}
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };
  return (
    <CPPanel header='Reports'>
      <Container padding={10}>
        <TabStrip
          selected={selectedTab}
          onSelect={handleSelect}
          tabPosition={"left"}
          animation={false}
        >
          <TabStripTab title='Generate Report'>
            <Container padding={"0 20px"} screen='md'>
              <ReportForm
                onSubmit={handleGenerateReport}
                isLoading={genReport.isLoading}
                allowCarrierSelection={allowCarrierSelection}
              />
            </Container>
          </TabStripTab>
          <TabStripTab title='Schedule Report'>
            <Container padding={"0 20px"} screen='md'>
              <ReportSchedulerForm defaultEmail={defaultEmail} />
            </Container>
          </TabStripTab>
          <TabStripTab title='Manage Subscriptions'>
            <Container padding={"0 20px"} screen='lg'>
              <SubscribedReports />
            </Container>
          </TabStripTab>
        </TabStrip>
      </Container>
      {genReport.data && (
        <CPExcelExport
          filename={genReport.requestedReport?.reportName}
          data={genReport.data.results}
          headers={genReport.data.headers}
        />
      )}
    </CPPanel>
  );
};

export default ReportsPage;
