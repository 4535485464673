import { IAccessRequest } from "./types";

function FCompanyAccessRequest(init: IAccessRequest) {
  return {
    ...init,
    accountType: "Factoring Company",
    companyRemitZip: (init.companyRemitZip ?? "").toString(),
  };
}

export default FCompanyAccessRequest;
