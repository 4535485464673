// @intent: Display a notice for user to perform a new search, or show message no results is found.
import { StackLayout } from "@progress/kendo-react-layout";
import { Box, CPText, Container } from "components/base";
import CPButton from "components/shared/CPButton/CPButton";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";
import * as React from "react";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";

const InvoiceSearchNoResultNotice: React.FC = () => {
  const startNewSearch = useInvoiceSearchStore((ctx) => ctx.startNewSearch);
  const toggleForm = useInvoiceSearchStore((ctx) => ctx.toggleForm);
  const searchResults = useInvoiceSearchStore((ctx) => ctx.searchResults);

  return (
    <Container padding={"10em 0"}>
      <StackLayout
        orientation='vertical'
        align={{ horizontal: "center" }}
        gap={30}
      >
        <Box>
          <CPText
            align='center'
            textStyle='h3'
            color={"primary"}
            hidden={searchResults == null}
          >
            NO RESULTS FOUND.
          </CPText>
        </Box>

        <Box justifyContent={"center"} alignItems={"center"}>
          <CPText align='center' textStyle='h6' gutterBottom>
            BEGIN A NEW SEARCH OR SELECT A RECENT/SAVED TEMPLATE SEARCH.
          </CPText>
          {/* No search has been performed yet. This one starts new search. */}
          {searchResults == null && (
            <CPButton
              size={"large"}
              themeColor={"primary"}
              onClick={startNewSearch}
            >
              <CPFontAwesome icon='fa-solid fa-magnifying-glass' inline />
              Begin Search
            </CPButton>
          )}

          {/* Did a search but no result(s). This one just opens modal to update search without clearing entered fields. */}
          {searchResults != null && (
            <CPButton
              size={"large"}
              themeColor={"primary"}
              onClick={toggleForm}
            >
              <CPFontAwesome icon='fa-solid fa-magnifying-glass' inline />
              Update Search
            </CPButton>
          )}
        </Box>
      </StackLayout>
    </Container>
  );
};

export default InvoiceSearchNoResultNotice;
