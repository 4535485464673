// @intent: Handle generate report submits / requests
import * as React from "react";
import { useMutation } from "react-query";
import http from "../../../common/http";
import { IReportRequest } from "../types";
import GenerateReportResult from "../types/GenerateReportResult";

const useGenerateReportCommand = () => {
  const [requestedReport, setRequestedReport] = React.useState<
    IReportRequest | undefined
  >();
  const command = useMutation((request: IReportRequest) => {
    setRequestedReport(request);
    return http.postAsync("Report/GenerateReport", request);
  });

  const mapResult = () => {
    if (command.data) {
      return GenerateReportResult({ ...command.data });
    }

    return null;
  };

  return {
    isLoading: command.isLoading,
    mutate: command.mutate,
    mutateAsync: command.mutateAsync,
    error: command.error,
    data: mapResult(),
    requestedReport,
  };
};

export default useGenerateReportCommand;
