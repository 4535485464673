import * as React from "react";
import http from "../../../../common/http";
import { ValueObject } from "../../../../common/types";
import useCPGridAdapter from "./useCPGridState";
import {
  CompositeFilterDescriptor,
  DataResult,
} from "@progress/kendo-data-query";
import { useDebouncedCallback } from "use-debounce";
import {
  dataToString,
  getFiltersFromDataState,
  getSortFromDataState,
} from "../Utils/Utils";
import { CPGridColumn, CPGridState } from "../types";

export interface CPDataApiAdapterResponse {
  records: any[];
  totalRecords: number;
}

/**
 * Custom Hook for working with an external data source such as api's.
 * --- Uses the useCPGridState hook internally.
 */
interface CPVirtualGridAdapter {
  columns: CPGridColumn[];
  route: string;
  routeParams?: ValueObject;
  initFilters?: CompositeFilterDescriptor;
  setGridState?: CPGridState;
}

const useCPVirtualGridAdapter = (options: CPVirtualGridAdapter) => {
  const [records, setRecords] = React.useState<DataResult>({
    data: [],
    total: 0,
  });

  const adapterBase = useCPGridAdapter({
    columns: options.columns,
    records,
    initFilters: options.initFilters,
    setGridState: options.setGridState,
  });

  const lastSuccess = React.useRef<string | undefined>();
  const [isPendingRequest, setIsPendingRequest] =
    React.useState<boolean>(false);

  const requestDataIfNeeded = useDebouncedCallback(async () => {
    if (
      isPendingRequest ||
      dataToString(adapterBase.getControls()) === lastSuccess.current
    ) {
      return;
    }

    const response = await fetchRecords();
    lastSuccess.current = dataToString(adapterBase.getControls());

    if (dataToString(adapterBase.gridState.controls) === lastSuccess.current) {
      setRecords({
        data: response.records,
        total: response.totalRecords,
      });
    } else {
      requestDataIfNeeded();
    }
  }, 800);

  React.useEffect(() => {
    requestDataIfNeeded();
  }, [requestDataIfNeeded, adapterBase.gridState.controls]);

  const fetchRecords = async (skip?: number, take?: number) => {
    try {
      setIsPendingRequest(true);

      const payload = {
        take: take ?? adapterBase.getControls().take,
        skip: skip ?? adapterBase.getControls().skip,
        ...getSortFromDataState(adapterBase.getControls()),
        ...getFiltersFromDataState(adapterBase.getControls()),
        ...options.routeParams,
      };

      const response =
        (await http.fetchAsync(options.route, payload)) ||
        ({ records: [], totalRecords: 0 } as CPDataApiAdapterResponse);

      setIsPendingRequest(false);

      return response;
    } catch (err) {
      setIsPendingRequest(false);
      console.error(`Error fetching grid data: `, err);

      return { records: [], totalRecords: 0 };
    }
  };

  const refresh = () => {
    if (isPendingRequest === false) {
      lastSuccess.current = "";
      requestDataIfNeeded();
    }
  };
  return {
    ...adapterBase,
    showLoader: isPendingRequest,
    refresh,
  };
};

export default useCPVirtualGridAdapter;
