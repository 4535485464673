import * as React from "react";
import classNames from "classnames";
import { MenuItem, StackedMenuListProps } from "../../types";
import "./StackedMenuListStyles.scss";
import { noop } from "../../../common/helpers";

const StackedMenuList: React.FC<StackedMenuListProps> = ({
  menuItems,
  onSelect = noop,
}) => {
  const [activeMenus, setActiveMenus] = React.useState<number[]>([]);

  const handleToggleSubMenu = (index: number) => {
    const isActive = activeMenus.includes(index);
    if (isActive) {
      setActiveMenus(activeMenus.filter((x: number) => x !== index));
    } else {
      setActiveMenus([...activeMenus, index]);
    }
  };

  const renderSubMenu = (subMenuItems: MenuItem[], parentIndex: number) => {
    return (
      <ul
        className={classNames("cp-sub-menu", {
          open: activeMenus.includes(parentIndex),
        })}
      >
        {subMenuItems.map((item, index) => (
          <li key={index} className='cp-sub-menu-item'>
            <button
              className='cp-sub-menu-item-label'
              onClick={() => {
                item.subMenu
                  ? handleToggleSubMenu(index)
                  : onSelect(item.text, index);
              }}
            >
              {item.text}
            </button>
            {item.subMenu && renderSubMenu(item.subMenu, parentIndex)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ul className='cp-menu-list'>
      {menuItems.map((item, index) => (
        <li
          key={index}
          className={classNames("cp-menu-item", {
            active: activeMenus.includes(index),
          })}
        >
          <button
            className={classNames("cp-menu-item-label")}
            onClick={() => onSelect(item.text, index)}
          >
            {item.text}
          </button>
          {item.subMenu && (
            <button
              onClick={() => handleToggleSubMenu(index)}
              className={classNames({ "show-caret": item.subMenu })}
            >
              <span
                className={classNames("cp-caret", {
                  "cp-caret-up": activeMenus.includes(index),
                })}
              ></span>
            </button>
          )}
          {item.subMenu && renderSubMenu(item.subMenu, index)}
        </li>
      ))}
    </ul>
  );
};

export default StackedMenuList;
