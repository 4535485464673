// @intent: Global state for handling user specific state within the app

import { create } from "zustand";

interface UserState {
  userId: number;
  firstname: string;
  lastname: string;
  primaryEmail: string;
  factoringCompanyExId?: string;
  carrierExId?: string;
  assignedCarriers: AssignedCarrier[];
  roles: string[];
  menus: MenuItem[];
  nameInitials: string;
}

interface UserStateActions {
  updateUserProfile: (profileData: UserState) => void;
  userInitialized: () => boolean;
  clearUserData: () => void;
  isInRole: (rolesToCheck: string[]) => boolean;
  accountType: () => "Factoring Company" | "Carrier" | "Employee";
}

export interface MenuItem {
  url?: string;
  icon?: string;
  name: string;
  menuOrder: number;
  hideFromMenu?: boolean;
}

interface AssignedCarrier {
  id?: number;
  name?: string;
}

const initUserData = {
  userId: -1,
  firstname: "",
  lastname: "",
  primaryEmail: "",
  factoringCompanyExId: undefined,
  carrierExId: undefined,
  assignedCarriers: [],
  roles: [],
  menus: [],
  nameInitials: "",
};

const useUserStore = create<UserState & UserStateActions>()((set, get) => {
  const userInitialized = () => get().userId > 0;

  const updateUserProfile = (profileData: UserState) =>
    set(() => ({
      ...profileData,
      nameInitials: `${profileData.firstname[0]}${profileData.lastname[0]}`,
    }));

  const clearUserData = () => set(initUserData);
  const isInRole = (rolesToCheck: string[]) => {
    const roles = get().roles;

    return roles.some(
      (role) =>
        rolesToCheck.map((x) => x.toUpperCase()).indexOf(role.toUpperCase()) >
        -1
    );
  };

  const computeAccountType = () => {
    const carrierExId = get().carrierExId;
    const fCompanyExId = get().factoringCompanyExId;

    if (carrierExId == null && fCompanyExId != null) {
      return "Factoring Company";
    }

    if (carrierExId != null && fCompanyExId == null) {
      return "Carrier";
    }

    return "Employee";
  };

  return {
    ...initUserData,
    updateUserProfile,
    userInitialized,
    clearUserData,
    isInRole,
    accountType: computeAccountType,
  };
});

export default useUserStore;
