// @intent: Methods for handling carrier manual setups

import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import { CPAutoCompleteDataItem } from "components/types";
import useSendCarrierSetupCommand from "features/LoginRequests/api/useSendCarrierSetupCommand";
import { accessRequestSuccess } from "common/constants";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import { EventTracking, useCPTracking } from "analytics";
import CarrierAccessRequest from "../types/CarrierAccessRequest";

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname is required."),
  lastName: Yup.string().required("Lastname is required."),
  phoneNumber: Yup.string().required("A valid phone number is required."),
  email: Yup.string().email().required("Email is required."),
  companyName: Yup.string().required("Company Name is required."),
  companyMCNumber: Yup.string().required("Company MC Number is required."),
  companyDOTNumber: Yup.string().required("Company DOT Number is required."),
});

const useCarrierManualForm = (onSuccessCbFn: (message: string) => void) => {
  const { trackEvent } = useCPTracking();
  const command = useSendCarrierSetupCommand();
  const [selectedCompany, setSelectedCompany] = React.useState<
    CPAutoCompleteDataItem | undefined
  >();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      companyMCNumber: "",
      companyDOTNumber: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = useEnterKeySubmit(async () => {
    trackEvent(EventTracking("Carrier Manual Submit", "form_submission"));

    if (formik.isValid && handlers.isValid()) {
      command
        .mutateAsync(CarrierAccessRequest({ ...formik.values }))
        .then(() => {
          onSuccessCbFn(accessRequestSuccess);
        })
        .catch(console.error);
    }
  });

  const onCompanyNameSelected = (selected?: CPAutoCompleteDataItem) => {
    setSelectedCompany(selected);

    const data = selected ? selected.value ?? {} : {};

    const formVals = {
      companyName: selected?.label ?? "",
      companyDOTNumber: data.dotNumber ?? "",
      companyMCNumber: data.mcNumber ?? "",
    };

    formik.setValues({ ...formik.values, ...formVals });
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    onCompanyNameSelected,
    selectedCompany,
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    onSubmit: () => formik.submitForm(),
    apiError: command.error as string,
    isSuccess: command.isSuccess,
    isLoading: command.isLoading,
  };
};

export default useCarrierManualForm;
