import * as React from "react";
import http from "../../../../common/http";
import { CPGridState } from "../types";
import { ValueObject } from "../../../../common/types";
import {
  MapToSaveStateJsonString,
  MapJsonStringToSaveState,
} from "../Utils/Utils";
import useGetSavedSearchQuery from "../api/useGetSavedSearchQuery";

interface SaveSearchOptions {
  payload?: ValueObject;
  onComplete?: () => void;
  onError?: (error: string) => void;
}

interface CPGridSavedSearchesOptions {
  /**
   * The route to fetch and save records to using 'GET' / 'POST'.
   * You can override the save route using the 'saveRoute' option.
   */
  route: string;
  /**
   * If set, uses this value instead of the value of 'route' option for a 'POST' request.
   */
  saveRoute?: string;
  /**
   * Params sent on every request
   */
  defaultRouteParams?: ValueObject;

  /**
   * Query params sent with fetch request
   */
  additionalRouteParams?: ValueObject;
}

/**
 * Custom hook for fetching and saving grid state(s)
 */
const useCPGridSavedSearches = (options: CPGridSavedSearchesOptions) => {
  const savedSearchQuery = useGetSavedSearchQuery({ ...options });

  const saveSearch = async (
    searchToSave: CPGridState,
    saveOptions?: SaveSearchOptions
  ) => {
    try {
      const search = MapToSaveStateJsonString(searchToSave);
      await http.postAsync(options.saveRoute ?? options.route, {
        search,
        ...options.defaultRouteParams,
        ...saveOptions?.payload,
      });

      if (saveOptions?.onComplete) {
        saveOptions.onComplete();
      }

      savedSearchQuery.refresh();
    } catch (err) {
      console.error(err);

      if (saveOptions?.onError) {
        saveOptions?.onError(err as string);
      }
    }
  };

  return {
    savedSearch: savedSearchQuery.savedSearch,
    saveSearch,
  };
};

export default useCPGridSavedSearches;
