// @intent: Methods for handling resetting user's password
import * as React from "react";

import * as Yup from "yup";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import { useFormik } from "formik";
import useResetPasswordCommand from "features/Auth/api/useResetPasswordCommand";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import PasswordReset from "features/Users/types/PasswordReset";

const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
  passwordConfirm: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password")], "Passwords must match."),
});

const useResetPasswordForm = (email: string, token: string) => {
  const resetPasswordCommand = useResetPasswordCommand();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = useEnterKeySubmit(async () => {
    if (formik.isValid) {
      resetPasswordCommand.mutate(
        PasswordReset({ ...formik.values, email, token })
      );
    }
  });

  return {
    saveError: resetPasswordCommand.error as string,
    isLoading: resetPasswordCommand.isLoading,
    isSuccess: resetPasswordCommand.isSuccess,
    errors: formik.errors,
    values: formik.values,
    onSubmit: () => formik.submitForm(),
    onChange: (event: TextBoxChangeEvent) =>
      formik.handleChange(event.syntheticEvent),
    onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) =>
      formik.handleBlur(event),
    disableSubmit: () => !formik.dirty || !formik.isValid,
  };
};

export default useResetPasswordForm;
