// @intent: Methods for handling invoice form inputs, events, etc
import { ISearchInvoicePayment } from "./../types/types.d";
import * as React from "react";
import * as Formik from "formik";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import { CPMultiTagTextBoxChangeEvent } from "components/shared/CPMultiTagTextBox/CPMultiTagTextBox";
import { XListBoxTransferEvent } from "../../../hooks/common/useXListBox";
import { ValueObject } from "common/types";
import { CPDropDownListEventChangeEvent } from "components/shared/CPDropDownList/CPDropDownList";
import SearchInvoicePayment from "../types/SearchInvoicePayment";

interface useInvoiceSearchFormOptions {
  initValues?: ISearchInvoicePayment;
}

const useInvoiceSearchForm = (options?: useInvoiceSearchFormOptions) => {
  const [selectedSaveSearch, setSelectedSaveSearch] = React.useState<
    ValueObject | undefined
  >();
  const formik = Formik.useFormik({
    initialValues: options?.initValues ?? SearchInvoicePayment(),
    onSubmit: () => {},
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleMultiTagChange = (event: CPMultiTagTextBoxChangeEvent) => {
    formik.setFieldValue(event.name, event.value);
  };

  const handleXListTransfer = (event: XListBoxTransferEvent) => {
    formik.setFieldValue(event.name, event.selected);
  };

  const handleSaveSearchSelect = (event: CPDropDownListEventChangeEvent) => {
    const selection = event.value as ValueObject;

    if (selection && "searchParams" in selection) {
      formik.setValues(SearchInvoicePayment(selection.searchParams));
    }

    setSelectedSaveSearch(selection);
  };

  const handleReset = () => {
    formik.resetForm();
    setSelectedSaveSearch(undefined);
  };

  return {
    ...handlers,
    reset: handleReset,
    values: formik.values,
    errors: formik.errors,
    onMultiTagChange: handleMultiTagChange,
    onTransfer: handleXListTransfer,
    handleSaveSearchSelect,
    selectedSaveSearch,
  };
};

export default useInvoiceSearchForm;
