// @intent: Get's users saved searches

import http from "common/http";
import { useQuery } from "react-query";

const useGetUserSavedSearches = (route: string, pageName: string) => {
  const query = useQuery(["UserSavedSearches", route], () => {
    return http.fetchAsync(route, { pageName });
  });

  return query;
};

export default useGetUserSavedSearches;
