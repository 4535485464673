// @intent: Methods for handling forgot password forms

import * as Yup from "yup";
import { useFormik } from "formik";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useSendForgotPasswordCommand from "api/commands/useSendForgotPasswordCommand";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Email is required."),
});

const useForgotPasswordForm = () => {
  const sendForgotPasswordCommand = useSendForgotPasswordCommand();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = useEnterKeySubmit(async () => {
    if (
      formik.isValid &&
      formik.values.email.length > 0 &&
      handlers.isValid()
    ) {
      sendForgotPasswordCommand.mutate(formik.values.email);
    }
  });

  return {
    ...handlers,
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    submit: () => formik.submitForm(),
    values: formik.values,
    isLoading: sendForgotPasswordCommand.isLoading,
    isSuccess: sendForgotPasswordCommand.isSuccess,
    isError: sendForgotPasswordCommand.isError,
    error: sendForgotPasswordCommand.error as string,
  };
};

export default useForgotPasswordForm;
