// @intent: Methods for handling getting token and email from url params

import * as React from "react";
import { useSearchParams } from "react-router-dom";

const useConfirmEmailUrlParams = () => {
  const [URLSearchParams] = useSearchParams();
  const [urlParams, setUrlParams] = React.useState({
    token: "",
    email: "",
    shouldRedirect: false,
  });

  React.useEffect(() => {
    let isMounted = true;
    const token = URLSearchParams.get("token") ?? "";
    const email = URLSearchParams.get("email") ?? "";

    if (isMounted) {
      setUrlParams((prev) => ({
        ...prev,
        email,
        token,
        shouldRedirect: !token || !email,
      }));
    }

    return () => {
      isMounted = false;
    };
  }, [setUrlParams, URLSearchParams]);

  return {
    ...urlParams,
  };
};

export default useConfirmEmailUrlParams;
