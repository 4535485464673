// @intent: Verify email confirmation and display a success or error.
import * as React from "react";

import { CPText } from "../../../components/base";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  StackLayout,
} from "@progress/kendo-react-layout";

import "./ConfirmEmailPageStyles.scss";
import ConfirmEmailCardFooterActions from "./components/ConfirmEmailCardFooterActions/ConfirmEmailCardFooterActions";
import { useNavigate } from "react-router-dom";
import ConfirmEmailCardBody from "./components/ConfirmEmailCardBody/ConfirmEmailCardBody";
import useConfirmEmailForm from "features/Auth/hooks/useConfirmEmailForm";
import CPButton from "components/shared/CPButton/CPButton";

const ConfirmEmailPage: React.FC = () => {
  const navigate = useNavigate();
  const form = useConfirmEmailForm();
  return (
    <Card className='confirm-password-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center'>
            Email Confirmation
          </CPText>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <StackLayout
          orientation='vertical'
          gap={20}
          align={{ horizontal: "center" }}
        >
          <ConfirmEmailCardBody {...form} message={form.message} />
        </StackLayout>
      </CardBody>
      <CardFooter>
        <StackLayout
          orientation='vertical'
          align={{ vertical: "middle", horizontal: "center" }}
          gap={20}
        >
          <ConfirmEmailCardFooterActions {...form} />
          <CPButton
            onClick={() => navigate("/", { replace: true })}
            size={"medium"}
            themeColor={"dark"}
            fillMode={"flat"}
            style={{ width: "100%" }}
          >
            Go Home
          </CPButton>
        </StackLayout>
      </CardFooter>
    </Card>
  );
};

export default ConfirmEmailPage;
