import * as React from "react";
import classNames from "classnames";
import { mergeObjects } from "../../../common/helpers";
import "./CPAppNavBarStyles.scss";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  Link,
  MenuButton,
  LinkButton,
  StackedMenuList,
} from "../../base";
import enocLogo from "../../../assets/ENOC-Official-320x128.png";

type MenuItem = {
  text: string;
  url: string;
  subMenu: MenuItem[];
};

interface CPAppNavBarInterface {
  menuItems?: MenuItem[];
  secondaryBarStyles?: React.CSSProperties;
  secondaryContent?: React.ReactNode;
}

const defaultMenuItems = [
  {
    text: "OUR SERVICES",
    url: "https://evansdelivery.com/services/",
    subMenu: [
      {
        text: "Intermodal/Drayage",
        url: "https://evansdelivery.com/services/intermodal-drayage/",
      },
      {
        text: "Truckload",
        url: "https://evansdelivery.com/services/truckload/",
      },
      {
        text: "Flatbed",
        url: "https://evansdelivery.com/services/flatbed/",
      },
      {
        text: "LTL/Final Mile",
        url: "https://evansdelivery.com/services/ltl-final-mile/",
      },
      {
        text: "Transportation & Logistics",
        url: "https://evansdelivery.com/services/transportation-logistics/",
      },
    ],
  },
  {
    text: "AGENT OPPORTUNITIES",
    url: "https://evansdelivery.com/agent-opportunities/",
  },
  {
    text: "DRIVER OPPORTUNITIES",
    url: "https://evansdelivery.com/driver-opportunities/",
  },
  {
    text: "ABOUT US",
    url: "https://evansdelivery.com/about-us/",
  },
];

const CPAppNavBar: React.FC<CPAppNavBarInterface> = ({
  menuItems = defaultMenuItems,
  secondaryBarStyles,
  secondaryContent,
}) => {
  const navigate = useNavigate();
  const menuTextUrls = React.useMemo(() => {
    return mergeObjects(menuItems, ["text", "url"]);
  }, [menuItems]);
  const [showMenu, setShowMenu] = React.useState(false);

  // Prevent the inactive class on a page load/refresh by check that
  // .active is present first.
  const hasActiveClass = () => {
    const els = document.getElementsByClassName("cp-nav-menu-mobile");

    if (els.length > 0 && els[0].classList.contains("active")) {
      return true;
    }

    return false;
  };

  const handleMobileMenuSelection = (label: string) => {
    const item = menuTextUrls.find((x) => x.text === label);
    if (item) {
      window.open(item.url, "_blank");
    }
  };

  // NEST OWNED 'SubComponents' inside the parent so the parent can be easily tested...
  // These are 'required' dependencies and never change so that's why the get nested here.
  const renderNavbarLinks = () => {
    return (
      <Box className='cp-navbar-links' flexDirection='row'>
        <Link
          color='white'
          title='Driver Portal'
          target='_blank'
          url='https://drivers.evansdelivery.com/login.aspx?targetpage=%2fdefault.aspx&_gl=1*6k8wrl*_ga*NjM0OTI5ODk3LjE2ODAwMzQzMzA.*_ga_9197MZHVKX*MTY4NDUxMzU0NC4yLjAuMTY4NDUxMzU0NC4wLjAuMA..*_ga_1T3VZ2BRMC*MTY4NDUxMzU0NC40LjAuMTY4NDUxMzU0NC42MC4wLjA.&_ga=2.198824232.601981014.1684513545-634929897.1680034330'
        />
        <Link
          color='white'
          title='Agent Portal'
          target='_blank'
          url='https://agents.evansdelivery.com/?_gl=1*1ybv301*_ga*NjM0OTI5ODk3LjE2ODAwMzQzMzA.*_ga_9197MZHVKX*MTY4NDUxMzU0NC4yLjAuMTY4NDUxMzU0NC4wLjAuMA..*_ga_1T3VZ2BRMC*MTY4NDUxMzU0NC40LjAuMTY4NDUxMzU0NC42MC4wLjA.&_ga=2.134577270.601981014.1684513545-634929897.1680034330'
        />
        <Link
          color='white'
          title='Carriers'
          target='_blank'
          url='https://evans-westcarriers.rmissecure.com/_s/reg/generalrequirementsv2.aspx'
        />
        <Link
          color='white'
          title='Service Locator'
          target='_blank'
          url='https://evansdelivery.com/service-center-locator/'
        />
        <Link
          color='white'
          title='Careers'
          target='_blank'
          url='https://recruiting.paylocity.com/recruiting/jobs/All/7999b6ac-0381-43de-ab3d-b61fb45ba5dd/The-Evans-Network-of-Companies'
        />
      </Box>
    );
  };

  const renderNavbarSecondary = () => {
    return (
      <Box
        className='cp-navbar-secondary desktop'
        flexDirection='row'
        style={{ ...secondaryBarStyles }}
      >
        <Box className='cp-navbar-secondary-logo'>
          <Link as='button' onClick={() => navigate("/")}>
            <Image source={enocLogo} width={150} height={60} />
          </Link>
        </Box>

        <Box>{secondaryContent}</Box>
        <Box flexDirection='row'>
          <Box className='cp-navbar-secondary-items' spacing={5}>
            <Box flexDirection='row'>
              {menuItems.map((item, index) => (
                <LinkButton
                  key={index}
                  text={item.text}
                  color='dark'
                  styleType='empty'
                  size='small'
                  url={item.url}
                  subMenuItems={item.subMenu}
                />
              ))}
            </Box>
            <Box flexDirection='row' spacing={5}>
              <LinkButton
                text='Contact'
                color='primary'
                size='medium'
                url='https://evansdelivery.com/get-quote/#tab-4'
              />
              <LinkButton
                text='Get Quote'
                color='primary'
                styleType='filled'
                size='medium'
                url='https://evansdelivery.com/get-quote/'
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Box className='cp-navbar-secondary mobile' flexDirection='row'>
        <Box className='cp-navbar-secondary-logo'>
          <Link url='https://evansdelivery.com/' target='_blank'>
            <Image source={enocLogo} width={150} height={60} />
          </Link>
        </Box>

        <Box flexDirection='row'>
          <MenuButton
            className='cp-nav-menu-btn'
            size='large'
            onClick={() => setShowMenu(!showMenu)}
            openLabel='Menu'
            closeLabel='Close'
          />

          <Box
            className={classNames("cp-nav-menu-mobile", {
              active: showMenu,
              inactive: hasActiveClass() && !showMenu,
            })}
          >
            <StackedMenuList
              menuItems={menuItems}
              onSelect={handleMobileMenuSelection}
            />
            <Box
              flexDirection='row'
              justifyContent='space-between'
              style={{ width: "400px", padding: 10, textAlign: "center" }}
            >
              <LinkButton
                text='Contact'
                color='primary'
                size='medium'
                url='https://evansdelivery.com/get-quote/#tab-4'
                style={{ width: "100%", marginRight: 5 }}
              />
              <LinkButton
                text='Get Quote'
                color='primary'
                styleType='filled'
                size='medium'
                url='https://evansdelivery.com/get-quote/'
                style={{ width: "100%" }}
              />
            </Box>
            {renderNavbarLinks()}
            <Box>{secondaryContent}</Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className='cp-navbar-base' style={{ padding: 0 }}>
      {renderNavbarLinks()}
      {renderNavbarSecondary()}
      {renderMobileMenu()}
    </Box>
  );
};

export default CPAppNavBar;
