// @intent: Query to get user contact data
import { useQuery } from "react-query";
import http from "../../../common/http";
import { IUserProfileContact } from "../types";
import UserProfileContact from "../types/UserProfileContact";

const useUserContactQuery = () => {
  const { data, isLoading, isError } = useQuery(
    ["UserProfile/Contacts"],
    () => {
      return http.fetchAsync("UserProfile/Contacts");
    },
    {
      staleTime: 1000,
    }
  );

  return {
    contacts: (data != null
      ? data.map((x: IUserProfileContact) => UserProfileContact(x))
      : []) as IUserProfileContact[],
    isLoading,
    isError,
  };
};

export default useUserContactQuery;
