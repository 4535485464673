// @intent: Display a list of days in a 'calendar' style picker
import { CPText, Container } from "components/base";
import * as React from "react";
import CPButton from "../CPButton/CPButton";
import "./CPDayOfMonthPickerStyles.scss";
import classNames from "classnames";

interface CPDayOfMonthPickerProps {
  min?: number;
  max?: number;
  value?: number;
  onClick?: (day: number) => void;
  label?: string;
  helpText?: string;
}

const CPDayOfMonthPicker: React.FC<CPDayOfMonthPickerProps> = (props) => {
  const min = React.useMemo(() => {
    if (props.min && props.min > 0 && props.min < 32) {
      return props.min;
    }

    return 1;
  }, [props.min]);

  const max = React.useMemo(() => {
    if (props.max && props.max > 0 && props.max < 31 && props.max > min) {
      return props.max;
    }

    return 32;
  }, [props.max, min]);

  const days = React.useMemo(() => {
    const d = [];
    for (let i = 1; i < 32; i++) {
      d.push(i);
    }

    return d;
  }, []);

  const handleClick = (day: number) => {
    if (props.onClick) {
      props.onClick(day);
    }
  };

  const renderDaysRow = (from: number, to: number) => {
    return (
      <Container direction='row' padding={2}>
        {days
          .filter((x) => x >= from && x <= to)
          .map((x) => (
            <div key={x} className='btn-option-container'>
              <CPButton
                disabled={x < min || x > max}
                onClick={() => handleClick(x)}
                fillMode={"flat"}
                className={classNames("btn-option", {
                  selected: x === props.value,
                })}
              >
                {x}
              </CPButton>
            </div>
          ))}
      </Container>
    );
  };

  return (
    <Container direction='column' className='cp-day-of-month-picker'>
      <CPText
        fontSize='13px'
        style={{ opacity: 0.8, marginBottom: "5px" }}
        transform='capitalize'
      >
        {props.label}
      </CPText>
      <Container direction='column' aligned={"center"}>
        {renderDaysRow(1, 7)}
        {renderDaysRow(8, 14)}
        {renderDaysRow(15, 21)}
        {renderDaysRow(22, 28)}
        {renderDaysRow(29, 31)}
      </Container>
      <CPText
        fontSize='13px'
        style={{ marginTop: "5px", width: "fit-content" }}
      >
        {props.helpText}
      </CPText>
    </Container>
  );
};

export default CPDayOfMonthPicker;
