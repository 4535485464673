// @intend: Methods for CP list box (handlers, state, mapping)
import * as React from "react";
import { ValueObject } from "common/types";
import { CPBoxOption } from "components/shared/CPListBox/CPListBox";

export interface XListBoxTransferEvent {
  name: string;
  available: string[];
  selected: string[];
}

interface useXListBoxProps<T> {
  name: string;
  available: T[];
  selected: T[];
  labelKey: string;
  valueKey?: string;
  onTransfer?: (event: XListBoxTransferEvent) => void;
}

const defaultOptions = {
  available: [],
  selected: [],
};

const useXListBox = (props: useXListBoxProps<ValueObject>) => {
  const currentCache = React.useRef<string>("");
  const vKey = props.valueKey ?? props.labelKey;
  const [options, setOptions] = React.useState<CPBoxOption>(defaultOptions);

  const onChange = React.useCallback(
    (options: CPBoxOption) => {
      if (props.onTransfer) {
        const available = options.available.map((x) => x.value);
        const selected = options.selected.map((x) => x.value);

        props.onTransfer({ name: props.name, available, selected });
      }

      setOptions(options);
    },
    [props]
  );

  React.useEffect(() => {
    const available = props.available.map((x) => ({
      label: x[props.labelKey],
      value: x[vKey],
      selected: false,
    }));
    const selected = props.selected.map((x) => ({
      label: x[props.labelKey],
      value: x[vKey],
      selected: false,
    }));

    const options = { available, selected };
    if (currentCache.current !== JSON.stringify(options)) {
      setOptions(options);
      currentCache.current = JSON.stringify(options);

      onChange(options);
    }
  }, [props.available, props.selected, vKey, props.labelKey, onChange]);

  return {
    boxOptions: options,
    onChange,
  };
};

export default useXListBox;
