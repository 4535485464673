// @intent: Component wrapper for kendo dropdownlist that provides a few default props
import * as React from "react";
import {
  DropDownList,
  DropDownListBlurEvent,
  DropDownListChangeEvent,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import { ValueObject } from "common/types";

interface CPDropDownListProps
  extends Omit<DropDownListProps, "onChange" | "name" | "onBlur"> {
  valueKey?: string;
  required?: boolean;
  helpText?: string;
  errorMsg?: string;
  name: string;
  onChange?: (event: CPDropDownListEventChangeEvent) => void;
  onBlur?: (event: CPDropDownListEvent) => void;
}

export interface CPDropDownListEventChangeEvent
  extends Omit<CPDropDownListEvent, "type"> {
  type: "CPDropDownListEventChangeEvent";
}

export interface CPDropDownListEvent {
  name: string;
  type: "CPDropDownListEvent";
  syntheicEvent: React.SyntheticEvent<HTMLElement, Event>;
  value: string | ValueObject;
}

const CPDropDownList: React.FC<CPDropDownListProps> = (props) => {
  let value = props.value ?? null;

  if (value && props.data && props.valueKey) {
    value = props.data.find(
      (x: ValueObject) => x[props.valueKey ?? ""] === value
    );
  }

  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        name: props.name,
        type: "CPDropDownListEventChangeEvent",
        syntheicEvent: event.syntheticEvent,
        value:
          props.valueKey && event.value
            ? event.value[props.valueKey]
            : event.value,
      });
    }
  };

  const handleBlur = (event: DropDownListBlurEvent) => {
    if (props.onBlur) {
      props.onBlur({
        name: props.name,
        type: "CPDropDownListEvent",
        syntheicEvent: event.syntheticEvent,
        value: props.value,
      });
    }
  };

  return (
    <StackLayout orientation='vertical'>
      <DropDownList
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label={props.required ? `${props.label} *` : props.label}
        size={props.size ?? "medium"}
      />
      <CPText textStyle='caption' color={props.errorMsg ? "error" : "primary"}>
        {props.errorMsg ?? props.helpText}
      </CPText>
    </StackLayout>
  );
};

export default CPDropDownList;
