// @intent: Provide state and methods in a hook store
import { create } from "zustand";

interface ConfirmModalState {
  display: boolean;
  confirmMessage?: string;
  response: boolean;
}

interface ConfirmModalActions {
  toggle: (display?: boolean) => void;
  confirm: (
    onResponseCbFn: (yes: boolean) => void,
    confirmMessage?: string
  ) => void;
  onResponse: (response: boolean) => void;
}

const initState = {
  display: false,
  confirmMessage: undefined,
  response: false,
};

const useCPConfirmModalContext = create<
  ConfirmModalState & ConfirmModalActions
>()((set, get) => {
  let responseCnFn = (yes: boolean) => {};

  const toggle = (open?: boolean) => {
    set(() => ({ display: open ?? !get().display }));
  };

  const confirm = (
    onResponseCbFn: (yes: boolean) => void,
    confirmMessage?: string
  ) => {
    responseCnFn = onResponseCbFn;
    set({ ...initState, confirmMessage, display: true });
  };

  const onResponse = (response: boolean) => {
    set(() => ({ display: false, response }));

    responseCnFn(response);
  };

  return {
    ...initState,
    toggle,
    confirm,
    onResponse,
  };
});

export default useCPConfirmModalContext;
