import * as React from "react";
import { Box, Link, List, ListItem, Spacer } from "../../base";

import "./CPAppActionBarStyles.scss";
import { DropDownButton } from "@progress/kendo-react-buttons";
import useUserStore from "../../../state/stores/useUserStore";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import { useNavigate } from "react-router-dom";
import CPText from "components/base/CPText/CPText";
import CPInvoiceSearchBox from "../CPInvoiceSearchBox/CPInvoiceSearchBox";
import CPEnocOrderSearchBox from "../CPEnocOrderSearchBox/CPEnocOrderSearchBox";
import useAppStore from "state/stores/useAppStore";
import classNames from "classnames";

interface AppActionBarProps {
  fixed?: boolean;
}

const AppActionBar: React.FC<AppActionBarProps> = (props) => {
  return (
    <Box className={classNames("cp-actionbar", { fixed: props.fixed })}>
      <Box className='cp-actionbar-content'>
        <CPInvoiceSearchBox />
        <Spacer />
        <CPEnocOrderSearchBox />
        <Spacer />
      </Box>
    </Box>
  );
};

export default AppActionBar;
