// @intent: Display assign carrier form and list in a modal
import * as React from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPHrBreak, CPText } from "components/base";
import CPModal from "components/shared/CPModal/CPModal";
import SearchOtherCarriersAutoComplete from "./SearchOtherCarriersAutoComplete";
import AssignedCarriersList from "./AssignedCarriersList";
import useGetAssignedCarriersQuery from "../api/useGetAssignedCarriersQuery";
import { ValueObject } from "common/types";
import useAssignCarrierToFCompanyCommand from "../api/useAssignCarrierToFCompanyCommand";
import useCPConfirmModalContext from "components/shared/CPConfirmModal/useCPConfirmModalContext";
import useRemoveAssignedCarrierCommand from "../api/useRemoveAssignedCarrierCommand";

interface AssignCarrierModalProps {
  factoringCompanyExId: string;
  factoringCompanyName: string;
  onClose?: () => void;
}

const AssignCarrierModal: React.FC<AssignCarrierModalProps> = (props) => {
  const { confirm } = useCPConfirmModalContext();
  const removeCommand = useRemoveAssignedCarrierCommand(
    props.factoringCompanyExId
  );
  const assignCommand = useAssignCarrierToFCompanyCommand(
    props.factoringCompanyExId
  );
  const { assignedCarriers, reload, isLoading } = useGetAssignedCarriersQuery(
    props.factoringCompanyExId
  );

  const handleRemoveAssignedCarrier = (carrier: ValueObject) => {
    if (carrier && carrier.externalId) {
      confirm(async () => {
        try {
          await removeCommand.mutateAsync(carrier.externalId);
          reload();
        } catch (err) {
          console.error(err);
        }
      }, `Are you sure you want to remove the assigned carrier?`);
    }
  };

  const handleAssignCarrier = async (carrierExId: string) => {
    try {
      await assignCommand.mutateAsync(carrierExId);
      reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CPModal
      open={true}
      title={`${props.factoringCompanyName}`}
      onClose={props.onClose}
      width={800}
    >
      <StackLayout gap={50} orientation='vertical'>
        <SearchOtherCarriersAutoComplete
          factoringCompanyExId={props.factoringCompanyExId}
          onAdd={handleAssignCarrier}
        />

        <StackLayout orientation='vertical'>
          <CPText>Assigned Carriers</CPText>
          <CPHrBreak spacing={5} />

          <AssignedCarriersList
            showLoader={
              removeCommand.isLoading || assignCommand.isLoading || isLoading
            }
            onRemove={handleRemoveAssignedCarrier}
            factoringCompanyExId={props.factoringCompanyExId}
            assignedCarriers={assignedCarriers}
          />
        </StackLayout>
      </StackLayout>
    </CPModal>
  );
};

export default AssignCarrierModal;
