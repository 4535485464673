// @intent: Container for carriers grid and management components
import * as React from "react";
import { PageVisitTracking, useCPTracking } from "analytics";
import CPPanel from "components/shared/CPPanel/CPPanel";
import CarriersGrid from "features/Carriers/components/CarriersGrid";

const CarriersPage: React.FC = () => {
  useCPTracking(PageVisitTracking());
  return (
    <CPPanel header='Carrier Match'>
      <CarriersGrid />
    </CPPanel>
  );
};

export default CarriersPage;
