import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from "@progress/kendo-data-query";
import { ValueObject } from "../../../../common/types";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { CPGridColumn, CPGridState } from "../types";
import { objToString, strToObject } from "common/helpers";
import { GetCustomFilterOperator } from "../CPFilters/CPFilterOperators";

/**
 * Determine which type of filter information the supplied filter is.
 * @param filter
 * @returns
 */
export const isFilterDescriptor = (
  filter: CompositeFilterDescriptor | FilterDescriptor
) => {
  return (filter as FilterDescriptor).field !== undefined;
};

/**
 * Extracts the filter information from State interface in a friendlier format
 * @param dataState
 * @returns
 */
export const getFiltersFromDataState = (dataState: State) => {
  if (
    dataState.filter &&
    dataState.filter.filters &&
    dataState.filter.filters.length > 0
  ) {
    return dataState.filter.filters.reduce(
      (
        x: ValueObject,
        filter: CompositeFilterDescriptor | FilterDescriptor
      ) => {
        // Currently just working with direct filers and not indefinite filters via CompositeFilterDescriptor.
        if (isFilterDescriptor(filter)) {
          const filterData = filter as FilterDescriptor;

          // Only handling string field types for now.
          // field can also be a function, will implement later if need be.
          if (
            filterData.field != null &&
            typeof filterData.field === "string"
          ) {
            const val =
              ["Please Choose:"].indexOf(filterData.value) > -1
                ? ""
                : filterData.value;
            x[filterData.field] = val;
          }
        }

        return x;
      },
      {}
    );
  }

  return {};
};

/**
 * Extracts the sort information from State interface in a friendlier format.
 * @param dataState
 * @returns
 */
export const getSortFromDataState = (dataState: State) => {
  if (dataState.sort && dataState.sort.length > 0) {
    const sort = dataState.sort[0] || {};
    return {
      sortBy: sort.field,
      ascending: sort.dir === "asc",
    };
  }

  return {};
};

/**
 * Converts state interface to a JSON string
 * @param state
 * @returns
 */
export const dataToString = (state: State) => {
  try {
    return JSON.stringify(state);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Merge kendo grid column state information to the supplied CPGridColumns.
 * @param columns
 * @param updatedColumns
 * @returns
 */
export const updateColumnState = (
  columns: CPGridColumn[],
  updatedColumns: GridColumnProps[]
) => {
  return updatedColumns.map((x, i) => {
    const matchCol = columns.find((y) => y.field === x.field) || {};
    return {
      ...matchCol,
      ...x,
    } as CPGridColumn;
  });
};

/**
 * Combines two column arrays. If using propsToKeep, will only pick props from the first array 'setOne', ignorings any property not
 * the propsToKeep array. Useful if only need a few properties from the first array 'setOne'
 */
export const mergeColumns = (
  setOne: CPGridColumn[],
  setTwo: CPGridColumn[],
  propsToKeep?: string[]
) => {
  propsToKeep = propsToKeep ?? [];
  const noFieldNames = setOne.filter((x) => !x.field);

  if (setTwo.length === 0) {
    return setOne;
  }

  const items = setOne
    .filter((x) => x.field)
    .reduce((cols: ValueObject[], col: ValueObject) => {
      const obj: ValueObject = { field: col.field };
      propsToKeep?.forEach((key) => {
        if (Object(col).hasOwnProperty(key)) {
          obj[key] = col[key];
        }
      });

      cols.push(obj);

      return cols;
    }, []);

  const items2 = setTwo
    .filter((x) => x.field)
    .reduce((cols: ValueObject[], col: ValueObject) => {
      const match = items.find((y) => y.field === col.field) || {};

      cols.push({ ...col, ...match });

      return cols;
    }, []);

  return [...noFieldNames, ...items2] as CPGridColumn[];
};

/**
 * Convert CPGridState into a save-able string.
 * @param state
 * @returns
 */
export const MapToSaveStateJsonString = (state: CPGridState) => {
  // Leaving for now. Was needed, but might not be anymore with other changes made.
  // if (state.controls.filter) {
  //   // If we have a filter operator function we need to add the name to the object,
  //   // since JSON.stringify will remove it. This way we have access to the which operator
  //   // function is being used.
  //   state.controls.filter.filters = state.controls.filter.filters.map(
  //     (filter) => {
  //       if ("operator" in filter && typeof filter.operator === "function") {
  //         filter.operator = filter.operator.name;
  //       }

  //       return filter;
  //     }
  //   );
  // }

  return objToString(state);
};

/**
 * Map a saved search json string to a valid kendo-react with custom filtering CPGridState
 * @param savedSearch
 * @returns
 */
export const MapJsonStringToSaveState = (savedSearch: string) => {
  const state = strToObject(savedSearch) as CPGridState;

  const getColumnFilterType = (fieldName?: string) => {
    const column = state.columns.find((x) => x.field === fieldName) || {};

    return column.filterType;
  };

  if (state.controls.filter) {
    // If we have a filter operator function we need to add the name to the object,
    // since JSON.stringify will remove it. This way we have access to the which operator
    // function is being used.
    // Also kendo grid date columns expect a date instance, not a string.
    state.controls.filter.filters = state.controls.filter.filters.map(
      (filter) => {
        if ("field" in filter) {
          const filterType = getColumnFilterType(filter.field as string);

          if (filterType === "date_range") {
            const start = filter.value.start
              ? new Date(filter.value.start)
              : undefined;
            const end = filter.value.end
              ? new Date(filter.value.end)
              : undefined;
            filter.value = { start, end };
          }

          if (filterType === "date") {
            filter.value = filter.value ? new Date(filter.value) : undefined;
          }

          filter.operator =
            GetCustomFilterOperator(filterType) ?? filter.operator;
        }

        return filter;
      }
    );
  }

  return state;
};
