// @intent: Provide default action list items
import * as React from "react";
import { CPDataAdapter, CPGridColumn } from "./types";
import CPActionItem, { CPActionItemProps } from "./CPActionList/CPActionItem";
import CPActionList, { ActionListChildren } from "./CPActionList/CPActionList";
import CPShowHideModal, {
  CPShowHideModalUpdateType,
} from "./CPShowHideModal/CPShowHideModal";

interface CPDefaultActionListProps {
  dataAdapter: CPDataAdapter;
  children?: ActionListChildren;
}

const CPDefaultActionList: React.FC<CPDefaultActionListProps> = (props) => {
  const [openShowHideModal, setOpenShowHideModal] = React.useState(false);
  const defItems = [
    <CPActionItem
      key='clearFiltersAction'
      label='Clear Filters'
      onSelect={props.dataAdapter.clearFilters}
    />,
    <CPActionItem
      key='refreshAction'
      label='Refresh'
      onSelect={props.dataAdapter.refresh}
    />,
    <CPActionItem
      key='saveSearchAction'
      label='Save Search'
      onSelect={props.dataAdapter.onSaveSearch}
    />,
    <CPActionItem
      key='showHideAction'
      label='Show/Hide Columns'
      onSelect={() => setOpenShowHideModal(true)}
    />,
  ];

  const children = React.Children.toArray(
    props.children
  ) as React.ReactElement<CPActionItemProps>[];

  const mapped = children.map((child, i) =>
    React.cloneElement(child, { key: i, ...child.props })
  );

  const handleUpdateGridColumns = (
    type: CPShowHideModalUpdateType,
    columns: CPGridColumn[]
  ) => {
    if (props.dataAdapter.onColumnUpdate) {
      props.dataAdapter.onColumnUpdate({ updatedColumns: columns });
    }

    if (type === "set_default" && props.dataAdapter.onSaveSearch) {
      props.dataAdapter.onSaveSearch({
        ...props.dataAdapter.gridState,
        columns,
      });
    }

    setOpenShowHideModal(false);
  };

  return (
    <>
      <CPActionList>{[...defItems, ...mapped]}</CPActionList>
      {openShowHideModal && (
        <CPShowHideModal
          open={true}
          onClose={() => setOpenShowHideModal(false)}
          columns={props.dataAdapter.gridState.columns}
          onUpdate={handleUpdateGridColumns}
        />
      )}
    </>
  );
};

export default CPDefaultActionList;
