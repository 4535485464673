// @intent: Display roles in a transfer list box
import * as React from "react";
import CPListBox from "../CPListBox/CPListBox";
import useGetRoleListQuery from "api/queries/useGetRoleListQuery";
import useXListBox, { XListBoxTransferEvent } from "hooks/common/useXListBox";

interface CPRoleXListBoxProps {
  currentRoles?: string[];
  onTransfer?: (event: XListBoxTransferEvent) => void;
  hide?: boolean;
}

const CPRoleXListBox: React.FC<CPRoleXListBoxProps> = (props) => {
  const query = useGetRoleListQuery();

  const currentRoles = React.useMemo(() => {
    if (props.currentRoles) {
      return props.currentRoles.map((x) => ({ roleName: x }));
    }

    return [];
  }, [props.currentRoles]);

  const XListBox = useXListBox({
    name: "roles",
    labelKey: "roleName",
    valueKey: "roleName",
    available: query.roles.filter(
      (x) => currentRoles.some((y) => y.roleName === x.roleName) === false
    ),
    selected: currentRoles,
    onTransfer: props.onTransfer,
  });
  return props.hide ? null : (
    <CPListBox {...XListBox} headerText='Assign Roles' />
  );
};

export default CPRoleXListBox;
