// @intent: Methods for handling invoice search grid

import * as React from "react";
import {
  useCPGridSavedSearches,
  useCPVirtualGridAdapter,
} from "components/shared/CPGrid";
import useCPLocalDataGridAdapter from "components/shared/CPGrid/Hooks/useCPLocalDataGridAdapter";
import {
  CPGridColumn,
  CPGridState,
  CustomCellProps,
} from "components/shared/CPGrid/types";
import useAppStore from "state/stores/useAppStore";
import useUserStore from "state/stores/useUserStore";
import { CPText, DateText } from "components/base";

const useInvoiceSearchGridMethods = (gridName: string, data?: any[]) => {
  const userId = useUserStore((user) => user.userId);
  const isAdminEmployee = useUserStore((user) =>
    user.isInRole(["Admin", "Employee"])
  );
  const toast = useAppStore((app) => app.setToastMsg);
  const { savedSearch, saveSearch } = useCPGridSavedSearches({
    route: "UserProfile/SavedSearchFilters",
    defaultRouteParams: {
      pageName: window.location.pathname,
      userId,
      name: gridName,
    },
  });

  const columns: CPGridColumn[] = [
    { field: "carrierName", title: "Carrier Name", width: 250 },

    {
      field: "paymentStatus",
      title: "Payment Status",
      width: 200,
      filterType: "dropdownlist",
      dropdownListOptions: ["To Be Processed", "Scheduled To Pay", "Paid"],
    },
    {
      field: "checkDate",
      title: "Check Date",
      width: 150,
      minResizableWidth: 80,
      renderCell: (data: CustomCellProps) => {
        return <DateText date={data.dataItem.checkDate} />;
      },

      filterType: "date_range",
    },
    {
      field: "scheduledCheckDate",
      title: "Scheduled Check Date",
      width: 150,
      minResizableWidth: 80,
      renderCell: (data: CustomCellProps) => (
        <DateText date={data.dataItem.scheduledCheckDate} />
      ),
      filterType: "date_range",
    },
    {
      field: "checkNumber",
      title: "Check Number",
      width: 175,
    },
    {
      field: "amount",
      title: "Amount",
      width: 175,
      filterable: false,
      renderCell: (data: CustomCellProps) => {
        return <CPText fontSize='13px'>{`$${data.dataItem.amount}`}</CPText>;
      },
    },
    {
      field: "carrierInvoiceNumber",
      title: "Carrier Invoice #",
      width: 175,
    },
    {
      field: "enocOrderId",
      title: "ENOC Order #",
      width: 175,
    },
    { field: "origin", title: "Origin", width: 200 },
    { field: "destination", title: "Destination", width: 200 },
    {
      field: "deliveryDate",
      title: "Delivery Date",
      width: 150,
      minResizableWidth: 80,
      renderCell: (data: CustomCellProps) => (
        <DateText date={data.dataItem.deliveryDate} />
      ),
      filterType: "date_range",
    },
  ];

  if (isAdminEmployee) {
    columns.push({
      field: "evansAuthority",
      title: "Evans Authority",
      width: 200,
    });
    columns.push({
      field: "evansAgencyId",
      title: "Evans Agency Id",
      width: 200,
    });
  }

  const columnsToUse = savedSearch?.columns ?? columns;

  const gridAdapter = useCPLocalDataGridAdapter({
    columns: columnsToUse,
    data: data ?? [],
    setGridState: savedSearch,
  });

  const onSaveSearch = (gridState?: CPGridState) => {
    const state = { ...gridAdapter.gridState, ...gridState };
    saveSearch(state, {
      onComplete: () => toast("Search saved successfully."),
    });
  };

  return {
    columns: gridAdapter.gridState.columns,
    onSaveSearch,
    gridAdapter,
  };
};

export default useInvoiceSearchGridMethods;
