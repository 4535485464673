// @intent: Form for searching invoices
import { StackLayout } from "@progress/kendo-react-layout";
import { ButtonClickTracking, useCPTracking } from "analytics";
import { CPHrBreak } from "components/base";
import CPAgencyXListBox from "components/shared/CPAgencyXListBox/CPAgencyXListBox";
import CPButton from "components/shared/CPButton/CPButton";
import CPCompanyXListBox from "components/shared/CPCompanyXListBox/CPCompanyXListBox";
import CPDateRange from "components/shared/CPDateRange/CPDateRange";
import CPDropDownList from "components/shared/CPDropDownList/CPDropDownList";
import CPMultiTagTextBox from "components/shared/CPMultiTagTextBox/CPMultiTagTextBox";
import useUserSavedSearches from "hooks/common/useUserSavedSearches";
import useInvoiceSearchForm from "features/Invoices/hooks/useInvoiceSearchForm";
import * as React from "react";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";
import { ISearchInvoicePayment } from "../types";

interface InvoiceSearchFormProps {
  initValues?: ISearchInvoicePayment;
  allowCarrierMultiSelect?: boolean;
}

const InvoiceSearchForm: React.FC<InvoiceSearchFormProps> = (props) => {
  const { trackingEventDelegate } = useCPTracking();
  const updateForm = useInvoiceSearchStore((ctx) => ctx.updateForm);
  const openSaveSearchModal = useInvoiceSearchStore(
    (ctx) => ctx.openSaveSearchModal
  );
  const { searches } = useUserSavedSearches({
    pageName: "Invoice Search",
  });
  const form = useInvoiceSearchForm({ initValues: props.initValues });

  React.useEffect(() => {
    updateForm(form.values);
  }, [form.values, updateForm]);

  return (
    <StackLayout orientation='vertical' gap={40}>
      <StackLayout gap={100}>
        {props.allowCarrierMultiSelect && (
          <CPMultiTagTextBox
            label='Carrier Name(s)'
            name='carriers'
            helpText='Add filter(s) or search all.'
            onChange={form.onMultiTagChange}
            value={form.values.carriers}
          />
        )}

        <CPDropDownList
          {...form}
          label='Payment Status'
          data={["To Be Processed", "Scheduled To Pay", "Paid"]}
          defaultItem={"Any"}
          name='paymentStatus'
          value={form.values.paymentStatus}
          style={{ minWidth: 400 }}
        />
        <StackLayout align={{ vertical: "middle", horizontal: "end" }} gap={20}>
          <CPDropDownList
            label='Saved Search'
            name='saveSearches'
            data={searches}
            textField='searchName'
            helpText='Select save search to auto-populate form.'
            onChange={form.handleSaveSearchSelect}
            value={form.selectedSaveSearch}
            style={{ width: 400, flex: 1 }}
          />
          <CPButton
            fillMode={"outline"}
            size={"small"}
            onClick={trackingEventDelegate(
              openSaveSearchModal,
              ButtonClickTracking("Invoice Save Search", { ...form.values })
            )}
          >
            Save Search
          </CPButton>
        </StackLayout>
      </StackLayout>
      <CPHrBreak />
      <StackLayout gap={50}>
        <CPDateRange
          {...form}
          value={form.values.deliveryDate}
          name='deliveryDate'
          label='Delivery Date'
        />
        <CPDateRange
          {...form}
          value={form.values.scheduledCheckDate}
          name='scheduledCheckDate'
          label='Scheduled Check Date'
        />
        <CPDateRange
          {...form}
          value={form.values.checkDate}
          name='checkDate'
          label='Check Date'
        />
      </StackLayout>
      <CPHrBreak />
      <StackLayout gap={100}>
        <CPMultiTagTextBox
          label='Carrier Invoice Number(s)'
          name='carrierInvoiceNumbers'
          helpText='Add filter(s) or search all.'
          onChange={form.onMultiTagChange}
          value={form.values.carrierInvoiceNumbers}
        />

        <CPMultiTagTextBox
          label='Order Id(s)'
          name='enocOrderIds'
          helpText='Add filter(s) or search all.'
          onChange={form.onMultiTagChange}
          value={form.values.enocOrderIds}
        />

        <CPMultiTagTextBox
          label='Check Number(s)'
          name='checkNumbers'
          helpText='Add filter(s) or search all.'
          onChange={form.onMultiTagChange}
          value={form.values.checkNumbers}
        />
      </StackLayout>
      <CPHrBreak />
      <StackLayout gap={150}>
        <CPMultiTagTextBox
          label='Origin'
          name='origins'
          helpText='Search by City, State - Example: Yardley, PA'
          onChange={form.onMultiTagChange}
          value={form.values.origins}
        />
        <CPMultiTagTextBox
          label='Destination'
          name='destinations'
          helpText='Search by City, State - Example: Yardley, PA'
          onChange={form.onMultiTagChange}
          value={form.values.destinations}
        />
      </StackLayout>
      {/*F <CPHrBreak />
       <StackLayout gap={50}>
        <CPCompanyXListBox
          name='operatingEntities'
          onTransfer={form.onTransfer}
          selected={form.values.operatingEntities ?? []}
        />
        <CPAgencyXListBox
          name='agencies'
          onTransfer={form.onTransfer}
          selected={form.values.agencies ?? []}
        />
      </StackLayout> */}
    </StackLayout>
  );
};

export default InvoiceSearchForm;
