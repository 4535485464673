// @intent: Methods for handling excel exports
import * as React from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ValueObject } from "common/types";
import dayjs from "dayjs";

interface ExcelExportAdapterOptions {
  data?: ValueObject[];
  fileName?: string;
}

const useExcelExportAdapter = (options?: ExcelExportAdapterOptions) => {
  const exportRef = React.useRef<ExcelExport | null>(null);
  const handleExport = (data?: ValueObject[]) => {
    if (exportRef.current !== null) {
      // Overrides, use method param, if nothing use options param, if nothing, then
      // it will fall back to using what is available on current grid.
      exportRef.current.save(data ?? options?.data);
    }
  };
  return {
    ref: exportRef,
    data: options?.data || [],
    export: handleExport,
    fileName:
      options?.fileName ??
      `Carrier Portal Export ${dayjs(Date.now()).format(
        "MM/DD/YYYY hh:mm:ss"
      )}`,
  };
};

export default useExcelExportAdapter;
