// @intent: Get carrier contacts
import { useQuery } from "react-query";
import http from "../../../common/http";
import ReportOption from "../types/ReportOption";
import { IReportListOption } from "../types";

const useReportListQuery = () => {
  const query = useQuery(
    ["Lookup/GetReportList"],
    () => {
      return http.fetchAsync("Lookup/GetReportList");
    },
    {
      staleTime: 5000,
      select: (results) => {
        return results.map((x: IReportListOption) => ReportOption(x));
      },
    }
  );

  return {
    refresh: query.refetch,
    reports: (query.data ?? []) as IReportListOption[],
    isLoading: query.isLoading,
  };
};

export default useReportListQuery;
