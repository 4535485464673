// @intent: Provide method for refreshing user session.
import { useQuery } from "react-query";
import http from "common/http";
import { accessTokenName, refreshTokenName } from "../../../common/config";
import dayjs from "dayjs";
import { getValueFromCookie, storeCookie } from "../../../common/helpers";
import TokenResponse from "../types/TokenResponse";
import { ITokenResponse } from "../types";

const useAuthRefreshQuery = (
  keepSessionAlive?: boolean,
  onSettled?: (result: ITokenResponse) => void
) => {
  const query = useQuery(
    ["RefreshTokens"],
    () => {
      return fetchNewTokens();
    },
    {
      enabled: keepSessionAlive,
      staleTime: 3000 * 60,
      refetchOnWindowFocus: true,
      refetchInterval: 2000 * 60,
      refetchIntervalInBackground: true,
      onSettled: (data) => {
        if (onSettled && data) {
          onSettled(data);
        }
      },
    }
  );

  const fetchNewTokens = async () => {
    const refreshToken = getValueFromCookie(refreshTokenName, "token");
    const result = TokenResponse();
    const api = http.createAxiosInstance(); // Need a new instance here.

    if (!refreshToken) {
      result.error = "No refresh token.";
      return result;
    }

    try {
      const params = new URLSearchParams({ refreshToken }).toString();
      const { data } =
        (await api.get(`Token/GetRefreshTokens?${params}`)) || {};

      if (data.success === false) {
        console.error(data.srrors[0]);
        result.error = data.errors[0];
        return result;
      }

      const tokenResponse = data || {};

      if (tokenResponse.accessToken) {
        storeCookie(
          accessTokenName,
          tokenResponse.accessToken.token,
          tokenResponse.accessToken.expiresAt ??
            dayjs(Date.now()).add(1, "hour").toDate().toUTCString()
        );

        http.setAuthHeader(tokenResponse.accessToken.token);
        result.accessToken = tokenResponse.accessToken.token;
      }

      if (tokenResponse.refreshToken) {
        storeCookie(
          refreshTokenName,
          tokenResponse.refreshToken.token,
          tokenResponse.refreshToken.expiresAt ??
            dayjs(Date.now()).add(7, "days").toDate().toUTCString()
        );

        result.refreshToken = tokenResponse.refreshToken.token;
      }
    } catch (err) {
      console.error(err);
      result.error = "No refresh token.";
    }

    return result;
  };

  return {
    clear: query.remove,
    error: query.error,
    isError: query.isError,
    isSuccess: query.isSuccess,
    isLoading: query.isLoading,
  };
};

export default useAuthRefreshQuery;
