// @intent: Handle authentication and refresh sessions
import * as React from "react";
import http from "../../../common/http";
import useAuthRefreshQuery from "../api/useAuthRefreshQuery";
import { accessTokenName, refreshTokenName } from "../../../common/config";
import { getValueFromCookie, removeCookie } from "../../../common/helpers";
import useLoginCommands from "../api/useLoginCommand";
import { ILoginRequest } from "../types";
import { useQuery } from "react-query";

const getTokens = () => {
  return {
    accessToken: getValueFromCookie(accessTokenName, "token"),
    refreshToken: getValueFromCookie(refreshTokenName, "token"),
    rememberMe: getValueFromCookie(refreshTokenName, "token") != null,
  };
};

const useAuth = () => {
  //Using query here to keep session 'reactive', it will update state on
  //each pull, when tokens are not valid then kills session.
  const authTokensCache = useQuery(
    ["Auth"],
    () => Promise.resolve(getTokens()),
    {
      staleTime: Infinity,
      initialData: getTokens(),
      select: (tokens) => {
        if (!tokens) {
          return false;
        }

        if (tokens.refreshToken != null) {
          return true;
        }

        return tokens.accessToken != null;
      },
    }
  );

  useAuthRefreshQuery(getTokens().refreshToken != null, (result) => {
    if (result.error === "No refresh token.") {
      signout();
    }
  });

  const loginCommands = useLoginCommands();

  const signout = () => {
    removeCookie(refreshTokenName);
    removeCookie(accessTokenName);

    authTokensCache.refetch();
  };

  const signinAsync = async (creds: ILoginRequest) => {
    const result = await loginCommands.loginAsync(creds);

    // Valid, then refresh the localstate
    if (!result.error) {
      authTokensCache.refetch();
    }

    // Failed to login.
    return result.error ? "Email or password is incorrect." : "";
  };

  http.addResponseMiddleware(
    async (response) => response,
    async (error) => {
      if (typeof error === "string") return Promise.reject(error);

      const status = error.status ?? error.request.status;
      if (status === 401 && !getTokens().refreshToken) {
        signout();
      }

      return Promise.reject(error);
    }
  );

  return {
    isAuthenticated: authTokensCache.data ?? false,
    signout,
    signinAsync,
    isSigningIn: loginCommands.isLoading,
  };
};

export default useAuth;
