import { CPText } from "components/base";
import { CPTextProps } from "components/types";
import * as React from "react";

interface CPErrorMessageProps extends Omit<CPTextProps, "children"> {
  errorMsg?: string;
  showError?: boolean;
  filled?: boolean;
}

const CPErrorMessage: React.FC<CPErrorMessageProps> = (props) => {
  const showError = () => {
    if (props.showError != null) {
      return props.showError;
    }

    return props.errorMsg != null && props.errorMsg.length > 0;
  };
  return showError() ? (
    <CPText
      {...props}
      color={props.filled ? "white" : "danger"}
      style={{
        backgroundColor: props.filled ? "#ce3434" : "inherit",
        padding: "5px",
        textTransform: "capitalize",
        marginTop: "5px",
        marginBottom: "5px",
      }}
    >
      {props.errorMsg}
    </CPText>
  ) : null;
};

export default CPErrorMessage;
