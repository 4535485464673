import StateProvinces from "./stateProvinces.json";

export type DATA_TYPES = "STATES" | "PROVINCES" | "STATE_PROVINCE";

export default function LocalDataAdapter(dataType: DATA_TYPES) {
  switch (dataType) {
    case "STATES":
      return StateProvinces.filter((x) => x.country === "US");
    case "PROVINCES":
      return StateProvinces.filter((x) => x.country === "Canada");
    case "STATE_PROVINCE":
      return StateProvinces;

    default:
      return [];
  }
}
