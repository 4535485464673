import * as React from "react";
import { Box, Container } from "../../base";
import { Loader } from "@progress/kendo-react-indicators";
import "./CPLoaderStyles.scss";

interface CPLoaderProps {
  isLoading: boolean;
}

const CPLoader: React.FC<CPLoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <Container className='cp-loader'>
      <Box className='loader-overlay'></Box>
      <Box className='loader-spinner'>
        <Loader type={"infinite-spinner"} size='large' />
      </Box>
    </Container>
  ) : null;
};

export default CPLoader;
