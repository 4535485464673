// @intent: Display form for employee access requests

import * as React from "react";
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";

import CPButton from "../../../../../components/shared/CPButton/CPButton";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";
import useEmployeeSetupForm from "features/LoginRequests/hooks/useEmployeeSetupForm";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPhoneTextBox from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import { CPText } from "components/base";
import {
  ButtonClickTracking,
  PageVisitTracking,
  useCPTracking,
} from "analytics";

interface EmployeeSetupFormProps {
  onSuccess: (message: string) => void;
}

const EmployeeSetupForm: React.FC<EmployeeSetupFormProps> = ({ onSuccess }) => {
  const { trackingEventDelegate } = useCPTracking(
    PageVisitTracking("Register/Employee")
  );
  const form = useEmployeeSetupForm(onSuccess);
  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={5}>
        <StackLayout orientation='vertical'>
          <CPText align='center' textStyle='subtitle'>
            Request Employee Access
          </CPText>
          <CPText align='center'>
            Request to have your account details reviewed and approved manually.
          </CPText>
        </StackLayout>
        <CPErrorMessage errorMsg={form.apiError} align='center' />
        <StackLayout gap={10}>
          <CPTextBox
            {...form}
            label='First Name'
            name='firstName'
            value={form.values.firstName}
            errorMsg={form.errors.firstName}
            required
            personNameCharacters
            toCapital
          />

          <CPTextBox
            {...form}
            label='Last Name'
            name='lastName'
            value={form.values.lastName}
            errorMsg={form.errors.lastName}
            required
            personNameCharacters
            toCapital
          />
        </StackLayout>

        <CPEmailTextBox
          {...form}
          name='email'
          label='Email Address'
          value={form.values.email}
          errorMsg={form.errors.email}
          required
        />
        <CPPhoneTextBox
          {...form}
          name='phoneNumber'
          label='Phone Number'
          value={form.values.phoneNumber}
          errorMsg={form.errors.phoneNumber}
          required
        />

        <CPButton
          size={"large"}
          icon='track-changes-enable'
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Request Access - Employee Form", { ...form })
          )}
          showLoader={form.isLoading}
        >
          Request Access
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default EmployeeSetupForm;
