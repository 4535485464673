// @intent: Methods for handling formik change, blur, and validation event(s). Best used with custom 'CP' TextBox, EmailTextBox, TextBoxes....
import * as React from "react";
import { TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { ValueObject } from "common/types";
import {
  CPEmailTextBoxChangeEvent,
  CPEmailTextBoxEvent,
} from "components/shared/CPEmailTextBox/CPEmailTextBox";
import {
  CPPhoneTextChangeEvent,
  CPPhoneTextEvent,
} from "components/shared/CPPhoneTextBox/CPPhoneTextBox";
import {
  CPTextBoxChangeEvent,
  CPTextBoxEvent,
} from "components/shared/CPTextBox/CPTextBox";
import {
  CPZipCodeTextBoxChangeEvent,
  CPZipCodeTextBoxEvent,
} from "components/shared/CPZipCodeTextBox/CPZipCodeTextBox";
import { CPDateRangeChangeEvent } from "components/shared/CPDateRange/CPDateRange";
import {
  CPDropDownListEvent,
  CPDropDownListEventChangeEvent,
} from "components/shared/CPDropDownList/CPDropDownList";
import { CPTimePickerChangeEvent } from "components/shared/CPTimePicker/CPTimePicker";

interface useFormikCustomHandlersProps {
  setFieldError: (field: string, value: string | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (
    e: React.FocusEvent<any, Element> | React.SyntheticEvent<HTMLElement, Event>
  ) => void;
}

export type FormikCustomHandlerEvent =
  | TextBoxChangeEvent
  | CPPhoneTextChangeEvent
  | CPEmailTextBoxChangeEvent
  | CPTextBoxChangeEvent
  | CPZipCodeTextBoxChangeEvent
  | CPDateRangeChangeEvent
  | CPDropDownListEventChangeEvent
  | CPTimePickerChangeEvent;

export interface FormikCustomHandlers {
  onChange: (event: FormikCustomHandlerEvent) => void;
  onBlur: (
    event:
      | CPTextBoxEvent
      | CPPhoneTextEvent
      | CPEmailTextBoxEvent
      | CPZipCodeTextBoxEvent
      | CPDropDownListEvent
      | React.FocusEvent<any, Element>
  ) => void;
  errors: ValueObject;
  isValid: () => boolean;
}

const useFormikCustomHandlers = (props: useFormikCustomHandlersProps) => {
  const [internalErrorTracking, setInternalErrorTracking] =
    React.useState<ValueObject>({});

  const updateErrorTracking = (key: string, value?: string) => {
    if (!value) {
      const errors = Object.keys(internalErrorTracking).reduce(
        (x: ValueObject, oKey: string) => {
          if (oKey !== key) {
            x[oKey] = internalErrorTracking[oKey];
          }
          return x;
        },
        {}
      );

      setInternalErrorTracking(errors);
    } else {
      setInternalErrorTracking((prev) => ({
        ...prev,
        [key]: value,
      }));
    }

    props.setFieldError(key, value);
  };

  const onChange = (event: FormikCustomHandlerEvent) => {
    if (
      "type" in event &&
      [
        "CPPhoneTextChangeEvent",
        "CPTextBoxChangeEvent",
        "CPEmailTextBoxChangeEvent",
        "CPZipCodeTextBoxChangeEvent",
        "CPDateRangeChangeEvent",
        "CPDropDownListEventChangeEvent",
        "CPTimePickerChangeEvent",
      ].indexOf(event.type) > -1
    ) {
      props.setFieldValue(event.name, event.value);
    } else if ("syntheticEvent" in event) {
      props.handleChange(event.syntheticEvent);
    }

    if ("error" in event) {
      updateErrorTracking(event.name, event.error);
    }
  };

  const onBlur = (
    event:
      | CPTextBoxEvent
      | CPPhoneTextEvent
      | CPEmailTextBoxEvent
      | CPZipCodeTextBoxEvent
      | CPDropDownListEvent
      | React.FocusEvent<any, Element>
  ) => {
    if ("error" in event) {
      updateErrorTracking(event.name, event.error);
    }

    if ("syntheticEvent" in event) {
      props.handleBlur(event.syntheticEvent);
    }
  };

  return {
    onChange,
    onBlur,
    errors: internalErrorTracking,
    isValid: () => Object.keys(internalErrorTracking).length === 0,
  };
};

export default useFormikCustomHandlers;
