import * as React from "react";
import classNames from "classnames";
import "./CPFontAwesomeStyles.scss";

interface CPFontAwesomeProps {
  icon?: string;
  style?: React.CSSProperties;
  size?: number | string;
  inline?: boolean;
}

const CPFontAwesome: React.FC<CPFontAwesomeProps> = ({
  icon,
  style,
  size,
  inline,
}) => {
  return (
    <div
      className='cp-font-awesome'
      style={{
        //minWidth: "30px",
        ...style,
        fontSize: size,
        display: inline ? "inline-block" : "inherit",
      }}
    >
      <i className={classNames(icon)}></i>
    </div>
  );
};

export default CPFontAwesome;
