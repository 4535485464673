// @intent: Methods for handling factoring company access requests

import * as React from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { CPAutoCompleteDataItem } from "components/types";
import useSendFCompanySetupCommand from "features/LoginRequests/api/useSendFCompanySetupCommand";
import { accessRequestSuccess } from "common/constants";
import FCompanyAccessRequest from "features/LoginRequests/types/FCompanyAccessRequest";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import { EventTracking, useCPTracking } from "analytics";

const FormSchema = Yup.object().shape({
  email: Yup.string().required("Email is required."),
  firstName: Yup.string().required("Firstname is required."),
  lastName: Yup.string().required("Lastname is required."),
  phoneNumber: Yup.string().required("Phone number is required."),
  companyName: Yup.string().required("Company name is required."),
  companyRemitAddress: Yup.string().required("Address is required."),
  companyRemitCity: Yup.string().required("City is required."),
  companyRemitState: Yup.string().required("State is required."),
  companyRemitZip: Yup.string().required("Zip is required."),
});

const useFCompanySetupForm = (onSuccessCbFn: (message: string) => void) => {
  const { trackEvent } = useCPTracking();
  const command = useSendFCompanySetupCommand();
  const [selectedCompany, setSelectedCompany] = React.useState<
    CPAutoCompleteDataItem | undefined
  >();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      companyName: "",
      companyRemitAddress: "",
      companyRemitCity: "",
      companyRemitState: "",
      companyRemitZip: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = useEnterKeySubmit(async () => {
    trackEvent(
      EventTracking("Factoring Company Manual Submit", "form_submission")
    );
    if (formik.isValid && handlers.isValid()) {
      command
        .mutateAsync(FCompanyAccessRequest({ ...formik.values }))
        .then(() => {
          onSuccessCbFn(accessRequestSuccess);
        })
        .catch(console.error);
    }
  });

  const onCompanyNameSelected = (selection?: CPAutoCompleteDataItem) => {
    setSelectedCompany(selection);

    const value = selection?.value || {};

    const formVals = {
      companyName: selection?.label ?? "",
      companyRemitAddress: value.address ?? "",
      companyRemitCity: value.city ?? "",
      companyRemitState: value.state ?? "",
      companyRemitZip: value.zipCode ?? value.zip ?? "",
    };

    formik.setValues({ ...formik.values, ...formVals });
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    onCompanyNameSelected,
    selectedCompany,
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    onSubmit: () => formik.submitForm(),
    apiError: command.error as string,
    isSuccess: command.isSuccess,
    isLoading: command.isLoading,
    onNumericChange: (event: React.SyntheticEvent<any, Event>) =>
      formik.handleChange(event),
  };
};

export default useFCompanySetupForm;
