// @intent: simple hook for working with intervals
import * as React from "react";

const useInterval = (initialValue: number, ms: number, stopAt?: number) => {
  const [value, setValue] = React.useState(initialValue);
  const intervalRef = React.useRef<any>(null);
  const valRef = React.useRef<number>(0);
  const stopVal = stopAt ?? 100;

  const start = React.useCallback(() => {
    if (intervalRef.current !== null) {
      return;
    }

    intervalRef.current = setInterval(() => {
      if (valRef.current < stopVal) {
        setValue((v) => v + 1);
        valRef.current = valRef.current + 1;
      }
    }, ms);
  }, []);

  const stop = React.useCallback(() => {
    if (intervalRef.current === null) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);

  const reset = React.useCallback(() => {
    setValue(0);
    valRef.current = 0;
  }, []);

  return {
    value,
    start,
    stop,
    reset,
  };
};

export default useInterval;
