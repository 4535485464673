// @intent: display friendly error page
import * as React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";
import { Navigate, useNavigate } from "react-router-dom";
import { CPText } from "../../../components/base";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card className='forgot-password-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center' style={{ flex: 1 }}>
            An Error Happened.
          </CPText>
        </CardTitle>
        <CardSubtitle>
          <CPText align='center' textStyle='subtitle'>
            An error occurred during the request. Go to the home screen and try
            the request again. If the problem persists please contact Help Desk.
          </CPText>
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <CPButton
          onClick={() => navigate("/")}
          fillMode={"flat"}
          themeColor={"dark"}
          style={{ width: "100%", marginTop: 20 }}
        >
          Go Home
        </CPButton>
      </CardBody>
    </Card>
  );
};

export default ErrorPage;
