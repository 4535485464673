// @intent: Provide a dropdown list with option to choose US states, Canada Provinces, or both.
import * as React from "react";
import CPDropDownList, {
  CPDropDownListEventChangeEvent,
} from "../CPDropDownList/CPDropDownList";
import LocalDataAdapter from "common/data/localDataAdapter";
import { ValueObject } from "common/types";

interface CPStateProvinceDropDownProps {
  usStatesOnly?: boolean;
  provincesOnly?: boolean;
  optionLabel?: "abbr" | "fullname";
  valueField?: "abbr" | "fullname";
  name?: string;
  label?: string;
  value?: string;
  onChange?: (event: CPDropDownListEventChangeEvent) => void;
  required?: boolean;
}

const CPStateProvinceDropDown: React.FC<CPStateProvinceDropDownProps> = (
  props
) => {
  const data = React.useMemo(() => {
    let options: ValueObject[] = [];

    if (props.usStatesOnly ?? true) {
      options = options.concat(LocalDataAdapter("STATES"));
    }

    if (props.provincesOnly ?? true) {
      options = options.concat(LocalDataAdapter("PROVINCES"));
    }

    return options.map((x) => {
      const abbrName = `${x.abbr} - ${x.name}`;

      return {
        label: props.optionLabel ? x[props.optionLabel] : abbrName,
        value: props.valueField ? x[props.valueField] : x.abbr,
      };
    });
  }, [props]);

  return (
    <CPDropDownList
      value={props.value}
      data={data}
      name={props.name ?? "stateProvince"}
      label={props.label ?? "Select..."}
      dataItemKey='value'
      textField='label'
      valueKey='value'
      onChange={props.onChange}
      required={props.required}
    />
  );
};

export default CPStateProvinceDropDown;
