import * as React from "react";
import { BrowserRouter } from "react-router-dom";

import ProtectedRoutes from "./protected/ProtectedRoutes";
import PublicRoutes from "./public/PublicRoutes";
import useAuth from "features/Auth/hooks/useAuth";

const Router: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};
export default Router;
