// @intent: Get the current app version for cache busting
import http from "common/http";
import { useQuery } from "react-query";

const useGetAppVersionQuery = () => {
  const query = useQuery(["App/Version"], () => {
    return http.fetchAsync("App/Version");
  });

  return query;
};

export default useGetAppVersionQuery;
