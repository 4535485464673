import { ICarrierContactFraudVerification } from "./types";

const CarrierContactFraudVerification = (
  init?: ICarrierContactFraudVerification | undefined
) => {
  return {
    ...init,
  };
};

export default CarrierContactFraudVerification;
