// @intent: Methods for handling account creation.
import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import { CPAutoCompleteDataItem } from "components/types";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import useUpsertAccountCommand from "features/Users/api/useUpsertAccountCommand";
import useAppStore from "state/stores/useAppStore";
import { XListBoxTransferEvent } from "../../../hooks/common/useXListBox";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import UpsertAccount from "../types/UpsertAccount";
import { IUpsertAccount, IUserAssignedCarrier } from "../types";
import { ValueObject } from "common/types";

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string().email().required("Email is required."),
  accountType: Yup.string()
    .oneOf(["Carrier", "Employee", "Factoring Company"])
    .required(
      "Account Type is required and must be either 'Carrier', 'Employee', or 'Factoring Company'"
    ),
  phoneNumber: Yup.string().required("A valid phone number is required."),
  assignedCarriers: Yup.string().when("accountType", {
    is: (option: string) => option === "Carrier",
    then: () =>
      Yup.array().min(1, "Please select and assign a carrier from the list."),
    otherwise: () => Yup.array().min(0).nullable(),
  }),
  factoringCompanyExId: Yup.string().when("accountType", {
    is: (option: string) => option === "Factoring Company",
    then: () =>
      Yup.string().required("Please select a factoring company from the list."),
    otherwise: () => Yup.string().nullable(),
  }),
  rolesToAdd: Yup.array().min(
    1,
    "Please select at least one role from the list."
  ),
});

const useUpsertAccountForm = (props: IUpsertAccount) => {
  const toast = useAppStore((app) => app.setToastMsg);
  const [banned, setBanned] = React.useState<boolean>(props.banned ?? false);
  const command = useUpsertAccountCommand();
  const [selectedCompany, setSelectedCompany] = React.useState<
    CPAutoCompleteDataItem | undefined
  >({
    label: props.companyName ?? "",
    value: { externalId: props.factoringCompanyExId },
  });

  const formik = useFormik({
    initialValues: {
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      accountType: props.accountType,
      phoneNumber: props.phoneNumber,
      factoringCompanyExId: props.factoringCompanyExId,
      assignedCarriers: props.assignedCarriers ?? [],
      rolesToAdd: props.rolesToAdd ?? [],
      newPassword: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const formError = React.useMemo(() => {
    const x = { ...formik.errors } as ValueObject;
    const firstErrorKey = Object.keys(x).find((key) => x[key]);

    if (firstErrorKey) {
      return x[firstErrorKey];
    }

    return null;
  }, [formik.errors]);

  const handleSubmit = useEnterKeySubmit(async () => {
    if (formik.isValid && handlers.isValid()) {
      command
        .mutateAsync(
          UpsertAccount({
            ...formik.values,
            id: props.id,
            banned: props.id != null ? banned : undefined,
          })
        )
        .then(() => {
          toast(`User updated successfully.`);
        })
        .catch(console.error);
    }
  });

  const onAccountSelected = (event: DropDownListChangeEvent) => {
    let val = event.target.value;
    val = val === "Select Account Type..." ? "" : val;
    formik.setFieldValue("accountType", val);
    formik.setFieldValue("rolesToAdd", [val]);
  };

  const onCompanySelected = (selectedItem?: CPAutoCompleteDataItem) => {
    setSelectedCompany(selectedItem);

    if (formik.values.accountType === "Carrier") {
      formik.setFieldValue("carrierExId", selectedItem?.value.externalId ?? "");
    }

    if (formik.values.accountType === "Factoring Company") {
      formik.setFieldValue(
        "factoringCompanyExId",
        selectedItem?.value.externalId ?? ""
      );
    }
  };

  const handleRemoveAssignedCarrier = (id: string) => {
    const carriers = formik.values.assignedCarriers?.filter((x) => x.id !== id);
    formik.setFieldValue("assignedCarriers", carriers);
  };

  const handleAddCarrier = (items: IUserAssignedCarrier[]) => {
    formik.setFieldValue("assignedCarriers", [
      ...formik.values.assignedCarriers,
      ...items,
    ]);
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    onAccountSelected,
    formError,
    saveError: (command.isLoading === false ? command.error : "") as string,
    isLoading: command.isLoading,
    disableSubmit: () => !formik.isValid || !handlers.isValid(),
    onSubmit: () => formik.handleSubmit(),
    onCompanySelected,
    selectedCompany,
    onTransfer: (event: XListBoxTransferEvent) => {
      formik.setFieldValue("rolesToAdd", event.selected);
    },
    hideRoleSelect: !formik.values.accountType,
    isEdit: props.id != null && props.id > 0,
    updateBanned: (event: SwitchChangeEvent) => setBanned(event.target.value),
    userBanned: banned,
    handleAddCarrier,
    handleRemoveAssignedCarrier,
  };
};

export default useUpsertAccountForm;
