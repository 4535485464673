// @intent: 2FA Form
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { Box, Container } from "components/base";
import * as React from "react";
import CPTextBox from "../CPTextBox/CPTextBox";
import CPButton from "../CPButton/CPButton";
import use2faForm from "components/shared/CP2faModal/use2faForm";
import CPErrorMessage from "../CPErrorMessage/CPErrorMessage";

const CP2faForm: React.FC = () => {
  const form = use2faForm();

  const renderForm = () => {
    return (
      <Container padding={5}>
        <StackLayout gap={5} orientation='vertical'>
          <CPErrorMessage align='center' errorMsg={form.apiError} />

          <CPTextBox
            {...form}
            label='Verification Code'
            name='verificationCode'
            value={form.values.verificationCode}
            errorMsg={form.errors.verificationCode}
            required
            alphaNumeric
            disableSpaces
            maxLength={6}
            toUppercase
          />
          <StackLayout gap={5} style={{ marginTop: 20 }}>
            <CPButton
              size={"small"}
              themeColor={"primary"}
              fillMode={"outline"}
              style={{ width: "100%" }}
              disabled={form.disableResend()}
              onClick={() => form.onResendCode()}
            >
              {form.resendBtnText()}
            </CPButton>
            <CPButton
              size={"small"}
              themeColor={"primary"}
              style={{ width: "100%" }}
              disabled={form.disableSubmit()}
              onClick={() => form.onSubmit()}
              showLoader={form.isLoading}
            >
              Verify Code
            </CPButton>
          </StackLayout>
        </StackLayout>
      </Container>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default CP2faForm;
