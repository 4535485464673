// @intent: Get roles

import http from "common/http";
import Role from "features/Users/types/Role";
import { useQuery } from "react-query";
import { IRole } from "../../features/Users/types";

const useGetRoleListQuery = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["Lookup/GetRolesList"],
    () => {
      return http.fetchAsync("Lookup/GetRolesList");
    },
    {
      staleTime: 5 * 60 * 1000,
    }
  );

  return {
    roles: (data ?? []).map((x: IRole) => Role({ ...x })) as IRole[],
    isLoading,
    error,
  };
};

export default useGetRoleListQuery;
