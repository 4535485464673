import * as React from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  ListBoxToolbarClickEvent,
  ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";

import "./CPListBoxStyles.scss";
import { noop } from "../../../common/helpers";
import CPText from "components/base/CPText/CPText";
import { Box } from "components/base";

export type CPBoxOption = {
  available: CPListBoxItem[];
  selected: CPListBoxItem[];
};

interface CPListBoxProps {
  headerText?: string;
  boxOptions: CPBoxOption;
  onChange: (options: CPBoxOption) => void;
  boxStyles?: React.CSSProperties;
}

export interface CPListBoxItem {
  label: string;
  value: string;
  selected: boolean;
}

const CPListBox: React.FC<CPListBoxProps> = ({
  headerText,
  boxOptions,
  onChange = noop,
  boxStyles,
}) => {
  const handleItemClick = (
    event: ListBoxItemClickEvent,
    box: "available" | "selected"
  ) => {
    if (box === "available") {
      const mapped = boxOptions.available.map((item) => {
        if (item.label === event.dataItem.label) {
          item.selected = !item.selected;
        } else if (!event.nativeEvent.ctrlKey) {
          item.selected = false;
        }
        return item;
      });

      onChange({
        available: mapped,
        selected: boxOptions.selected.map((item) => ({
          ...item,
          selected: false,
        })),
      });
    } else {
      const mapped = boxOptions.selected.map((item) => {
        if (item.label === event.dataItem.label) {
          item.selected = !item.selected;
        } else if (!event.nativeEvent.ctrlKey) {
          item.selected = false;
        }
        return item;
      });

      onChange({
        available: boxOptions.available.map((item) => ({
          ...item,
          selected: false,
        })),
        selected: mapped,
      });
    }
  };

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    let result: any = processListBoxData(
      boxOptions.available,
      boxOptions.selected,
      toolName,
      "selected"
    );

    const available = result.listBoxOneData.map((x: CPListBoxItem) => ({
      ...x,
      selected: false,
    }));
    const selected = result.listBoxTwoData.map((x: CPListBoxItem) => ({
      ...x,
      selected: false,
    }));

    onChange({
      available,
      selected,
    });
  };

  return (
    <div className='cp-listbox-container'>
      <CPText textStyle='subtitle'>{headerText}</CPText>
      <div className='cp-listboxes'>
        <div className='cp-available-listbox'>
          <CPText>Available</CPText>
          <ListBox
            draggable={false}
            style={{
              height: 350,
              width: "100%",
              ...boxStyles,
              maxHeight: 600,
            }}
            data={boxOptions.available}
            textField='label'
            selectedField='selected'
            onItemClick={(e: ListBoxItemClickEvent) =>
              handleItemClick(e, "available")
            }
            toolbar={() => {
              return (
                <ListBoxToolbar
                  tools={[
                    "transferTo",
                    "transferFrom",
                    "transferAllTo",
                    "transferAllFrom",
                  ]}
                  data={boxOptions.available}
                  dataConnected={boxOptions.selected}
                  onToolClick={handleToolBarClick}
                />
              );
            }}
          />
          <Box spacing={5}>
            <CPText textStyle='caption' transform='uppercase'>
              ( Hold CTRL + Click To Select Multiple )
            </CPText>
          </Box>
        </div>
        <div className='cp-selected-listbox'>
          <CPText>Selected</CPText>
          <ListBox
            draggable={false}
            style={{
              height: 350,
              width: "100%",
              ...boxStyles,
              maxHeight: 600,
            }}
            data={boxOptions.selected}
            textField='label'
            selectedField='selected'
            onItemClick={(e: ListBoxItemClickEvent) =>
              handleItemClick(e, "selected")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CPListBox;
