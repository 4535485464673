// @intent: Display the content portion for CPWizardModal
import { CPHrBreak, CPText, Container } from "components/base";
import * as React from "react";
import { CPWizardModalContentProps } from ".";

const CPWizardModalContent: React.FC<CPWizardModalContentProps> = (props) => {
  return (
    <Container className='cp-wizard-modal-content' key={props.step}>
      {props.headerTitle && (
        <>
          <CPText textStyle={"subtitle2"}>{props.headerTitle}</CPText>
          <CPText style={{ opacity: 0.7 }} fontSize='14px'>
            {props.subheaderTitle}
          </CPText>
          <CPHrBreak spacing={20} />
        </>
      )}
      {props.children}
    </Container>
  );
};

export default CPWizardModalContent;
