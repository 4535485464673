// @intent: Display a skeleton loading indicator in shape of a form with multiple inputs
import * as React from "react";
import { Skeleton } from "@progress/kendo-react-indicators";

import { Box } from "components/base";

interface CPSkeletonFormLoaderProps {
  show?: boolean;
  numOfItems?: number;
  children?: React.ReactNode;
}

const CPSkeletonFormLoader: React.FC<CPSkeletonFormLoaderProps> = (props) => {
  const numOfItems = props.numOfItems ?? 4;
  return (
    <Box>
      {props.show &&
        Array.from({ length: numOfItems }).map((_, i) => (
          <Skeleton key={i} style={{ width: "100%", minHeight: 30 }} />
        ))}

      {!props.show && props.children}
    </Box>
  );
};

export default CPSkeletonFormLoader;
