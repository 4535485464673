// @intent: Wrapper component to display a listview
import * as React from "react";
import {
  ListView,
  ListViewHeader,
  ListViewFooter,
  ListViewItemProps,
} from "@progress/kendo-react-listview";
import "./CPListViewStyles.scss";
import { ValueObject } from "common/types";
import { Box, CPText, Container } from "components/base";
import { Pager, PageChangeEvent } from "@progress/kendo-react-data-tools";

import CPTextBox, { CPTextBoxChangeEvent } from "../CPTextBox/CPTextBox";
import { StackLayout } from "@progress/kendo-react-layout";
import CPButton from "../CPButton/CPButton";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import { useDebouncedCallback } from "use-debounce";
import { Loader } from "@progress/kendo-react-indicators";

interface CPListViewHeaderItem {
  label: string;
  dataField: string;
}

interface CPActionButton {
  fontAwesomeIcon: string;
  title?: string;
  color?:
    | "success"
    | "error"
    | "info"
    | "base"
    | "primary"
    | "light"
    | "secondary"
    | "tertiary"
    | "warning"
    | "dark"
    | "inverse"
    | null
    | undefined;
  onClick?: (dataItem: ValueObject) => void;
}

interface CPListViewProps {
  data: ValueObject[];
  header: CPListViewHeaderItem[];
  pageable?: boolean;
  filterable?: boolean;
  actionBtns?: CPActionButton[];
  placeholder?: string;
  take?: number;
  onFilter?: (search: string) => void;
  showLoader?: boolean;
}

const CPListView: React.FC<CPListViewProps> = (props) => {
  const defaultTake = props.take ?? 10;
  const [filterInput, setFilterInput] = React.useState<string | undefined>();
  const [page, setPage] = React.useState({
    skip: 0,
    take: props.pageable === false ? props.data.length : defaultTake,
  });
  const { skip, take } = page;

  const items = React.useMemo(() => {
    if (!filterInput) return props.data;

    return props.data.filter((x) => {
      return props.header.some((y) => {
        if (x[y.dataField]) {
          return (
            x[y.dataField]
              .toString()
              .toUpperCase()
              .indexOf(filterInput.toUpperCase()) > -1
          );
        }

        return false;
      });
    });
  }, [props, filterInput]);

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const renderFooter = () => (
    <ListViewFooter className='cp-listview-footer'>
      {props.data.length} total.
    </ListViewFooter>
  );

  const renderHeader = () => (
    <ListViewHeader className='cp-listview-header'>
      {props.header.map((x, i) => (
        <Container key={i} style={{ flex: 1 }}>
          <CPText align={"left"} fontSize='14px'>
            {x.label}
          </CPText>
        </Container>
      ))}
    </ListViewHeader>
  );

  const updateOnFilter = useDebouncedCallback((value?: string) => {
    if (props.onFilter && value) {
      props.onFilter(value);
    }
  }, 300);

  const handleFilter = (event: CPTextBoxChangeEvent) => {
    const value = (event.value ?? "") as string;

    updateOnFilter(value);

    setPage({ skip: 0, take: defaultTake });
    setFilterInput(value.toUpperCase());
  };

  const renderItem = (itemProps: ListViewItemProps) => {
    const renderActionBtns = () => {
      if (props.actionBtns == null) return null;

      return props.actionBtns.map((btn, bi) => (
        <CPButton
          className='btn-small'
          key={bi}
          themeColor={btn.color ?? "inverse"}
          title={btn.title}
          fillMode={"flat"}
          onClick={() => btn.onClick != null && btn.onClick(itemProps.dataItem)}
        >
          <CPFontAwesome icon={btn.fontAwesomeIcon} />
        </CPButton>
      ));
    };

    const isLastItem = (index: number) => {
      return index === props.header.length;
    };

    let item = itemProps.dataItem;

    if (items.length === 0) {
      return <div className='cp-listview-item'>No Result(s)</div>;
    }

    return (
      <div className='cp-listview-item'>
        {props.header.map((x, i) => (
          <div key={i}>
            <CPText fontSize='13px'>{item[x.dataField] ?? "N/A"}</CPText>
            {isLastItem(i + 1) && renderActionBtns()}
          </div>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (props.showLoader) {
      return (
        <Container
          aligned={"center"}
          justified={"center"}
          padding={40}
          spacing={10}
        >
          <CPText textStyle='caption'>Loading...</CPText>
          <Loader type={"infinite-spinner"} size={"medium"} />;
        </Container>
      );
    }

    if (resultSet.length > 0) {
      return (
        <ListView
          data={resultSet}
          item={renderItem}
          header={renderHeader}
          //footer={renderFooter}
        />
      );
    }

    return (
      <Container aligned={"center"} justified={"center"} padding={40}>
        <CPText align='center' fontSize='14px'>
          No Result(s)
        </CPText>
      </Container>
    );
  };

  const resultSet = items.slice(skip, skip + take);

  return (
    <StackLayout gap={10} orientation='vertical'>
      {(props.filterable ?? true) && (
        <CPTextBox
          value={filterInput}
          onChange={handleFilter}
          name='list-view-filter'
          label={props.placeholder ? props.placeholder : "Search..."}
        />
      )}
      <Container className='cp-listview'>{renderContent()}</Container>
      {(props.pageable ?? true) && (
        <Pager
          buttonCount={0}
          previousNext
          className='k-listview-pager cp-listview-pager'
          skip={skip}
          take={take}
          onPageChange={handlePageChange}
          total={items.length}
        />
      )}
    </StackLayout>
  );
};

export default CPListView;
