// @intent: Display a modal for handling 2FA form submits
import * as React from "react";
import CP2faForm from "./CP2faForm";
import useCP2faContext from "./useCP2faContext";
import { CPHrBreak, CPText } from "components/base";
import { StackLayout } from "@progress/kendo-react-layout";
import CPModal from "../CPModal/CPModal";

const CP2faModal: React.FC = () => {
  const { openModal, closeModal, sendTo } = useCP2faContext();

  return (
    <CPModal
      open={openModal}
      title={"Two Factor Verification"}
      onClose={closeModal}
      width={500}
    >
      <StackLayout orientation='vertical'>
        <CPText align='center' gutterBottom>
          Please enter and verify the code that was sent to:
        </CPText>
        <CPText
          align='center'
          gutterBottom
          color='primary'
          bold
          textStyle='subtitle'
        >
          {sendTo}
        </CPText>

        <CPText textStyle='caption' align='center'>
          *Allow at least a few mins to receive code, be sure to check any
          spam/junk folder(s)
        </CPText>

        <CPHrBreak spacing={20} />
        <CP2faForm />
      </StackLayout>
    </CPModal>
  );
};

export default CP2faModal;
