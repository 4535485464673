import * as React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import DashboardPage from "../../pages/protected/DashboardPage/DashboardPage";
import ErrorPage from "../../pages/public/ErrorPage/ErrorPage";
import LoginRequestsPage from "../../pages/protected/LoginRequestsPage/LoginRequestsPage";
import useUserStore from "../../state/stores/useUserStore";
import CPAppLoader from "../../components/shared/CPAppLoader/CPAppLoader";
import CPLoggedInLayout from "components/shared/CPLoggedInLayout/CPLoggedInLayout";
import InvoiceSearchPage from "pages/protected/InvoiceSearchPage/InvoiceSearchPage";
import UserManagementPage from "pages/protected/UserManagementPage/UserManagementPage";
import FactoringCompanyManagementPage from "pages/protected/FactoringCompaniesPage/FactoringCompaniesPage";
import CarriersPage from "pages/protected/CarriersPage/CarriersPage";
import MyProfilePage from "pages/protected/MyProfilePage/MyProfilePage";
import ReportsPage from "pages/protected/ReportsPage/ReportsPage";
import useGetUserProfileQuery from "features/Users/api/useGetUserProfileQuery";
import useAuth from "features/Auth/hooks/useAuth";

const LogoutRoute: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const clearUserStore = useUserStore((user) => user.clearUserData);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      auth.signout();
      clearUserStore();

      navigate("/");
    }

    return () => {
      isMounted = false;
    };
  }, [auth, navigate, clearUserStore]);

  return null;
};

const ProtectedRoutes: React.FC = () => {
  const navigate = useNavigate();
  const userInitialized = useUserStore((user) => user.userInitialized);
  const updateUserProfile = useUserStore((user) => user.updateUserProfile);
  const userId = useUserStore((user) => user.userId);
  const auth = useAuth();
  const { profile } = useGetUserProfileQuery(auth.isAuthenticated);
  const pages = useUserStore((user) => user.menus);

  React.useEffect(() => {
    const path = window.location.pathname;

    const restricted =
      userInitialized() &&
      [
        "/LOGOUT",
        "/",
        ...pages.map((x) => x.url && x.url.toUpperCase()),
      ].indexOf(path.toUpperCase()) === -1;

    if (restricted) {
      return navigate("/", { replace: true });
    }
  }, [pages, userInitialized, navigate]);

  React.useEffect(() => {
    if (profile != null) {
      updateUserProfile(profile);
    }
  }, [profile, updateUserProfile]);

  if (auth.isAuthenticated && userInitialized() === false) {
    return <CPAppLoader isLoading={true} />;
  }

  return (
    <Routes key={userId}>
      <Route
        path='/'
        element={<CPLoggedInLayout />}
        errorElement={<ErrorPage />}
      >
        <Route index element={<DashboardPage />} />
        <Route path='/Carriers' element={<CarriersPage />} />
        <Route
          path='/FactoringCompanies'
          element={<FactoringCompanyManagementPage />}
        />
        <Route path='/UserManagement' element={<UserManagementPage />} />
        <Route path='/Invoices' element={<InvoiceSearchPage />} />

        <Route path='/LoginRequests' element={<LoginRequestsPage />} />
        <Route path='/MyProfile' element={<MyProfilePage />} />
        <Route path='/Reports' element={<ReportsPage />} />
        <Route path='/Logout' element={<LogoutRoute />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
export default ProtectedRoutes;
