// @intent: Handles upsert scheduling a report
import { useMutation } from "react-query";
import http from "../../../common/http";
import { IReportScheduleRequest } from "../types";

const useScheduleReportCommand = () => {
  const command = useMutation((request: IReportScheduleRequest) => {
    return http.postAsync("Report/ReportSubscription", request);
  });

  return {
    isLoading: command.isLoading,
    mutate: command.mutate,
    mutateAsync: command.mutateAsync,
    error: command.error,
    data: command.data,
    clear: command.reset,
  };
};

export default useScheduleReportCommand;
