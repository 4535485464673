// @intent: Wrapper component for displaying a list of items that can be sorted using dragging/dropping
import { CPText, Container } from "components/base";
import * as React from "react";
import {
  Sortable,
  SortableItemUIProps,
  SortableOnDragOverEvent,
} from "@progress/kendo-react-sortable";
import "./CPSortableListStyles.scss";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import classNames from "classnames";

export interface CPSortableListItem {
  key: string | number;
  text?: string;
  isActive?: boolean;
}

interface CPSortableListProps {
  data: CPSortableListItem[];
  onSort?: (items: CPSortableListItem[]) => void;
}

const CPSortableList: React.FC<CPSortableListProps> = (props) => {
  const renderSortableItemUI = (props: SortableItemUIProps) => {
    const { attributes, dataItem, forwardRef, isActive, style } = props;

    return (
      <div
        ref={forwardRef}
        {...attributes}
        className={classNames("cp-sortable-list-item", { active: isActive })}
        style={style}
      >
        <CPFontAwesome icon='fa-solid fa-bars' />
        <CPText>{dataItem.text}</CPText>
      </div>
    );
  };

  const onDragOver = (event: SortableOnDragOverEvent) => {
    if (props.onSort) {
      props.onSort(event.newState as CPSortableListItem[]);
    }
  };

  return (
    <Container>
      <Sortable
        className='cp-sortable-list'
        idField={"key"}
        data={props.data}
        itemUI={renderSortableItemUI}
        onDragOver={onDragOver}
      />
    </Container>
  );
};

export default CPSortableList;
