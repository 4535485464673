// @intent: Submit employee setup form.

import http from "common/http";
import { useMutation } from "react-query";
import { IAccessRequest } from "../types";

const useSendEmployeeSetupCommand = () => {
  const command = useMutation((payload: IAccessRequest) => {
    return http.postAsync(`RequestAccess/Employee`, payload);
  });

  return command;
};

export default useSendEmployeeSetupCommand;
