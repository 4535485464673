// @intent: Display a carrier search and listing of user's current assigned carriers
import * as React from "react";
import CPListView from "components/shared/CPListView/CPListView";
import { IUserAssignedCarrier } from "features/Users/types";
import { ValueObject } from "common/types";

interface AssignedCarriersListProps {
  carriers: IUserAssignedCarrier[];
  onDelete: (id: string) => void;
}

const AssignedCarriersList: React.FC<AssignedCarriersListProps> = (props) => {
  return (
    <CPListView
      take={5}
      placeholder='Search Assigned...'
      header={[
        { label: "ID", dataField: "externalId" },
        { label: "Name", dataField: "name" },
        { label: "MC Number", dataField: "mcNumber" },
        { label: "DOT Number", dataField: "dotNumber" },
      ]}
      data={props.carriers}
      actionBtns={[
        {
          title: "Remove Carrier",
          onClick: (item: ValueObject) => props.onDelete(item.id),
          fontAwesomeIcon: "fa-solid fa-trash-can",
        },
      ]}
    />
  );
};

export default AssignedCarriersList;
