import * as React from "react";
import "./CPHrBreakStyles.scss";

interface CPHrBreakProps {
  spacing?: number;
  marginTop?: number;
  marginBottom?: number;
}

const CPHrBreak: React.FC<CPHrBreakProps> = (props) => {
  return (
    <div
      className='cp-hr-line-break'
      style={{
        marginTop: props.marginTop ?? props.spacing,
        marginBottom: props.marginBottom ?? props.spacing,
      }}
    ></div>
  );
};

export default CPHrBreak;
