// @intent: Handle api requests for assigning a carrier to factoring company
import http from "common/http";
import { useMutation } from "react-query";

const useAssignCarrierToFCompanyCommand = (factoringCompanyExId: string) => {
  const command = useMutation((carrierExId: string) => {
    return http.postAsync("FactoringCompany/AssignCarrier", {
      factoringCompanyExId,
      carrierExId,
    });
  });

  return command;
};

export default useAssignCarrierToFCompanyCommand;
