// @intent: Methods for dealing with carrier fraud detection
import * as React from "react";
import useGetCarrierContactFraudCheckQuery from "../api/useGetCarrierContactFraudCheckQuery";

interface useCarrierFraudDetectionOptions {
  carrierExId?: string;
  dotNumber?: string;
}

const useCarrierFraudDetection = (options: useCarrierFraudDetectionOptions) => {
  const [credsToCheck, setCredsToCheck] = React.useState({
    ...options,
    email: "",
  });

  const checkFraudQuery = useGetCarrierContactFraudCheckQuery(credsToCheck);

  const checkFraud = (email: string) => {
    if (email === credsToCheck.email) {
      checkFraudQuery.refresh();
    }

    setCredsToCheck((prev) => ({ ...prev, email }));
  };

  return {
    reset: () => setCredsToCheck({ ...options, email: "" }),
    checkFraud,
    checkFraudResult: checkFraudQuery.fraudCheck,
    isLoading: checkFraudQuery.isLoading,
  };
};

export default useCarrierFraudDetection;
