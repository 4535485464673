import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import CPGuestLandingLayout from "../../components/shared/CPGuestLandingLayout/CPGuestLandingLayout";

import LoginPage from "../../pages/public/LoginPage/LoginPage";
import ForgotPasswordPage from "../../pages/public/ForgotPasswordPage/ForgotPasswordPage";
import RegisterLandingPage from "../../pages/public/RegisterPage/RegisterPage";
import ConfirmEmailPage from "../../pages/public/ConfirmEmailPage/ConfirmEmailPage";
import ResetPasswordPage from "../../pages/public/ResetPasswordPage/ResetPasswordPage";
import CPAppLoader from "../../components/shared/CPAppLoader/CPAppLoader";

import useAppStore from "../../state/stores/useAppStore";
import ErrorPage from "pages/public/ErrorPage/ErrorPage";

const PublicRoutes: React.FC = () => {
  const isLoading = useAppStore((app) => app.isLoading);

  if (isLoading) {
    return <CPAppLoader isLoading={true} />;
  }

  return (
    <Routes>
      <Route
        path='/'
        element={<CPGuestLandingLayout />}
        errorElement={<ErrorPage />}
      >
        <Route element={<LoginPage />} index />
        <Route path='ForgotPassword' element={<ForgotPasswordPage />} />
        <Route path='Register' element={<RegisterLandingPage />} />
        <Route path='ConfirmEmail' element={<ConfirmEmailPage />} />
        <Route path='ResetPassword' element={<ResetPasswordPage />} />
        <Route path='Error' element={<ErrorPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};
export default PublicRoutes;
