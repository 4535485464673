// @intent: Display reset password form and success when completed.

import * as React from "react";

import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import CPButton from "../../../../../components/shared/CPButton/CPButton";
import CPPasswordTextBox from "components/shared/CPPasswordTextBox/CPPasswordTextBox";
import useResetPasswordForm from "features/Auth/hooks/useResetPasswordForm";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import useResetPasswordUrlParams from "features/Auth/hooks/useResetPasswordUrlParams";
import { useNavigate } from "react-router-dom";

const ResetPasswordFormCardBody: React.FC = () => {
  const navigate = useNavigate();
  const { email, resetToken } = useResetPasswordUrlParams();
  const form = useResetPasswordForm(email, resetToken);

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={30}>
        <CPErrorMessage errorMsg={form.saveError ?? ""} align='center' />
        <StackLayout orientation='vertical' gap={10}>
          <CPPasswordTextBox
            {...form}
            label='Password'
            name='password'
            value={form.values.password}
            helpText='Must include an uppercase, lowercase, special character, and be at least 8 characters long.'
            errorMsg={form.errors.password}
          />

          <CPPasswordTextBox
            {...form}
            label='Confirm Password'
            name='passwordConfirm'
            value={form.values.passwordConfirm}
            errorMsg={form.errors.passwordConfirm}
          />
        </StackLayout>

        <CPButton
          size={"large"}
          themeColor={"primary"}
          type={"submit"}
          style={{ width: "100%" }}
          disabled={form.disableSubmit()}
          onClick={form.onSubmit}
          showLoader={form.isLoading}
        >
          Reset Password
        </CPButton>
      </StackLayout>
    );
  };

  const renderSuccess = () => {
    return (
      <StackLayout orientation='vertical' gap={30}>
        <CPText textStyle='subtitle' color='success' align='center'>
          Password Reset Successfully.
        </CPText>
        <CPButton onClick={() => navigate("/Login")} themeColor={"primary"}>
          Goto Login
        </CPButton>
      </StackLayout>
    );
  };

  return (
    <Form
      onSubmit={(_, e) => form.onSubmit()}
      render={form.isSuccess ? renderSuccess : renderForm}
    />
  );
};

export default ResetPasswordFormCardBody;
