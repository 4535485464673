// @intent: A textbox for building an array of user input values
import * as React from "react";
import CPTextBox, {
  CPTextBoxChangeEvent,
  CPTextBoxProps,
} from "../CPTextBox/CPTextBox";
import { StackLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import "./CPMultiTagTextBoxStyles.scss";
import { Box } from "components/base";

interface CPMultiTagTextBoxProps
  extends Omit<CPTextBoxProps, "onChange" | "onBlur" | "value"> {
  onChange?: (event: CPMultiTagTextBoxChangeEvent) => void;
  value?: string[];
}

export interface CPMultiTagTextBoxChangeEvent
  extends Omit<CPMultiTagTextBoxEvent, "type"> {
  type: "CPMultiTagTextBoxChangeEvent";
}

export interface CPMultiTagTextBoxEvent {
  name: string;
  type: "CPMultiTagTextBoxEvent";
  value: string[];
}

const CPMultiTagTextBox: React.FC<CPMultiTagTextBoxProps> = (props) => {
  const tags = props.value ?? [];
  //const [tags, setTags] = React.useState<string[]>(props.value ?? []);
  const [inputVal, setInputVal] = React.useState<string>();

  const triggerChangeEvent = (value: string[]) => {
    if (props.onChange) {
      props.onChange({
        name: props.name,
        value: value.map((x) => x.toUpperCase()),
        type: "CPMultiTagTextBoxChangeEvent",
      });
    }
  };

  const handleInputChange = (event: CPTextBoxChangeEvent) => {
    const value = event.value as string;
    setInputVal(value);
  };

  const addTag = () => {
    if (inputVal) {
      const newVal = [...tags, inputVal];
      //setTags(newVal);
      setInputVal(undefined);
      triggerChangeEvent(newVal);
    }
  };

  const removeTag = (index: number) => {
    const newVal = tags.filter((_, i) => i !== index);
    //setTags(newVal);
    triggerChangeEvent(newVal);
  };

  const renderTags = () => {
    if (tags.length === 0) return null;
    if (tags.length < 10) {
      return tags.map((tag, i) => (
        <Button
          onClick={() => removeTag(i)}
          key={i}
          className='cp-multitag-textbox-button'
          themeColor={"light"}
        >
          {tag}
          <CPFontAwesome icon='fa-regular fa-circle-xmark' inline />
        </Button>
      ));
    }

    return (
      <Button
        onClick={() => triggerChangeEvent([])}
        className='cp-multitag-textbox-button'
        themeColor={"light"}
      >
        {tags.length} selected
        <CPFontAwesome icon='fa-regular fa-circle-xmark' inline />
      </Button>
    );
  };

  const renderSuffixBtn = () => {
    return (
      <Button
        themeColor={"primary"}
        rounded={"small"}
        onClick={() => addTag()}
        disabled={props.disabled ?? !inputVal}
      >
        Add
      </Button>
    );
  };

  return (
    <Box className='cp-multitag-textbox'>
      <CPTextBox
        {...props}
        value={inputVal}
        onChange={handleInputChange}
        renderSuffix={renderSuffixBtn}
        helpText={tags.length === 0 ? props.helpText : ""}
      />
      <StackLayout
        className='cp-multitag-tag-list'
        align={{ horizontal: "start" }}
        gap={10}
      >
        {renderTags()}
      </StackLayout>
    </Box>
  );
};

export default CPMultiTagTextBox;
