// @intent: Query to get user's saved grid filters
import http from "common/http";
import { ValueObject } from "common/types";
import { useQuery } from "react-query";
import { MapJsonStringToSaveState } from "../Utils/Utils";

interface useGetSavedSearchQueryOptions {
  /**
   * The route to fetch and save records to using 'GET' / 'POST'.
   * You can override the save route using the 'saveRoute' option.
   */
  route: string;
  /**
   * Params sent on every request
   */
  defaultRouteParams?: ValueObject;

  /**
   * Query params sent with fetch request
   */
  additionalRouteParams?: ValueObject;
}

const useGetSavedSearchQuery = (options: useGetSavedSearchQueryOptions) => {
  const query = useQuery(
    [
      options.route,
      { ...options.defaultRouteParams, ...options.additionalRouteParams },
    ],
    () => {
      return http.fetchAsync(options.route, {
        ...options.defaultRouteParams,
        ...options.additionalRouteParams,
      });
    },
    {
      enabled: options.route != null,
      select: (result) => {
        if (result) {
          return MapJsonStringToSaveState(result.search);
        }

        return undefined;
      },
    }
  );

  return {
    savedSearch: query.data,
    isLoading: query.isLoading,
    refresh: query.refetch,
  };
};

export default useGetSavedSearchQuery;
