// @intent: Get user's report subscriptions
import { useQuery } from "react-query";
import http from "../../../common/http";
import ReportSubscription from "../types/ReportSubscription";
import { IReportSubscription } from "../types";

const useGetUserReportSubscriptionsQuery = () => {
  const query = useQuery(
    ["Report/ReportSubscriptions"],
    () => {
      return http.fetchAsync("Report/ReportSubscriptions");
    },
    {
      staleTime: 5000,
      select: (results) => {
        return results.map((x: IReportSubscription) => ReportSubscription(x));
      },
    }
  );

  return {
    refresh: query.refetch,
    reports: (query.data ?? []) as IReportSubscription[],
    isLoading: query.isLoading,
  };
};

export default useGetUserReportSubscriptionsQuery;
