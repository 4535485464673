import * as React from "react";
import { ImageProps } from "../../types";

const Image: React.FC<ImageProps> = ({
  source,
  height,
  width,
  style,
  alt = "",
}) => {
  return (
    <img
      src={source}
      style={{ ...style }}
      height={height}
      width={width}
      alt={alt}
    />
  );
};

export default Image;
