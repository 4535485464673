// @intent: Container panel for displaying invoice search form
import * as React from "react";
import { Box, CPText, Container } from "components/base";
import "./InvoiceSearchCardStyles.scss";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";
import classNames from "classnames";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";
import { ButtonClickTracking, useCPTracking } from "analytics";
import CPButton from "components/shared/CPButton/CPButton";

interface InvoiceSearchCardProps {
  children?: React.ReactNode;
}

const InvoiceSearchCard: React.FC<InvoiceSearchCardProps> = ({ children }) => {
  const { trackingEventDelegate } = useCPTracking();
  const form = useInvoiceSearchStore((ctx) => ctx.searchForm);
  const showForm = useInvoiceSearchStore((ctx) => ctx.showForm);
  const startNewSearch = useInvoiceSearchStore((ctx) => ctx.startNewSearch);
  const toggleShowForm = useInvoiceSearchStore((ctx) => ctx.toggleForm);
  const applySearch = useInvoiceSearchStore((ctx) => ctx.applySearch);

  return (
    <Container
      className={classNames("cp-invoice-search-card", { "slide-up": showForm })}
    >
      <Box
        className='cp-invoice-search-card-header'
        justifyContent={"space-between"}
        onClick={toggleShowForm}
        alignItems='center'
      >
        <Box flexDirection='row' alignItems='center' spacing={5}>
          <CPFontAwesome icon='fa-solid fa-magnifying-glass' size={15} />
          <CPText fontSize='14px'>Search Invoice Payments</CPText>
        </Box>

        {showForm ? (
          <Box alignItems='center' flexDirection='row' spacing={5}>
            <CPButton
              fillMode={"flat"}
              size={"small"}
              onClick={trackingEventDelegate(
                startNewSearch,
                ButtonClickTracking("Clear Invoice Form")
              )}
            >
              Clear Form
            </CPButton>
            <CPButton
              size={"small"}
              fillMode={"flat"}
              onClick={trackingEventDelegate(
                toggleShowForm,
                ButtonClickTracking("Cancel Invoice Search")
              )}
              title='Close'
            >
              Cancel
            </CPButton>
            <CPButton
              size={"small"}
              themeColor={"primary"}
              onClick={trackingEventDelegate(
                applySearch,
                ButtonClickTracking("Apply Invoice Search", { ...form })
              )}
            >
              Apply
            </CPButton>
          </Box>
        ) : (
          <CPFontAwesome icon='fa-solid fa-chevron-up' />
        )}
      </Box>
      <Box className='cp-invoice-search-card-content'>{children}</Box>
    </Container>
  );
};

export default InvoiceSearchCard;
