import { CPDataType, ICPTrackingData } from "analytics/types";
import dayjs from "dayjs";
import { v4 as Guid } from "uuid";

export const ButtonClickTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "button",
    eventAction: "click",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const ButtonHoverTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "button",
    eventAction: "hover",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const ButtonOtherTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "button",
    eventAction: "other",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};
