import { TextBox, TextBoxProps } from "@progress/kendo-react-inputs";
import * as React from "react";
import CPPasswordToggle from "../CPPasswordToggle/CPPasswordToggle";
import usePasswordToggle from "hooks/common/usePasswordToggle";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";

interface CPPasswordTextBoxProps
  extends Omit<TextBoxProps, "required" | "name"> {
  name: string;
  label?: string;
  helpText?: string;
  errorMsg?: string;
  required?: boolean;
}

const CPPasswordTextBox: React.FC<CPPasswordTextBoxProps> = (props) => {
  const { inputType, toggleShowPassword } = usePasswordToggle();

  const renderTextBox = () => {
    return (
      <TextBox
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={props.className}
        color={props.color}
        onFocus={props.onFocus}
        id={props.name ?? "password"}
        placeholder={props.placeholder ?? "Password"}
        size={props.size ?? "medium"}
        name={props.name ?? "password"}
        autoComplete='off'
        suffix={() => <CPPasswordToggle onClick={toggleShowPassword} />}
        type={inputType}
      />
    );
  };

  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <FloatingLabel
          label={props.required ? `${props.label} *` : props.label}
          editorValue={props.value as string}
          editorId={props.name ?? "password"}
        >
          {renderTextBox()}
        </FloatingLabel>
      ) : (
        renderTextBox()
      )}
      {props.errorMsg != null || props.helpText != null ? (
        <CPText
          textStyle='caption'
          color={props.errorMsg ? "error" : "primary"}
        >
          {props.errorMsg ?? props.helpText}
        </CPText>
      ) : null}
    </StackLayout>
  );
};

export default CPPasswordTextBox;
