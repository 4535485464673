import { CPDataType, ICPTrackingData } from "analytics/types";
import dayjs from "dayjs";
import { v4 as Guid } from "uuid";

export const NavigationTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "navigation",
    eventAction: "event",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const NavigationClickTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "navigation",
    eventAction: "click",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};

export const NavigationOtherTracking = (eventId: string, data?: CPDataType) => {
  return {
    _uid: Guid(),
    eventCategory: "navigation",
    eventAction: "other",
    eventId,
    timestamp: dayjs(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    data: data,
  } as ICPTrackingData;
};
