// @intent: Display a form for picking report to generate with optional field(s) / dateranges
import { StackLayout } from "@progress/kendo-react-layout";
import CPDateRange from "components/shared/CPDateRange/CPDateRange";
import * as React from "react";
import useReportRequestForm from "../hooks/useReportForm";
import ReportListDDL from "./ReportListDDL";
import CPButton from "components/shared/CPButton/CPButton";
import { IReportRequest } from "../types";
import CPCarrierAutoComplete from "components/shared/CPCarrierAutoComplete/CPCarrierAutoComplete";
import { CPHrBreak } from "components/base";

interface ReportRequestFormProps {
  onSubmit?: (
    data: IReportRequest,
    onComplete?: () => void
  ) => void | Promise<void>;
  initData?: IReportRequest;
  isLoading?: boolean;
  allowCarrierSelection?: boolean;
}

const ReportRequestForm: React.FC<ReportRequestFormProps> = (props) => {
  const form = useReportRequestForm({
    formData: props.initData,
    onSubmit: props.onSubmit,
    isLoading: props.isLoading,
  });

  return (
    <StackLayout orientation='vertical' gap={20}>
      <StackLayout gap={20}>
        <ReportListDDL
          onSelect={form.handleReportSelection}
          value={form.selectedReport}
        />

        {props.allowCarrierSelection && (
          <CPCarrierAutoComplete
            label='Request Report by Carrier'
            onChange={form.handleCarrierSelect}
            value={form.selectedCarrier}
          />
        )}
      </StackLayout>

      <CPDateRange
        onChange={form.handleDateChange}
        value={form.values.reportDates}
        name='reportDates'
        label='Generate Between Date(s)'
        quickDateOptions={form.quickSelectOptions}
      />

      <CPHrBreak />

      <StackLayout gap={20} align={{ vertical: "middle" }}>
        <CPButton
          fillMode={"outline"}
          onClick={form.resetForm}
          disabled={form.isLoading}
        >
          Reset
        </CPButton>
        <CPButton
          showLoader={form.isLoading}
          themeColor={"primary"}
          disabled={form.disabled}
          onClick={form.onsubmit}
        >
          Generate
        </CPButton>
      </StackLayout>
    </StackLayout>
  );
};

export default ReportRequestForm;
