// @intent: Display grid for working with user data
import * as React from "react";

import { CPActionItem, CPGrid } from "components/shared/CPGrid";
import useUserGrid from "features/Users/hooks/useUserGridMethods";
import { Container } from "components/base";

import { StackLayout } from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";
import CPDefaultActionList from "components/shared/CPGrid/CPDefaultActionList";
import UpsertAccountWizardModal from "./UpsertAccountWizardModal/UpsertAccountWizardModal";
import UpsertAccount from "../types/UpsertAccount";

const UserGrid: React.FC = () => {
  const GRIDNAME = "userGrid";
  const userGrid = useUserGrid(GRIDNAME);

  const renderCreateBtn = () => {
    if (userGrid.canCreateAccount()) {
      return (
        <CPButton
          themeColor={"light"}
          className='btn-small'
          onClick={() => {
            userGrid.selectableOptions.clearSelection();
            userGrid.toggleModal(true);
          }}
          icon='plus-outline'
          title='Create a new user account.'
        >
          Create Account
        </CPButton>
      );
    }
  };

  const renderEditUserBtn = () => {
    if (userGrid.canEditAccount()) {
      return (
        <CPButton
          className='btn-small'
          themeColor={"light"}
          disabled={userGrid.editButtonDisabled}
          onClick={userGrid.editUser}
          icon='edit'
          title='Select a user from the grid to edit.'
        >
          Edit Account
        </CPButton>
      );
    }
  };

  const renderToolBar = () => {
    const additionalOptions = [];

    if (userGrid.canEditAccount()) {
      additionalOptions.push({
        label: "Clear Selection",
        handler: userGrid.selectableOptions.clearSelection,
      });
    }

    return (
      <StackLayout gap={10} align={{ vertical: "middle" }}>
        <CPDefaultActionList
          dataAdapter={{
            ...userGrid.gridAdapter,
            onSaveSearch: userGrid.onSaveSearch,
          }}
        >
          <CPActionItem
            label='Clear Selection'
            onSelect={userGrid.selectableOptions.clearSelection}
          />
        </CPDefaultActionList>
        {renderCreateBtn()}
        {renderEditUserBtn()}
      </StackLayout>
    );
  };

  return (
    <Container>
      <CPGrid
        {...userGrid.selectableOptions}
        name={GRIDNAME}
        dataAdapter={userGrid.gridAdapter}
        filterable={true}
        sortable={true}
        pageable={true}
        columns={userGrid.columns}
        showToolbar={true}
        renderToolBar={renderToolBar}
      />

      {userGrid.openModal && (
        <UpsertAccountWizardModal
          onClose={() => userGrid.toggleModal(false)}
          model={UpsertAccount({ ...userGrid.userToEdit })}
        />
      )}
    </Container>
  );
};

export default UserGrid;
