// @intent: Submit carrier manual setup form.
import http from "common/http";
import { useMutation } from "react-query";
import { IAccessRequest } from "../types";

const useSendCarrierSetupCommand = () => {
  const command = useMutation((payload: IAccessRequest) => {
    return http.postAsync("RequestAccess/Carrier", payload);
  });

  return command;
};

export default useSendCarrierSetupCommand;
