// @intent: Submit carrier self service setup form

import http from "common/http";
import { useMutation } from "react-query";
import { IAccessRequest } from "../types";

const useSendCarrierSelfServiceCommand = () => {
  const command = useMutation((payload: IAccessRequest) => {
    return http.postAsync(`Register/CarrierSelfService`, payload);
  });

  return command;
};

export default useSendCarrierSelfServiceCommand;
