import * as React from "react";
import classNames from "classnames";
import {
  TextBox,
  TextBoxChangeEvent,
  TextBoxHandle,
} from "@progress/kendo-react-inputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";

export interface CPTextFilterProps extends GridFilterCellProps {
  placeholder?: string;
}

const CPTextFilter: React.FC<CPTextFilterProps> = (props) => {
  let textBox: TextBoxHandle | null;

  const onChange = (event: TextBoxChangeEvent) => {
    props.onChange({
      value: textBox?.value,
      operator: props.operator ?? "startswith",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const value = props.value || "";

  return (
    <TextBox
      value={value}
      ref={(text) => {
        textBox = text;
      }}
      placeholder={props.placeholder ?? "Search By..."}
      onChange={onChange}
      className={classNames("cp-filter-base")}
      required={false}
    />
  );
};

export default CPTextFilter;
