// @intent: provide common methods for using grid selection
import * as React from "react";
import { getter } from "@progress/kendo-react-common";
import {
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { ValueObject } from "common/types";
import { CPGridColumn } from "../types";
import { getFirstSharedKey } from "common/helpers";

interface SelectableOptions {
  dataItemKey: string;
  selectedField: string;
  columns: CPGridColumn[];
  data: ValueObject[];
  drag?: boolean;
  cell?: boolean;
  mode?: "single" | "multiple";
  showSelectAll?: boolean;
  verifyCanSelectItem?: (selectedItem: ValueObject) => boolean;
}

const useSelectableOptions = (options: SelectableOptions) => {
  const idGetter = getter(options.dataItemKey);
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  options.data.forEach(
    (d) => (d.selected = selectedState[idGetter(d)] ?? false)
  );

  let checkboxColumn = {
    field: options.selectedField,
    width: 50,
    pinned: true,
    filterable: false,
  };

  if (!options.showSelectAll) {
    checkboxColumn = Object.assign({ headerCell: () => null }, checkboxColumn);
  }
  const columns = [
    checkboxColumn,
    ...options.columns.filter((x) => x.field !== options.selectedField),
  ];

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: options.dataItemKey,
      });

      const sharedKey = getFirstSharedKey(newSelectedState, selectedState);

      if (sharedKey) {
        newSelectedState[sharedKey] = !selectedState[sharedKey];
      }

      if (options.verifyCanSelectItem) {
        const item = event.dataItems.find((x) =>
          getFirstSharedKey({ [x[options.dataItemKey]]: "" }, newSelectedState)
        );

        if (item && options.verifyCanSelectItem(item) === false) {
          return;
        }
      }

      if (options.mode === "multiple") {
        setSelectedState((prev) => ({ ...prev, ...newSelectedState }));
      } else {
        setSelectedState(newSelectedState);
      }
    },
    [selectedState, options]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: ValueObject = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    [idGetter]
  );

  const getSelectedItems = React.useCallback(() => {
    return options.data.filter((item) => selectedState[idGetter(item)]);
  }, [selectedState, options.data, idGetter]);

  const clearSelection = React.useCallback(() => {
    setSelectedState({});
    return options.data.map((item) => (item[options.selectedField] = false));
  }, [options.data, options.selectedField]);

  return {
    dataItemKey: options.dataItemKey,
    selectedField: options.selectedField,
    selectable: {
      enabled: true,
      drag: options.drag ?? false,
      cell: options.cell ?? false,
      mode: options.mode ?? "single",
    },
    onSelectionChange,
    onHeaderSelectionChange,
    getSelectedItems,
    selectedItem: getSelectedItems()[0],
    selectedCount: getSelectedItems().length,
    clearSelection,
    columns,
  };
};

export default useSelectableOptions;
