// @intent: Show token is invalid with links to reset password or goto homepage.

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import CPButton from "components/shared/CPButton/CPButton";

const ResetPasswordTokenInvalidCardBody: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StackLayout orientation='vertical' gap={30}>
      <CPText color='error' align='center' textStyle='subtitle'>
        Token is expired or no longer valid.
      </CPText>

      <StackLayout orientation='vertical' gap={15}>
        <CPButton
          themeColor={"primary"}
          onClick={() => navigate("/ForgotPassword", { replace: true })}
        >
          Try Reset Again
        </CPButton>
        <CPButton
          themeColor={"dark"}
          fillMode={"flat"}
          onClick={() => navigate("/", { replace: true })}
        >
          Go Home
        </CPButton>
      </StackLayout>
    </StackLayout>
  );
};

export default ResetPasswordTokenInvalidCardBody;
