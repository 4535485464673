import { IAccessRequest } from "./types";

function EmployeeAccessRequest(init: IAccessRequest) {
  return {
    ...init,
    accountType: "Employee",
  };
}

export default EmployeeAccessRequest;
