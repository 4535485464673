// @intent: Context for invoice searches
import { create } from "zustand";
import { ValueObject } from "common/types";
import http from "../../../common/http";
import useAppStore from "../../../state/stores/useAppStore";
import { ISearchInvoicePayment } from "../types";
import SearchInvoicePayment from "../types/SearchInvoicePayment";

interface InvoiceState {
  displayGridResults: boolean;
  showForm: boolean;
  searchForm: ISearchInvoicePayment;
  searchResults: any[] | undefined;
  showNoResults: boolean;
  showSaveSearchModal: boolean;
  allowCarrierMultiSelect: boolean;
}

interface InvoiceStateActions {
  toggleForm: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startNewSearch: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  applySearch: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  resetStore: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  saveSearch: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  updateForm: (data: ValueObject) => void;
  closeSaveSearchModal: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  openSaveSearchModal: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  quickSearch: (type: "InvoiceNumber" | "OrderId", value: string) => void;
}

const initData = {
  displayGridResults: false,
  showForm: true,
  showNoResults: true,
  searchResults: undefined,
  searchForm: SearchInvoicePayment(),
  showSaveSearchModal: false,
  allowCarrierMultiSelect: false,
};

const useInvoiceSearchStore = create<InvoiceState & InvoiceStateActions>()(
  (set, get) => {
    const toggleForm = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event) {
        event.stopPropagation();
      }

      set(() => ({ showForm: !get().showForm }));
    };

    const applySearch = async (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      try {
        if (event && "stopPropagation" in event) {
          event.stopPropagation();
        }

        useAppStore.getState().setIsLoading(true);

        const result = (await http.postAsync(
          "Invoice/SearchInvoices",
          get().searchForm
        )) || { records: [] };

        set(() => ({
          searchResults: result.records,
          showForm: false,
          showNoResults: result.records.length === 0,
        }));
      } catch (err) {
        console.error(err);
        useAppStore
          .getState()
          .setToastMsg("Error occurred during request", "error");
      }

      useAppStore.getState().setIsLoading(false);
    };

    const saveSearch = async (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event && "stopPropagation" in event) {
        event.stopPropagation();
      }
    };

    const resetStore = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event && "stopPropagation" in event) {
        event.stopPropagation();
      }

      set(initData);
    };

    const clearFormData = () => {
      set(() => ({ searchForm: SearchInvoicePayment() }));
    };

    const updateForm = (data: ISearchInvoicePayment) => {
      set(() => ({ searchForm: data }));
    };

    const startNewSearch = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event && "stopPropagation" in event) {
        event.stopPropagation();
      }

      const formOpen = get().showForm;

      if (formOpen === false) {
        set(() => ({ showForm: true }));
      }

      clearFormData();
    };

    const openSaveSearchModal = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event && "stopPropagation" in event) {
        event.stopPropagation();
      }

      set(() => ({ showSaveSearchModal: true }));
    };

    const closeSaveSearchModal = (
      event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (event && "stopPropagation" in event) {
        event.stopPropagation();
      }

      set(() => ({ showSaveSearchModal: false }));
    };

    const quickSearch = (type: "InvoiceNumber" | "OrderId", value: string) => {
      const model = SearchInvoicePayment();

      if (type === "InvoiceNumber") {
        model.carrierInvoiceNumbers = [value];
      }

      if (type === "OrderId") {
        model.enocOrderIds = [value];
      }

      set(() => ({ searchForm: model }));
      applySearch();
    };

    return {
      ...initData,
      toggleForm,
      applySearch,
      resetStore,
      saveSearch,
      updateForm,
      startNewSearch,
      openSaveSearchModal,
      closeSaveSearchModal,
      quickSearch,
    };
  }
);

export default useInvoiceSearchStore;
