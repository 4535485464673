// @intent: Submit Factoring Company setup form.

import http from "common/http";
import { useMutation } from "react-query";
import { IAccessRequest } from "../types";

const useSendFCompanySetupCommand = () => {
  const command = useMutation((payload: IAccessRequest) => {
    return http.postAsync("RequestAccess/FactoringCompany", payload);
  });

  return command;
};

export default useSendFCompanySetupCommand;
