import * as React from "react";
import { BoxProps } from "../../types";

import "./BoxStyles.scss";

const Box: React.FC<BoxProps> = ({
  children,
  className,
  flexDirection,
  justifyContent,
  alignItems,
  spacing,
  style,
  onClick,
  htmlAttributes,
}) => {
  return (
    <div
      {...htmlAttributes}
      className={`cp-box-base ${className ?? ""}`}
      style={{
        flexDirection,
        justifyContent,
        alignItems,
        lineHeight: "1.5",
        boxSizing: "border-box",
        display: "flex",
        gap: spacing ?? 0,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Box;
