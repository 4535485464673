import { IconButton } from "components/base";
import { InputSuffix } from "@progress/kendo-react-inputs";
import * as React from "react";

interface CPPasswordToggleProps {
  onClick: () => void;
}

const CPPasswordToggle: React.FC<CPPasswordToggleProps> = (props) => {
  return (
    <InputSuffix onClick={props.onClick}>
      <IconButton icon='preview' />
    </InputSuffix>
  );
};

export default CPPasswordToggle;
