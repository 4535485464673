// @intent: Send request to verify email and token for email confirmations

import * as React from "react";
import http from "common/http";
import { useMutation } from "react-query";

const useSendVerifyEmailTokenCommand = (email: string, token: string) => {
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [msg, setMsg] = React.useState<string>("");

  const command = useMutation(
    ({ email, token }: { email: string; token: string }) => {
      return http.postAsync("Account/ValidateEmailToken", {
        token,
        email,
      });
    },
    {
      onMutate: () => setIsLoading(true),
      onSuccess: () => {
        setIsValid(true);
        setMsg(`Email has been verified. You may sign in now.`);
      },
      onError: () =>
        setMsg(
          `The confirmation token is not valid. Resend a new confirmation email or request access with updated information.`
        ),
      onSettled: () => setIsLoading(false),
    }
  );

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted && isValid === false && email && token) {
      command.mutate({ email, token });
    }

    return () => {
      isMounted = false;
    };
  }, [email, token]);

  return {
    message: msg,
    isValid,
    isLoading,
    setMsg,
  };
};

export default useSendVerifyEmailTokenCommand;
