// @intent: Query to get user's saved grid filters
import http from "common/http";
import { useQuery } from "react-query";
import UserSearchFilter from "../types/UserSearchFilter";
import { IUserSearchFilter } from "../types";

const useGetAllSavedFiltersQuery = () => {
  const query = useQuery(
    ["UserProfile/UserSavedGridFiltersList"],
    () => {
      return http.fetchAsync("UserProfile/UserSavedGridFiltersList");
    },
    {
      select: (result) => {
        return result.map((x: IUserSearchFilter) => UserSearchFilter(x));
      },
    }
  );

  return {
    savedGridFilters: query.data ?? [],
    isLoading: query.isLoading,
    reload: query.refetch,
  };
};

export default useGetAllSavedFiltersQuery;
