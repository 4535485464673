// @intent: Module for providing custom filter operators / operations.
import {
  RegExpPatterns,
  filterValidCharacters,
  getFlatDayjsDate,
} from "common/helpers";
import { CPFilterType, NumericRangeValue } from "../types";

interface DateRangeFilterVal {
  start?: Date | string | number;
  end?: Date | string | number;
}

export const isDatesEqual = (current: Date | string, filterVal: number) => {
  const d1 = getFlatDayjsDate(current);
  const d2 = getFlatDayjsDate(filterVal);

  return d1.isSame(d2);
};

export const isDateInRange = (
  current: Date | string,
  filterVal: DateRangeFilterVal
) => {
  const d1 = getFlatDayjsDate(current);
  const start = getFlatDayjsDate(filterVal.start);
  const end = getFlatDayjsDate(filterVal.end);

  return (
    (filterVal.start == null || d1.isSame(start) || d1.isAfter(start)) &&
    (filterVal.end == null || d1.isSame(end) || d1.isBefore(end))
  );
};

export const isNumericInRange = (
  current: number,
  { min, max }: NumericRangeValue
) => (min == null || current >= min) && (max == null || current <= max);

export const isPhoneMatch = (current: string, filterVal: string) => {
  const currentVal = filterValidCharacters(
    current ?? "",
    RegExpPatterns.numbersOnly
  );

  const value = filterValidCharacters(filterVal, RegExpPatterns.numbersOnly);

  return currentVal.indexOf(value) > -1;
};

export const isDropDownMatch = (current: string, filterVal: string) => {
  if (filterVal == null) return true;

  return (current ?? "").toUpperCase() === filterVal.toUpperCase();
};

/**
 * Utility function that returns a reference to the matching custom filter operator.
 */
export const GetCustomFilterOperator = (filterType?: CPFilterType) => {
  switch (filterType) {
    case "numeric_range":
      return isNumericInRange;
    case "phone_number":
      return isPhoneMatch;
    case "date":
      return isDatesEqual;
    case "date_range":
      return isDateInRange;
    case "dropdownlist":
      return isDropDownMatch;
    default:
      return null;
  }
};
