// @intent - display form for carrier self service, with options to goto login or do manual setup

import * as React from "react";
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";

import CPButton from "../../../../../components/shared/CPButton/CPButton";
import useCarrierSelfServiceForm from "features/LoginRequests/hooks/useCarrierSelfServiceForm";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPTextBox from "components/shared/CPTextBox/CPTextBox";
import CPNumericTextBox from "components/shared/CPNumericTextBox/CPNumericTextBox";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPasswordTextBox from "components/shared/CPPasswordTextBox/CPPasswordTextBox";
import { CPText, Link } from "components/base";
import {
  ButtonClickTracking,
  PageVisitTracking,
  useCPTracking,
} from "analytics";

interface CarrierSelfServiceFormProps {
  onManualSetupClick: () => void;
  onSuccess: (message: string) => void;
}

const CarrierSelfServiceForm: React.FC<CarrierSelfServiceFormProps> = ({
  onManualSetupClick,
  onSuccess,
}) => {
  const { trackingEventDelegate } = useCPTracking(
    PageVisitTracking("Register/CarrierSelfService")
  );
  const form = useCarrierSelfServiceForm(onSuccess);

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={10}>
        <StackLayout orientation='vertical'>
          <CPText align='center' textStyle='subtitle'>
            Self Service Option
          </CPText>
          <CPText align='center'>
            Automatic Carrier registration and verification using RMIS
          </CPText>
          <Link
            as='button'
            onClick={onManualSetupClick}
            style={{ fontSize: "1em" }}
          >
            Not monitored with RMIS?
          </Link>
        </StackLayout>
        <CPErrorMessage errorMsg={form.apiError} align='center' />
        <StackLayout orientation='vertical' gap={5}>
          <StackLayout gap={10}>
            <CPTextBox
              {...form}
              name='firstName'
              value={form.values.firstName}
              label='First Name'
              errorMsg={form.errors.firstName}
              required
              personNameCharacters
              toCapital
            />
            <CPTextBox
              {...form}
              name='lastName'
              value={form.values.lastName}
              label='Last Name'
              errorMsg={form.errors.lastName}
              required
              personNameCharacters
              toCapital
            />
          </StackLayout>

          <CPNumericTextBox
            {...form}
            label='DOT Number'
            name='companyDOTNumber'
            value={form.values.companyDOTNumber}
            errorMsg={form.errors.companyDOTNumber}
            onNumericBlur={() => form.setTouched("companyDOTNumber")}
            required
          />
          <CPEmailTextBox
            {...form}
            name='email'
            value={form.values.email}
            label='Contact Email Address'
            required
          />
          <CPPasswordTextBox
            {...form}
            label='Password'
            name='password'
            value={form.values.password}
            helpText='Must be at least 6 characters, include an uppercase, lowercase and
              special character.'
            errorMsg={form.errors.password}
            required
          />

          <CPPasswordTextBox
            {...form}
            label='Confirm Password'
            name='passwordConfirm'
            value={form.values.passwordConfirm}
            errorMsg={form.errors.passwordConfirm}
            required
          />
        </StackLayout>

        <CPButton
          size={"large"}
          icon='track-changes-enable'
          themeColor={"primary"}
          style={{ width: "100%", marginTop: "20px" }}
          disabled={form.disableSubmit()}
          onClick={trackingEventDelegate(
            form.onSubmit,
            ButtonClickTracking("Register Button - Carrier Self Service", {
              ...form,
            })
          )}
          showLoader={form.isLoading}
        >
          Register
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.onSubmit()} render={renderForm} />;
};

export default CarrierSelfServiceForm;
