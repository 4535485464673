// @intent: Wrapper to build custom, common and default grid items so having setup when used on pages.

import * as React from "react";
import classNames from "classnames";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Box, Container } from "../../base";
import "./CPGridStyles.scss";
import { CPGridColumn, CPGridProps, CustomCellProps } from "./types";
import CPLoader from "./CPLoader/CPLoader";
import { noop } from "../../../common/helpers";
import CPDropDownFilter, {
  CPDropDownFilterProps,
} from "./CPFilters/CPDropDownFilter";
import CPTextFilter from "./CPFilters/CPTextFilter";
import CPNumericRangeFilter from "./CPFilters/CPNumericRangeFilter";
import CPPhoneFilter from "./CPFilters/CPPhoneFilter";
import { ValueObject } from "../../../common/types";
import CPEmailFilter from "./CPFilters/CPEmailFilter";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import CPDateFilter from "./CPFilters/CPDateFilter";
import CPDateRangeFilter from "./CPFilters/CPDateRangeFilter";

const CPGrid: React.FC<CPGridProps> = (props) => {
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(800);
  const { onColumnUpdate: columnStateUpdater } = props.dataAdapter || {
    onColumnUpdate: noop,
  };

  // merge options
  const options = { ...props, ...props.dataAdapter };
  const columns = options.columns ?? [];

  // Resize grid to fit better in allowed space
  React.useEffect(() => {
    const container = document.getElementById(props.name);
    const vHeight = container?.clientHeight;
    setGridHeight(vHeight == null ? 800 : vHeight / 1.8);
  }, []);

  // Callback to update gridstate
  const handleColumnUpdate = (prop: ValueObject) => {
    if (columnStateUpdater != null) {
      columnStateUpdater(prop);
    }
  };

  // Custom filter builder.
  const getFilter = (column: CPGridColumn) => {
    const buildDropDownListFilter = (props: CPDropDownFilterProps) => {
      const options = column.dropdownListOptions || [];
      return <CPDropDownFilter {...props} options={options} />;
    };

    switch (column.filterType) {
      case "numeric_range":
        return CPNumericRangeFilter;
      case "phone_number":
        return CPPhoneFilter;
      case "dropdownlist":
        return buildDropDownListFilter;
      case "email":
        return CPEmailFilter;
      case "date":
        return CPDateFilter;
      case "date_range":
        return CPDateRangeFilter;
      default:
        return CPTextFilter;
    }
  };

  // Render custom cell
  const renderCell = (column: CPGridColumn) => {
    const CustomCell: React.FC<CustomCellProps> = (props) => {
      const cell = column.renderCell ?? (() => null);

      return (
        <td
          style={{ ...props.style }}
          role='gridcell'
          colSpan={1}
          className={classNames("cp-grid-custom-cell-container", {
            "k-grid-content-sticky": column.pinned,
          })}
        >
          <div>{cell(props)}</div>
        </td>
      );
    };

    if (column.renderCell != null) {
      return CustomCell;
    }

    return undefined;
  };

  const getPaging = () => {
    if (options.pageable && options.paging == null) {
      return {
        buttonCount: 0,
        pageSizes: [5, 10, 20],
      };
    }

    return options.paging;
  };

  const renderColumns = () => {
    const lockedCount = columns.filter((x) => x.pinned).length;

    return columns
      .filter((x) => !x.hide)
      .map((column, i) => (
        <GridColumn
          {...column}
          filter={undefined}
          width={column.width}
          minResizableWidth={column.minResizableWidth ?? 100}
          locked={column.pinned}
          filterable={column.filterable ?? !column.pinned}
          reorderable={column.reorderable ?? !column.pinned}
          sortable={column.sortable ?? !column.pinned}
          resizable={column.resizable ?? !column.pinned}
          key={i}
          filterCell={getFilter(column)}
          cell={renderCell(column)}
          orderIndex={
            column.pinned ? 0 : lockedCount + (column.orderIndex ?? 1)
          }
          className={classNames({ noWrap: column.noWrap })}
        />
      ));
  };

  return (
    <Container
      className='cp-grid-container'
      id={props.name}
      key={options.records?.data.length}
    >
      <Box style={{ position: "relative", padding: 0 }}>
        <ExcelExport
          {...props.excelAdapter}
          data={
            options.dataAdapter?.exportData != null
              ? options.dataAdapter.exportData()
              : options.records?.data
          }
        >
          <Grid
            {...options}
            size={options.size ?? "small"}
            style={{ maxHeight: gridHeight }}
            onColumnReorder={(event) =>
              handleColumnUpdate({ updatedColumns: event.columns })
            }
            onColumnResize={(event) =>
              handleColumnUpdate({ updatedColumns: event.columns })
            }
            reorderable={options.reorderable ?? true}
            resizable={options.resizable ?? true}
            filter={options.gridState?.controls.filter}
            sort={options.gridState?.controls.sort}
            pageable={getPaging()}
            skip={options.gridState?.controls.skip}
            take={options.gridState?.controls.take}
            total={options.records?.total}
            data={options.records}
            onDataStateChange={options.onGridStateUpdate}
            className={classNames(
              "cp-grid",
              "hide-filter-operator",
              options.className
            )}
            onSortChange={options.onSort}
            onFilterChange={options.onFilter}
          >
            {options.showToolbar && options.renderToolBar != null ? (
              <GridToolbar>
                {options.renderToolBar(options.records, options.gridState)}
              </GridToolbar>
            ) : null}
            {renderColumns()}
          </Grid>
        </ExcelExport>
        <CPLoader
          showLoader={options.dataAdapter?.showLoader ?? options.showLoader}
        />
      </Box>
    </Container>
  );
};

export default CPGrid;
