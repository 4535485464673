// @intent: Page for my profile content
import {
  StackLayout,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { PageVisitTracking, useCPTracking } from "analytics";
import { Box, CPText, Container } from "components/base";
import * as React from "react";
import ContactInfoCard from "../../../features/Users/components/ContactInfoCard";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";
import MyCarriersCard from "../../../features/Users/components/MyCarriersCard";
import useUserStore from "state/stores/useUserStore";

import ChangePasswordForm from "features/Auth/components/ChangePasswordForm";
import UserSavedGridFilterList from "features/Users/components/UserSavedGridFilterList";

const MyProfilePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  useCPTracking(PageVisitTracking());
  const accountType = useUserStore((user) => user.accountType);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };
  return (
    <Container padding='2em'>
      <CPText textStyle='h6' gutterBottom>
        <CPFontAwesome icon='fa-solid fa-user' inline /> My Profile
      </CPText>
      <TabStrip
        selected={selectedTab}
        onSelect={handleSelect}
        tabPosition={"left"}
        animation={false}
      >
        <TabStripTab title='Contact Info'>
          <Container padding={"0 20px"} screen='lg'>
            <ContactInfoCard />
          </Container>
        </TabStripTab>
        <TabStripTab title='Update Password'>
          <Container padding={"0 20px"} screen='lg'>
            <ChangePasswordForm />
          </Container>
        </TabStripTab>
        <TabStripTab title='Saved Filters'>
          <Container padding={"0 20px"} screen='lg'>
            <UserSavedGridFilterList />
          </Container>
        </TabStripTab>
        {accountType() === "Factoring Company" && (
          <TabStripTab title='My Carriers'>
            <Container padding={"0 20px"} screen='lg'>
              <MyCarriersCard />
            </Container>
          </TabStripTab>
        )}
      </TabStrip>
    </Container>
  );
};

export default MyProfilePage;
