// @intent: Display listing and managing of report subscriptions
import { Container } from "components/base";
import * as React from "react";
import SubscribedReportsList from "./SubscribedReportsList";
import { IReportScheduleRequest, IReportSubscription } from "../types";
import CPModal from "components/shared/CPModal/CPModal";
import ReportSchedulerForm from "./ReportSchedulerForm";
import ReportScheduleRequest, {
  mapArrayToDayOfWeek,
} from "../types/ReportScheduleRequest";

const SubscribedReports: React.FC = () => {
  const [subToEdit, setSubToEdit] = React.useState<
    IReportScheduleRequest | undefined
  >();

  const handleEdit = (data: IReportSubscription) => {
    const daysOfWeek = data.daysOfWeek?.split(";") ?? [];

    const model = ReportScheduleRequest();
    model.dayOfMonth = data.dayOfMonth;
    model.id = data.id;
    model.reportId = data.reportId;
    model.scheduleInterval = data.scheduleInterval;
    model.sendToEmail = data.sendToEmail;
    model.daysOfWeek = mapArrayToDayOfWeek(daysOfWeek);

    setSubToEdit(model);
  };

  return (
    <Container>
      <SubscribedReportsList onEdit={handleEdit} key={subToEdit?.id} />

      <CPModal
        width={800}
        title='Edit Subscription'
        open={subToEdit != null}
        onClose={() => setSubToEdit(undefined)}
      >
        <ReportSchedulerForm
          initData={subToEdit}
          onComplete={() => setSubToEdit(undefined)}
        />
      </CPModal>
    </Container>
  );
};

export default SubscribedReports;
