// @intent: display contact info form in a card
import * as React from "react";
import ContactInfoForm from "features/Users/components/ContactInfoForm";
import useUserContactQuery from "features/Users/api/useUserContactQuery";
import CPSkeletonFormLoader from "components/shared/CPSkeletonLoaders/CPSkeletonFormLoader";

const ContactInfoCard: React.FC = () => {
  const query = useUserContactQuery();
  return (
    <CPSkeletonFormLoader show={query.isLoading}>
      <ContactInfoForm userContactData={query.contacts[0]} />
    </CPSkeletonFormLoader>
  );
};

export default ContactInfoCard;
