// @intent: Display a modal with ability to show/hide/re-order columns
import CPModal from "components/shared/CPModal/CPModal";
import * as React from "react";
import { CPGridColumn } from "../types";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import CPButton from "components/shared/CPButton/CPButton";
import CPShowHideList from "./CPShowHideList";
import { CPText } from "components/base";
import CPReorderColumnsList from "./CPReorderColumnsList";

export type CPShowHideModalUpdateType = "apply" | "set_default";

interface CPShowHideModalProps {
  open: boolean;
  onClose: () => void;
  columns: CPGridColumn[];
  onUpdate: (type: CPShowHideModalUpdateType, columns: CPGridColumn[]) => void;
}

const CPShowHideModal: React.FC<CPShowHideModalProps> = (props) => {
  const [mutatedColumns, setMutatedColumns] = React.useState<CPGridColumn[]>(
    props.columns.slice()
  );
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const renderActionBtns = () => {
    return (
      <>
        <CPButton
          fillMode={"outline"}
          themeColor={"primary"}
          onClick={() => props.onUpdate("set_default", mutatedColumns)}
        >
          Set Default
        </CPButton>
        <CPButton
          themeColor={"primary"}
          onClick={() => props.onUpdate("apply", mutatedColumns)}
        >
          Apply
        </CPButton>
      </>
    );
  };

  const handleShowHideClick = (column: CPGridColumn) => {
    const itemIndex = mutatedColumns.findIndex((x) => x.title === column.title);
    const colgrp1 = mutatedColumns.slice(0, itemIndex);
    const colgrp2 = mutatedColumns.slice(itemIndex + 1);
    setMutatedColumns([...colgrp1, column, ...colgrp2]);
  };

  const handleColumnSort = (columns: CPGridColumn[]) => {
    setMutatedColumns(columns);
  };

  return (
    <CPModal
      open={props.open}
      title='Show/Hide/Reorder Columns'
      onClose={props.onClose}
      actionBtns={renderActionBtns()}
    >
      <TabStrip
        selected={selectedTab}
        onSelect={handleTabSelect}
        animation={false}
      >
        <TabStripTab title='Show/Hide Columns'>
          <CPText align='center'>Check columns you want to show.</CPText>
          <CPShowHideList
            columns={mutatedColumns}
            onUpdate={handleShowHideClick}
          />
        </TabStripTab>
        <TabStripTab title='Reorder Columns'>
          <CPReorderColumnsList
            columns={mutatedColumns}
            onUpdate={handleColumnSort}
          />
        </TabStripTab>
      </TabStrip>
    </CPModal>
  );
};

export default CPShowHideModal;
