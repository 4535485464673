import * as React from "react";
import "./CPAppLoaderStyles.scss";
import { Box, CPText, Container } from "../../base";
import { Loader } from "@progress/kendo-react-indicators";

interface CPAppLoaderProps {
  isLoading: boolean;
}

const CPAppLoader: React.FC<CPAppLoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <Container className='cp-app-loader'>
      <Box className='loader-overlay'></Box>
      <Box className='loader-spinner'>
        <CPText
          style={{ marginBottom: 50 }}
          textStyle={"h3"}
          transform='uppercase'
          align='center'
        >
          Carrier Portal
        </CPText>

        <Loader type={"infinite-spinner"} size='large' />
      </Box>
    </Container>
  ) : null;
};

export default CPAppLoader;
