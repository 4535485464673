// @intent: Display a list of assigned carriers with option to remove from list
import * as React from "react";
import CPListView from "../../../components/shared/CPListView/CPListView";
import { EventTracking, useCPTracking } from "analytics";
import { IAssignedCarrier } from "../types";
import { ValueObject } from "common/types";

interface AssignedCarriersListProps {
  factoringCompanyExId: string;
  assignedCarriers: IAssignedCarrier[];
  onRemove: (carrier: ValueObject) => void;
  showLoader?: boolean;
}

const AssignedCarriersList: React.FC<AssignedCarriersListProps> = (props) => {
  const { trackEvent } = useCPTracking();

  return (
    <CPListView
      showLoader={props.showLoader}
      take={5}
      placeholder='Search Assigned...'
      header={[
        { label: "ID", dataField: "externalId" },
        { label: "Name", dataField: "name" },
        { label: "MC Number", dataField: "mcNumber" },
        { label: "DOT Number", dataField: "dotNumber" },
      ]}
      data={props.assignedCarriers}
      actionBtns={[
        {
          title: "Remove Carrier",
          onClick: props.onRemove,
          fontAwesomeIcon: "fa-solid fa-trash-can",
        },
      ]}
      onFilter={(value) =>
        trackEvent(
          EventTracking("Search Assigned Carrier", "change", {
            value,
            factoringCompanyExId: props.factoringCompanyExId,
          })
        )
      }
    />
  );
};

export default AssignedCarriersList;
