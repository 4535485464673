import { IUpsertAccount } from "./types";

function UpsertAccount(init?: IUpsertAccount) {
  const accountType = ["Carrier", "Employee", "Factoring Company"].find(
    (x) => x === init?.accountType
  );

  return { ...init, accountType };
}

export default UpsertAccount;
