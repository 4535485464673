import * as React from "react";
import classNames from "classnames";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import { isDropDownMatch } from "./CPFilterOperators";

export interface CPDropDownFilterProps extends GridFilterCellProps {
  options?: string[];
  style?: React.CSSProperties;
  onSelect?: (item: string) => void;
}

const CPDropDownFilter: React.FC<CPDropDownFilterProps> = (props) => {
  let ddRef = React.useRef<string | undefined>();

  const handleChange = (event: DropDownListChangeEvent) => {
    let val = event.target.value;

    if (val === "Please Choose:") {
      val = "";
    }

    ddRef.current = val;

    if (props.onSelect) {
      props.onSelect(val);
    }

    props.onChange({
      value: val,
      operator: isDropDownMatch,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const options = props.options || [];

  return (
    <DropDownList
      data={["Please Choose:", ...options]}
      value={props.value || "Please Choose:"}
      onChange={handleChange}
      style={{ ...props.style }}
      className={classNames("cp-filter-base")}
    />
  );
};

export default CPDropDownFilter;
