// @intent: Display a list of selectable items using checkboxes
import { Checkbox } from "@progress/kendo-react-inputs";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import classNames from "classnames";
import * as React from "react";
import "./CPSelectListStyles.scss";
import { CPText } from "components/base";

export interface CPSelectListItem {
  key?: string | number;
  text?: string;
  checked: boolean;
}

interface CPSelectListProps {
  data: CPSelectListItem[];
  onSelect: (selectedItem: CPSelectListItem) => void;
}

const CPSelectList: React.FC<CPSelectListProps> = (props) => {
  const renderItem = (itemProps: ListViewItemProps) => {
    let item = itemProps.dataItem;

    return (
      <div
        onClick={() => props.onSelect(item)}
        className={classNames("cp-select-list-item")}
      >
        <Checkbox value={item.checked} />
        <CPText>{item.text}</CPText>
      </div>
    );
  };

  return (
    <ListView data={props.data} item={renderItem} className='cp-select-list' />
  );
};

export default CPSelectList;
