// @intent: Send request for email confirmation

import http from "common/http";
import { useMutation } from "react-query";

const useSendEmailConfirmationCommand = () => {
  const command = useMutation((email: string) => {
    return http.fetchAsync("Account/SendEmailConfirmation", { email });
  });

  return command;
};

export default useSendEmailConfirmationCommand;
