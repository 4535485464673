import * as React from "react";

import {
  NumericTextBox,
  NumericTextBoxBlurEvent,
  NumericTextBoxChangeEvent,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";

interface CPNumericTextBoxProps
  extends Omit<
    NumericTextBoxProps,
    "name" | "suffix" | "prefix" | "label" | "value" | "onChange" | "onBlur"
  > {
  value?: number | string;
  name: string;
  label?: string;
  helpText?: string;
  errorMsg?: string;
  onNumericChange?: (event: React.SyntheticEvent<any, Event>) => void;
  onNumericBlur?: (event: React.SyntheticEvent<any, Event>) => void;
  renderSuffix?: () => React.ReactNode;
  renderPrefix?: () => React.ReactNode;
}

const CPNumericTextBox: React.FC<CPNumericTextBoxProps> = (props) => {
  const parseStr = (str: string | number) => {
    return str !== "" && isNaN(Number(str)) === false
      ? Number(props.value)
      : null;
  };

  const getMax = (val: number | null) => {
    if (props.max && val) {
      return val <= props.max ? val : props.max;
    }

    return val;
  };

  const renderTextBox = () => {
    return (
      <NumericTextBox
        {...props}
        required={undefined}
        fillMode={"solid"}
        size={"medium"}
        name={props.name}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (props.onNumericChange) {
            props.onNumericChange(event.syntheticEvent);
          }
        }}
        value={getMax(parseStr(props.value ?? ""))}
        spinners={props.spinners ?? false}
        format={props.format ?? ""}
        onBlur={(event: NumericTextBoxBlurEvent) => {
          if (props.onNumericBlur) {
            props.onNumericBlur(event.syntheticEvent);
          }
        }}
        label={undefined}
      />
    );
  };

  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <FloatingLabel
          label={props.required ? `${props.label} *` : props.label}
          editorValue={props.value as string}
          editorId={props.name}
        >
          {renderTextBox()}
        </FloatingLabel>
      ) : (
        renderTextBox()
      )}
      {props.errorMsg != null || props.helpText != null ? (
        <CPText
          textStyle='caption'
          color={props.errorMsg ? "error" : "primary"}
        >
          {props.errorMsg ?? props.helpText}
        </CPText>
      ) : null}
    </StackLayout>
  );
};

export default CPNumericTextBox;
