// @intent: Container for login form.
import * as React from "react";
import { CPText, Link, Container, Box } from "../../../components/base";
import { useNavigate } from "react-router-dom";
import "./LoginPageStyles.scss";
import LoginForm from "./components/LoginForm/LoginForm";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
  CardFooter,
  StackLayout,
} from "@progress/kendo-react-layout";
import { PageVisitTracking, useCPTracking } from "analytics";

const LoginPage: React.FC = () => {
  useCPTracking(PageVisitTracking());

  const navigate = useNavigate();

  return (
    <Card className='login-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center'>
            Sign In
          </CPText>
        </CardTitle>
        <CardSubtitle>
          <StackLayout gap={15} orientation='vertical'>
            <CPText textStyle='subtitle' align='center'>
              Sign In to get pending and historical payment information from the
              Evans Network of Companies
            </CPText>
          </StackLayout>
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <LoginForm />
      </CardBody>
      <CardFooter>
        <StackLayout align={{ horizontal: "stretch" }}>
          <StackLayout
            orientation={"vertical"}
            align={{ horizontal: "start" }}
            gap={5}
          >
            <Link
              style={{ fontSize: "16px" }}
              as='button'
              title="Don't have an account?"
              onClick={() => navigate("/Register")}
            />

            <Link
              title='Forgot your password?'
              as='button'
              onClick={() => navigate("/ForgotPassword")}
            />
          </StackLayout>
        </StackLayout>
      </CardFooter>
    </Card>
  );
};

export default LoginPage;
