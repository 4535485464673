// @intent: Methods for handling adding/editing carrier contacts
import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import { ICarrierContact } from "../types";
import CarrierContact from "../types/CarrierContact";
import useCarrierFraudDetection from "./useCarrierFraudDetection";

const CarrierContactFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
});

interface useUpsertCarrierContactFormOptions {
  carrierExId?: string;
  initData?: ICarrierContact;
  onSubmit: (contact: ICarrierContact) => void;
  resetFormOnSubmit?: boolean;
}

const useUpsertCarrierContactForm = (
  options: useUpsertCarrierContactFormOptions
) => {
  const formik = useFormik({
    initialValues:
      options.initData ?? CarrierContact({ carrierExId: options.carrierExId }),
    validationSchema: CarrierContactFormSchema,
    onSubmit: () => {
      options.onSubmit(formik.values);

      if (options.resetFormOnSubmit) {
        formik.resetForm();
        formik.setValues(CarrierContact({ carrierExId: options.carrierExId }));
      }
    },
  });

  const isContactEditState = (formik.values.id ?? 0) > 0;

  const handlers = useFormikCustomHandlers({ ...formik });
  const { checkFraud, checkFraudResult, reset, isLoading } =
    useCarrierFraudDetection({
      ...options,
    });

  const emailNeedsVerified = React.useMemo(() => {
    if (!isContactEditState) {
      return false;
    }

    if (!formik.values.email) {
      return false;
    }

    if (
      checkFraudResult.checked &&
      formik.values.email.toUpperCase() ===
        checkFraudResult.checkedEmail?.toUpperCase()
    ) {
      return !checkFraudResult.passed;
    }

    if (options.initData) {
      return (
        formik.values.email.toUpperCase() !==
        (options.initData.email ?? "").toUpperCase()
      );
    }

    return true;
  }, [options.initData, formik.values, isContactEditState, checkFraudResult]);

  const disableSubmitBtn = React.useMemo(() => {
    return (
      !formik.dirty ||
      !formik.isValid ||
      !handlers.isValid() ||
      (emailNeedsVerified &&
        checkFraudResult.checked &&
        checkFraudResult.passed === false)
    );
  }, [formik, handlers, checkFraudResult, emailNeedsVerified]);

  const getCustomValidationErrors = () => {
    if (!formik.dirty) return "";

    if (!formik.values.email && !formik.values.phone) {
      return `Email or phone is required.`;
    }

    if (checkFraudResult.checked && checkFraudResult.message) {
      return checkFraudResult.message;
    }
  };

  const handleVerify = () => {
    if (formik.values.email) {
      checkFraud(formik.values.email);
    }
  };

  const handleReset = () => {
    reset();
    formik.resetForm();
  };

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    formError: getCustomValidationErrors(),
    disableSubmitBtn,
    onSubmit: () => formik.submitForm(),
    reset: handleReset,
    onVerify: handleVerify,
    emailNeedsVerified,
    fraudChecked: checkFraudResult.checked ?? false,
    showCarrierVerified: checkFraudResult.passed && !checkFraudResult.message,
    isLoading: isLoading,
  };
};

export default useUpsertCarrierContactForm;
