// @intent: Custom Hook for working with local data arrays. --- Uses the useCPGridState hook internally.
import useCPGridAdapter from "./useCPGridState";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { CPGridColumn, CPGridState } from "../types";
import { ValueObject } from "common/types";

export interface CPDataApiAdapterResponse {
  records: any[];
  totalRecords: number;
}

interface CPLocalDataGridAdapter {
  columns: CPGridColumn[];
  data: ValueObject[];
  initFilters?: CompositeFilterDescriptor;
  setGridState?: CPGridState;
}

const useCPLocalDataGridAdapter = (options: CPLocalDataGridAdapter) => {
  const adapterBase = useCPGridAdapter({
    columns: options.columns,
    records: { data: options.data, total: options.data.length },
    initFilters: options.initFilters,
    setGridState: options.setGridState,
    localFiltering: true,
  });

  return {
    ...adapterBase,
    showLoader: false,
    refresh: adapterBase.reload,
  };
};

export default useCPLocalDataGridAdapter;
