// @intent: A common hook to add a prevent scroll class to the main content area.

const useCSSClassMethods = () => {
  const addClass = (
    queryClassName: string,
    classToAdd: string,
    applyToAll?: boolean
  ) => {
    const elements = document.getElementsByClassName(queryClassName);

    if (elements.length === 0) return;

    if (applyToAll) {
      Array.from(elements).forEach((element) => {
        element.classList.add(classToAdd);
      });
    } else {
      const first = elements.item(0);
      first?.classList.add(classToAdd);
    }
  };

  const removeClass = (
    queryClassName: string,
    classToRemove: string,
    applyToAll?: boolean
  ) => {
    const elements = document.getElementsByClassName(queryClassName);

    if (elements.length === 0) return;

    if (applyToAll) {
      Array.from(elements).forEach((element) => {
        element.classList.remove(classToRemove);
      });
    } else {
      const first = elements.item(0);
      first?.classList.remove(classToRemove);
    }
  };

  return {
    addClass,
    removeClass,
  };
};

export default useCSSClassMethods;
