import { StackLayout } from "@progress/kendo-react-layout";
import * as React from "react";
import { Box } from "../../../components/base";
import CPText from "components/base/CPText/CPText";
import { ButtonClickTracking, useCPTracking } from "analytics";
import CPButton from "components/shared/CPButton/CPButton";

interface ChangeDenyConfirmProps {
  updatedBy?: string;
  reason?: string;
  onProceed: () => void;
}

const ChangeDenyConfirm: React.FC<ChangeDenyConfirmProps> = (props) => {
  const { trackingEventDelegate } = useCPTracking();
  return (
    <StackLayout orientation='vertical' gap={30}>
      <Box>
        <CPText>Are you sure you want to change the status?</CPText>
        <CPText>Denied By: {props.updatedBy || "N/A"}</CPText>
        <CPText>Reason: {props.reason || "N/A"}</CPText>
      </Box>

      <CPButton
        onClick={trackingEventDelegate(
          props.onProceed,
          ButtonClickTracking("Change Deny Status")
        )}
        fillMode={"solid"}
        themeColor={"primary"}
      >
        Proceed
      </CPButton>
    </StackLayout>
  );
};

export default ChangeDenyConfirm;
