import * as React from "react";
import classNames from "classnames";
import { ContainerProps } from "../../types";

import "./ContainerStyles.scss";

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <div
      id={props.id}
      className={classNames(
        "cp-container",
        props.className,
        props.screen,
        props.fluid
      )}
      style={{
        ...props.style,
        padding: props.padding ?? props.style?.padding,
        width: props.width ?? props.style?.width,
        display: props.display,
        justifyContent: props.justified,
        alignItems: props.aligned,
        flexDirection: props.direction,
        gap: props.spacing,
        flexWrap: props.wrap ? "wrap" : "nowrap",
      }}
    >
      {props.children}
    </div>
  );
};

export default Container;
