import {
  IReportScheduleRequest,
  ReportScheduleIntervalDayOfWeek,
} from "./types";

export const mapArrayToDayOfWeek = (strArray: string[]) => {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const mappedDays: ReportScheduleIntervalDayOfWeek[] = [];

  strArray.forEach((x) => {
    if (days.some((y) => y === x.toUpperCase())) {
      mappedDays.push(x as ReportScheduleIntervalDayOfWeek);
    }
  });

  return mappedDays;
};

const ReportScheduleRequest = (
  initData: IReportScheduleRequest | undefined = {
    id: undefined,
    reportId: undefined,
    scheduleInterval: undefined,
    dayOfMonth: undefined,
    timeOfDay: undefined,
    sendToEmail: undefined,
  }
) => {
  return {
    ...initData,
  };
};

export default ReportScheduleRequest;
