import * as React from "react";
import classNames from "classnames";
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import { isDateInRange } from "./CPFilterOperators";

export interface CPDateFilterProps extends GridFilterCellProps {
  placeholder?: string;
}

const CPDateRangeFilter: React.FC<CPDateFilterProps> = (props) => {
  const onChange = (event: DateRangePickerChangeEvent) => {
    props.onChange({
      value: event.value,
      operator: isDateInRange,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const value = props.value || null;

  return (
    <DateRangePicker
      value={value}
      onChange={onChange}
      className={classNames("cp-filter-base")}
    />
  );
};

export default CPDateRangeFilter;
