import * as React from "react";
import { IconButtonProps } from "../../types";
import { Button } from "@progress/kendo-react-buttons";

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  color,
  fillMode,
  children,
  onClick,
  style,
}) => {
  fillMode = fillMode ?? "flat";

  return (
    <Button
      icon={icon}
      themeColor={color}
      fillMode={fillMode}
      onClick={onClick}
      style={{ ...style, marginTop: "5px" }}
    >
      {children}
    </Button>
  );
};

export default IconButton;
