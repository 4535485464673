// @intent: Display grid and handle user interactions for invoice searches
import * as React from "react";

import { CPActionItem, CPActionList, CPGrid } from "components/shared/CPGrid";
import CPDefaultActionList from "components/shared/CPGrid/CPDefaultActionList";
import useInvoiceSearchGrid from "features/Invoices/hooks/useInvoiceSearchGridMethods";
import useExcelExportAdapter from "hooks/adapters/useExcelExportAdapter";
import dayjs from "dayjs";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";

const InvoiceSearchGrid: React.FC = () => {
  const searchResults = useInvoiceSearchStore((ctx) => ctx.searchResults);
  const search = useInvoiceSearchStore((ctx) => ctx.applySearch);
  const GRIDNAME = "invoicePaymentsGrid";
  const { gridAdapter, onSaveSearch } = useInvoiceSearchGrid(
    GRIDNAME,
    searchResults
  );

  const excelAdapter = useExcelExportAdapter({
    fileName: `InvoicePayments_${dayjs(Date.now()).format(
      "MM/DD/YYYY hh:mm:ss"
    )}`,
  });

  return (
    <CPGrid
      key={searchResults?.length}
      name={GRIDNAME}
      dataAdapter={gridAdapter}
      filterable={true}
      sortable={true}
      pageable={true}
      columns={gridAdapter.gridState.columns}
      showToolbar={true}
      excelAdapter={excelAdapter}
      renderToolBar={() => (
        <CPDefaultActionList
          dataAdapter={{
            ...gridAdapter,
            refresh: search,
            onSaveSearch: onSaveSearch,
          }}
        >
          <CPActionItem label='Export Results' onSelect={excelAdapter.export} />
        </CPDefaultActionList>
      )}
    />
  );
};

export default InvoiceSearchGrid;
