// @intent: Handle api requests for removing assigned carrier from factoringcompony
import http from "common/http";
import { useMutation } from "react-query";

const useRemoveAssignedCarrierCommand = (factoringCompanyExId: string) => {
  const command = useMutation((carrierExId: string) => {
    return http.postAsync("FactoringCompany/RemoveAssignedCarrier", {
      factoringCompanyExId,
      carrierExId,
    });
  });

  return command;
};

export default useRemoveAssignedCarrierCommand;
