// @intent: Display a form or token validation error for resetting password

import * as React from "react";
import { CPText, Container } from "../../../components/base";
import "./ResetPasswordPageStyles.scss";
import useValidatePasswordResetToken from "features/Auth/hooks/useValidatePasswordResetToken";
import CPAppLoader from "components/shared/CPAppLoader/CPAppLoader";
import ResetPasswordFormCardBody from "./components/ResetPasswordFormCardBody/ResetPasswordFormCardBody";
import ResetPasswordTokenInvalidCardBody from "./components/ResetPasswordTokenInvalidCardBody/ResetPasswordTokenInvalidCardBody";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";

const ResetPasswordPage: React.FC = () => {
  const { isLoading, isValid } = useValidatePasswordResetToken();

  if (isLoading) {
    return <CPAppLoader isLoading={true} />;
  }

  const renderCardBody = (showForm: boolean) => {
    return showForm ? (
      <ResetPasswordFormCardBody />
    ) : (
      <ResetPasswordTokenInvalidCardBody />
    );
  };

  return (
    <Card className='reset-password-form-card'>
      <CardHeader>
        <CardTitle>
          <CPText textStyle='h5' align='center'>
            Reset Password
          </CPText>
        </CardTitle>
      </CardHeader>
      <CardBody>{renderCardBody(isValid)}</CardBody>
    </Card>
  );
};

export default ResetPasswordPage;
