// @intent: Methods for saving, parsing, fetching user saved searches/parameters.
import useSaveUserSearchCommand from "api/commands/useSaveUserSearchCommand";
import { ValueObject } from "common/types";
import { IUserSavedSearch } from "features/Users";
import useGetUserSavedSearches from "features/Users/api/useGetUserSavedSearches";
import UserSavedSearch from "features/Users/types/UserSavedSearch";
import useAppStore from "state/stores/useAppStore";

interface CPSavedSearchesOptions {
  /**
   * The route to fetch and save records to using 'GET' / 'POST'.
   * Defaults to: 'UserProfile/SaveSearch'
   */
  route?: string;

  /**
   * The name of the page to associate the save search to.
   */
  pageName: string;

  /**
   * If set, uses this value instead of the value of 'route' option for a 'POST' request.
   */
  saveRoute?: string;

  /**
   * The search model to save
   */
  searchToSave?: ValueObject;
}

const useUserSavedSearches = (options: CPSavedSearchesOptions) => {
  const toast = useAppStore((ctx) => ctx.setToastMsg);
  const route = options.route ?? "UserProfile/SaveSearch";
  const { data, refetch } = useGetUserSavedSearches(route, options.pageName);
  const command = useSaveUserSearchCommand(route);

  const saveSearch = async (name: string, searchParams?: ValueObject) => {
    try {
      const searchToSave = searchParams ?? options.searchToSave;
      const searchNameToSave = name;

      if (searchToSave == null) return;
      if (searchNameToSave === null) return;

      const search = {
        pageName: options.pageName,
        searchName: searchNameToSave,
        searchParams: searchToSave,
      };
      await command.mutateAsync(search);
      toast("Saved Search Successfully.");
      refetch();
    } catch (err) {
      console.error(err);
      toast("Error occurred while saving search.", "error");
    }
  };

  return {
    saveSearch,
    searches: (data != null
      ? data.map((x: IUserSavedSearch) => UserSavedSearch(x))
      : []) as IUserSavedSearch[],
  };
};

export default useUserSavedSearches;
