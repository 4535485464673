// @intent: Methods for handling email confirmation

import useSendEmailConfirmationCommand from "api/commands/useSendEmailConfirmationCommand";
import useConfirmEmailUrlParams from "./useConfirmEmailUrlParams";
import useSendVerifyEmailTokenCommand from "api/commands/useSendVerifyEmailTokenCommand";

const useConfirmEmailForm = () => {
  const urlParams = useConfirmEmailUrlParams();
  const sendConfirmEmailCommand = useSendEmailConfirmationCommand();
  const confirmValidator = useSendVerifyEmailTokenCommand(
    urlParams.email,
    urlParams.token
  );

  const onResendConfirmationEmail = async () => {
    const { email } = urlParams;
    sendConfirmEmailCommand
      .mutateAsync(email)
      .then(() => {
        confirmValidator.setMsg(
          `Resent email successfully. Please allow a few minutes for it to arrive. `
        );
      })
      .catch(() =>
        confirmValidator.setMsg(`The confirmation token or email is not valid.`)
      );
  };

  return {
    ...confirmValidator,
    emailVerified: confirmValidator.isValid,
    showResend:
      confirmValidator.isValid === false &&
      confirmValidator.isLoading === false &&
      sendConfirmEmailCommand.isError === false,
    isResending: sendConfirmEmailCommand.isLoading,
    resendSuccess: sendConfirmEmailCommand.isSuccess,
    resendError: sendConfirmEmailCommand.error as string,
    onResendConfirmationEmail,
  };
};

export default useConfirmEmailForm;
