// @intent: Methods for handing carrier self service setups
import * as Yup from "yup";
import { useFormik } from "formik";

import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import useSendCarrierSelfServiceCommand from "features/LoginRequests/api/useSendCarrierSelfServiceCommand";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useSendEmailConfirmationCommand from "api/commands/useSendEmailConfirmationCommand";
import CarrierAccessRequest from "../types/CarrierAccessRequest";

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  companyDOTNumber: Yup.number().required("DOT Number is required."),
  email: Yup.string().email().required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
  passwordConfirm: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password")], "Passwords must match."),
});

const useCarrierSelfServiceForm = (
  onSuccessCbFn: (message: string) => void
) => {
  const sendEmailConfirm = useSendEmailConfirmationCommand();
  const command = useSendCarrierSelfServiceCommand();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyDOTNumber: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = useEnterKeySubmit(async () => {
    if (formik.isValid && handlers.isValid()) {
      command
        .mutateAsync(CarrierAccessRequest({ ...formik.values }))
        .then(() => {
          sendEmailConfirm.mutateAsync(formik.values.email).then(() => {
            onSuccessCbFn(
              `Account created successfully. Please check your email to confirm.`
            );
          });
        })
        .catch(console.error);
    }
  });

  return {
    ...handlers,
    errors: formik.errors,
    values: formik.values,
    isLoading: command.isLoading,
    isSuccess: command.isSuccess,
    apiError: command.error as string,
    onSubmit: () => formik.handleSubmit(),
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    setTouched: formik.setFieldTouched,
    onNumericChange: (event: React.SyntheticEvent<any, Event>) =>
      formik.handleChange(event),
  };
};

export default useCarrierSelfServiceForm;
