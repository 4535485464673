// @intent: Landing page
import * as React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  StackLayout,
} from "@progress/kendo-react-layout";
import { Box, Container } from "components/base";
import CPFontAwesome from "components/shared/CPFontAwesome/CPFontAwesome";

import { useNavigate } from "react-router-dom";
import useUserStore from "state/stores/useUserStore";
import "./DashboardPageStyles.scss";
import { PageVisitTracking, useCPTracking } from "analytics";

const DashboardPage: React.FC = () => {
  useCPTracking(PageVisitTracking());
  const navigate = useNavigate();
  const menus = useUserStore((user) => user.menus);
  const menuItems = React.useMemo(() => {
    // Force myprofile to show as we are using a custom component in menu for that item.
    return menus
      .filter((x) =>
        x.hideFromMenu ? x.url?.toUpperCase() === "/MYPROFILE" : true
      )
      .map((x) => ({
        text: x.name,
        selected: false,
        route: x.url,
        icon: x.icon,
      }));
  }, [menus]);

  const handleMenuSelection = (route: string) => {
    navigate(route ?? "/");
  };
  return (
    <Container padding='2em' className='cp-dashboard'>
      <StackLayout
        gap={50}
        align={{ horizontal: "start" }}
        style={{ flexWrap: "wrap" }}
      >
        {menuItems.map((menu, i) => (
          <Card
            orientation='vertical'
            key={i}
            onClick={() => navigate(menu.route ?? "/")}
            className='dashboard-card'
          >
            <Box alignItems='center' className='card-icon'>
              <CPFontAwesome icon={menu.icon} size={50} />
            </Box>

            <CardHeader className='card-header'>
              <CardTitle>{menu.text}</CardTitle>
            </CardHeader>
          </Card>
        ))}
      </StackLayout>
    </Container>
  );
};

export default DashboardPage;
