// @intent: Send request for creating accounts

import http from "common/http";
import { useMutation } from "react-query";
import { IUpsertAccount } from "../types";

const useUpsertAccountCommand = () => {
  const command = useMutation((model: IUpsertAccount) => {
    const payload = {
      ...model,
      payeeIdsToAdd: model.assignedCarriers?.map((x) => x.id),
    };

    if ((payload.id ?? 0) > 0) {
      return http.postAsync(`Admin/EditAccount`, payload);
    }

    return http.postAsync("Admin/CreateAccount", payload);
  });

  return command;
};

export default useUpsertAccountCommand;
