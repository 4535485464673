// @intent: Autocomplete search using local data array.

import * as React from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "../../base";
import "./CPAutoCompleteStyles.scss";
import classNames from "classnames";
import { CPAutoCompleteProps } from "components/types";
import useAutoCompleteAdapter from "hooks/adapters/useAutoCompleteAdapter";

const CPAutoComplete: React.FC<CPAutoCompleteProps> = (props) => {
  const adapter = useAutoCompleteAdapter({ ...props });

  const renderPopupFooter = () => {
    return (
      <StackLayout style={{ backgroundColor: "#eee" }}>
        <CPText align='center' textStyle='caption'>
          {adapter.virtual.total} result(s)
        </CPText>
      </StackLayout>
    );
  };

  return (
    <StackLayout orientation='vertical' gap={5}>
      <ComboBox
        {...adapter}
        className={classNames("cp-virtual-autocomplete-combobox", {
          error: adapter.showError(),
        })}
        fillMode={"solid"}
        label={props.label}
        name={props.name}
        id={props.name ?? props.label ?? "cp-autocomplete"}
        filterable={true}
        textField='label'
        dataItemKey='label'
        size={props.inputSize ?? "medium"}
        allowCustom={props.allowManualEntry ?? true}
        footer={renderPopupFooter()}
        suggest={true}
      />
      {adapter.showError() ? (
        <CPText color='error' textStyle='caption'>
          {props.errorMsg ?? `This field is required.`}
        </CPText>
      ) : (
        <CPText textStyle='caption'>{props.helpText}</CPText>
      )}
    </StackLayout>
  );
};

export default CPAutoComplete;
