// Subcomponent Ref: https://www.telerik.com/kendo-react-ui/components/dropdowns/combobox/filtering/#toc-filtering-with-remote-data-and-virtualization

import * as React from "react";
import { ComboBox, ListItemProps } from "@progress/kendo-react-dropdowns";
import { CPVirtualAutoCompleteProps } from "../../types";

import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "../../base";
import "./CPVirtualAutoCompleteStyles.scss";
import classNames from "classnames";
import useVirtualAutoCompleteAdapter from "hooks/adapters/useVirtualAutoCompleteAdapter";

const CPVirtualAutoComplete: React.FC<CPVirtualAutoCompleteProps> = (props) => {
  const adapter = useVirtualAutoCompleteAdapter({
    ...props,
    initData: props.data,
  });
  const renderPopupFooter = () => {
    return (
      <StackLayout style={{ backgroundColor: "#eee" }}>
        <CPText align='center' textStyle='caption'>
          {adapter.virtual.total} result(s)
        </CPText>
      </StackLayout>
    );
  };

  const renderItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const dataItem = itemProps.dataItem ?? { label: "", value: {} };
    const index = itemProps.index;
    const defaultContent = <CPText fontSize='14px'>{dataItem.label}</CPText>;

    if (props.useTemplate == null || dataItem.label === "...Loading...") {
      return React.cloneElement(li, li.props, defaultContent);
    }

    const child = props.useTemplate(dataItem, index);
    return React.cloneElement(
      li,
      { ...li.props, className: "cp-autocomplete-item-template" },
      child
    );
  };

  return (
    <StackLayout
      orientation='vertical'
      gap={5}
      key={props.value?.value}
      style={props.style}
    >
      <ComboBox
        {...adapter}
        className={classNames("cp-virtual-autocomplete-combobox", {
          error: adapter.showError(),
        })}
        fillMode={"solid"}
        label={props.label}
        name={props.name}
        id={props.name ?? props.label}
        value={props.value}
        filterable={true}
        textField='label'
        size={props.inputSize ?? "medium"}
        //filter={searchText}
        allowCustom={props.allowManualEntry ?? true}
        footer={renderPopupFooter()}
        suggest={true}
        itemRender={props.renderItem ?? renderItem}
      />
      {adapter.showError() ? (
        <CPText color='error' textStyle='caption'>
          {props.errorMsg ?? `This field is required.`}
        </CPText>
      ) : (
        <CPText textStyle='caption'>{props.helpText}</CPText>
      )}
    </StackLayout>
  );
};

export default CPVirtualAutoComplete;
