// @intent: Container wrapper with a text header
import { StackLayout } from "@progress/kendo-react-layout";
import { Box, CPText, Container } from "components/base";
import * as React from "react";
import "./CPPanelStyles.scss";

interface CPPanelProps {
  header?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  padding?: number | string;
  actionBtns?: React.ReactNode;
}

const CPPanel: React.FC<CPPanelProps> = (props) => {
  const renderHeader = () => {
    if (props.header == null) return null;

    return (
      <Box className='cp-panel-header'>
        <CPText style={{ fontSize: ".9em" }}>{props.header}</CPText>
        <Box
          alignItems='center'
          flexDirection='row'
          justifyContent='space-between'
          spacing={5}
        >
          {props.actionBtns}
        </Box>
      </Box>
    );
  };

  return (
    <Container className='cp-panel-container' style={props.style} padding={0}>
      {renderHeader()}
      <Box className='cp-panel-content'>{props.children}</Box>
    </Container>
  );
};

export default CPPanel;
