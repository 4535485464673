import * as React from "react";
import classNames from "classnames";

import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import CPEmailTextBox, {
  CPEmailTextBoxChangeEvent,
} from "components/shared/CPEmailTextBox/CPEmailTextBox";

export interface CPTextFilterProps extends GridFilterCellProps {
  placeholder?: string;
}

const CPEmailFilter: React.FC<CPTextFilterProps> = (props) => {
  const onChange = (event: CPEmailTextBoxChangeEvent) => {
    props.onChange({
      value: event.target?.value,
      operator: props.operator ?? "startswith",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const value = props.value || "";

  return (
    <CPEmailTextBox
      value={value}
      placeholder={props.placeholder ?? "email@address.com"}
      onChange={onChange}
      className={classNames("cp-filter-base")}
      required={false}
    />
  );
};

export default CPEmailFilter;
