// @intent: Send a request to verify the password reset token and email is still valid.

import * as React from "react";
import http from "common/http";
import { useMutation } from "react-query";

const useSendVerifyResetPasswordTokenCommand = (
  email: string,
  token: string
) => {
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const command = useMutation(
    ({ email, token }: { email: string; token: string }) => {
      return http.postAsync("Account/ValidateResetPasswordToken", {
        email,
        token,
      });
    },
    {
      onMutate: () => setIsLoading(true),
      onSuccess: setIsValid,
      onSettled: () => setIsLoading(false),
    }
  );

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted && isValid === false && email && token) {
      command.mutate({ email, token });
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [email, token]);

  return {
    isValid,
    isLoading,
  };
};

export default useSendVerifyResetPasswordTokenCommand;
