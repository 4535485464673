// @intent: Fetch user profile data
import http from "common/http";
import { useQuery } from "react-query";

const useGetUserProfileQuery = (isAuthenticated?: boolean) => {
  const query = useQuery(
    ["UserProfile"],
    () => {
      return http.fetchAsync("UserProfile");
    },
    {
      enabled: isAuthenticated,
    }
  );

  return {
    profile: query.data,
    isLoadingProfile: query.isLoading,
  };
};

export default useGetUserProfileQuery;
