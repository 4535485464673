// @intent: Methods for tracking window events.
import * as React from "react";
import useCPTracking from "./useCPTracking";
import { NavigationTracking } from "./models/NavigationTrackings";

let inactivityTimer: NodeJS.Timeout | number | null = null;
const inactivityCutoff = 5 * 60 * 1000; // in milliseconds
const sendAtLimit = 15;

const useInactivityTracker = (cbFn: () => void) => {
  const { trackEvent, trackingData } = useCPTracking();

  React.useEffect(() => {
    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []);

  const resetInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }

    if (
      trackingData().tracking &&
      trackingData().tracking.length >= sendAtLimit
    ) {
      cbFn();
    }

    inactivityTimer = setTimeout(() => {
      cbFn();
    }, inactivityCutoff);
  };

  const handleUnload = () => {
    trackEvent(NavigationTracking("Window/Tab Closed"));
    cbFn();
  };
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      trackEvent(NavigationTracking("Window/Tab Visible"));
    }

    if (document.visibilityState === "hidden") {
      trackEvent(NavigationTracking("Window/Tab Hidden"));
    }

    cbFn();
  };

  const addEventListeners = () => {
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keypress", resetInactivityTimer);
    window.addEventListener("touchstart", resetInactivityTimer);
    window.addEventListener("unload", handleUnload);
    window.addEventListener("visibilitychange", handleVisibilityChange);
  };

  const removeEventListeners = () => {
    window.removeEventListener("mousemove", resetInactivityTimer);
    window.removeEventListener("keypress", resetInactivityTimer);
    window.removeEventListener("touchstart", resetInactivityTimer);
    window.removeEventListener("unload", handleUnload);
    window.removeEventListener("visibilitychange", handleVisibilityChange);
  };
};

export default useInactivityTracker;
