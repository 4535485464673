// @intent: Display form for logging in
import * as React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import "./LoginFormStyles.scss";
import CPButton from "../../../../../components/shared/CPButton/CPButton";
import useLoginForm from "features/Auth/hooks/useLoginForm";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import CPPasswordTextBox from "components/shared/CPPasswordTextBox/CPPasswordTextBox";

const LoginForm: React.FC = () => {
  const form = useLoginForm();

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={20}>
        <CPErrorMessage errorMsg={form.error} align='center' />
        <StackLayout orientation='vertical' gap={10}>
          <CPEmailTextBox
            {...form}
            value={form.values.email}
            useValidator={false}
          />
          <CPPasswordTextBox
            {...form}
            value={form.values.password}
            name='password'
          />
        </StackLayout>

        <Checkbox
          label={"Remember Me"}
          value={form.rememberMe}
          onChange={form.toggleRememberMe}
        />
        <CPButton
          size={"large"}
          icon='lock'
          themeColor={"primary"}
          type={"submit"}
          style={{ width: "100%" }}
          onClick={form.submit}
          disabled={form.disableSubmit()}
          showLoader={form.signInProcessing}
        >
          Sign In
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.submit()} render={renderForm} />;
};

export default LoginForm;
