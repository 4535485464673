import * as React from "react";
import Box from "../Box/Box";

interface SpacerProps {
  width?: string | number;
}

const Spacer: React.FC<SpacerProps> = ({ width }) => {
  return <Box style={{ width: width ?? 10 }}></Box>;
};

export default Spacer;
