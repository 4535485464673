import { IAccessRequest } from "./types";

function CarrierAccessRequest(init: IAccessRequest) {
  return {
    ...init,
    accountType: "Carrier",
  };
}

export default CarrierAccessRequest;
