import dayjs from "dayjs";
import { v4 as Guid } from "uuid";
import * as platform from "platform";

const getDeviceInfo = () => {
  const userAgent = window.navigator.userAgent;
  const info = platform.parse(userAgent);

  return {
    description: info.description,
    browser: info.name,
    version: info.version,
  };
};

const TrackingSession = (sessionId?: string) => {
  return {
    sessionId: sessionId ?? Guid(),
    userId: undefined,
    username: undefined,
    tracking: [],
    device: getDeviceInfo(),
  };
};

export default TrackingSession;
