// @intent: hook logic for working with kendo-react virtual and onPageChange components, utilizing the virtual scroll option

import * as React from "react";
import { ValueObject } from "common/types";
import { useDebouncedCallback } from "use-debounce";
import {
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
  MultiSelectPageChangeEvent,
} from "@progress/kendo-react-dropdowns";
import http from "common/http";
import { getRecordsFromObj, getTotalFromObj } from "common/helpers";

export interface CPVirtualPagingResult {
  records: ValueObject[];
  totalCount: number;
}

interface useVirtualPaginOptions {
  route: string;
  routeParams?: ValueObject;
  fetchOnInit?: boolean;
  httpType?: "GET" | "POST";
  mappingLabelKey: string;
  mappingValueKey?: string;
  pageSize?: number;
  initData?: ValueObject[];
  allowManualEntry?: boolean;
}

const useVirtualPaging = (options: useVirtualPaginOptions) => {
  const PAGE_SIZE = options.pageSize ?? 10;
  const httpType = options.httpType ?? "GET";
  const initData = options.initData ?? ([] as ValueObject[]);
  const [viewingRecords, setViewingRecords] = React.useState<ValueObject[]>(
    initData.slice()
  );
  const [total, setTotal] = React.useState(initData.length);
  const skipRef = React.useRef(0);
  const resultCache = React.useRef<ValueObject[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);

  const resetCache = () => {
    resultCache.current.length = 0;
  };

  const loadingItem = React.useMemo(() => {
    return {
      [options.mappingLabelKey ?? "label"]: "...Loading...",
      [options.mappingValueKey ?? "label"]: null,
    };
  }, [options.mappingLabelKey, options.mappingValueKey]);

  const loadingData = React.useMemo(() => {
    return Array(PAGE_SIZE).fill(loadingItem);
  }, [PAGE_SIZE, loadingItem]);

  const resetAll = React.useCallback(() => {
    resetCache();
    setTotal(0);
    skipRef.current = 0;
    setViewingRecords([]);
  }, []);

  const shouldRequestData = React.useCallback(
    (skip: number) => {
      for (let i = 0; i < PAGE_SIZE; i++) {
        if (!resultCache.current[skip + i]) {
          return true;
        }
      }

      return false;
    },
    [resultCache, PAGE_SIZE]
  );

  const getCachedData = React.useCallback(
    (skip: number) => {
      const items: ValueObject[] = [];

      for (let i = 0; i < PAGE_SIZE; i++) {
        const item = resultCache.current[i + skip];
        items.push(item ?? loadingItem);
      }

      return items;
    },
    [PAGE_SIZE, loadingItem]
  );

  const fetchApiDataRequest = React.useCallback(
    async (searchText: string, skip: number) => {
      if (httpType === "GET") {
        return await http.fetchAsync(
          options.route,
          {
            ...options.routeParams,
            searchText,
            skip,
            take: PAGE_SIZE,
          } ?? {
            take: PAGE_SIZE,
          }
        );
      }

      if (httpType === "POST") {
        return await http.postAsync(
          options.route,
          {
            ...options.routeParams,
            searchText,
            skip,
            take: options.pageSize,
          } ?? {
            take: options.pageSize,
          }
        );
      }
    },
    [httpType, options, PAGE_SIZE]
  );

  const requestData = useDebouncedCallback(
    async (searchText: string, skip: number) => {
      setIsLoading(true);

      let records: any[] = [];
      let total: number = 0;

      const response = await fetchApiDataRequest(searchText, skip);

      if (Array.isArray(response)) {
        records = response;
        total = response.length;
      }

      if (typeof response === "object") {
        total = getTotalFromObj(response);
        records = getRecordsFromObj(response);
      }

      // Go ahead and cache the current request results
      records.forEach((item, i) => {
        resultCache.current[i + skip] = item;
      });

      // Only set the displayed records if the current request
      if (skip === skipRef.current) {
        setViewingRecords(records);
        setTotal(total);
      }

      setIsLoading(false);
    },
    300
  );

  React.useEffect(() => {
    requestData(searchText, 0);

    return () => {
      resetCache();
    };
  }, []);

  // This is the text input
  const onFilter = React.useCallback(
    async (value: ComboBoxFilterChangeEvent | string | undefined) => {
      const searchBy = !value
        ? ""
        : typeof value === "string"
        ? value
        : value.filter.value;

      resetCache();

      setSearchText(searchBy);
      setViewingRecords(loadingData);
      skipRef.current = 0;
      await requestData(searchBy, 0);
    },
    [requestData, loadingData]
  );

  const onPaging = React.useCallback(
    (event: ComboBoxPageChangeEvent | MultiSelectPageChangeEvent) => {
      const newSkip = event.page.skip;

      // Fire off a api request in the background
      if (shouldRequestData(newSkip)) {
        requestData(searchText, newSkip);
      }

      const records = getCachedData(newSkip);
      setViewingRecords(records);
      skipRef.current = newSkip;
    },
    [searchText, requestData, getCachedData, shouldRequestData]
  );

  return {
    virtual: {
      pageSize: PAGE_SIZE,
      skip: skipRef.current,
      total: total,
    },
    data: viewingRecords,
    loading: isLoading,
    onPageChange: onPaging,
    onInputChange: onFilter,
    searchText,
  };
};

export default useVirtualPaging;
