import { v4 as Guid } from "uuid";
import { ISearchInvoicePayment } from "./types";

const SearchInvoicePayment = (
  init: ISearchInvoicePayment | undefined = {
    carriers: [],
    paymentStatus: undefined,
    deliveryDate: { start: null, end: null },
    scheduledCheckDate: { start: null, end: null },
    checkDate: { start: null, end: null },
    carrierInvoiceNumbers: [],
    enocOrderIds: [],
    checkNumbers: [],
    origins: [],
    destinations: [],
    operatingEntities: [],
    agencies: [],
  }
) => {
  const _uid = Guid();
  return {
    ...init,
    _uid,
  };
};

export default SearchInvoicePayment;
