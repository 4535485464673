import * as React from "react";
import classNames from "classnames";
import {
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import { isPhoneMatch } from "./CPFilterOperators";
import { RegExpPatterns, filterValidCharacters } from "common/helpers";

export interface CPPhoneFilterProps extends GridFilterCellProps {}

const filterValues = (value: string | undefined) => {
  return filterValidCharacters(value ?? "", RegExpPatterns.numbersOnly);
};

const CPPhoneFilter: React.FC<CPPhoneFilterProps> = (props) => {
  // This is to keep the mask on the filter input -> (__) __-____
  const inputValueRef = React.useRef("");

  // Handle clear filters
  if (
    !props.value ||
    filterValues(props.value) !== filterValues(inputValueRef.current)
  ) {
    inputValueRef.current = props.value ?? "";
  }

  const onChange = (event: MaskedTextBoxChangeEvent) => {
    inputValueRef.current = event.target.value;

    props.onChange({
      value: filterValues(event.target.value),
      operator: isPhoneMatch,
      syntheticEvent: event.syntheticEvent,
    });
  };

  return (
    <MaskedTextBox
      mask='(999) 000-0000'
      onChange={onChange}
      value={inputValueRef.current ?? ""}
      className={classNames("cp-filter-base")}
      required={false}
      valid={true}
      placeholder='(###) ###-####'
    />
  );
};

export default CPPhoneFilter;
