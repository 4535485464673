// @intent: Methods for handling employee access requests

import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import useSendEmployeeSetupCommand from "features/LoginRequests/api/useSendEmployeeSetupCommand";
import EmployeeAccessRequest from "features/LoginRequests/types/EmployeeAccessRequest";
import { accessRequestSuccess } from "common/constants";
import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import { EventTracking, useCPTracking } from "analytics";

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string().required("Email is required."),
  phoneNumber: Yup.string().required("A valid phone number is required."),
});

const useEmployeeSetupForm = (onSuccessCbFn: (message: string) => void) => {
  const { trackEvent } = useCPTracking();
  const command = useSendEmployeeSetupCommand();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: FormSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  const handleSubmit = useEnterKeySubmit(async () => {
    trackEvent(
      EventTracking("Employee Registration Submit", "form_submission")
    );
    if (formik.isValid && handlers.isValid()) {
      await command
        .mutateAsync(EmployeeAccessRequest({ ...formik.values }))
        .then(() => {
          onSuccessCbFn(accessRequestSuccess);
        })
        .catch(console.error);
    }
  });

  return {
    ...handlers,
    values: formik.values,
    errors: formik.errors,
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    onSubmit: () => formik.submitForm(),
    apiError: command.error as string,
    isSuccess: command.isSuccess,
    isLoading: command.isLoading,
  };
};

export default useEmployeeSetupForm;
