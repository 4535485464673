import * as React from "react";
import classNames from "classnames";
import "./CPText-styles.scss";
import { CPTextProps } from "../../types";

const CPText: React.FC<CPTextProps> = ({
  textStyle = "body",
  transform = "normal",
  style,
  children,
  color,
  align,
  font,
  gutterBottom,
  className,
  hidden,
  bold,
  fontSize,
  noWrap,
}) => {
  textStyle = textStyle ?? "body";
  transform = transform ?? "normal";
  align = align ?? "left";
  font = font ?? "default";
  gutterBottom = gutterBottom ?? false;

  const renderSpacers = React.Children.count(children) > 1;

  if (hidden) {
    return null;
  }

  return (
    <div
      className={classNames(
        "cp-typography",
        textStyle,
        transform,
        color,
        align,
        font,
        className,
        { "cp-gutter": gutterBottom },
        { bold: bold },
        { noWrap: noWrap }
      )}
      style={{ fontSize, ...style }}
    >
      {renderSpacers
        ? React.Children.map(children, (x, i) => (
            <div className='cp-typography-child' key={i}>
              {x}
            </div>
          ))
        : children}
    </div>
  );
};

export default CPText;
