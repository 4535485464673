import * as React from "react";
import classNames from "classnames";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
  NumericTextBoxHandle,
} from "@progress/kendo-react-inputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import "./CPFilterStylesBase.scss";
import { NumericRangeValue } from "../types";
import { isNumericInRange } from "./CPFilterOperators";

export interface CPNumericRangeFilterProps
  extends Omit<GridFilterCellProps, "value"> {
  value?: NumericRangeValue;
}

const CPNumericRangeFilter: React.FC<CPNumericRangeFilterProps> = (props) => {
  let minTextBox: NumericTextBoxHandle | null;
  let maxTextBox: NumericTextBoxHandle | null;

  const onChange = (event: NumericTextBoxChangeEvent) => {
    props.onChange({
      value: { min: minTextBox?.value, max: maxTextBox?.value },
      operator: isNumericInRange,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: isNumericInRange,
      syntheticEvent: event,
    });
  };

  const value = props.value || null;

  return (
    <div>
      Min:
      <span style={{ margin: "0 16px 0 2px" }}>
        <NumericTextBox
          width='70px'
          value={value && value.min}
          ref={(numeric) => {
            minTextBox = numeric;
          }}
          onChange={onChange}
          className={classNames("cp-filter-base")}
        />
      </span>
      Max:
      <span style={{ margin: "0 2px 0 4px" }}>
        <NumericTextBox
          width='70px'
          value={value && value.max}
          ref={(numeric) => {
            maxTextBox = numeric;
          }}
          onChange={onChange}
          className={classNames("cp-filter-base")}
        />
      </span>
      <button
        className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-button k-button-md k-rounded-md k-button-solid k-button-solid-base-icon k-clear-button-visible'
        title='Clear'
        disabled={!value}
        onClick={(event) => onClearButtonClick(event)}
      >
        <span className='k-icon k-i-filter-clear' />
      </button>
    </div>
  );
};

export default CPNumericRangeFilter;
