// @intent: Custom component for a dropdown with checkbox item(s)
// TODO: FINISH -> Accepting init prop values or prop value changes.
import * as React from "react";
import {
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectHandle,
  MultiSelectOpenEvent,
  MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ValueObject } from "common/types";
import "./CPMultiCheckBoxStyles.scss";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Loader } from "@progress/kendo-react-indicators";

export interface CPMultiCheckBoxOption {
  label: string;
  value?: number | string | Date;
  subText?: string;
}

export interface CPMultiCheckBoxSelectEvent {
  type: "CPMultiCheckBoxSelectEvent";
  name: string;
  value: any[];
  syntheticEvent: React.SyntheticEvent<HTMLElement, Event>;
}

export interface CPMultiCheckBoxProps
  extends Omit<
    MultiSelectProps,
    "value" | "name" | "allowCustom" | "onChange" | "onSelect"
  > {
  name: string;
  selectedItems: CPMultiCheckBoxOption[] | any[];
  searchText?: string | undefined;
  onSearch?: (search: string | undefined) => void;
  options: ValueObject[] | any[];
  onSelect?: (event: CPMultiCheckBoxSelectEvent) => void;
  label?: string;
  required?: boolean;
  errorMsg?: string;
  helpText?: string;
  disableSelectAll?: boolean;
  allowManualEntry?: boolean;
  idKey?: string;
  mappingLabelKey?: string;
  mappingValueKey?: string;
  renderItemSubText?: (data: ValueObject) => string;
  onScrollEnd?: () => void;
  groupTagLimit?: number;
}

const selectAllOption = { id: -1, label: "Select All", value: "" };

const CPMultiCheckBox: React.FC<CPMultiCheckBoxProps> = (props) => {
  const idKey = props.idKey ?? props.mappingLabelKey ?? "label";
  const labelKey = props.mappingLabelKey ?? "label";
  const valueKey = props.mappingValueKey ?? "";
  const allowSelectAll = !props.disableSelectAll ?? true;
  const selected = props.selectedItems.length;
  const groupTagLimit = props.groupTagLimit ?? 4;

  const mapped = React.useMemo(() => {
    const data = props.options.map((item: ValueObject) => {
      const checked = props.selectedItems.some((x) => x.id === item[idKey]);

      return {
        id: item[idKey],
        label: item[labelKey],
        value: valueKey ? item[valueKey] : item,
        checked,
      };
    });

    if (allowSelectAll && data.length > 0) {
      data.unshift({
        ...selectAllOption,
        checked: selected === props.options.length,
      });
    }

    return data;
  }, [
    props.options,
    idKey,
    labelKey,
    valueKey,
    allowSelectAll,
    props.selectedItems,
    selected,
  ]);

  const handleChange = (event: MultiSelectChangeEvent) => {
    let eventValue = event.value;

    const currentSelectAll = props.selectedItems.some(
      (i: CPMultiCheckBoxOption) => i.label === "Select All"
    );
    const nextSelectAll = eventValue.some(
      (i: CPMultiCheckBoxOption) => i.label === "Select All"
    );
    const currentCount = props.selectedItems.length;
    const nextCount = eventValue.length;

    if (
      nextCount > currentCount &&
      !currentSelectAll &&
      !nextSelectAll &&
      mapped.length - 1 === nextCount
    ) {
      eventValue = mapped;
    } else if (
      nextCount < currentCount &&
      currentCount === mapped.length &&
      currentSelectAll &&
      nextSelectAll
    ) {
      eventValue = eventValue.filter(
        (v: CPMultiCheckBoxOption) => v.label !== "Select All"
      );
    } else if (!currentSelectAll && nextSelectAll) {
      eventValue = mapped;
    } else if (currentSelectAll && !nextSelectAll) {
      eventValue = [];
    }

    //setSelectedItems(eventValue);

    if (props.onSelect) {
      props.onSelect({
        type: "CPMultiCheckBoxSelectEvent",
        name: props.name,
        value: eventValue.filter((x: CPMultiCheckBoxOption) => x.value),
        syntheticEvent: event.syntheticEvent,
      });
    }
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { id, label, subText, checked } = itemProps.dataItem;
    // const isChecked =
    //   checked ?? props.selectedItems.some((x) => x.label === label);
    const optionItem = props.options.find((x) => x[idKey] === id);

    const getSubText = () => {
      if (subText == null && props.renderItemSubText && optionItem) {
        return props.renderItemSubText(optionItem);
      }

      return subText;
    };

    return (
      <li
        onClick={(event) => itemProps.onClick(itemProps.index, event)}
        className='list-check-item'
        key={id}
      >
        <Checkbox label={label} checked={checked} />
        <span className='list-check-item-subText'>{getSubText()}</span>
      </li>
    );
  };

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <span className='k-icon k-i-warning' style={{ fontSize: "2.5em" }} />
        <br />
        <br />
        no data here
      </h4>
    );

    const noDataStillLoading = (
      <div>
        <h4>Loading...</h4>
        <Loader size='medium' type={"converging-spinner"} />;
      </div>
    );

    return React.cloneElement(
      element,
      { ...element.props },
      props.loading ? noDataStillLoading : noData
    );
  };

  const handleFilter = (event: MultiSelectFilterChangeEvent) => {
    if (props.onSearch) {
      props.onSearch(event.filter.value);
    }
  };

  const renderTags = () => {
    if (props.tags) {
      return props.tags;
    }

    return selected === 0
      ? []
      : selected <= groupTagLimit
      ? undefined // setting undefined triggers using the default tag render
      : [
          {
            text: `${selected} items selected`,
            data: [...props.selectedItems],
          },
        ];
  };

  const render = () => {
    return (
      <MultiSelect
        filter={props.searchText}
        className='cp-multicheckbox'
        onFilterChange={handleFilter}
        loading={props.loading}
        filterable={props.filterable}
        fillMode={props.fillMode ?? "solid"}
        textField='label'
        dataItemKey={idKey}
        data={mapped}
        itemRender={itemRender}
        autoClose={false}
        value={props.selectedItems}
        onChange={handleChange}
        listNoDataRender={listNoDataRender}
        allowCustom={props.allowManualEntry}
        label={props.required ? `${props.label} *` : props.label}
        placeholder={props.placeholder}
        size={props.size ?? "medium"}
        tags={renderTags()}
        virtual={props.virtual}
        onPageChange={props.onPageChange}
        footer={props.footer}
        header={props.header}
        opened={props.opened}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        popupSettings={props.popupSettings}
      />
    );
  };

  return (
    <StackLayout orientation='vertical'>
      {render()}
      <CPText textStyle='caption' color={props.errorMsg ? "error" : "primary"}>
        {props.errorMsg ?? props.helpText}
      </CPText>
    </StackLayout>
  );
};

export default CPMultiCheckBox;
