import * as React from "react";
import { MenuButtonProps } from "../../types";
import "./MenuButtonStyles.scss";
import { noop } from "../../../common/helpers";

const MenuButton: React.FC<MenuButtonProps> = ({
  size,
  onClick = noop,
  openLabel,
  closeLabel,
  className,
}) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleOnClick = () => {
    setIsActive(!isActive);
    onClick();
  };

  return (
    <div
      onClick={() => handleOnClick()}
      className={`cp-menu-button-wrapper ${className}`}
    >
      <div className='cp-menu-button-label'>
        {isActive ? closeLabel : openLabel}
      </div>
      <div
        className={`cp-menu-button ${size ?? ""} ${isActive ? "active" : ""}`}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MenuButton;
