import { strToObject } from "common/helpers";
import { IUserSavedSearch } from "./types";

const UserSavedSearch = (init: IUserSavedSearch) => {
  return {
    ...init,
    searchParams:
      typeof init.searchParams == "string"
        ? strToObject(init.searchParams)
        : init.searchParams,
  };
};

export default UserSavedSearch;
