// @intent: Display loader wheel or message for confirm email card

import * as React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "components/base";

interface ConfirmEmailCardBodyProps {
  isLoading: boolean;
  message?: string;
}

const ConfirmEmailCardBody: React.FC<ConfirmEmailCardBodyProps> = (props) => {
  if (props.isLoading) {
    return <Loader size='medium' type='infinite-spinner' />;
  }

  return (
    <StackLayout>
      <CPText align='center' textStyle='subtitle'>
        {props.message}
      </CPText>
    </StackLayout>
  );
};

export default ConfirmEmailCardBody;
