// @intent: Provide common methods for autocomplete components
import * as React from "react";
import { ValueObject } from "../../common/types";

import {
  ComboBoxBlurEvent,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
} from "@progress/kendo-react-dropdowns";

import {
  CPAutoCompleteCustomFilteredResponse,
  CPAutoCompleteDataItem,
  CPAutoCompleteResponse,
} from "../../components/types";
import { filterBy } from "@progress/kendo-data-query";

export interface CPAutoCompleteOptions {
  data: ValueObject[];
  value?: CPAutoCompleteDataItem;
  labelKey?: string;
  valueKey?: string;
  pageSize?: 20 | 10 | 5;
  allowManualEntry?: boolean;
  label?: string;
  required?: boolean;
  onResponseFilter?: (
    result: CPAutoCompleteResponse
  ) => CPAutoCompleteCustomFilteredResponse;
  onBlur?: (event: ComboBoxBlurEvent) => void;
  onChange: (selectedItem?: CPAutoCompleteDataItem) => void;
  errorMsg?: string;
}

const useAutoCompleteAdapter = (options?: CPAutoCompleteOptions) => {
  const selectedValue = React.useRef<CPAutoCompleteDataItem | undefined>();
  const inputVal = React.useRef<string | undefined>();
  const mapped = React.useMemo(() => {
    if (options?.data) {
      return options.data
        .filter((x) => x[options.labelKey ?? "label"])
        .map((x) => {
          return {
            label: x[options.labelKey ?? "label"],
            value: x[options.valueKey ?? "value"] ?? { ...x },
            inputValue: x[options.labelKey ?? "label"],
          };
        });
    }

    return [];
  }, [options]);
  const filteredData = React.useRef(mapped.slice());
  const [state, setState] = React.useState({
    skip: 0,
    total: filteredData.current.length,
    subsetData: mapped.slice(0, options?.pageSize ?? 20),
  });

  // On init only
  React.useEffect(() => {
    if (options?.value && options.value.label) {
      //setSelectedValue(options.value);
      selectedValue.current = options.value;
    }
  }, []);

  // This is the text input
  const onFilterChange = React.useCallback(
    (event: ComboBoxFilterChangeEvent) => {
      inputVal.current = event.filter.value;

      filteredData.current = filterBy(mapped.slice(), event.filter);
      const data = filteredData.current.slice(0, options?.pageSize ?? 20);
      setState({
        subsetData: data,
        skip: 0,
        total: filteredData.current.length,
      });
    },
    [options, mapped]
  );

  const onPageChange = (event: ComboBoxPageChangeEvent) => {
    const skip = event.page.skip;
    const take = event.page.take;
    const newSubsetData = filteredData.current.slice(skip, skip + take);
    setState({ ...state, subsetData: newSubsetData, skip: skip });
  };

  // This is the filter selection
  const onSelect = React.useCallback(
    (event: ComboBoxChangeEvent) => {
      let result = event.target.value;

      if (result && result["id"] === -1) {
        return;
      }

      if (
        result == null &&
        inputVal.current != null &&
        inputVal.current.length > 0
      ) {
        result = {
          label: inputVal.current,
          value: undefined,
          inputValue: inputVal.current,
        };
      }

      if (result != null && !("value" in result)) {
        result.value = undefined;
      }

      if (result != null && !("inputValue" in result)) {
        result.inputValue = inputVal.current;
      }

      if (options?.onChange) {
        options.onChange(result);
      }

      //setSelectedValue(result);
      selectedValue.current = result;
    },
    [options, inputVal.current]
  );

  const onBlur = React.useCallback(
    (event: ComboBoxBlurEvent) => {
      if (options?.onBlur) {
        options.onBlur(event);
      }

      // if (options?.onChange) {
      //   options.onChange(selectedValue);
      // }
    },
    [options, selectedValue]
  );

  const showError = () => {
    if (options?.errorMsg) {
      return true;
    }

    if (options?.allowManualEntry) {
      return options.required && selectedValue == null;
    }

    return options?.required && state.total <= 0;
  };

  const onClose = () => {
    if (options?.onChange) {
      options.onChange(selectedValue.current);
    }
  };

  return {
    value: selectedValue.current,
    virtual: {
      pageSize: options?.pageSize ?? 20,
      skip: state.skip,
      total: state.total,
    },
    data: state.subsetData,
    dataItemKey: "vaiue",
    textField: "label",
    onBlur,
    onChange: onSelect,
    onPageChange,
    onFilterChange,
    showError,
    onClose,
  };
};

export default useAutoCompleteAdapter;
