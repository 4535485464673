// @intent: Methods for dealing with cache busting app, resetting state, etc
import * as React from "react";
import cpAppStorage from "common/cpStorage";
import useGetAppVersionQuery from "api/queries/useGetAppVersionQuery";

const useCacheBuster = () => {
  const [needsUpdated, setNeedsUpdated] = React.useState<boolean>(false);
  const lastCheckDate = React.useRef<string | undefined>();
  const { data, isError, isLoading } = useGetAppVersionQuery();

  const syncBuildDate = async () => {
    try {
      if (data != null) {
        await cpAppStorage.setItem("LAST_BUILD", data);
        return true;
      }
    } catch (err) {
      console.error(`Error syncing local data: `, err);
    }

    return false;
  };

  const getLocalBuildDate = async () => {
    try {
      const result = await cpAppStorage.getItem("LAST_BUILD");
      return result as string;
    } catch (err) {
      console.error(`Error syncing local data: `, err);
      return "";
    }
  };

  /**
   * Syncs build dates, and clears cache
   */
  const callCacheBusters = async () => {
    const success = await syncBuildDate();

    if (success) {
      // Add additional cache clearing...

      // Then force reload page.
      window.location.reload();
    }

    setNeedsUpdated(false);
  };

  const determineNeedsUpdate = async () => {
    const localDate = await getLocalBuildDate();

    if (!localDate) {
      lastCheckDate.current = data;
      syncBuildDate();
    } else if (localDate !== data) {
      lastCheckDate.current = data;

      // On init force a cache bust
      setNeedsUpdated(true);
    }
  };

  if (data != null && lastCheckDate.current !== data) {
    determineNeedsUpdate();
  }

  return {
    needsUpdated: needsUpdated,
    updateApp: callCacheBusters,
    checkingVersion: isLoading,
  };
};

export default useCacheBuster;
