// @intent: Textbox that handles a invoice payment search.
import * as React from "react";
import CPFontAwesome from "../CPFontAwesome/CPFontAwesome";
import useInvoiceSearchStore from "features/Invoices/providers/useInvoiceSearchStore";
import { useNavigate } from "react-router-dom";
import CPTextBox from "../CPTextBox/CPTextBox";
import CPButton from "../CPButton/CPButton";
import { ButtonClickTracking, useCPTracking } from "analytics";

const CPEnocOrderSearchBox: React.FC = () => {
  const { trackingEventDelegate } = useCPTracking();
  const [inputVal, setInputVal] = React.useState<string | undefined>();
  const navigate = useNavigate();
  const quickSearch = useInvoiceSearchStore((ctx) => ctx.quickSearch);

  const handleSearch = () => {
    if (inputVal) {
      navigate("/Invoices");
      quickSearch("OrderId", inputVal);
      setInputVal(undefined);
    }
  };

  return (
    <CPTextBox
      style={{ minWidth: 120, maxWidth: 180, fontSize: ".9em" }}
      name='orderSearch'
      value={inputVal}
      placeholder='Evans Order Id'
      onChange={({ value }) => setInputVal(value as string)}
      renderSuffix={() => (
        <CPButton
          fillMode={"flat"}
          onClick={trackingEventDelegate(
            () => handleSearch(),
            ButtonClickTracking("ENOC OrderId Quick Search", {
              value: inputVal,
            })
          )}
          style={{ padding: 5 }}
          title="Search Evan's Order Id"
        >
          <CPFontAwesome icon='fa-solid fa-magnifying-glass' />
        </CPButton>
      )}
    />
  );
};

export default CPEnocOrderSearchBox;
