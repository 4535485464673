// @intent: display an autocomplete ddl listing of carriers
import { CPText, Container } from "components/base";
import {
  CPAutoCompleteDataItem,
  CPAutoCompleteResponse,
  CPVirtualAutoCompleteProps,
} from "components/types";
import * as React from "react";
import CPVirtualAutoComplete from "../CPVirtualAutoComplete/CPVirtualAutoComplete";

interface CPCarrierAutoCompleteProps
  extends Omit<CPVirtualAutoCompleteProps, "route" | "data"> {}

const CPCarrierAutoComplete: React.FC<CPCarrierAutoCompleteProps> = (props) => {
  const mapSearchResponse = React.useCallback(
    (data: CPAutoCompleteResponse) => {
      const records = data.records.map((x) => ({ label: x.name, value: x }));

      const totalCount =
        data.totalCount - (data.records.length - records.length);

      return { records, totalCount };
    },
    []
  );

  const renderTemplate = (dataItem: CPAutoCompleteDataItem) => {
    const address = `
      ${dataItem.value?.address ?? ""} 
      ${dataItem.value?.city ?? ""}
      ${dataItem.value?.state ?? ""}
      ${dataItem.value?.zipCode ?? ""}`;

    return (
      <Container spacing={5} padding={5}>
        <CPText fontSize='14px'>{`(${dataItem.value?.externalId}) ${dataItem.label}`}</CPText>
        <Container direction='row' spacing={15}>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {`DOT: ${dataItem.value.dotNumber ?? "N/A"}`}
          </CPText>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {`MC: ${dataItem.value.mcNumber ?? "N/A"}`}
          </CPText>
          <CPText textStyle='caption' style={{ opacity: 0.8 }}>
            {address}
          </CPText>
        </Container>
      </Container>
    );
  };

  const handleChange = (selectedItem?: CPAutoCompleteDataItem | undefined) => {
    if (props.onChange) {
      props.onChange(selectedItem);
    }
  };

  return (
    <CPVirtualAutoComplete
      name='carriers'
      label={props.label ?? "Carrier Search"}
      route='Carrier/Search'
      routeParams={{ take: 50 }}
      onChange={handleChange}
      onResponseFilter={props.onResponseFilter ?? mapSearchResponse}
      labelKey='name'
      valueKey='externalId'
      allowManualEntry={false}
      value={props.value}
      helpText={props.helpText ?? "Search carrier by name, dot, or mc number."}
      useTemplate={renderTemplate}
      style={{ width: "100%" }}
    />
  );
};

export default CPCarrierAutoComplete;
