// @intent: Send payload for updating contact information

import http from "common/http";

import { useMutation } from "react-query";
import { IUpdatePasswordModel } from "../types";

const useUpdatePassword = () => {
  const command = useMutation((payload: IUpdatePasswordModel) => {
    return http.postAsync("UserProfile/UpdatePassword", payload);
  });

  return command;
};

export default useUpdatePassword;
