// @intent Methods for handling login

import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useEnterKeySubmit from "../../../hooks/common/useEnterKeySubmit";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";

import useFormikCustomHandlers from "../../../hooks/common/useFormikCustomHandlers";
import useAuth from "./useAuth";

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required."),
  password: Yup.string().required("Password is required."),
});

const useLoginForm = () => {
  const [error, setError] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState<boolean>(false);
  const { isSigningIn, signinAsync } = useAuth();
  const handleSubmit = useEnterKeySubmit(async () => {
    setError("");

    const { email, password } = formik.values;

    if (formik.isValid && email && password) {
      const error = await signinAsync({
        email,
        password,
        rememberMe,
      });
      if (error) {
        setError(error);
      }
    }
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => handleSubmit(),
  });

  const handlers = useFormikCustomHandlers({ ...formik });

  return {
    ...handlers,
    toggleRememberMe: (event: CheckboxChangeEvent) =>
      setRememberMe(event.value),
    hasError: () => error && error.length > 0,
    values: formik.values,
    rememberMe,
    submit: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      formik.submitForm(),
    disableSubmit: () =>
      !formik.dirty || !formik.isValid || !handlers.isValid(),
    error,
    signInProcessing: isSigningIn,
  };
};

export default useLoginForm;
