// @intent: Get carrier contacts
import { useQuery } from "react-query";
import http from "../../../common/http";
import CarrierContact from "../types/CarrierContact";
import { ICarrierContact } from "../types";

const useGetCarrierContactsQuery = (carrierExId?: string) => {
  const query = useQuery(
    ["CarrierContact/GetCarrierContacts", carrierExId],
    () => {
      return http.fetchAsync("CarrierContact/GetCarrierContacts", {
        carrierExId,
      });
    },
    {
      enabled: carrierExId != null,
      staleTime: 5000,
    }
  );

  const mapToModel = () => {
    if (query.data) {
      const contacts = query.data ?? [];
      return contacts.map((x: ICarrierContact) => CarrierContact(x));
    }

    return [];
  };

  return {
    refresh: query.refetch,
    contacts: mapToModel(),
    isLoading: query.isLoading,
  };
};

export default useGetCarrierContactsQuery;
