// @intent: Form for requesting a password reset email
import * as React from "react";
import { Form } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { CPText } from "../../../../../components/base";

import CPButton from "../../../../../components/shared/CPButton/CPButton";
import CPEmailTextBox from "components/shared/CPEmailTextBox/CPEmailTextBox";
import useForgotPasswordForm from "features/Auth/hooks/useForgotPasswordForm";
import CPErrorMessage from "components/shared/CPErrorMessage/CPErrorMessage";

const ForgotPasswordForm: React.FC = () => {
  const form = useForgotPasswordForm();

  const renderForm = () => {
    return (
      <StackLayout orientation='vertical' gap={30}>
        <CPErrorMessage errorMsg={form.error as string} align='center' />
        <CPText color={"success"} align='center' hidden={!form.isSuccess}>
          Please check your email for a password reset link.
        </CPText>
        <CPEmailTextBox
          {...form}
          value={form.values.email}
          useValidator={false}
        />

        <CPButton
          size={"large"}
          themeColor={"primary"}
          type={"submit"}
          style={{ width: "100%" }}
          disabled={form.disableSubmit()}
          onClick={form.submit}
          showLoader={form.isLoading}
        >
          Reset Password
        </CPButton>
      </StackLayout>
    );
  };

  return <Form onSubmit={(_, e) => form.submit()} render={renderForm} />;
};

export default ForgotPasswordForm;
