// @intent: Methods for determining which form to display on registration.

import * as React from "react";

const renderVals = {
  CARRIER: false,
  CARRIER_MANUAL: false,
  FCOMPANY: false,
  EMPLOYEE: false,
};

const useRenderRegisterForm = (accountType: string) => {
  const lastType = React.useRef("");
  const [view, setView] = React.useState(renderVals);

  const shouldRenderCarrierManualSetup = React.useCallback(
    (shouldRender: boolean) => {
      setView({
        ...renderVals,
        CARRIER_MANUAL: shouldRender,
        CARRIER: !shouldRender,
      });
    },
    []
  );

  React.useEffect(() => {
    if (accountType !== lastType.current) {
      lastType.current = accountType;
      setView({
        CARRIER: accountType === "Carrier",
        CARRIER_MANUAL: false,
        FCOMPANY: accountType === "Factoring Company",
        EMPLOYEE: accountType === "Employee",
      });
    }
  }, [accountType]);

  return {
    view,
    shouldRenderCarrierManualSetup,
  };
};

export default useRenderRegisterForm;
