import * as React from "react";
import {
  TextBox,
  TextBoxChangeEvent,
  TextBoxHandle,
  TextBoxProps,
} from "@progress/kendo-react-inputs";
import useEmailValidator from "hooks/common/useEmailValidator";
import CPErrorMessage from "../CPErrorMessage/CPErrorMessage";
import { StackLayout } from "@progress/kendo-react-layout";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { RegExpPatterns, filterValidCharacters } from "common/helpers";

interface CPEmailTextBoxProps
  extends Omit<TextBoxProps, "value" | "onChange" | "onBlur" | "required"> {
  value?: string;
  useValidator?: boolean;
  label?: string;
  errorMsg?: string;
  onChange?: (event: CPEmailTextBoxChangeEvent) => void;
  onBlur?: (evemt: CPEmailTextBoxEvent) => void;
  required?: boolean;
}

const defaultErrorMsg = "Please enter a valid email address.";

export interface CPEmailTextBoxChangeEvent
  extends Omit<CPEmailTextBoxEvent, "type"> {
  type: "CPEmailTextBoxChangeEvent";
}

export interface CPEmailTextBoxEvent {
  name: string;
  type: "CPEmailTextBoxEvent";
  syntheticEvent: React.ChangeEvent<HTMLInputElement>;
  value: React.InputHTMLAttributes<HTMLInputElement>["value"];
  error: string | undefined;
  isValid: boolean;
  target: TextBoxHandle;
}

const CPEmailTextBox: React.FC<CPEmailTextBoxProps> = (props) => {
  const required = props.required ?? props.useValidator ?? true;
  const { isValid } = useEmailValidator(props.value ?? "", required);

  const formatString = React.useCallback((value: string | undefined) => {
    if (value == null || value.length === 0) return "";

    return filterValidCharacters(value, RegExpPatterns.validEmailCharacters);
  }, []);

  const handleOnChange = (event: TextBoxChangeEvent) => {
    if (props.onChange) {
      const value = formatString(event.value as string);

      props.onChange({
        name: props.name ?? "email",
        type: "CPEmailTextBoxChangeEvent",
        value: value,
        syntheticEvent: event.syntheticEvent,
        target: event.target,
        isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const handleBlur = (event: React.FocusEvent<any, Element>) => {
    if (props.onBlur) {
      props.onBlur({
        name: props.name ?? "email",
        type: "CPEmailTextBoxEvent",
        value: event.target.value ?? props.value,
        syntheticEvent: event,
        target: event.target,
        isValid,
        error: isValid ? undefined : defaultErrorMsg,
      });
    }
  };

  const renderTextBox = () => {
    return (
      <TextBox
        //{...props}
        fillMode={props.fillMode ?? "solid"}
        size={props.size ?? "medium"}
        autoComplete='off'
        name={props.name ?? "email"}
        placeholder={props.placeholder ?? "Email Address"}
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={props.value ?? ""}
        type='email'
      />
    );
  };
  return (
    <StackLayout orientation='vertical'>
      {props.label != null ? (
        <FloatingLabel
          label={props.required ? `${props.label} *` : props.label}
          editorValue={props.value as string}
          editorId={props.name ?? "email"}
        >
          {renderTextBox()}
        </FloatingLabel>
      ) : (
        renderTextBox()
      )}
      <CPErrorMessage
        fontSize='13px'
        errorMsg={
          !props.value
            ? undefined
            : isValid === false
            ? defaultErrorMsg
            : props.errorMsg
        }
      />
    </StackLayout>
  );
};

export default CPEmailTextBox;
