// @intent: component wrapper/helper for exporting array to excel using kendo react components
import dayjs from "dayjs";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { ValueObject } from "common/types";
import * as React from "react";

interface CPExcelExportProps {
  data: ValueObject[];
  headers: string[];
  filename?: string;
}

const CPExcelExport: React.FC<CPExcelExportProps> = (props) => {
  const d = dayjs(new Date()).format("MM-DD-YYYY-HHmmss");
  const filename = props.filename ? `${props.filename}-${d}` : `Report-${d}`;
  const exportRef = React.createRef<ExcelExport>();
  const downloaded = React.useRef(false);

  React.useEffect(() => {
    if (exportRef.current && props.data && downloaded.current === false) {
      exportRef.current.save();
      downloaded.current = true;
    }
  }, [props.data, exportRef, downloaded]);

  return (
    <ExcelExport
      data={props.data}
      //group={group}
      collapsible={true}
      fileName={filename}
      ref={exportRef}
    >
      {props.headers.map((header, i) => (
        <ExcelExportColumn field={header} title={header} key={i} width={200} />
      ))}
    </ExcelExport>
  );
};

export default CPExcelExport;
