import localForage from "localforage";

const cpAppStorage = localForage.createInstance({
  name: "cpApp",
  driver: localForage.LOCALSTORAGE,
  version: 1.0,
  storeName: "cpAppStore",
  description: "Application Specific Data",
});

const instance = {
  setItem: cpAppStorage.setItem,
  getItem: cpAppStorage.getItem,
  removeItem: cpAppStorage.removeItem,
  clear: cpAppStorage.clear,
};

export default instance;
