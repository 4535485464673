import * as React from "react";

import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

export interface CPButtonProps extends ButtonProps {
  showLoader?: boolean;
  hideButton?: boolean;
}

const CPButton: React.FC<CPButtonProps> = ({
  showLoader,
  children,
  hideButton,
  ...rest
}) => {
  showLoader = showLoader ?? false;

  if (hideButton) {
    return null;
  }

  return showLoader ? (
    <Button type={"button"} {...rest} disabled={true} icon={""}>
      <Loader type={"infinite-spinner"} size='small' />
    </Button>
  ) : (
    <Button type={"button"} {...rest}>
      {children}
    </Button>
  );
};

export default CPButton;
