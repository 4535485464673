// @intent: Display a modal informing user to update the app (refresh, clear cache, etc)
import * as React from "react";
import { CPText } from "components/base";

import "./CPCacheBusterModalStyles.scss";
import CPModal from "../CPModal/CPModal";
import CPButton from "../CPButton/CPButton";
import { ButtonClickTracking, useCPTracking } from "analytics";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import useInterval from "hooks/common/useInterval";
import { StackLayout } from "@progress/kendo-react-layout";

interface CPCacheBusterModalProps {
  onConfirm: () => void;
  show: boolean;
}

const CPCacheBusterModal: React.FC<CPCacheBusterModalProps> = ({
  show,
  onConfirm,
}) => {
  const { trackingEventDelegate } = useCPTracking();
  const { value, start } = useInterval(0, 25);

  React.useEffect(() => {
    let mounted = true;
    if (value >= 100 && mounted) {
      onConfirm();
    }

    return () => {
      mounted = false;
    };
  }, [value, onConfirm]);

  return (
    <CPModal
      title=' New Version Available!'
      open={show}
      width={400}
      className='cp-cache-buster-modal'
      actionBtns={
        <CPButton
          disabled={value > 0}
          themeColor={"primary"}
          onClick={trackingEventDelegate(
            () => start(),
            ButtonClickTracking("Update Version")
          )}
        >
          Update
        </CPButton>
      }
    >
      {value <= 0 && (
        <StackLayout>
          <CPText align='center'>
            You are currently running on an older version, press the update
            button to be upgraded to the newest version.
          </CPText>
        </StackLayout>
      )}

      {value > 0 && (
        <StackLayout
          orientation='vertical'
          align={{ horizontal: "center" }}
          gap={20}
        >
          <CPText align='center'>
            Updating. Please do not close the browser.
          </CPText>
          <ProgressBar value={value} />
        </StackLayout>
      )}
    </CPModal>
  );
};

export default CPCacheBusterModal;
