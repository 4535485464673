// @intent: Display list of carriers assigned to factoring company
import * as React from "react";
import CPSkeletonFormLoader from "components/shared/CPSkeletonLoaders/CPSkeletonFormLoader";
import CPListView from "components/shared/CPListView/CPListView";
import useGetAssignedCarriersQuery from "features/FCompanies/api/useGetAssignedCarriersQuery";
import useUserStore from "state/stores/useUserStore";

const MyCarriersCard: React.FC = () => {
  const fcompanyId = useUserStore((user) => user.factoringCompanyExId);
  const { isLoading, assignedCarriers } =
    useGetAssignedCarriersQuery(fcompanyId);
  return (
    <CPSkeletonFormLoader show={isLoading}>
      <CPListView
        header={[
          { label: "ID", dataField: "externalId" },
          { label: "Name", dataField: "name" },
          { label: "MC Number", dataField: "mcNumber" },
          { label: "DOT Number", dataField: "dotNumber" },
        ]}
        data={assignedCarriers}
      />
    </CPSkeletonFormLoader>
  );
};

export default MyCarriersCard;
