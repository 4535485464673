// @intent: Methods/Hook to capture enter key presses.

import * as React from "react";

// Usage:
// const handleSubmit = useEnterKeySubmit(callBackFn)
// Returns the callBackFn which can be used in other places of your component
//
// Check if the enter key is press, when true, invokes the callback function
function useEnterKeySubmit(cbFn: Function) {
  React.useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event && event.key === "Enter") {
        cbFn();
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [cbFn]);

  return cbFn;
}

export default useEnterKeySubmit;
