// @intent: Provide methods for upserting carrier contact list
import http from "common/http";

import { useMutation } from "react-query";
import { ICarrierContact } from "../types";

const useUpsertCarrierContactListCommand = () => {
  return useMutation((contacts: ICarrierContact[]) => {
    return http.postAsync("CarrierContact/UpsertCarrierContactList", {
      contacts,
    });
  });
};

export default useUpsertCarrierContactListCommand;
