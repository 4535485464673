// @intent: Get user details for editing
import * as React from "react";
import { useQuery } from "react-query";
import http from "../../../common/http";
import UpsertAccount from "../types/UpsertAccount";

const useGetUserToEditQuery = () => {
  const [fetchUserId, setFetchUserId] = React.useState<number | undefined>();

  const query = useQuery(
    ["User/GetUser", fetchUserId],
    () => {
      return http.fetchAsync("User/GetUser", { userId: fetchUserId });
    },
    {
      enabled: fetchUserId != null,
    }
  );

  const mapToModel = () => {
    if (query.data) {
      const user = query.data;
      const model = UpsertAccount();
      model.id = user.userId;
      model.rolesToAdd = user.roles;
      model.firstName = user.firstname;
      model.lastName = user.lastname;
      model.accountType = user.accountType;
      model.banned = user.banned;

      model.factoringCompanyExId = user.factoringCompanyExId;
      model.companyName = user.companyName;
      model.phoneNumber = user.phoneNumber;
      model.email = user.email;

      model.assignedCarriers = user.carriers;

      return model;
    }

    return null;
  };

  const handleReset = () => {
    setFetchUserId(undefined);
    query.remove();
  };

  return {
    execute: setFetchUserId,
    reset: handleReset,
    userToEdit: mapToModel(),
    isLoading: query.isLoading,
  };
};

export default useGetUserToEditQuery;
