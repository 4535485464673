import * as React from "react";
import { Box, CPText, Container } from "../../base";
import "./CPGuestLandingLayoutStyles.scss";
import { Outlet } from "react-router-dom";
import AppNavBar from "../CPAppNavBar/CPAppNavBar";

const CPGuestLandingLayout: React.FC = () => {
  return (
    <Container className='guest-content'>
      <AppNavBar />

      <Box className='overlay'></Box>
      <Box alignItems='center' justifyContent='center' className='content'>
        <CPText
          textStyle='h3'
          color='white'
          font={"montserrat"}
          transform={"uppercase"}
          align='center'
        >
          Carrier Portal
        </CPText>
        <Outlet />
      </Box>
    </Container>
  );
};

export default CPGuestLandingLayout;
